import { useMemo } from "react";

import { useAppSelector } from "../../../../../store/hooks";
import { Sources } from "../../../../../types/Screener";
import findScreenerFirstSource from "../../../../../utils/functions/find-screener-first-source";
import DashboardScreenerStyles from "../DashboardScreener.module.css"

type Props = {
    sources: Sources
}

const DashboardScreenerListItemSourceItem: React.FC<Props> = (props) => {
    const allScreeners = useAppSelector((state) => state.allScreeners)
    const user = useAppSelector((state) => state.user)

    const firstSourceName: string | null = useMemo(() => findScreenerFirstSource(props.sources, allScreeners, user), [props.sources])
    
    if (firstSourceName !== null) {
        return (<div className={DashboardScreenerStyles.screener_input_list_item}>
            <div className={DashboardScreenerStyles.screener_input_list_logo_wrapper}>
                <img alt="Input List" src="/logos/traddle_logo_black_on_white_comp.png" className={DashboardScreenerStyles.screener_input_list_logo} />
            </div>
            <span className={DashboardScreenerStyles.screener_input_list_name}>{firstSourceName}</span>
        </div>
        )
    }

    return null
}
    
export default DashboardScreenerListItemSourceItem