import { useState } from "react";
import { useNavigate } from "react-router-dom";

import AppTopNavMiddleStyles from "../AppTopNavMiddleStyles.module.css";

const AppTopNavButtonHome: React.FC = () => {
    const [isHovered, setIsHovered] = useState(false);

    const navigate = useNavigate();

    const buttonClickHome = () => {
        navigate(`/app`);
    }
    
    return (
        <div className={AppTopNavMiddleStyles.middle_item_wrapper} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={() => buttonClickHome}>
            <div className={AppTopNavMiddleStyles.middle_item} style={{ backgroundColor: isHovered ? "rgb(var(--dl-color-backgrounds-hover1))" : "" }}>
                <span className={AppTopNavMiddleStyles.middle_item_text} style={{ color: isHovered ? "rgb(var(--dl-color-black))" : "" }}>Home</span>
            </div>
        </div>
    )
}

export default AppTopNavButtonHome