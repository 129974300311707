import { Link } from 'react-router-dom'

import { useAppSelector } from '../../../../store/hooks';
import AppTopNavLeftStyles from './AppTopNavLeftStyles.module.css'

const AppTopNavLeft: React.FC = () => {
    const user = useAppSelector((state) => state.user);

    return (
        <div className={AppTopNavLeftStyles.container_left}>
            <Link to="/app" className={AppTopNavLeftStyles.navlink}>
                <div className={AppTopNavLeftStyles.logo_container}>
                    <img
                        loading="eager"
                        src={user.userData.theme.dark ? "/logos/traddle_logo_black_transparent_comp.png" : "/logos/traddle_logo_white_transparent_comp.png"}
                        alt="Traddle Logo"
                        className={AppTopNavLeftStyles.logo_image}
                    />
                </div>
            </Link>
        </div>
    )
}

export default AppTopNavLeft