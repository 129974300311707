import { Column } from "react-table";

export interface ColumnBuilder {
    header: string;
    id: string;
    parameters?: ColumnBuilderParameter[]
}

export interface ColumnBuilderParameter {
    name: string;
    options: ColumnBuilderParameterOption[]
}

export interface ColumnBuilderParameterOption {
    name: string;
    id: string;
}

export const columnBuilderOptions: ColumnBuilder[] = [
    {
        header: "Ticker",
        id: "ticker",
    },
    {
        header: "Chart",
        id: "chart",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "1h",
                        id: "1h"
                    },
                    {
                        name: "1D",
                        id: "1D"
                    },
                    {
                        name: "1W",
                        id: "1W"
                    },
                    {
                        name: "1M",
                        id: "1M"
                    },
                    {
                        name: "1Y",
                        id: "1Y"
                    }
                ]
            }
        ]
    },
    {
        header: "Current Price",
        id: "price_current",
    },
    {
        header: "Change",
        id: "change",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "1m",
                        id: "1m"
                    },
                    {
                        name: "5m",
                        id: "5m"
                    },
                    {
                        name: "15m",
                        id: "15m"
                    },
                    {
                        name: "1h",
                        id: "1h"
                    },
                    {
                        name: "1D",
                        id: "1D"
                    },
                    {
                        name: "1W",
                        id: "1W"
                    },
                    {
                        name: "1M",
                        id: "1M"
                    },
                    {
                        name: "1Y",
                        id: "1Y"
                    }
                ]
            },
            {
                name: "Unit",
                options: [
                    {
                        name: "%",
                        id: "pct"
                    },
                    {
                        name: "$",
                        id: "dollar"
                    }
                ]
            }
        ]
    },
    {
        header: "Change From Open",
        id: "change_from_open",
        parameters: [
            {
                name: "Unit",
                options: [
                    {
                        name: "%",
                        id: "pct"
                    },
                    {
                        name: "$",
                        id: "dollar"
                    }
                ]
            }
        ]
    },
    {
        header: "Change From LDC",
        id: "change_from_ldc",
        parameters: [
            {
                name: "Unit",
                options: [
                    {
                        name: "%",
                        id: "pct"
                    },
                    {
                        name: "$",
                        id: "dollar"
                    }
                ]
            }
        ]
    },
    {
        header: "Change Pre-Market",
        id: "change_pm",
        parameters: [
            {
                name: "Unit",
                options: [
                    {
                        name: "%",
                        id: "pct"
                    },
                    {
                        name: "$",
                        id: "dollar"
                    }
                ]
            }
        ]
    },
    {
        header: "Change After-Hours",
        id: "change_ah",
        parameters: [
            {
                name: "Unit",
                options: [
                    {
                        name: "%",
                        id: "pct"
                    },
                    {
                        name: "$",
                        id: "dollar"
                    }
                ]
            }
        ]
    },
    {
        header: "Overnight Gap",
        id: "overnight_gap",
        parameters: [
            {
                name: "Unit",
                options: [
                    {
                        name: "%",
                        id: "pct"
                    },
                    {
                        name: "$",
                        id: "dollar"
                    }
                ]
            }
        ]
    },
    {
        header: "Volume",
        id: "volume",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "1m",
                        id: "1m"
                    },
                    {
                        name: "5m",
                        id: "5m"
                    },
                    {
                        name: "15m",
                        id: "15m"
                    },
                    {
                        name: "1h",
                        id: "1h"
                    },
                    {
                        name: "1D",
                        id: "1D"
                    },
                    {
                        name: "1W",
                        id: "1W"
                    },
                    {
                        name: "1M",
                        id: "1M"
                    },
                    {
                        name: "1Y",
                        id: "1Y"
                    }
                ]
            }
        ]
    },
    {
        header: "Volume Today",
        id: "volume_today",
    },
    {
        header: "Volume Pre-Market",
        id: "volume_pm",
    },
    {
        header: "Volume After-Hours",
        id: "volume_ah",
    },
    {
        header: "Average Daily Volume",
        id: "average_daily_volume",
    },
    {
        header: "Relative Volume Today",
        id: "relative_volume_today",
    },
    {
        header: "Dollar Volume Today",
        id: "dollar_volume",
    },
    {
        header: "Simple Moving Average",
        id: "sma",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "1m",
                        id: "1m"
                    },
                    {
                        name: "5m",
                        id: "5m"
                    },
                    {
                        name: "15m",
                        id: "15m"
                    },
                    {
                        name: "1h",
                        id: "1h"
                    },
                    {
                        name: "1D",
                        id: "1D"
                    },
                    {
                        name: "1W",
                        id: "1W"
                    },
                    {
                        name: "1M",
                        id: "1M"
                    },
                    {
                        name: "1Y",
                        id: "1Y"
                    }
                ]
            },
            {
                name: "Length",
                options: [
                    {
                        name: "5",
                        id: "5"
                    },
                    {
                        name: "10",
                        id: "10"
                    },
                    {
                        name: "20",
                        id: "20"
                    },
                    {
                        name: "50",
                        id: "50"
                    },
                    {
                        name: "100",
                        id: "100"
                    },
                    {
                        name: "200",
                        id: "200"
                    }
                ]
            }
        ]
    },
    {
        header: "Exponential Moving Average",
        id: "ema",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "1m",
                        id: "1m"
                    },
                    {
                        name: "5m",
                        id: "5m"
                    },
                    {
                        name: "15m",
                        id: "15m"
                    },
                    {
                        name: "1h",
                        id: "1h"
                    },
                    {
                        name: "1D",
                        id: "1D"
                    },
                    {
                        name: "1W",
                        id: "1W"
                    },
                    {
                        name: "1M",
                        id: "1M"
                    },
                    {
                        name: "1Y",
                        id: "1Y"
                    }
                ]
            },
            {
                name: "Length",
                options: [
                    {
                        name: "5",
                        id: "5"
                    },
                    {
                        name: "10",
                        id: "10"
                    },
                    {
                        name: "20",
                        id: "20"
                    },
                    {
                        name: "50",
                        id: "50"
                    },
                    {
                        name: "100",
                        id: "100"
                    },
                    {
                        name: "200",
                        id: "200"
                    }
                ]
            }
        ]
    },
    {
        header: "Relative Strength Index",
        id: "rsi",
    },
    {
        header: "VWAP",
        id: "vwap"
    },
    {
        header: "Average True Range",
        id: "atr"
    },
    {
        header: "Volatility",
        id: "volatility",
        parameters: [
            {
                name: "Length",
                options: [
                    {
                        name: "Week",
                        id: "1W"
                    },
                    {
                        name: "Month",
                        id: "1M"
                    },
                    {
                        name: "3 Months",
                        id: "3M"
                    },
                    {
                        name: "Year",
                        id: "1Y"
                    },
                ]
            }
        ]
    },
    {
        header: "Sector",
        id: "sector"
    },
    {
        header: "Industry",
        id: "industry"
    },
    {
        header: "Country",
        id: "country"
    },
    {
        header: "Market Capitalization",
        id: "market_cap"
    },
    {
        header: "Float",
        id: "free_float",
    },
    {
        header: "Shares Outstanding",
        id: "shares_outstanding",
    },
    {
        header: "Short Float",
        id: "short_float",
    },
    {
        header: "Institutional Ownership",
        id: "institutional_ownership",
    },
    {
        header: "Insider Ownership",
        id: "insider_ownership",
    },
    {
        header: "Net Cash Flow",
        id: "net_cash_flow",
        parameters: [
            {
                name: "Type",
                options: [
                    {
                        name: "Total",
                        id: "total"
                    },
                    {
                        name: "Operating",
                        id: "operating"
                    },
                    {
                        name: "Financing",
                        id: "financing"
                    },
                    {
                        name: "Investing",
                        id: "investing"
                    },
                ]
            },
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Free Cash Flow",
        id: "free_cash_flow",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Free Cash Flow Margin",
        id: "free_cash_flow_margin",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Assets",
        id: "assets",
        parameters: [
            {
                name: "Type",
                options: [
                    {
                        name: "Total",
                        id: "total"
                    },
                    {
                        name: "Fixed",
                        id: "fixed"
                    },
                    {
                        name: "Current",
                        id: "current"
                    },
                    {
                        name: "Non-Current",
                        id: "non_current"
                    },
                ]
            },
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Liabilities",
        id: "liabilities",
        parameters: [
            {
                name: "Type",
                options: [
                    {
                        name: "Total",
                        id: "total"
                    },
                    {
                        name: "Current",
                        id: "current"
                    },
                    {
                        name: "Non-Current",
                        id: "non_current"
                    },
                ]
            },
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Equity",
        id: "equity",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Book Value",
        id: "book_value",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Enterprise Value",
        id: "enterprise_value",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Return On Equity",
        id: "return_on_equity",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "QoQ",
                        id: "qoy"
                    },
                    {
                        name: "YoY",
                        id: "yoy"
                    },
                    {
                        name: "TTM",
                        id: "ttm"
                    }
                ]
            }
        ]
    },
    {
        header: "Return On Assets",
        id: "return_on_assets",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "QoQ",
                        id: "qoy"
                    },
                    {
                        name: "YoY",
                        id: "yoy"
                    },
                    {
                        name: "TTM",
                        id: "ttm"
                    }
                ]
            }
        ]
    },
    {
        header: "Total Revenue",
        id: "total_revenue",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Revenue Growth",
        id: "revenue_growth",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "QoQ",
                        id: "qoy"
                    },
                    {
                        name: "YoY",
                        id: "yoy"
                    },
                    {
                        name: "TTM",
                        id: "ttm"
                    }
                ]
            }
        ]
    },
    {
        header: "Gross Profit",
        id: "gross_profit",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Gross Margin",
        id: "gross_margin",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Net Income",
        id: "net_income",
        parameters: [
            {
                name: "Type",
                options: [
                    {
                        name: "Total",
                        id: "total"
                    },
                    {
                        name: "Current",
                        id: "current"
                    },
                    {
                        name: "Non-Current",
                        id: "non_current"
                    }
                ]
            },
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Net Margin",
        id: "net_margin",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Operating Margin",
        id: "operating_margin",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Earnings Per Share",
        id: "eps",
        parameters: [
            {
                name: "Type",
                options: [
                    {
                        name: "Basic",
                        id: "basic"
                    },
                    {
                        name: "Diluted",
                        id: "diluted"
                    }
                ]
            },
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "EPS Growth",
        id: "eps_growth",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "QoQ",
                        id: "qoy"
                    },
                    {
                        name: "YoY",
                        id: "yoy"
                    },
                    {
                        name: "TTM",
                        id: "ttm"
                    }
                ]
            }
        ]
    },
    {
        header: "Cost of Revenue",
        id: "cost_of_revenue",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Costs and Expenses",
        id: "costs_and_expenses",
        parameters: [
            {
                name: "Type",
                options: [
                    {
                        name: "Total",
                        id: "total"
                    },
                    {
                        name: "Operating",
                        id: "operating"
                    },
                    {
                        name: "Income Tax",
                        id: "income tax"
                    },
                    {
                        name: "Interest",
                        id: "interest"
                    }
                ]
            },
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Dividends Per Share",
        id: "dividends_per_share",
    },
    {
        header: "Dividend Yield",
        id: "dividend_yield",
    },
    {
        header: "Dividend Growth",
        id: "dividend_growth",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "QoQ",
                        id: "qoy"
                    },
                    {
                        name: "YoY",
                        id: "yoy"
                    },
                    {
                        name: "TTM",
                        id: "ttm"
                    }
                ]
            }
        ]
    },
    {
        header: "Price To Sales",
        id: "price_to_sales",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Price To Earnings",
        id: "price_to_earnings",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Price To Free Cash Flow",
        id: "price_to_fcf",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    },
    {
        header: "Price To Book",
        id: "price_to_book",
        parameters: [
            {
                name: "Timeframe",
                options: [
                    {
                        name: "Quarter",
                        id: "q"
                    },
                    {
                        name: "Year",
                        id: "y"
                    }
                ]
            }
        ]
    }
]

const createColumn = (header: string, id: string, overrides?: Partial<Column<object>>): Column<object> => ({
    filter: "between",
    minWidth: 60,
    width: 150,
    maxWidth: 400,
    ...overrides,
    Header: header,
    accessor: id
})

export const getDefaultColumns = ():Column<object>[] => [
    createColumn("Ticker", "ticker", { minWidth: 70, width: 200 }),
    createColumn("Sector", "sector"),
    createColumn("Industry", "industry"),
    createColumn("Country", "country"),
    createColumn("Free Float", "free_float"),
    createColumn("Price To Earnings (Year)", "price_to_earnings-y"),
    createColumn("Change from Close (%)", "change_from_ldc-pct"),
    createColumn("Change (1W, %)", "change-1W-pct"),
    createColumn("Current Price", "price_current"),
    createColumn("Volume Today", "volume_today"),
    createColumn("Dummy", "dummy", { minWidth: 26, width: 26, maxWidth: 26 })
]

export default createColumn