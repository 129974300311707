import { useEffect, useRef, useState } from 'react';

import { setPopupElement } from '../../../../../store/features/management';
import { changeScreenerData, saveScreener } from '../../../../../store/features/screener';
import { useAppDispatch } from '../../../../../store/hooks';
import { Screener } from '../../../../../types/Screener';
import useClickOutsidePopup from '../../../../../utils/hooks/use-click-outside-popup';
import DashboardScreenerStyles from '../DashboardScreener.module.css';

type Props = {
    screener: Screener
}

const DashboardScreenerListItemSettingsName: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    
    const [nameInput, setNameInput] = useState(props.screener.name);
    const [nameInputError, setNameInputError] = useState("");

    const refClickOutside = useClickOutsidePopup();

    const nameInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        nameInputRef.current?.focus();
        nameInputRef.current?.setSelectionRange(nameInputRef.current?.value.length, nameInputRef.current?.value.length)
    }, [])

    const nameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.trim().length > 150) {
            setNameInputError("Maximum number of characters reached.");
        } else {
            setNameInputError("");
            setNameInput(event.target.value);
        }
    }

    const saveName = () => {
        if (nameInput.trim().length < 3) {
            setNameInputError("Please enter at least 3 characters.");
        } else {
            dispatch(changeScreenerData({ ...props.screener, name: nameInput.trim() }));
            dispatch(saveScreener());
            dispatch(setPopupElement(null));
        }
    }

    return (
        <div className="app_popup_wrapper_middle" ref={refClickOutside}>
            <div className={DashboardScreenerStyles.delete_container}>
                <span className={DashboardScreenerStyles.delete_header}>Edit Screener Name</span>
                <div className={DashboardScreenerStyles.delete_confirmation_wrapper}>
                    <input
                        type="text"
                        className={DashboardScreenerStyles.delete_confirmation_input}
                        style={{ width: "400px", borderColor: nameInputError !== "" ? "rgb(var(--dl-color-colors-red-error))" : "" }}
                        placeholder="Edit name..."
                        onChange={(event) => nameInputChange(event)}
                        value={nameInput}
                        ref={nameInputRef}
                    />
                    {nameInputError && (
                        <span className={DashboardScreenerStyles.delete_confirmation_text} style={{ color: "rgb(var(--dl-color-colors-red-error))" }}>{nameInputError}</span>
                    )}
                </div>
                <div className={DashboardScreenerStyles.delete_state_button_row}>
                    <div className={DashboardScreenerStyles.delete_state_button} onClick={() => dispatch(setPopupElement(null))}>
                        <span className={DashboardScreenerStyles.delete_state_button_text}>Cancel</span>
                    </div>
                    <div className={`${DashboardScreenerStyles.delete_state_button} ${DashboardScreenerStyles.delete_state_button_delete}`} onClick={() => saveName()}>
                        <span className={DashboardScreenerStyles.delete_state_button_text} style={{ color: "rgb(var(--dl-color-white-always))" }}>Save</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardScreenerListItemSettingsName