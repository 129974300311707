import { TableColumnPresets } from "../../types/User";
import createColumn from "./table-columns";

const tableColumnPresets: TableColumnPresets = {
    "performance": {
        name: "Performance",
        columns: [
            createColumn("Change from Open (%)", "change_from_open-pct"),
            createColumn("Change from Close (%)", "change_from_ldc-pct"),
            createColumn("Change (1W, %)", "change-1W-pct"),
            createColumn("Change (1M, %)", "change-1M-pct"),
            createColumn("Change (1Y, %)", "change-1Y-pct"),
            createColumn("Change Pre-Market (%)", "change_pm-pct"),
            createColumn("Change After-Hours (%)", "change_ah-pct"),
            createColumn("Overnight Gap (%)", "overnight_gap-pct"),
            createColumn("Average Daily Volume", "average_daily_volume"),
            createColumn("Volume Today", "volume_today"),
            createColumn("Current Price", "price_current"),
        ]
    },
    "technical": {
        name: "Technical",
        columns: [
            createColumn("Average True Range", "atr"),
            createColumn("Volatility (Week)", "volatility-1W"),
            createColumn("Volatility (Month)", "volatility-1M"),
            createColumn("Volatility (3 Months)", "volatility-3M"),
            createColumn("Volatility (Year)", "volatility-1Y"),
            createColumn("Simple Moving Average (1D, 20)", "sma-1D-20"),
            createColumn("Simple Moving Average (1D, 50)", "sma-1D-50"),
            createColumn("Simple Moving Average (1D, 200)", "sma-1D-200"),
            createColumn("Relative Strength Index", "rsi"),
            createColumn("Change from Close (%)", "change_from_ldc-pct"),
            createColumn("Change from Open (%)", "change_from_open-pct"),
            createColumn("Overnight Gap (%)", "overnight_gap-pct"),
            createColumn("Average Daily Volume", "average_daily_volume"),
            createColumn("Volume Today", "volume_today"),
            createColumn("Current Price", "price_current"),
        ]
    },
    "financial": {
        name: "Financial",
        columns: [
            createColumn("Market Capitalization", "market_cap"),
            createColumn("Dividends Per Share", "dividends_per_share"),
            createColumn("Return On Equity (YoY)", "return_on_equity-yoy"),
            createColumn("Return On Assets (YoY)", "return_on_assets-yoy"),
            createColumn("Gross Margin (Year)", "gross_margin-y"),
            createColumn("Net Margin (Year)", "net_margin-y"),
            createColumn("Operating Margin (Year)", "operating_margin-y"),
            createColumn("Free Cash Flow Margin (Year)", "free_cash_flow_margin-y"),
            createColumn("Change from Close (%)", "change_from_ldc-pct"),
            createColumn("Volume Today", "volume_today"),
            createColumn("Current Price", "price_current"),
        ]
    },
    "valuation": {
        name: "Valuation",
        columns: [
            createColumn("Market Capitalization", "market_cap"),
            createColumn("Price To Earnings (Year)", "price_to_earnings-y"),
            createColumn("Price To Sales (Year)", "price_to_sales-y"),
            createColumn("Price To Book (Year)", "price_to_book-y"),
            createColumn("Price To Free Cash Flow (Year)", "price_to_fcf-y"),
            createColumn("Change from Close (%)", "change_from_ldc-pct"),
            createColumn("Volume Today", "volume_today"),
            createColumn("Current Price", "price_current"),
        ]
    },
    "dividend": {
        name: "Dividend",
        columns: [
            createColumn("Dividends Per Share", "dividends_per_share"),
            createColumn("Dividends Yield", "dividend_yield"),
            createColumn("Dividend Growth (YoY)", "dividend_growth-yoy"),
            createColumn("Dividend Growth (TTM)", "dividend_growth-ttm"),
            createColumn("Change from Close (%)", "change_from_ldc-pct"),
            createColumn("Average Daily Volume", "average_daily_volume"),
            createColumn("Volume Today", "volume_today"),
            createColumn("Current Price", "price_current"),
        ]
    },
    "ownership": {
        name: "Ownership",
        columns: [
            createColumn("Market Capitalization", "market_cap"),
            createColumn("Shares Outstanding", "shares_outstanding"),
            createColumn("Free Float", "free_float"),
            createColumn("Short Float", "short_float"),
            createColumn("Institutional Ownership", "institutional_ownership"),
            createColumn("Insider Ownership", "insider_ownership"),
            createColumn("Change from Close (%)", "change_from_ldc-pct"),
            createColumn("Average Daily Volume", "average_daily_volume"),
            createColumn("Volume Today", "volume_today"),
            createColumn("Current Price", "price_current"),
        ]
    }
}

export default tableColumnPresets