/* eslint-disable */

import { useLayoutEffect } from 'react';
import { setWindowWidth } from '../../store/features/management';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const WindowListener = () => {
    const management = useAppSelector((state) => state.management)
    const dispatch = useAppDispatch();

    useLayoutEffect(() => {
        function updateSize() {
            dispatch(setWindowWidth(window.innerWidth))
        }
        window.addEventListener('resize', updateSize);
        
        return () => window.removeEventListener('resize', updateSize);
    }, [management]);

    return <></>
};

export default WindowListener