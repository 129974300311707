import { AnyAction, CombinedState, combineReducers, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import allDashboardsReducer, { AllDashboardsState } from './features/allDashboards'
import allScreenersReducer, { AllScreenersState } from './features/allScreeners'
import dashboardReducer, { DashboardState } from './features/dashboard'
import managementReducer, { ManagementState } from './features/management'
import screenerReducer, { DashboardScreenerStyles } from './features/screener'
import userReducer, { UserState } from './features/user'

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ["management"]
}

const combinedReducer = combineReducers({
    user: userReducer,
    screener: screenerReducer,
    allScreeners: allScreenersReducer,
    dashboard: dashboardReducer,
    allDashboards: allDashboardsReducer,
    management: managementReducer
})

const rootReducer = (state: CombinedState<{
  user: UserState;
  screener: DashboardScreenerStyles;
  allScreeners: AllScreenersState;
  dashboard: DashboardState;
  allDashboards: AllDashboardsState;
  management: ManagementState;
}> | undefined, action: AnyAction) => {
    if (action.type === 'user/logout') {
        state = undefined;
    }
    return combinedReducer(state, action);
};

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer)

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }).concat(thunk),
    enhancers: [sentryReduxEnhancer]
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)