import { WidgetConnectionColorName } from "../../../../../types/Dashboard";
import TableWidgetContent from "../../../../../utils/components/dashboard-widgets/table/table-widget";
import DashboardWidgetStyles from "./DashboardWidgetStyles.module.css"
import GridWidgetSettings from "./settings/grid-widget-settings";

type Props = {
    id: string;
    connectionName: WidgetConnectionColorName;
    isHovered: boolean;
}

const WidgetTable: React.FC<Props> = (props) => (
    <div className={DashboardWidgetStyles.container}>
        <GridWidgetSettings id={props.id} connectionName={props.connectionName} visible={props.isHovered} />
        <span className={DashboardWidgetStyles.title}>Table</span>
        <TableWidgetContent id={props.id} />
    </div>
)

export default WidgetTable