import { ReactNode } from "react";
import { createPortal } from "react-dom";

import { useAppSelector } from "../../../../../../../../store/hooks";
import { EconomicCalendarSettings, Widget } from "../../../../../../../../types/Dashboard";
import usePopperPopup from "../../../../../../../hooks/use-popper-popup";
import WidgetTopRowStyles from "../../../../../WidgetTopRowStyles.module.css"
import EconomyButtonCategoryItem from "./economy-button-category-item";

const economicCalendarCategories = [
    "Interest rate",
    "Inflation",
    "Bonds",
    "Consumer",
    "GDP",
    "Government",
    "Housing",
    "Labor",
    "Markets",
    "Money",
    "Prices",
    "Trade",
    "Business"
]

type Props = {
    id: string;
    width: number;
}

const EconomyButtonCategory: React.FC<Props> = (props) => {
    const widget: Widget = useAppSelector((state) => state.dashboard.data.widgets.find((w) => w.id === props.id))!

    const { setButton, setPopup, buttonRef, styles, attributes, isOpen, setIsOpen } = usePopperPopup({ offsetY: 4 });

    const settings = widget.settings as EconomicCalendarSettings

    const { categories } = settings.economy

    const getButtonText = (): ReactNode => {
        if (categories.length === 0) {
            if (props.width > 480) {
                return <>All categories</>
            }
            return <>All</>
        }
        if (props.width > 480) {
            return <>{categories.length} selected</>
        }
        return <>{categories.length}</>
    }

    return (
        <div className="popper_wrapper shrink" ref={buttonRef}>
            <div className={WidgetTopRowStyles.dropdown_button} style={{ width: props.width > 480 ? "156px" : "83px", borderColor: categories.length !== 0 || isOpen ? "rgb(var(--dl-color-primary-100))" : "", backgroundColor: categories.length !== 0 ? "rgba(var(--dl-color-primary-100), 0.09)" : "" }} ref={setButton} onClick={() => setIsOpen(!isOpen)}>
                <div className={WidgetTopRowStyles.dropdown_button_left}>
                    <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.dropdown_button_icon} style={{ width: "18px", height: "18px", fill: categories.length !== 0 ? "rgb(var(--dl-color-primary-100))" : "" }}>
                        <path d="M341.333 298.667h554.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-554.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM341.333 554.667h554.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-554.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM341.333 810.667h554.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-554.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM170.667 256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667 19.115 42.667 42.667 42.667 42.667-19.115 42.667-42.667zM170.667 512c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667 19.115 42.667 42.667 42.667 42.667-19.115 42.667-42.667zM170.667 768c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667 19.115 42.667 42.667 42.667 42.667-19.115 42.667-42.667z" />
                    </svg>
                    <span className={WidgetTopRowStyles.dropdown_button_text} style={{ color: categories.length !== 0 ? "rgb(var(--dl-color-primary-100))" : "" }}>{getButtonText()}</span>
                </div>
                <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.dropdown_button_icon} style={{ width: "18px", height: "18px", fill: categories.length !== 0 ? "rgb(var(--dl-color-primary-100))" : "", transform: isOpen ? "rotate(180deg)" : "" }}>
                    <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                </svg>
            </div>
            {isOpen ? createPortal(
                <div className={WidgetTopRowStyles.popup_wrapper} ref={setPopup} style={styles.popper} {...attributes.popper}>
                    <div className={WidgetTopRowStyles.popup_wrapper_scroll}>
                        <EconomyButtonCategoryItem id={props.id} settings={settings} categories={categories} name="All categories" />
                        {economicCalendarCategories.sort((a, b) => {
                            const fa = a.toLowerCase();
                            const fb = b.toLowerCase();
                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        }).map((category) => <EconomyButtonCategoryItem key={category} id={props.id} settings={settings} categories={categories} name={category} />)}
                    </div>
                </div>,
                document.body
            ) : null}
        </div>
    )
}

export default EconomyButtonCategory