import { useMemo } from "react";

import { changeScreenerData, saveScreener } from "../../../../../../store/features/screener";
import { useAppDispatch } from "../../../../../../store/hooks";
import { Screener } from "../../../../../../types/Screener";
import { Watchlist } from "../../../../../../types/User"
import BootstrapTooltip from "../../../../../../utils/components/tooltip/bootstrap-tooltip";
import DashboardScreenerSourceStyles from "./DashboardScreenerSource.module.css"

type Props = {
    screener: Screener;
    watchlist: Watchlist;
    search: string
}

const DashboardScreenerSourceTabWatchlistsItem: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const sources = useMemo(() => ({ ...props.screener.sources }), [props.screener.sources])

    const indices = useMemo(() => ([...sources.indices]), [sources])
    const screeners = useMemo(() => ([...sources.screeners]), [sources])
    const watchlists = useMemo(() => ([...sources.watchlists]), [sources])

    const index = useMemo(() => (watchlists.findIndex((w) => w === props.watchlist.id)), [sources, watchlists, props.watchlist])

    const notAllowed = useMemo(() => (indices.length + screeners.length + watchlists.length === 1), [indices, screeners, watchlists])

    const addWatchlist = () => {
        if (index === -1) {
            watchlists.push(props.watchlist.id);
            sources.watchlists = watchlists;
        } else {
            if (notAllowed) {
                return
            }
            watchlists.splice(index, 1)
            sources.watchlists = watchlists
        }
        dispatch(changeScreenerData({
            ...props.screener,
            sources
        }));
        dispatch(saveScreener());
    }

    if (props.watchlist.name.toLowerCase().replace(" ", "").includes(props.search.toLowerCase().replace(" ", ""))) {
        return (
            <BootstrapTooltip title="You have to select at least one item" followCursor={true} disableHoverListener={!notAllowed || index === -1}>
                <div className={DashboardScreenerSourceStyles.stocklist_row} style={{ cursor: notAllowed && index > -1 ? "not-allowed" : "default", backgroundColor: index > -1 ? "rgba(var(--dl-color-colors-green-dark), 0.18)" : "" }} onClick={() => addWatchlist()}>
                    <div className={DashboardScreenerSourceStyles.stocklist_row_left} style={{ width: "100%", flexShrink: "1" }}>
                        <div className={DashboardScreenerSourceStyles.stocklist_row_logo_wrapper}>
                            <img alt="Input List" src="/logos/traddle_logo_black_on_white_comp.png" className={DashboardScreenerSourceStyles.stocklist_row_logo} />
                        </div>
                        <span className={DashboardScreenerSourceStyles.stocklist_row_text}>{props.watchlist.name}</span>
                    </div>
                    <div className={DashboardScreenerSourceStyles.symbol_count}>
                        <span className={DashboardScreenerSourceStyles.symbol_count_text_gray}>Symbols</span>
                        <span className={DashboardScreenerSourceStyles.stocklist_row_text}>{props.watchlist.tickers.length}</span>
                    </div>
                </div>
            </BootstrapTooltip>
        )
    }
    return null
}

export default DashboardScreenerSourceTabWatchlistsItem