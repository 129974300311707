import DashboardWidgetStyles from "../DashboardWidgetStyles.module.css";

const GridWidgetSettingsOptionSaveTemplate: React.FC = () => (
    <div className={DashboardWidgetStyles.settings_popup_item}>
        <div className={DashboardWidgetStyles.settings_popup_item_left}>
            <svg viewBox="0 0 877.7142857142857 1024" className={DashboardWidgetStyles.settings_popup_item_icon}>
                <path d="M219.429 877.714h438.857v-219.429h-438.857v219.429zM731.429 877.714h73.143v-512c0-10.857-9.714-34.286-17.143-41.714l-160.571-160.571c-8-8-30.286-17.143-41.714-17.143v237.714c0 30.286-24.571 54.857-54.857 54.857h-329.143c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-73.143v731.429h73.143v-237.714c0-30.286 24.571-54.857 54.857-54.857h475.429c30.286 0 54.857 24.571 54.857 54.857v237.714zM512 347.429v-182.857c0-9.714-8.571-18.286-18.286-18.286h-109.714c-9.714 0-18.286 8.571-18.286 18.286v182.857c0 9.714 8.571 18.286 18.286 18.286h109.714c9.714 0 18.286-8.571 18.286-18.286zM877.714 365.714v530.286c0 30.286-24.571 54.857-54.857 54.857h-768c-30.286 0-54.857-24.571-54.857-54.857v-768c0-30.286 24.571-54.857 54.857-54.857h530.286c30.286 0 72 17.143 93.714 38.857l160 160c21.714 21.714 38.857 63.429 38.857 93.714z"/>
            </svg>
            <p className={DashboardWidgetStyles.settings_popup_item_text}>Save widget as template</p>
        </div>
        <svg viewBox="0 0 1024 1024" className={DashboardWidgetStyles.settings_popup_item_icon_arrow}>
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z" />
        </svg>
    </div>
)

export default GridWidgetSettingsOptionSaveTemplate