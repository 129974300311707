import React from "react";

import WidgetStyles from "../../../../WidgetStyles.module.css"
import TableDividendsHeader from "./table-dividends-header";
import TableDividendsList from "./table-dividends-list";
import TableDividendsSeparator from "./table-dividends-separator";

type Props = {
    tickerSearch: string;
}

const TableDividends: React.FC<Props> = (props) => (
    <div className={WidgetStyles.simple_table_wrapper}>
        <TableDividendsHeader />
        <TableDividendsSeparator />
        <TableDividendsList tickerSearch={props.tickerSearch} />
    </div>
)

export default TableDividends