import { HeaderGroup, TableState } from "react-table"

import WidgetTopRowStyles from "../../WidgetTopRowStyles.module.css"
import TableTopRowButtonColumns from "./columns/table-top-row-button-columns"
import TableTopRowButtonFilter from "./filter/table-top-row-button-filter"
import TableTopRowButtonSettings from "./settings/table-top-row-button-settings"
import TableTopRowButtonSources from "./sources/table-top-row-button-sources"
import TableTopRowButtonResetWidths from "./table-top-row-button-reset-widths"

type Props = {
    id: string;
    headerGroup: HeaderGroup<object>;
    tableState: TableState<object>;
}

const TableTopRow: React.FC<Props> = (props) => (
    <div className={WidgetTopRowStyles.container}>
        <div className={WidgetTopRowStyles.group_container}>
            <TableTopRowButtonSources id={props.id} />
            <TableTopRowButtonColumns id={props.id} />
            <TableTopRowButtonFilter id={props.id} headerGroup={props.headerGroup} tableState={props.tableState} />
        </div>
        <div className={WidgetTopRowStyles.group_container}>
            <TableTopRowButtonResetWidths id={props.id} />
            <TableTopRowButtonSettings id={props.id} />
        </div>
    </div>
)

export default TableTopRow