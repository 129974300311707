import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import { CountryMap, getAllCountries, sortCountriesByName } from '../../../../../constants/country-map';
import ConditionRow from '../../../ConditionRow.module.css';
import CriteriaDropdownSelection from '../../criteria-dropdown-selection';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        countries: [],
    },
    setParameters: () => {}
}

const allCountries: CountryMap = getAllCountries()

const CriteriaParameterCountry: React.FC<Props> = (props) => {
    const countries = useMemo(() => props.parameters.countries ? props.parameters.countries : defaultProps.parameters.countries!, [props.parameters.countries])

    const setCountries = (newCountries: string[]) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, countries: newCountries }, defaultProps.parameters))
    }

    return (
        <>
            <span className={ConditionRow.criteria_text}>is</span>
            <CriteriaDropdownSelection dropdownOptions={sortCountriesByName(allCountries)} transformType="countries" withSearch={true} values={countries} setValues={setCountries} />
        </>
    )
}

export default CriteriaParameterCountry