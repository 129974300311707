import { useEffect } from "react";

import { createNewScreener, setScreener } from "../../../../../store/features/screener";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import sortScreenersByName from "../../../../../types/utils/sort-screeners-by-name"
import useResizeRightScreenerList from "../../../../../utils/hooks/use-resize-right-screener-list";
import DashboardScreenerStyles from "../DashboardScreener.module.css"
import DashboardScreenerListItem from "./dashboard-screener-list-item"

const DashboardScreenerList: React.FC = () => {
    const dispatch = useAppDispatch();

    const allScreeners = useAppSelector((state) => state.allScreeners)
    const screener = useAppSelector((state) => state.screener)

    const { resizableRef, resizeHandlerRef } = useResizeRightScreenerList();

    useEffect(() => {
        if (screener.data.id === "" && Object.keys(allScreeners.screeners).length > 0) {
            const key = sortScreenersByName(allScreeners.screeners)[0]
            const sc = allScreeners.screeners[key]
            dispatch(setScreener(sc.id))
        }
    }, [allScreeners])

    const newScreenerClick = async () => {
        await dispatch(createNewScreener({
            name: `Screener ${Object.keys(allScreeners.screeners).length + 1}`,
        })).unwrap();
    }

    return (
        <div className={DashboardScreenerStyles.screener_list_container}>
            <div className={DashboardScreenerStyles.screener_list_wrapper} ref={resizableRef}>
                <div className={DashboardScreenerStyles.add_screener_button} onClick={() => newScreenerClick()}>
                    <span className={DashboardScreenerStyles.add_screener_button_text}>Add new Screener</span>
                    <div className={DashboardScreenerStyles.add_button_icon_wrapper}>
                        <svg viewBox="0 0 1024 1024" className={DashboardScreenerStyles.add_button_icon}>
                            <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z" />
                        </svg>
                    </div>
                </div>
                <div className={DashboardScreenerStyles.screener_list}>
                    {sortScreenersByName(allScreeners.screeners).map((screenerId ) => {
                        const sc = allScreeners.screeners[screenerId]
                        return <DashboardScreenerListItem screener={sc} isSelected={screenerId === screener.data.id} key={screenerId} />
                    })}
                </div>
            </div>
            <div className={DashboardScreenerStyles.screener_list_separator} ref={resizeHandlerRef} />
        </div>
    )
}
    
export default DashboardScreenerList