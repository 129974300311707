import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import CriteriaInputTimeframe from '../../criteria-input-timeframe';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        timeframe: "1 minute",
    },
    setParameters: () => {},
}

const CriteriaParameterRelativeVolumePeriod: React.FC<Props> = (props) => {
    const timeframe = useMemo(() => props.parameters.timeframe ? props.parameters.timeframe : defaultProps.parameters.timeframe!, [props.parameters.timeframe])

    const setTimeframe = (newTimeframe: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, timeframe: newTimeframe }, defaultProps.parameters))
    }

    return (
        <>
            <CriteriaInputTimeframe timeframe={timeframe} setTimeframe={setTimeframe} />
        </>
    )
}

export default CriteriaParameterRelativeVolumePeriod