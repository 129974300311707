import React from "react";

import IconStyle from "./IconStyle.module.css"

type Props = {
    colored: boolean;
}

const LayoutIcon: React.FC<Props> = (props) => (
    <div className={IconStyle.layout_icon}>
        <div className={IconStyle.layout_icon_horizontal_bar} style={{ borderColor: props.colored ? "rgb(var(--dl-color-primary-100))" : "" }} />
        <div className={IconStyle.layout_icon_vertical_bars}>
            <div className={IconStyle.layout_icon_vertical_bar} style={{ borderColor: props.colored ? "rgb(var(--dl-color-primary-100))" : "" }} />
            <div className={IconStyle.layout_icon_vertical_bar} style={{ borderColor: props.colored ? "rgb(var(--dl-color-primary-100))" : "" }} />
            <div className={IconStyle.layout_icon_vertical_bar} style={{ borderColor: props.colored ? "rgb(var(--dl-color-primary-100))" : "" }} />
        </div>
    </div>
)

export default LayoutIcon