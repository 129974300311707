import React from "react";

import WidgetStyles from "../../../../WidgetStyles.module.css"
import TableEarningsRowItem from "./table-earnings-row-item";

type Props = {
    tickerSearch: string;
}

const TableEarningsList: React.FC<Props> = (props) => (
    <div className={WidgetStyles.simple_table_wrapper_content}>
        <div className={WidgetStyles.simple_table_wrapper_scroll}>
            <TableEarningsRowItem tickerSearch={props.tickerSearch} />
            <TableEarningsRowItem tickerSearch={props.tickerSearch} />
            <TableEarningsRowItem tickerSearch={props.tickerSearch} />
        </div>
    </div>
)

export default TableEarningsList