import { getAllCountries } from "../constants/country-map";
import { comparisons } from "../constants/indicator-comparisons";

const allCountries = getAllCountries()

const getDropdownDisplayValue = (transformType: string, id: string): string => {
    if (transformType === "") {
        return id
    } if (transformType === "comparisons") {
        return comparisons[id]
    } if (transformType === "countries") {
        return allCountries[id]
    }
    return id
}

export default getDropdownDisplayValue