/* eslint-disable @typescript-eslint/no-explicit-any */
import { Cell } from "react-table";

import { TableSettings } from "../../../../../types/Dashboard";
import CellChange from "./cells/cell-change";
import CellDummy from "./cells/cell-dummy";
import CellLargeNumber from "./cells/cell-large-number";
import CellPercent from "./cells/cell-percent";
import CellSmallNumber from "./cells/cell-small-number";
import CellText from "./cells/cell-text";
import CellTicker from "./cells/cell-ticker";

type Props = {
    columnId: string;
    cell: Cell<object>;
    width: number;
    settings: TableSettings;
}

const TableCellSwitch: React.FC<Props> = (props) => {
    switch(props.columnId.split("-")[0]) {
    case "ticker": return <CellTicker cell={props.cell} width={props.width} settings={props.settings} />;
    case "price_current": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "change": return <CellChange width={props.width} cell={props.cell} />;
    case "change_from_open": return <CellChange width={props.width} cell={props.cell} />;
    case "change_from_ldc": return <CellChange width={props.width} cell={props.cell} />;
    case "change_pm": return <CellChange width={props.width} cell={props.cell} />;
    case "change_ah": return <CellChange width={props.width} cell={props.cell} />;
    case "overnight_gap": return <CellChange width={props.width} cell={props.cell} />;
    case "relative_volume_today": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "sma": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "ema": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "rsi": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "vwap": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "atr": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "volatility": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "sector": return <CellText width={props.width} cell={props.cell} />;
    case "industry": return <CellText width={props.width} cell={props.cell} />;
    case "country": return <CellText width={props.width} cell={props.cell} />;
    case "short_float": return <CellPercent width={props.width} cell={props.cell} />;
    case "institutional_ownership": return <CellPercent width={props.width} cell={props.cell} />;
    case "insider_ownership": return <CellPercent width={props.width} cell={props.cell} />;
    case "free_cash_flow_margin": return <CellPercent width={props.width} cell={props.cell} />;
    case "return_on_equity": return <CellPercent width={props.width} cell={props.cell} />;
    case "return_on_assets": return <CellPercent width={props.width} cell={props.cell} />;
    case "revenue_growth": return <CellPercent width={props.width} cell={props.cell} />;
    case "gross_margin": return <CellPercent width={props.width} cell={props.cell} />;
    case "net_margin": return <CellPercent width={props.width} cell={props.cell} />;
    case "operating_margin": return <CellPercent width={props.width} cell={props.cell} />;
    case "eps": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "eps_growth": return <CellPercent width={props.width} cell={props.cell} />;
    case "dividends_per_share": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "dividend_yield": return <CellPercent width={props.width} cell={props.cell} />;
    case "dividend_growth": return <CellPercent width={props.width} cell={props.cell} />;
    case "price_to_sales": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "price_to_earnings": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "price_to_fcf": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "price_to_book": return <CellSmallNumber width={props.width} cell={props.cell} />;
    case "dummy": return <CellDummy />;
    default: return <CellLargeNumber width={props.width} cell={props.cell} />;
    }
}

export default TableCellSwitch