/* eslint-disable @typescript-eslint/no-unused-expressions, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Screeners } from "../../types/Screener";

const namespace = "allScreeners"

export type AllScreenersState = {
    loading: boolean;
    initialized: boolean;
    errorMessage: string;
    screeners: Screeners;
}

const initialStateValue: AllScreenersState = {
    loading: false,
    initialized: false,
    errorMessage: "",
    screeners: {}
}

export const allScreenersSlice = createSlice({
    name: namespace,
    initialState: initialStateValue,
    reducers: {
        setScreeners: (state, action: PayloadAction<Screeners>) => {
            state.loading = false
            state.initialized = true
            state.errorMessage = ""
            state.screeners = action.payload
        },
    },
})

export const { setScreeners } = allScreenersSlice.actions;

export default allScreenersSlice.reducer;