import './index.css'
import './DateTimePicker.css'

import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ReactDOM from 'react-dom/client'

import App from './App';
import reportWebVitals from './reportWebVitals';

dayjs.extend(utc)
dayjs.extend(timezone)

Sentry.init({
    dsn: "https://42944091dcae4878957361fe2e44d08c@o4505594814529536.ingest.sentry.io/4505594820034560",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["https://traddle.io"],
        }),
        new Sentry.Replay()
    ],
    normalizeDepth: 10,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(<App />)

reportWebVitals()
