import { HeaderGroup } from "react-table";

import TableSortIcon from "../table-sort-icon";
import TableTableStyles from "../TableTableStyles.module.css";

type Props = {
    width: number;
    column: HeaderGroup<object>;
}

const HeaderCellStandard: React.FC<Props> = (props) => (
    <div className={TableTableStyles.header_cell_wrapper} style={{ padding: props.width > 140 ? "0px 16px" : "", gap: props.width > 140 ? "8px" : "" }}>
        <span className={TableTableStyles.header_text}>{props.column.render('Header')}</span>
        <TableSortIcon column={props.column} />
    </div>
)


export default HeaderCellStandard