import { useMemo } from "react";

import { changeDashboardData } from "../../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../../types/changeHandlers/change-dashboard-widget";
import { TableSettings, Widget } from "../../../../../../types/Dashboard";
import { Watchlist } from "../../../../../../types/User"
import WidgetTableSource from "./WidgetTableSource.module.css"

type Props = {
    widgetId: string;
    watchlist: Watchlist;
    search: string
}

const TableTopRowButtonSourceTabWatchlistsItem: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const dashboard = useAppSelector((state) => state.dashboard)

    const widget: Widget = useMemo(() => dashboard.data.widgets.find((w) => w.id === props.widgetId)!, [dashboard.data.widgets, props.widgetId])

    const settings = useMemo(() => widget.settings as TableSettings, [widget])

    const sources = useMemo(() => ({ ...settings.sources }), [settings.sources])

    const watchlists = useMemo(() => ([...sources.watchlists]), [sources])

    const index = useMemo(() => (watchlists.findIndex((w) => w === props.watchlist.id)), [sources, watchlists, props.watchlist])

    const addWatchlist = () => {
        if (index === -1) {
            watchlists.push(props.watchlist.id);
            sources.watchlists = watchlists;
        } else {
            watchlists.splice(index, 1)
            sources.watchlists = watchlists
        }
        const newSettings = {
            ...settings,
            sources
        }
        const newWidgets = changeDashboardWidgetData(props.widgetId, dashboard.data.widgets, { settings: newSettings })
        dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
    }

    if (props.watchlist.name.toLowerCase().replace(" ", "").includes(props.search.toLowerCase().replace(" ", ""))) {
        return (
            <div className={WidgetTableSource.stocklist_row} style={{ backgroundColor: index > -1 ? "rgba(var(--dl-color-colors-green-dark), 0.18)" : "" }} onClick={() => addWatchlist()}>
                <div className={WidgetTableSource.stocklist_row_left} style={{ width: "100%", flexShrink: "1" }}>
                    <div className={WidgetTableSource.stocklist_row_logo_wrapper}>
                        <img alt="Input List" src="/logos/traddle_logo_black_on_white_comp.png" className={WidgetTableSource.stocklist_row_logo} />
                    </div>
                    <span className={WidgetTableSource.stocklist_row_text}>{props.watchlist.name}</span>
                </div>
                <div className={WidgetTableSource.symbol_count}>
                    <span className={WidgetTableSource.symbol_count_text_gray}>Symbols</span>
                    <span className={WidgetTableSource.stocklist_row_text}>{props.watchlist.tickers.length}</span>
                </div>
            </div>
        )
    }
    return null
}

export default TableTopRowButtonSourceTabWatchlistsItem