import { useState } from 'react';

import ConditionRow from "../ConditionRow.module.css"

type Props = {
    keywords: string[];
    setKeywords: (keywords: string[]) => void;
    position: number;
}

const CriteriaInputKeywordItem: React.FC<Props> = (props) => {
    const [hovered, setHovered] = useState(false);

    const deleteKeyword = () => {
        const newKeywords = [...props.keywords]
        newKeywords.splice(props.position, 1)
        props.setKeywords(newKeywords)
    }

    return (
        <>
            <div
                className={ConditionRow.keyword_input_item_wrapper}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <span className={ConditionRow.keyword_input_item_text}>{props.keywords[props.position]}</span>
                {hovered && (
                    <div className={ConditionRow.keyword_input_item_delete} onClick={() => deleteKeyword()}>
                        <svg viewBox="0 0 1024 1024" className={ConditionRow.keyword_input_item_delete_icon}>
                            <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                        </svg>
                    </div>
                )}
            </div>
            <span style={{ fontSize: "9px" }}>or</span>
        </>
    )
}

export default CriteriaInputKeywordItem