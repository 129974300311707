import "./ResetTimeDateTimePicker.css";

import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useMemo, useRef, useState } from 'react';

import { changeScreenerData, saveScreener } from '../../../../../../../store/features/screener';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { State } from '../../../../../../../types/Screener';
import sortManualResetTimes from "../../../../../../../utils/functions/sort-manual-reset-times";
import BuilderSettingsResetTimeStyles from './BuilderSettingsResetTime.module.css';

type Props = {
    selectedState: State;
    statePosition: number;
    position: number;
}

const BuilderSettingsResetTimeManual: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const screener = useAppSelector((state) => state.screener);
    const user = useAppSelector((state) => state.user);

    const dateRef = useRef(null);
    const timeRef = useRef(null);

    const [isHovered, setIsHovered] = useState(false);

    const currentResetTime = useMemo(() => {
        let resetTime = props.selectedState.manualResets[props.position]
        if (resetTime.timezone === user.userData.timezone) {
            return resetTime
        }
        const resetTimeOld = dayjs(`${resetTime.date}T${resetTime.time}`, "MMMM D, YYYYTHH:mm").tz(resetTime.timezone, true)
        const resetTimeNew = resetTimeOld.tz(user.userData.timezone)
        resetTime = {
            date: resetTimeNew.format("MMMM D, YYYY") !== "Invalid Date" ? resetTimeNew.format("MMMM D, YYYY") : "",
            time: resetTimeNew.format("HH:mm") !== "Invalid Date" ? resetTimeNew.format("HH:mm") : "",
            timezone: user.userData.timezone
        }
        const newState = _.cloneDeep(props.selectedState);
        newState.manualResets.splice(props.position, 1, resetTime);
        newState.manualResets = [...newState.manualResets].sort((a, b) => sortManualResetTimes(a, b));
        const states = _.cloneDeep(screener.data.states);
        states.splice(props.statePosition, 1, newState);
        dispatch(changeScreenerData({
            ...screener.data,
            states,
        }));
        dispatch(saveScreener());
        return resetTime
    }, [props.selectedState.manualResets, props.position])

    const dateChange = (newDate: string) => {
        if (newDate !== currentResetTime.date) {
            const newState = _.cloneDeep(props.selectedState);
            newState.manualResets.splice(props.position, 1, {
                ...currentResetTime,
                date: newDate
            });
            newState.manualResets = [...newState.manualResets].sort((a, b) => sortManualResetTimes(a, b));
            const states = _.cloneDeep(screener.data.states);
            states.splice(props.statePosition, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
        const element: HTMLDivElement = dateRef.current!;
        element.blur();
    }
  
    const timeChange = (newTime: string) => {
        if (newTime !== currentResetTime.time) {
            const newState = _.cloneDeep(props.selectedState);
            newState.manualResets.splice(props.position, 1, {
                ...currentResetTime,
                time: newTime
            });
            newState.manualResets = [...newState.manualResets].sort((a, b) => sortManualResetTimes(a, b))
            const states = _.cloneDeep(screener.data.states);
            states.splice(props.statePosition, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
        const element: HTMLDivElement = timeRef.current!;
        element.blur();
    }
  
    const deleteManualResetTime = () => {
        const newState = _.cloneDeep(props.selectedState);
        newState.manualResets.splice(props.position, 1);
        const states = _.cloneDeep(screener.data.states);
        states.splice(props.statePosition, 1, newState);
        dispatch(changeScreenerData({
            ...screener.data,
            states,
        }));
        dispatch(saveScreener());
    }

    return (
        <div className={BuilderSettingsResetTimeStyles.recurring_picker_row} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className={BuilderSettingsResetTimeStyles.recurring_picker}>
                <div className="reset_time_date_picker" style={{ borderBottomLeftRadius: props.position === props.selectedState.manualResets.length - 1 ? "6px" : "" }}>
                    <DatePicker
                        format="MMMM D, YYYY"
                        allowClear={false}
                        popupClassName="date-picker"
                        value={currentResetTime.date !== "" ? dayjs(currentResetTime.date, "MMMM D, YYYY") : undefined}
                        onChange={(date, dateString) => {
                            dateChange(dateString)
                        }}
                        placeholder="Set Date"
                        ref={dateRef}
                        disabledDate={(current) => {
                            const d = new Date();
                            d.setDate(d.getDate() - 1);
                            return current && current.valueOf() < d.valueOf();
                        }}
                    />
                </div>
                <div className="reset_time_picker" style={{ borderWidth: isHovered ? "0px 1px 0px 0px" : "0px", marginRight: isHovered ? "28px" : "" }}>
                    <TimePicker
                        format="HH:mm"
                        allowClear={false}
                        popupClassName="date-picker"
                        value={currentResetTime.time !== "" ? dayjs(currentResetTime.time, "HH:mm") : undefined}
                        onChange={(time, timeString) => {
                            timeChange(timeString)
                        }}
                        placeholder="Set Time"
                        ref={timeRef}
                        disabledTime={(current) => {
                            const selectedDate = currentResetTime.date !== "" ? dayjs(currentResetTime.date, "MMMM D, YYYY") : undefined
                            if (selectedDate !== undefined) {
                                if (current.format('MMMM D, YYYY') === selectedDate.format('MMMM D, YYYY')) {
                                    return {
                                        disabledHours: () => Array.from(Array(23).keys()).filter((h) => h < current.get("h")),
                                        disabledMinutes: (selectedHour) => {
                                            if (selectedHour === current.get("h")) {
                                                return Array.from(Array(69).keys()).filter((m) => m - 1 < current.get("m"))
                                            }
                                            return []
                                        }
                                    }
                                }
                            }
                            return {}
                        }}
                    />
                </div>
            </div>
            <svg viewBox="0 0 804.5714285714286 1024" className={BuilderSettingsResetTimeStyles.delete_icon} style={{ opacity: isHovered ? "1" : "0" }} onClick={() => deleteManualResetTime()}>
                <path d="M292.571 420.571v329.143c0 10.286-8 18.286-18.286 18.286h-36.571c-10.286 0-18.286-8-18.286-18.286v-329.143c0-10.286 8-18.286 18.286-18.286h36.571c10.286 0 18.286 8 18.286 18.286zM438.857 420.571v329.143c0 10.286-8 18.286-18.286 18.286h-36.571c-10.286 0-18.286-8-18.286-18.286v-329.143c0-10.286 8-18.286 18.286-18.286h36.571c10.286 0 18.286 8 18.286 18.286zM585.143 420.571v329.143c0 10.286-8 18.286-18.286 18.286h-36.571c-10.286 0-18.286-8-18.286-18.286v-329.143c0-10.286 8-18.286 18.286-18.286h36.571c10.286 0 18.286 8 18.286 18.286zM658.286 834.286v-541.714h-512v541.714c0 27.429 15.429 43.429 18.286 43.429h475.429c2.857 0 18.286-16 18.286-43.429zM274.286 219.429h256l-27.429-66.857c-1.714-2.286-6.857-5.714-9.714-6.286h-181.143c-3.429 0.571-8 4-9.714 6.286zM804.571 237.714v36.571c0 10.286-8 18.286-18.286 18.286h-54.857v541.714c0 62.857-41.143 116.571-91.429 116.571h-475.429c-50.286 0-91.429-51.429-91.429-114.286v-544h-54.857c-10.286 0-18.286-8-18.286-18.286v-36.571c0-10.286 8-18.286 18.286-18.286h176.571l40-95.429c11.429-28 45.714-50.857 76-50.857h182.857c30.286 0 64.571 22.857 76 50.857l40 95.429h176.571c10.286 0 18.286 8 18.286 18.286z" />
            </svg>
        </div>
    )
}

export default BuilderSettingsResetTimeManual