import React from "react";

import { useAppSelector } from "../../../../../../store/hooks";
import { Screener } from "../../../../../../types/Screener";
import DashboardScreenerSourceTabWatchlistsItem from "./dashboard-screener-source-tab-watchlists-item";
import DashboardScreenerSourceStyles from "./DashboardScreenerSource.module.css";

type Props = {
    screener: Screener;
    search: string;
}

const DashboardScreenerSourceTabWatchlists: React.FC<Props> = (props) => {
    const watchlists = useAppSelector((state) => state.user.userData.watchlists)

    return (
        <div className={DashboardScreenerSourceStyles.stocklist_wrapper}>
            <div className={DashboardScreenerSourceStyles.dropdown_wrapper}>
                <div className={DashboardScreenerSourceStyles.dropdown_button}>
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerSourceStyles.dropdown_button_arrow_icon}>
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z" />
                    </svg>
                    <span className={DashboardScreenerSourceStyles.dropdown_button_text}>All watchlists</span>
                </div>
            </div>
            <div className={DashboardScreenerSourceStyles.stocklist_list}>
                <div className={DashboardScreenerSourceStyles.stocklist_list_scroll}>
                    {watchlists.sort((a, b) => {
                        if (a < b) {
                            return -1;
                        }
                        if (a > b) {
                            return 1;
                        }
                        return 0;
                    }).map((watchlist, index) => (
                        <DashboardScreenerSourceTabWatchlistsItem screener={props.screener} watchlist={watchlist} search={props.search} key={index}/>
                    ))}
                </div>
            </div>
        </div>
    )
}
    
export default DashboardScreenerSourceTabWatchlists