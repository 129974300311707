const formatLargeNumber = (number: number) => {
    return Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: 1
    }).format(number);
}

export const reverseFormatLargeNumber = (strNumber: string, oldValue: string, min: number, max: number) => {
    if (strNumber === "") {
        return strNumber
    }

    const lastChar = strNumber.toUpperCase().trim().slice(-1)
    let newNumber: number | undefined = undefined
    
    if (isNaN(parseInt(lastChar))) {
        if (lastChar === "K") {
            newNumber = Math.round(parseFloat(strNumber.slice(0, -1)) * 10) / 10 * 1000
        } else if (lastChar === "M") {
            newNumber = Math.round(parseFloat(strNumber.slice(0, -1)) * 10) / 10 * 1000000
        } else if (lastChar === "B") {
            newNumber = Math.round(parseFloat(strNumber.slice(0, -1)) * 10) / 10 * 1000000000
        } else {
            newNumber = Math.round(parseFloat(strNumber.trim()))
        }
    } else {
        newNumber = Math.round(parseFloat(strNumber.trim()))
    }
    
    newNumber = Math.min(newNumber, max)
    newNumber = Math.max(newNumber, min)
    
    return newNumber.toString() !== "NaN" ? newNumber.toString() : oldValue
}

export default formatLargeNumber