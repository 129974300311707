import { useEffect, useRef } from 'react'

import { changeUserData, saveUser } from '../../store/features/user';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import layoutSizes from '../constants/layout-sizes';

const useResizeTopScreener = (): {resizableRef: React.MutableRefObject<HTMLDivElement | null>, resizeHandlerRef: React.MutableRefObject<HTMLDivElement | null>} => {
    const dashboardScreenerHeight = useAppSelector((state) => state.user.userData.dashboardScreenerHeight)
    const dispatch = useAppDispatch();

    const resizableRef = useRef<HTMLDivElement | null>(null);
    const resizeHandlerRef = useRef<HTMLDivElement | null>(null);

    const setHeight = (height: number) => {
        const newHeight = Math.min(height, window.innerHeight - layoutSizes.topNavHeight)
        dispatch(changeUserData({
            dashboardScreenerHeight: newHeight,
        }));
        dispatch(saveUser());
    }

    useEffect(() => {
        const resizeableEle = resizableRef.current;

        if (!resizeableEle) {
            return
        }

        let height = dashboardScreenerHeight;
        let heightCompare = height;
        let y = 0;
        
        resizeableEle.style.height = `${height}px`;
        
        const onMouseMoveTopResize = (event: MouseEvent) => {
            const dy = event.clientY - y;
            if (!(resizeableEle.getBoundingClientRect().y <= layoutSizes.topNavHeight && dy < 0) && !(resizeableEle.clientHeight <= layoutSizes.dashboardScreenerHeightMin && dy > 0)) {
                height -= dy;
                if (height > window.innerHeight - layoutSizes.topNavHeight) {
                    height = window.innerHeight - layoutSizes.topNavHeight
                } else if (height < layoutSizes.dashboardScreenerHeightMin) {
                    height = layoutSizes.dashboardScreenerHeightMin
                }
                y = event.clientY;
                resizeableEle.style.height = `${height}px`;
            }
        };
    
        const onMouseUpTopResize = () => {
            document.body.classList.remove("noselect");
            const appTopNavEle = document.getElementById("app-top-nav");
            if (appTopNavEle) appTopNavEle.classList.remove("pointer_events_none");
            document.removeEventListener("mousemove", onMouseMoveTopResize);
            if (heightCompare !== height) {
                setHeight(height)
                heightCompare = height
            }
        };
    
        const onMouseDownTopResize = (event: MouseEvent) => {
            height = Number(resizeableEle.style.height.replace("px", ""))
            heightCompare = height
            document.body.classList.add("noselect");
            const appTopNavEle = document.getElementById("app-top-nav");
            if (appTopNavEle) appTopNavEle.classList.add("pointer_events_none");
            y = event.clientY;
            const newStyles = window.getComputedStyle(resizeableEle);
            resizeableEle.style.bottom = newStyles.bottom;
            resizeableEle.style.top = "";
            document.addEventListener("mousemove", onMouseMoveTopResize);
            document.addEventListener("mouseup", onMouseUpTopResize);
        };

        const resizerTop = resizeHandlerRef.current;
        
        if (!resizerTop) {
            return
        }

        resizerTop.addEventListener("mousedown", onMouseDownTopResize);
        
        return () => {
            resizerTop.removeEventListener("mousedown", onMouseDownTopResize);
        };
    }, [])

    return { resizableRef, resizeHandlerRef };
};

export default useResizeTopScreener