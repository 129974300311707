import { useEffect, useState } from 'react'

import { useAppSelector } from '../../store/hooks';

type Props = {
    checkUser?: boolean;
    checkScreener?: boolean;
    checkAllScreeners?: boolean;
    checkDashboard?: boolean;
    checkAllDashboards?: boolean;
}

const useIsLoading = (props: Props): boolean => {
    const user = useAppSelector((state) => state.user)
    const screener = useAppSelector((state) => state.screener)
    const allScreeners = useAppSelector((state) => state.allScreeners)
    const dashboard = useAppSelector((state) => state.dashboard)
    const allDashboards = useAppSelector((state) => state.allDashboards)
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!isLoading) {
            return
        }
        let loading = false;
        if(user.loading && props.checkUser) {
            loading = true;
        }
        if(screener.loading && props.checkScreener) {
            loading = true;
        }
        if(!allScreeners.initialized && props.checkAllScreeners) {
            loading = true;
        }
        if(dashboard.loading && props.checkDashboard) {
            loading = true;
        }
        if(!allDashboards.initialized && props.checkAllDashboards) {
            loading = true;
        }
        if (!loading) {
            setIsLoading(loading);
        }
    }, [user, screener, allScreeners, dashboard, allDashboards]);

    return isLoading;
};

export default useIsLoading