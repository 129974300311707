
import { Skeleton } from "@mui/material"

import { userInitialStateValue } from "../../../store/features/user"
import { Avatar } from "../../../types/Avatar"
import getIcons from "../icons/icons"
import ProfileAvatarStyles from "./ProfileAvatarStyles.module.css"

type Props = {
    size: number,
    avatar?: Avatar,
    displayName?: string;
    loading?: boolean;
}

const defaultProps = {
    size: 28,
    avatar: userInitialStateValue.userData.avatar,
    displayName: "?",
    loading: false
}

const ProfileAvatar: React.FC<Props> = (props) => {
    const { size, avatar, displayName, loading } = { ...defaultProps, ...props }

    const profileIcons = getIcons(Math.round(size * 0.57), "currentColor")

    if (typeof avatar === "undefined" || typeof displayName === "undefined") {
        return (
            <div className={ProfileAvatarStyles.avatar_wrapper} style={{ width: size, height: size }}>
                <Skeleton variant="circular" width={size} height={size} sx={{ bgcolor: "rgb(var(--dl-color-backgrounds-skeleton))" }} />
            </div>
        )
    }

    return (
        <div className={ProfileAvatarStyles.avatar_wrapper} style={{ width: size, height: size }}>
            {(!loading && avatar.type === "icon") && (
                <div className={ProfileAvatarStyles.avatar_image} style={{ width: size, height: size, backgroundColor: `rgb(${avatar.color})` }}>
                    {avatar.iconName === "Letter" ? (
                        <span>{displayName[0]}</span>
                    ) : (
                        profileIcons.find(Icon => Icon.key === avatar.iconName)
                    )}
                </div>
            )}
            {(!loading && avatar.type === "image") && (
                <img
                    src={avatar.imageUrl}
                    alt="Profile"
                    className={ProfileAvatarStyles.avatar_image}
                    style={{ width: size, height: size }}
                />
            )}
            {loading && (
                <Skeleton variant="circular" width={size} height={size} sx={{ bgcolor: "rgb(var(--dl-color-backgrounds-skeleton))" }} />
            )}
        </div>
    )
}

export default ProfileAvatar