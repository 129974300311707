import { setPopupElement } from "../../../../../store/features/management";
import { useAppDispatch } from "../../../../../store/hooks";
import LayoutIcon from "../../../icons/layout-icon";
import BootstrapTooltip from "../../../tooltip/bootstrap-tooltip";
import TableTopRowButtonColumnsPopup from "../top-row/columns/table-top-row-button-columns-popup";
import TableTableStyles from "./TableTableStyles.module.css";

type Props = {
    id: string;
}

const ColumnButton: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    return (
        <BootstrapTooltip title="Column setup">
            <div className={TableTableStyles.layout_button} onClick={() => dispatch(setPopupElement(<TableTopRowButtonColumnsPopup id={props.id} />))}>
                <LayoutIcon colored={false} />
            </div>
        </BootstrapTooltip>
    )
}

export default ColumnButton