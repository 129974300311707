import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import usePopperPopup from '../../../../../hooks/use-popper-popup';
import BootstrapTooltip from '../../../../tooltip/bootstrap-tooltip';
import ConditionRow from "../../../ConditionRow.module.css"
import CriteriaInputKeywords from '../../criteria-input-keywords';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
    titleWidth: string;
}

const defaultProps: Props = {
    parameters: {
        keywords: [],
    },
    setParameters: () => {},
    titleWidth: "100px",
}

const CriteriaParameterLatestNews: React.FC<Props> = (props) => {
    const keywords = useMemo(() => props.parameters.keywords ? props.parameters.keywords : defaultProps.parameters.keywords!, [props.parameters.keywords])

    const { setButton, setPopup, buttonRef: wrapperRef, styles, attributes, isOpen: dropdownOpen, setIsOpen: setDropdownOpen } = usePopperPopup({ offsetY: 4 })

    const setKeywords = (newKeywords: string[]) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, keywords: newKeywords }, defaultProps.parameters))
    }

    return (
        <div className={ConditionRow.criteria_dropdown_wrapper} ref={wrapperRef}>
            <div
                className={ConditionRow.criteria_dropdown_button}
                style={{ borderColor: dropdownOpen ? "rgb(var(--dl-color-primary-100))" : "", padding: "0px 8px" }}
                onClick={() => {setDropdownOpen(!dropdownOpen)}} ref={setButton}
            >
                <span className={ConditionRow.criteria_dropdown_button_text}>With Keywords</span>
            </div>
            {dropdownOpen ? (
                <div className={`${ConditionRow.criteria_dropdown_menu} ${ConditionRow.dropdown_parameter} ${ConditionRow.dropdown_news}`} ref={setPopup} style={styles.popper} {...attributes.popper}>
                    <div className={ConditionRow.criteria_dropdown_menu_scroll_wrapper} style={{ gap: "8px", overflow: "visible" }}>
                        <div className={ConditionRow.criteria_dropdown_parameter_title_row}>
                            <p className={ConditionRow.criteria_dropdown_parameter_title}>News</p>
                            <BootstrapTooltip title="Indicator Help">
                                <svg viewBox="0 0 1024 1024" className={ConditionRow.criteria_dropdown_indicator_help_icon}>
                                    <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                                </svg>
                            </BootstrapTooltip>
                        </div>
                        <div className={ConditionRow.criteria_popup_input}>
                            <p className={ConditionRow.criteria_popup_input_title} style={{ width: props.titleWidth }}>With Keywords</p>
                            <CriteriaInputKeywords keywords={keywords} setKeywords={setKeywords} />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default CriteriaParameterLatestNews