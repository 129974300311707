/* eslint-disable */

import { useEffect } from 'react';

import { useAppSelector } from '../../store/hooks';
import setThemeColors from '../../utils/functions/get-theme-colors';
import dayjs from 'dayjs';

const UserDataListener: React.FC = () => {
    const user = useAppSelector((state) => state.user)

    useEffect(() => {
        setThemeColors({dark: user.userData.theme.dark});
    }, [user.userData.theme]);

    useEffect(() => {
        dayjs.tz.setDefault(user.userData.timezone)
    }, [user.userData.timezone]);

    return <></>
};

export default UserDataListener