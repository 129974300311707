interface ContinentMap {
    [key: string]: CountryMap;
}

export interface CountryMap {
    [key: string]: string;
}

export const allContinents: ContinentMap = {
    "North America": {
        "ca": "Canada",
        "us": "USA",
    },
    "Europe": {
        "at": "Austria",
        "be": "Belgium",
        "ch": "Switzerland",
        "cy": "Cyprus",
        "cz": "Czech Republic",
        "de": "Germany",
        "dk": "Denmark",
        "ee": "Estonia",
        "es": "Spain",
        "fi": "Finland",
        "fr": "France",
        "gb": "United Kingdom",
        "gr": "Greece",
        "hu": "Hungary",
        "ie": "Ireland",
        "is": "Iceland",
        "it": "Italy",
        "lu": "Luxembourg",
        "lv": "Latvia",
        "lt": "Lithuania",
        "mc": "Monaco",
        "mt": "Malta",
        "nl": "Netherlands",
        "no": "Norway",
        "pl": "Poland",
        "pt": "Portugal",
        "ro": "Romania",
        "rs": "Serbia",
        "ru": "Russian Federation",
        "se": "Sweden",
        "sk": "Slovakia",
        "ua": "Ukraine"
    },
    "Middle East / Africa": {
        "ae": "United Arab Emirates",
        "il": "Israel",
        "kz": "Kazakhstan",
        "tr": "Turkey",
        "za": "South Africa"
    },
    "Mexico / South America": {
        "ar": "Argentina",
        "bm": "Bermuda",
        "br": "Brazil",
        "bs": "Bahamas",
        "cl": "Chile",
        "co": "Colombia",
        "cr": "Costa Rica",
        "ky": "Cayman Islands",
        "mx": "Mexico",
        "pa": "Panama",
        "pe": "Peru",
        "uy": "Uruguay",
        "ve": "Venezuela"
    },
    "Asia / Pacific": {
        "au": "Australia",
        "cn": "China",
        "hk": "Hong Kong",
        "id": "Indonesia",
        "in": "India",
        "jp": "Japan",
        "kr": "South Korea",
        "my": "Malaysia",
        "nz": "New Zealand",
        "ph": "Philippines",
        "sg": "Singapore",
        "tw": "Taiwan"
    }    
}

export const getAllCountries = (): CountryMap => {
    const allCountries: CountryMap = {};
    Object.keys(allContinents).forEach((continentId) => {
        const continent = allContinents[continentId]
        Object.keys(continent).forEach((countryId) => {
            allCountries[countryId] = continent[countryId]
        })
    })
    return allCountries
}

export const sortCountriesByName = (countries: CountryMap): string[] => Object.keys(countries).sort((a, b) => {
    const fa = countries[a].toLowerCase();
    const fb = countries[b].toLowerCase();
    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
})