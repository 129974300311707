import PopUp from "../../../pages/app/PopUp.module.css"
import { FilterAlertCondition, FilterAlertConditions } from "../../../types/FiltersAlerts"
import { CriteriaRow, State } from "../../../types/Screener"
import { getComparisonsOrdered } from "../../constants/indicator-comparisons"
import {
    IconIndicators,
    IconNews,
    IconPrice, IconVolume,
} from "../icons/custom-icons"
import ConditionRowIndicatorsATR from "./rows/indicators/indicators-atr"
import ConditionRowIndicatorsBollingerBands from "./rows/indicators/indicators-bollinger-bands"
import ConditionRowIndicatorsEMA from "./rows/indicators/indicators-ema"
import ConditionRowIndicatorsMACD from "./rows/indicators/indicators-macd"
import ConditionRowIndicatorsRSI from "./rows/indicators/indicators-rsi"
import ConditionRowIndicatorsSMA from "./rows/indicators/indicators-sma"
import ConditionRowIndicatorsStochastic from "./rows/indicators/indicators-stochastic"
import ConditionRowIndicatorsVolatility from "./rows/indicators/indicators-volatility"
import ConditionRowIndicatorsVWAP from "./rows/indicators/indicators-vwap"
import ConditionRowNewsChangeSinceNews from "./rows/news/news-change-since-news"
import ConditionRowNewsNewNews from "./rows/news/news-new-news"
import ConditionRowNewsVolumeSinceNews from "./rows/news/news-volume-since-news"
import ConditionRowPriceChange from "./rows/price/price-change"
import ConditionRowPriceChangeAH from "./rows/price/price-change-ah"
import ConditionRowPriceChangeFromLDC from "./rows/price/price-change-from-ldc"
import ConditionRowPriceChangeFromOpen from "./rows/price/price-change-from-open"
import ConditionRowPriceChangePM from "./rows/price/price-change-pm"
import ConditionRowPriceCurrent from "./rows/price/price-current"
import ConditionRowPriceLastFullCandle from "./rows/price/price-last-full-candle"
import ConditionRowPriceNewHigh from "./rows/price/price-new-high"
import ConditionRowPriceNewLow from "./rows/price/price-new-low"
import ConditionRowPriceOvernightGap from "./rows/price/price-overnight-gap"
import ConditionRowVolumeAH from "./rows/volume/volume-ah"
import ConditionRowVolumeAverageVolume from "./rows/volume/volume-average-volume"
import ConditionRowVolumeCandle from "./rows/volume/volume-candle"
import ConditionRowVolumeDollarVolume from "./rows/volume/volume-dollar-volume"
import ConditionRowVolumePM from "./rows/volume/volume-pm"
import ConditionRowVolumeRelativeVolumePeriod from "./rows/volume/volume-relative-volume-period"
import ConditionRowVolumeRelativeVolumeToday from "./rows/volume/volume-relative-volume-today"
import ConditionRowVolumeToday from "./rows/volume/volume-today"

const getAlertConditions = (state: State, row: CriteriaRow): FilterAlertConditions => {
    const allConditions: FilterAlertConditions = {
        price: {
            name: "Price",
            icon:
                <svg viewBox="0 0 583.4605714285714 1024" className={PopUp.sidebar_top_item_icon}>
                    <path d="M558.857 677.143c0 116.571-83.429 208.571-204.571 228.571v100c0 10.286-8 18.286-18.286 18.286h-77.143c-9.714 0-18.286-8-18.286-18.286v-100c-133.714-18.857-206.857-98.857-209.714-102.286-5.714-6.857-6.286-16.571-1.143-23.429l58.857-77.143c2.857-4 8-6.286 13.143-6.857s10.286 1.143 13.714 5.143c1.143 0.571 81.143 77.143 182.286 77.143 56 0 116.571-29.714 116.571-94.286 0-54.857-67.429-81.714-144.571-112.571-102.857-40.571-230.857-92-230.857-235.429 0-105.143 82.286-192 201.714-214.857v-102.857c0-10.286 8.571-18.286 18.286-18.286h77.143c10.286 0 18.286 8 18.286 18.286v100.571c116 13.143 177.714 76 180 78.286 5.714 6.286 6.857 14.857 2.857 21.714l-46.286 83.429c-2.857 5.143-7.429 8.571-13.143 9.143-5.714 1.143-10.857-0.571-15.429-4-0.571-0.571-69.714-61.714-155.429-61.714-72.571 0-122.857 36-122.857 88 0 60.571 69.714 87.429 150.857 118.857 105.143 40.571 224 86.857 224 224.571z" />
                </svg>,
            color: "116, 94, 238",
            conditionsList: [
                {
                    id: "price-current",
                    name: "Current Price",
                    description: "The current share price in $ updated every second.",
                    icon: <IconPrice />,
                    rowElement: <ConditionRowPriceCurrent
                        id="price-current"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-value",
                            "price-last-full-candle",
                            "indicators-bollinger-bands",
                            "indicators-sma",
                            "indicators-ema",
                            "indicators-vwap",
                            "price-pm-ohlc",
                            "price-ah-ohlc",
                        ])}
                    />
                },
                {
                    id: "price-last-full-candle",
                    name: "Last Full Candle",
                    description: "The open/high/low/close price of the last candle of a specific timeframe.",
                    icon: <IconPrice />,
                    rowElement: <ConditionRowPriceLastFullCandle
                        id="price-last-full-candle"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-value",
                            "price-current",
                            "indicators-bollinger-bands",
                            "indicators-sma",
                            "indicators-ema",
                            "indicators-vwap",
                            "price-pm-ohlc",
                            "price-ah-ohlc",
                        ])}
                    />
                },
                {
                    id: "price-change",
                    name: "Price Change",
                    description: "The price change over a specific period of time.",
                    icon: <IconPrice />,
                    rowElement: <ConditionRowPriceChange
                        id="price-change"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-change",
                            "percent-change",
                            "indicators-atr",
                            "indicators-volatility",
                            "price-change-ah",
                            "price-change-from-open",
                            "price-change-from-ldc",
                            "price-change-pm",
                            "price-overnight-gap"
                        ])}
                    />
                },
                {
                    id: "price-change-from-open",
                    name: "Price Change from Open",
                    description: "The price change from today's open.",
                    icon: <IconPrice />,
                    rowElement: <ConditionRowPriceChangeFromOpen
                        id="price-change-from-open"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-change",
                            "percent-change",
                            "indicators-atr",
                            "indicators-volatility",
                            "price-change",
                            "price-change-ah",
                            "price-change-from-ldc",
                            "price-change-pm",
                            "price-overnight-gap"
                        ])}
                    />
                },
                {
                    id: "price-change-from-ldc",
                    name: "Price Change from Last Day's Close",
                    description: "The price change from the prior trading day's closing price.",
                    icon: <IconPrice />,
                    rowElement: <ConditionRowPriceChangeFromLDC
                        id="price-change-from-ldc"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-change",
                            "percent-change",
                            "indicators-atr",
                            "indicators-volatility",
                            "price-change",
                            "price-change-ah",
                            "price-change-from-open",
                            "price-change-pm",
                            "price-overnight-gap"
                        ])}
                    />
                },
                {
                    id: "price-change-pm",
                    name: "Price Change Pre-Market",
                    description: "The price change during pre-market trading.",
                    icon: <IconPrice />,
                    rowElement: <ConditionRowPriceChangePM
                        id="price-change-pm"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-change",
                            "percent-change",
                            "indicators-atr",
                            "indicators-volatility",
                            "price-change",
                            "price-change-ah",
                            "price-change-from-open",
                            "price-change-from-ldc",
                            "price-overnight-gap"
                        ])}
                    />
                },
                {
                    id: "price-change-ah",
                    name: "Price Change After-Hours",
                    description: "The price change during after-hours trading.",
                    icon: <IconPrice />,
                    rowElement: <ConditionRowPriceChangeAH
                        id="price-change-ah"
                        state={state}
                        row={row}
                        type="alert"
                        
                        comparisons={getComparisonsOrdered([
                            "dollar-change",
                            "percent-change",
                            "indicators-atr",
                            "indicators-volatility",
                            "price-change",
                            "price-change-from-open",
                            "price-change-from-ldc",
                            "price-change-pm",
                            "price-overnight-gap"
                        ])}
                    />
                },
                {
                    id: "price-overnight-gap",
                    name: "Overnight Gap",
                    description: "The price change over night from prior day's close to today's open.",
                    icon: <IconPrice />,
                    rowElement: <ConditionRowPriceOvernightGap
                        id="price-overnight-gap"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-change",
                            "percent-change",
                            "indicators-atr",
                            "indicators-volatility",
                            "price-change",
                            "price-change-ah",
                            "price-change-from-open",
                            "price-change-from-ldc",
                            "price-change-pm"
                        ])}
                    />
                },
                {
                    id: "price-new-high",
                    name: "New High",
                    description: "The price makes a new high compared to a historic time window.",
                    icon: <IconPrice />,
                    rowElement: <ConditionRowPriceNewHigh
                        id="price-new-high"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={[
                            "time-range"
                        ]}
                    />
                },
                {
                    id: "price-new-low",
                    name: "New Low",
                    description: "The price makes a new low compared to a historic time window.",
                    icon: <IconPrice />,
                    rowElement: <ConditionRowPriceNewLow
                        id="price-new-low"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={[
                            "time-range"
                        ]}
                    />
                }
            ]
        },
        volume: {
            name: "Volume",
            icon:
                <svg viewBox="0 0 1024 1024" className={PopUp.sidebar_top_item_icon}>
                    <path d="M0 832h1024v128h-1024zM128 576h128v192h-128zM320 320h128v448h-128zM512 512h128v256h-128zM704 128h128v640h-128z" />
                </svg>,
            color: "235, 132, 19",
            conditionsList: [
                {
                    id: "volume-candle",
                    name: "Candle Volume",
                    description: "The absolute volume over a specific period of time.",
                    icon: <IconVolume />,
                    rowElement: <ConditionRowVolumeCandle
                        id="volume-candle"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "number",
                            "volume-average-volume",
                            "volume-pm",
                            "volume-ah",
                            "volume-today"
                        ])}
                    />
                },
                {
                    id: "volume-today",
                    name: "Volume Today",
                    description: "The absolute volume of the current trading day.",
                    icon: <IconVolume />,
                    rowElement: <ConditionRowVolumeToday
                        id="volume-today"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "number",
                            "volume-average-volume",
                            "volume-candle"
                        ])}
                    />
                },
                {
                    id: "volume-pm",
                    name: "Volume Pre-Market",
                    description: "The absolute volume of pre-market trading.",
                    icon: <IconVolume />,
                    rowElement: <ConditionRowVolumePM
                        id="volume-pm"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "number",
                            "volume-average-volume",
                            "volume-candle",
                            "volume-ah"
                        ])}
                    />
                },
                {
                    id: "volume-ah",
                    name: "Volume After-Hours",
                    description: "The absolute volume of after-hours trading.",
                    icon: <IconVolume />,
                    rowElement: <ConditionRowVolumeAH
                        id="volume-ah"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "number",
                            "volume-average-volume",
                            "volume-candle",
                            "volume-pm"
                        ])}
                    />
                },
                {
                    id: "volume-relative-volume-period",
                    name: "Relative Volume",
                    description: "The volume as a multiple of a simple moving average of the volume.",
                    icon: <IconVolume />,
                    rowElement: <ConditionRowVolumeRelativeVolumePeriod
                        id="volume-relative-volume-period"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "ratio"
                        ])}
                    />
                },
                {
                    id: "volume-relative-volume-today",
                    name: "Relative Volume Today",
                    description: "Today's volume as a multiple compared to the prior trading days.",
                    icon: <IconVolume />,
                    rowElement: <ConditionRowVolumeRelativeVolumeToday
                        id="volume-relative-volume-today"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "ratio"
                        ])}
                    />
                },
                {
                    id: "volume-average-volume",
                    name: "Average Volume",
                    description: "The average volume over a specific period of time.",
                    icon: <IconVolume />,
                    rowElement: <ConditionRowVolumeAverageVolume
                        id="volume-average-volume"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "number",
                            "volume-candle",
                            "volume-pm",
                            "volume-ah",
                            "volume-today"
                        ])}
                    />
                },
                {
                    id: "volume-dollar-volume",
                    name: "Dollar Volume",
                    description: "The volume multiplied by the price over a specific period of time.",
                    icon: <IconVolume />,
                    rowElement: <ConditionRowVolumeDollarVolume
                        id="volume-dollar-volume"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "number"
                        ])}
                    />
                },
            ]
        },
        indicators: {
            name: "Indicators",
            icon:
                <svg viewBox="0 0 1024 1024" className={PopUp.sidebar_top_item_icon}>
                    <path d="M128 896h896v128h-1024v-1024h128zM288 832c-53.020 0-96-42.98-96-96s42.98-96 96-96c2.828 0 5.622 0.148 8.388 0.386l103.192-171.986c-9.84-15.070-15.58-33.062-15.58-52.402 0-53.020 42.98-96 96-96s96 42.98 96 96c0 19.342-5.74 37.332-15.58 52.402l103.192 171.986c2.766-0.238 5.56-0.386 8.388-0.386 2.136 0 4.248 0.094 6.35 0.23l170.356-298.122c-10.536-15.408-16.706-34.036-16.706-54.11 0-53.020 42.98-96 96-96s96 42.98 96 96c0 53.020-42.98 96-96 96-2.14 0-4.248-0.094-6.35-0.232l-170.356 298.124c10.536 15.406 16.706 34.036 16.706 54.11 0 53.020-42.98 96-96 96s-96-42.98-96-96c0-19.34 5.74-37.332 15.578-52.402l-103.19-171.984c-2.766 0.238-5.56 0.386-8.388 0.386s-5.622-0.146-8.388-0.386l-103.192 171.986c9.84 15.068 15.58 33.060 15.58 52.4 0 53.020-42.98 96-96 96z" />
                </svg>,
            color: "53, 114, 250",
            conditionsList: [
                {
                    id: "indicators-sma",
                    name: "Simple Moving Average (SMA)",
                    description: "The average price over a specific period.",
                    icon: <IconIndicators />,
                    rowElement: <ConditionRowIndicatorsSMA
                        id="indicators-sma"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-value",
                            "indicators-sma",
                            "indicators-ema",
                            "indicators-bollinger-bands",
                            "indicators-vwap",
                            "price-current",
                            "price-last-full-candle",
                            "price-pm-ohlc",
                            "price-ah-ohlc"                            
                        ])}
                    />
                },
                {
                    id: "indicators-ema",
                    name: "Exponential Moving Average (EMA)",
                    description: "The average price over a specific period with more weight to more recent prices.",
                    icon: <IconIndicators />,
                    rowElement: <ConditionRowIndicatorsEMA
                        id="indicators-ema"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-value",
                            "indicators-sma",
                            "indicators-ema",
                            "indicators-bollinger-bands",
                            "indicators-vwap",
                            "price-current",
                            "price-last-full-candle",
                            "price-pm-ohlc",
                            "price-ah-ohlc"                            
                        ])}
                    />
                },
                {
                    id: "indicators-vwap",
                    name: "Volume Weighted Average Price (VWAP)",
                    description: "The volume weighted average price of the day.",
                    icon: <IconIndicators />,
                    rowElement: <ConditionRowIndicatorsVWAP
                        id="indicators-vwap"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-value",
                            "indicators-sma",
                            "indicators-ema",
                            "indicators-bollinger-bands",
                            "price-current",
                            "price-last-full-candle",
                            "price-pm-ohlc",
                            "price-ah-ohlc"
                        ])}
                    />
                },
                {
                    id: "indicators-atr",
                    name: "Average True Range (ATR)",
                    description: "The volatility of a stock by averaging the range of prior candles including gaps.",
                    icon: <IconIndicators />,
                    rowElement: <ConditionRowIndicatorsATR
                        id="indicators-atr"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-value",
                            "percent-value",
                            "indicators-atr",
                            "indicators-volatility"                          
                        ])}
                    />
                },
                {
                    id: "indicators-volatility",
                    name: "Volatility",
                    description: "The volatility of a stock by calculating the standard deviation of prior candles.",
                    icon: <IconIndicators />,
                    rowElement: <ConditionRowIndicatorsVolatility
                        id="indicators-volatility"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-value",
                            "percent-value",
                            "indicators-atr",
                            "indicators-volatility"                          
                        ])}
                    />
                },
                {
                    id: "indicators-bollinger-bands",
                    name: "Bollinger Bands",
                    description: "An indication for unusual deviations from the mean. Consists of an upper and a lower band.",
                    icon: <IconIndicators />,
                    rowElement: <ConditionRowIndicatorsBollingerBands
                        id="indicators-bollinger-bands"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-value",
                            "indicators-sma",
                            "indicators-ema",
                            "indicators-bollinger-bands",
                            "indicators-vwap",
                            "price-current",
                            "price-last-full-candle",
                            "price-pm-ohlc",
                            "price-ah-ohlc"
                        ])}
                    />
                },                
                {
                    id: "indicators-macd",
                    name: "Moving Average Convergence Divergence (MACD)",
                    description: "The relationship between a faster and a slower exponential moving average.",
                    icon: <IconIndicators />,
                    rowElement: <ConditionRowIndicatorsMACD
                        id="indicators-macd"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-value",
                            "indicators-macd"
                        ])}
                    />
                },
                {
                    id: "indicators-rsi",
                    name: "Relative Strength Index (RSI)",
                    description: "The speed and magnitude of recent price changes to evaluate overvalued or undervalued conditions.",
                    icon: <IconIndicators />,
                    rowElement: <ConditionRowIndicatorsRSI
                        id="indicators-rsi"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "range-value",
                            "indicators-rsi",
                            "indicators-stochastic"
                        ])}
                    />
                },
                {
                    id: "indicators-stochastic",
                    name: "Stochastic",
                    description: "A momentum indicator comparing the closing price to a range of its prices over a specific period of time.",
                    icon: <IconIndicators />,
                    rowElement: <ConditionRowIndicatorsStochastic
                        id="indicators-stochastic"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "range-value",
                            "indicators-rsi",
                            "indicators-stochastic"
                        ])}
                    />
                }
            ]
        },
        news: {
            name: "News",
            icon:
                <svg viewBox="0 0 1170.2857142857142 1024" className={PopUp.sidebar_top_item_icon}>
                    <path d="M585.143 292.571h-219.429v219.429h219.429v-219.429zM658.286 658.286v73.143h-365.714v-73.143h365.714zM658.286 219.429v365.714h-365.714v-365.714h365.714zM1024 658.286v73.143h-292.571v-73.143h292.571zM1024 512v73.143h-292.571v-73.143h292.571zM1024 365.714v73.143h-292.571v-73.143h292.571zM1024 219.429v73.143h-292.571v-73.143h292.571zM146.286 768v-548.571h-73.143v548.571c0 20 16.571 36.571 36.571 36.571s36.571-16.571 36.571-36.571zM1097.143 768v-621.714h-877.714v621.714c0 12.571-2.286 25.143-6.286 36.571h847.429c20 0 36.571-16.571 36.571-36.571zM1170.286 73.143v694.857c0 60.571-49.143 109.714-109.714 109.714h-950.857c-60.571 0-109.714-49.143-109.714-109.714v-621.714h146.286v-73.143h1024z" />
                </svg>,
            color: "238, 67, 241",
            conditionsList: [
                {
                    id: "news-new-news",
                    name: "New News",
                    description: "New news for the stock.",
                    icon: <IconNews />,
                    rowElement: <ConditionRowNewsNewNews
                        id="news-new-news"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={[""]}
                    />
                },
                {
                    id: "news-change-since-news",
                    name: "Change since News",
                    description: "Price change since the latest news release for the stock.",
                    icon: <IconNews />,
                    rowElement: <ConditionRowNewsChangeSinceNews
                        id="news-change-since-news"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "dollar-change",
                            "percent-change"
                        ])}
                    />
                },
                {
                    id: "news-volume-since-news",
                    name: "Volume since News",
                    description: "Traded volume since the latest news release for the stock.",
                    icon: <IconNews />,
                    rowElement: <ConditionRowNewsVolumeSinceNews
                        id="news-volume-since-news"
                        state={state}
                        row={row}
                        type="alert"
                        comparisons={getComparisonsOrdered([
                            "number"
                        ])}
                    />
                },
            ]
        }
    }
    return allConditions
}

export const getAlertCondition = (
    state: State, row: CriteriaRow, searchId: string
): FilterAlertCondition | undefined => {
    const allConditions = getAlertConditions(state, row)
    const filteredCondition = Object.keys(allConditions).map((key) =>
        allConditions[key].conditionsList
    ).flat(1).find((condition) =>
        condition.id === searchId
    )
    return filteredCondition
}

export default getAlertConditions