import { changeDashboardData, saveDashboard } from "../../../../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../../../../types/changeHandlers/change-dashboard-widget";
import { EconomicCalendarSettings } from "../../../../../../../../types/Dashboard";
import WidgetTopRowStyles from "../../../../../WidgetTopRowStyles.module.css"

type Props = {
    id: string;
    settings: EconomicCalendarSettings;
    categories: string[];
    name: string;
}

const EconomyButtonCategoryItem: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const dashboard = useAppSelector((state) => state.dashboard)
    
    const changeValues = () => {
        const newEconomy = { ...props.settings.economy }
        if (props.name === "All categories") {
            newEconomy.categories = []
        } else {
            const newCategories = [ ...props.categories ]
            const index = newCategories.findIndex((c) => c === props.name)
            if(index > -1) {
                newCategories.splice(index, 1)
            } else {
                newCategories.push(props.name)
            }
            newEconomy.categories = newCategories
        }
        const newSettings = {
            ...props.settings,
            economy: newEconomy
        }
        const newWidgets = changeDashboardWidgetData(props.id, dashboard.data.widgets, { settings: newSettings })
        dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
        dispatch(saveDashboard());
    }

    return (
        <div className={WidgetTopRowStyles.popup_item} style={{ backgroundColor: (props.categories.includes(props.name) && props.name !== "All categories") || (props.name === "All categories" && props.categories.length === 0) ? "rgb(var(--dl-color-primary-100))" : "" }} onClick={changeValues}>
            <span className={WidgetTopRowStyles.popup_item_text} style={{ color: (props.categories.includes(props.name) && props.name !== "All categories") || (props.name === "All categories" && props.categories.length === 0) ? "rgb(var(--dl-color-white-always))" : "" }}>{props.name}</span>
        </div>
    )
}

export default EconomyButtonCategoryItem