import { WidgetConnectionColorName } from "../../../../../types/Dashboard";
import EconomicCalendarWidgetContent from "../../../../../utils/components/dashboard-widgets/economic-calendar/economic-calendar-widget";
import DashboardWidgetStyles from "./DashboardWidgetStyles.module.css"
import GridWidgetSettings from "./settings/grid-widget-settings";

type Props = {
    id: string;
    connectionName: WidgetConnectionColorName;
    isHovered: boolean;
}

const WidgetEconomicCalendar: React.FC<Props> = (props) => (
    <div className={DashboardWidgetStyles.container}>
        <GridWidgetSettings id={props.id} connectionName={props.connectionName} visible={props.isHovered} />
        <span className={DashboardWidgetStyles.title}>Calendar</span>
        <EconomicCalendarWidgetContent id={props.id} />
    </div>
)

export default WidgetEconomicCalendar