import React, { useEffect, useRef } from "react";
import { Column, TableInstance } from "react-table";

import { changeDashboardData, saveDashboard } from "../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../types/changeHandlers/change-dashboard-widget";
import { TableSettings, Widget } from "../../../../../types/Dashboard";
import CellNewsLeft from "./cells/cell-news-left";
import CellNewsRight from "./cells/cell-news-right";
import ColumnButton from "./column-button";
import HeaderCellSwitch from "./header-cell-switch";
import TableCellSwitch from "./table-cell-switch";
import TableTableStyles from "./TableTableStyles.module.css";

type Props = {
    id: string;
    tableInstance: TableInstance<object>
}

const TableTable: React.FC<Props> = (props) => {
    const dashboard = useAppSelector((state) => state.dashboard)
    const widget: Widget = useAppSelector((state) => state.dashboard.data.widgets.find((w) => w.id === props.id))!
    const dispatch = useAppDispatch();

    const settings = widget.settings as TableSettings

    const { showNews, columns } = settings

    const { getTableProps, getTableBodyProps, headerGroups, rows, state, prepareRow, setColumnOrder } = props.tableInstance
    
    const headerRef = useRef<HTMLDivElement | null>(null);
    const tableRef = useRef<HTMLDivElement | null>(null);

    const bottomSeparatorWidth = () => {
        if (headerRef.current?.clientWidth && tableRef.current?.clientWidth) {
            return Math.max(headerRef.current?.clientWidth, tableRef.current?.clientWidth)
        }
        if (headerRef.current?.clientWidth) {
            return headerRef.current?.clientWidth
        }
        if (tableRef.current?.clientWidth) {
            return tableRef.current?.clientWidth
        }
        return ""
    }
    
    useEffect(() => {
        if (rows.length > 0) {
            const currentColumnIds = rows[0].cells.map(cell => cell.column.id)
            const newColumnIds: string[] = []
            columns.forEach(c => {
                if (c.id) newColumnIds.push(c.id)
            })
            if (JSON.stringify(currentColumnIds) !== JSON.stringify(newColumnIds)) {
                setColumnOrder(newColumnIds)
            }
        }
    }, [columns])

    useEffect(() => {
        if (state.columnResizing.isResizingColumn) {
            return
        }
        const tableWidths = state.columnResizing.columnWidths as {[id: string]: number}
        const newColumns: Column<object>[] = []
        let flag = false
        columns.forEach((c) => {
            const newWidth = tableWidths[c.accessor as string]
            if (typeof c !== "undefined") {
                if (typeof newWidth !== "undefined" && c.width !== newWidth) {
                    flag = true;
                    newColumns.push({
                        ...c,
                        width: newWidth
                    })
                } else {
                    newColumns.push(c)
                }
            }
        })
        if (flag) {
            const newSettings = {
                ...settings,
                columns: newColumns
            }
            const newWidgets = changeDashboardWidgetData(props.id, dashboard.data.widgets, { settings: newSettings })
            dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
            dispatch(saveDashboard());
        }
    }, [state])

    const getRowClassNames = (length: number, indexCell: number) => {
        if (indexCell === length - 2) {
            return `${TableTableStyles.row_cell} ${TableTableStyles.row_cell_border_right}`
        }
        return `${TableTableStyles.row_cell}`
    }

    return (
        <div className={TableTableStyles.container}>
            <ColumnButton id={props.id} />
            <div className={TableTableStyles.table_dummy_background} />
            <div className={TableTableStyles.table_full_width_wrapper}>
                <div className={TableTableStyles.scroll_container}>
                    <div {...getTableProps()} className={TableTableStyles.table} ref={tableRef}>
                        <div className={TableTableStyles.header} ref={headerRef}>
                            {headerGroups.map((headerGroup, indexHeaderGroup) => (
                                <div {...headerGroup.getHeaderGroupProps()} className={TableTableStyles.row} key={indexHeaderGroup}>
                                    {headerGroup.headers.map((column) => (
                                        <React.Fragment key={column.id}>
                                            <div {...column.getHeaderProps(column.getSortByToggleProps())} className={TableTableStyles.header_cell}>
                                                <HeaderCellSwitch width={column.width ? Number(column.width) : 100} column={column} />
                                            </div>
                                            {column.id !== "dummy" ? (
                                                <div className={TableTableStyles.header_resizer_wrapper}>
                                                    <div
                                                        {...column.getResizerProps()}
                                                        className={column.isResizing ? `${TableTableStyles.header_resizer} ${TableTableStyles.header_resizer_active}` : `${TableTableStyles.header_resizer}`}
                                                    />
                                                </div>
                                            ) : (null)}
                                        </React.Fragment>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div {...getTableBodyProps()}>
                            {rows.map((row, rowIndex) => {
                                prepareRow(row)
                                return (
                                    <React.Fragment key={row.id}>
                                        <div {...row.getRowProps()}>
                                            {row.cells.map((cell, indexCell) => (
                                                <React.Fragment key={cell.column.id}>
                                                    <div {...cell.getCellProps()} className={getRowClassNames(row.cells.length, indexCell)}>
                                                        <TableCellSwitch columnId={cell.column.id} cell={cell} width={cell.column.width ? Number(cell.column.width) : 100} settings={settings} />
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        { showNews ? (
                                            <div {...row.getRowProps()}>
                                                {row.cells.map((cell, indexCell) => {
                                                    if (indexCell === 0) {
                                                        return <CellNewsLeft cell={cell} width={cell.column.width ? Number(cell.column.width) : 100} key={cell.column.id} />;
                                                    }
                                                    if (indexCell === 1) {
                                                        return <CellNewsRight width={cell.column.width ? Number(cell.column.width) : 100} firstCellWidth={row.cells[0].column.width ? Number(row.cells[0].column.width) : 200} key={cell.column.id} />;
                                                    }
                                                    return null
                                                })}
                                            </div>
                                        ) : (null)}
                                        {rowIndex !== (rows.length - 1) ? (
                                            <div className={TableTableStyles.row_separator} style={{ width: headerRef.current?.clientWidth ? `calc(${headerRef.current.clientWidth - 26}px)` : "" }}/>
                                        ) : (
                                            <div className={TableTableStyles.row_separator} style={{ width: bottomSeparatorWidth() }}/>
                                        )}
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableTable