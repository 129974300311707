import { useEffect, useRef, useState } from 'react';

import { setPopupElement } from '../../../../../store/features/management';
import { changeScreenerData, saveScreener } from '../../../../../store/features/screener';
import { useAppDispatch } from '../../../../../store/hooks';
import { Screener } from '../../../../../types/Screener';
import useClickOutsidePopup from '../../../../../utils/hooks/use-click-outside-popup';
import DashboardScreenerStyles from '../DashboardScreener.module.css';

type Props = {
    screener: Screener
}

const DashboardScreenerListItemSettingsDescription: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    
    const [descriptionInput, setDescriptionInput] = useState(props.screener.description);
    const [descriptionInputError, setDescriptionInputError] = useState("");

    const refClickOutside = useClickOutsidePopup();

    const descriptionInputRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        descriptionInputRef.current?.focus();
        descriptionInputRef.current?.setSelectionRange(descriptionInputRef.current?.value.length, descriptionInputRef.current?.value.length)
    }, [])

    const descriptionInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (event.target.value.trim().length > 500) {
            setDescriptionInputError("Maximum number of characters reached.");
        } else {
            setDescriptionInputError("");
            setDescriptionInput(event.target.value);
        }
    }

    const saveDescription = () => {
        if (descriptionInput.trim().length < 3) {
            setDescriptionInputError("Please enter at least 3 characters.");
        } else {
            dispatch(changeScreenerData({ ...props.screener, description: descriptionInput.trim() }));
            dispatch(saveScreener());
            dispatch(setPopupElement(null));
        }
    }

    return (
        <div className="app_popup_wrapper_middle" ref={refClickOutside}>
            <div className={DashboardScreenerStyles.delete_container}>
                <span className={DashboardScreenerStyles.delete_header}>Edit Screener Description</span>
                <div className={DashboardScreenerStyles.delete_confirmation_wrapper}>
                    <textarea
                        className={DashboardScreenerStyles.delete_confirmation_input}
                        style={{ width: "400px", height: "200px", resize: "none", padding: "8px", textAlign: "start", borderColor: descriptionInputError !== "" ? "rgb(var(--dl-color-colors-red-error))" : "" }}
                        placeholder="Edit description..."
                        onChange={(event) => descriptionInputChange(event)}
                        value={descriptionInput}
                        ref={descriptionInputRef}
                    />
                    {descriptionInputError && (
                        <span className={DashboardScreenerStyles.delete_confirmation_text} style={{ color: "rgb(var(--dl-color-colors-red-error))" }}>{descriptionInputError}</span>
                    )}
                </div>
                <div className={DashboardScreenerStyles.delete_state_button_row}>
                    <div className={DashboardScreenerStyles.delete_state_button} onClick={() => dispatch(setPopupElement(null))}>
                        <span className={DashboardScreenerStyles.delete_state_button_text}>Cancel</span>
                    </div>
                    <div className={`${DashboardScreenerStyles.delete_state_button} ${DashboardScreenerStyles.delete_state_button_delete}`} onClick={() => saveDescription()}>
                        <span className={DashboardScreenerStyles.delete_state_button_text} style={{ color: "rgb(var(--dl-color-white-always))" }}>Save</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardScreenerListItemSettingsDescription