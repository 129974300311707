import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../types/Screener';
import CriteriaInputNumber from '../criteria-input-number';
import checkUnusedParams from '../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        percentValue: "100",
    },
    setParameters: () => {},
}

const CriteriaParameterPercentValue: React.FC<Props> = (props) => {
    const number = useMemo(() => props.parameters.percentValue ? props.parameters.percentValue : defaultProps.parameters.percentValue!, [props.parameters.percentValue])

    const setNumber = (newNumber: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, percentValue: newNumber }, defaultProps.parameters))
    }

    return (
        <CriteriaInputNumber number={number} setNumber={setNumber} min={0} max={9999} step="0.01" width="70px" />
    )
}

export default CriteriaParameterPercentValue