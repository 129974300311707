import { useMemo } from "react";

import { setPopupElement } from "../../../../../../store/features/management";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { TableSettings, Widget } from "../../../../../../types/Dashboard";
import findScreenerFirstSource, { getAllSourcesNames } from "../../../../../functions/find-screener-first-source";
import BootstrapTooltip from "../../../../tooltip/bootstrap-tooltip";
import WidgetTopRowStyles from "../../../WidgetTopRowStyles.module.css"
import TableButtonSourcePopup from "./table-top-row-button-source-popup";

type Props = {
    id: string;
}

const TableTopRowButtonSources: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const allScreeners = useAppSelector((state) => state.allScreeners)
    const user = useAppSelector((state) => state.user)
    const dashboard = useAppSelector((state) => state.dashboard)

    const widget: Widget = useMemo(() => dashboard.data.widgets.find((w) => w.id === props.id)!, [dashboard.data.widgets, props.id])

    const settings = useMemo(() => widget.settings as TableSettings, [widget])

    const sources = useMemo(() => ({ ...settings.sources }), [settings.sources])

    const indices = useMemo(() => ([...sources.indices]), [sources])
    const screeners = useMemo(() => ([...sources.screeners]), [sources])
    const watchlists = useMemo(() => ([...sources.watchlists]), [sources])

    const sourcesLength = useMemo(() => (indices.length + screeners.length + watchlists.length), [indices, screeners, watchlists])
    
    const allSourcesNames = useMemo(() => getAllSourcesNames(indices, screeners, watchlists, allScreeners, user), [indices, screeners, watchlists])
    
    const firstSourceName: string | null = useMemo(() => findScreenerFirstSource(sources, allScreeners, user), [sources])

    return (
        <div className={WidgetTopRowStyles.dropdown_button} style={{ borderColor: sourcesLength > 0 ? "rgb(var(--dl-color-primary-100))" : "", backgroundColor: sourcesLength > 0 ? "rgba(var(--dl-color-primary-100), 0.09)" : "" }} onClick={() => dispatch(setPopupElement(<TableButtonSourcePopup id={props.id} />))}>
            <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.dropdown_button_icon} style={{ fill: sourcesLength > 0 ? "rgb(var(--dl-color-primary-100))" : "" }}>
                <path d="M470 682v-128h-428v-84h428v-128l170 170zM896 128q36 0 61 25t25 61v598q0 34-26 59t-60 25h-768q-34 0-60-25t-26-59v-172h86v172h768v-600h-768v172h-86v-170q0-34 26-60t60-26h768z" />
            </svg>
            <span className={WidgetTopRowStyles.dropdown_button_text} style={{ color: sourcesLength > 0 ? "rgb(var(--dl-color-primary-100))" : "" }}>{firstSourceName !== null ? firstSourceName : "No source selected"}</span>
            {sourcesLength > 1 ? (
                <BootstrapTooltip title={`${allSourcesNames}, ${allSourcesNames}`}>
                    <span className={WidgetTopRowStyles.source_count}>+{sourcesLength - 1}</span>
                </BootstrapTooltip>
            ) : null}
        </div>
    )
}

export default TableTopRowButtonSources