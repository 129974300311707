import { Cell } from "react-table";

import TableTableStyles from "../TableTableStyles.module.css"

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cell: Cell<object, any>;
    width: number;
}

const CellNewsLeft: React.FC<Props> = (props) => (
    <div {...props.cell.getCellProps()} className={TableTableStyles.row_cell}>
        <div className={TableTableStyles.cell_wrapper} style={{ justifyContent: "flex-start", padding: props.width > 140 ? "0px 16px 6px 16px" : "0px 8px 6px 8px" }}>
            <div className={TableTableStyles.news_dummy} />
            <div className={TableTableStyles.ticker_wrapper} style={{ backgroundColor: "rgb(var(--dl-color-colors-red-bright))" }}>
                <span className={TableTableStyles.ticker_text} style={{ color: "rgb(var(--dl-color-white-always))" }}>News</span>
            </div>
            <span className={TableTableStyles.news_provider}>Benzinga</span>
        </div>
    </div>
)

export default CellNewsLeft