/* eslint-disable */
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setPopupElement } from "../../store/features/management";

const useClickOutsidePopup = () => {
    const management = useAppSelector((state) => state.management);
    
    const dispatch = useAppDispatch();

    const ref = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as HTMLElement) && management.popupElement !== null) {
                dispatch(setPopupElement(null))
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return ref
}

export default useClickOutsidePopup