import { useEffect, useState } from 'react';

import { resetUserError } from '../../../store/features/user';
import { useAppDispatch } from '../../../store/hooks';
import App from '../App.module.css'
import ProfileChangeEmail from './account-changes/change-email';
import ProfileChangePassword from './account-changes/change-password';

const AppProfilePage: React.FC = () => {
    const [tab] = useState("email")
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetUserError());
    }, [tab]);

    return (
        <>
            <div className={App.content_container}>
                {tab === "password" && <ProfileChangePassword />}
                {tab === "email" && <ProfileChangeEmail />}
            </div>
        </>
    )
}

export default AppProfilePage