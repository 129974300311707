/* eslint-disable @typescript-eslint/no-unused-expressions, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const namespace = "management"

export type ManagementState = {
    popupElement: JSX.Element | null;
    showPageLoadingSpinner: boolean;
    windowWidth: number;
    conditionExpandedCard: string;
    showOnlyFavouriteConditions: boolean;
}

const initialStateValue: ManagementState = {
    popupElement: null,
    showPageLoadingSpinner: true,
    windowWidth: 1200,
    conditionExpandedCard: "",
    showOnlyFavouriteConditions: false
}

export const managementSlice = createSlice({
    name: namespace,
    initialState: initialStateValue,
    reducers: {
        resetManagement: (state) => {
            state.popupElement = initialStateValue.popupElement
            state.showPageLoadingSpinner = initialStateValue.showPageLoadingSpinner
        },
        setPopupElement: (state, action: PayloadAction<JSX.Element | null>) => {
            state.popupElement = action.payload
        },
        setShowPageLoadingSpinner: (state, action: PayloadAction<boolean>) => {
            state.showPageLoadingSpinner = action.payload
        },
        setWindowWidth: (state, action: PayloadAction<number>) => {
            state.windowWidth = action.payload
        },
        setConditionExpandedCard: (state, action: PayloadAction<string>) => {
            state.conditionExpandedCard = action.payload
        },
        setShowOnlyFavouriteConditions: (state, action: PayloadAction<boolean>) => {
            state.showOnlyFavouriteConditions = action.payload
        },
    }
})

export const {
    resetManagement,
    setPopupElement,
    setShowPageLoadingSpinner,
    setWindowWidth,
    setConditionExpandedCard,
    setShowOnlyFavouriteConditions
} = managementSlice.actions

export default managementSlice.reducer;