import { Helmet } from 'react-helmet-async';

import TopNavWebsite from './top-nav/website-top-nav';
import Website from './Website.module.css'

const Home: React.FC = () => (
    <div className={Website.home_page_wrapper}>
        <Helmet>
            <title>Traddle.io | Interactive Stock Screener</title>
            <meta
                property="og:title"
                content="Traddle.io | Interactive Stock Screener"
            />
        </Helmet>
        <TopNavWebsite />
        <div className={Website.home_page_container}>
            <p className={Website.header_h1}>Website Homepage</p>
        </div>
    </div>
)

export default Home