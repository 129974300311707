/* eslint-disable */

import { useEffect } from 'react';
import { firestoreDB } from '../../firebase/firebase-config';
import { collectionGroup, query, where } from 'firebase/firestore';
import { setDashboards } from '../../store/features/allDashboards';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Dashboard, Dashboards } from '../../types/Dashboard';
import { useCollection } from 'react-firebase-hooks/firestore';
import { resetDashboard } from '../../store/features/dashboard';

const DashboardsListener = () => {
    let allDashboards = useAppSelector((state) => state.allDashboards)
    const user = useAppSelector((state) => state.user)
    const dashboard = useAppSelector((state) => state.dashboard)
    const dispatch = useAppDispatch();

    const [snapshot, loading, error] = useCollection(query(collectionGroup(firestoreDB, "dashboards"), where("access", "array-contains", user.userData.uid)), {});

    useEffect(() => {
        if (!user.loggedIn || !snapshot) {
            return
        }
        const newDashboards: Dashboards = {};
        snapshot.docs.map((doc) => newDashboards[doc.id] = {...doc.data()} as Dashboard)
        if (!_.isEqual(allDashboards.dashboards, newDashboards) || !allDashboards.initialized) {
            dispatch(setDashboards(newDashboards));
            if (!Object.keys(newDashboards).includes(dashboard.data.id)) {
                dispatch(resetDashboard())
            }
        }
    }, [snapshot])

    return <></>
};

export default DashboardsListener