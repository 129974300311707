const resolveError = (error: string): string => {
    if (error.includes("auth/user-not-found")) {
        return "No account exists with that e-mail address.";
    }
    if (error.includes("auth/invalid-email")) {
        return "Please enter a valid e-mail address.";
    }
    if (error.includes("auth/invalid-password")) {
        return "Please enter a valid password.";
    }
    if (error.includes("auth/wrong-password")) {
        return "Wrong password.";
    }
    if (error.includes("auth/missing-password")) {
        return "Please enter your password.";
    }
    if (error.includes("auth/email-already-in-use")) {
        return "An account with that e-mail address already exists.";
    }
    if (error.includes("auth/invalid-display-name")) {
        return "Please enter a valid name.";
    }
    if (error.includes("auth/too-many-requests")) {
        return "Too many retries. Please wait a few minutes and then try again.";
    }
    if (error.includes("auth/invalid-user-token") || error.includes("requires-recent-login") || error.includes("user-token-expired")) {
        return "Session expired. Please login again.";
    }
    if (error.includes("auth/network-request-failed")) {
        return "Network request failed. Please check your internet connection.";
    }
    if (error.includes("auth/user-disabled")) {
        return "Your account has been disabled. Please contact our support.";
    }
    if (error.includes("popup-closed") || error.includes("cancelled-popup-request")) {
        return "Popup error.";
    }
    if (error.includes("unknown-error")) {
        return "An unknown error occured.";
    }
    if (error.includes("same-email")) {
        return "This is already your current e-mail address.";
    }
    return error;
}

export default resolveError