import {
    Accessibility,
    AccountBalance,
    AccountBalanceWallet,
    AccountBox,
    AccountCircle,
    AccountTree,
    Adjust,
    AdUnits,
    Air,
    AirlineSeatReclineExtra,
    AirplanemodeActive,
    AirportShuttle,
    Alarm,
    Album,
    AllInclusive,
    Analytics,
    Apartment,
    ArrowBack,
    ArrowCircleDown,
    ArrowCircleLeft,
    ArrowCircleRight,
    ArrowCircleUp,    
    ArrowDownward,
    ArrowForward,
    ArrowOutward,
    ArrowUpward,
    Assessment,
    AttachMoney,
    Attractions,
    Audiotrack,
    AutoAwesome,
    AutoFixHigh,
    AutoFixNormal,
    AutoGraph,
    Autorenew,
    Balance,
    BarChart,
    Battery0Bar,
    Battery4Bar,
    BatteryFull,
    Bedtime,
    Block,
    Bolt,
    BookmarkBorder,
    Brightness5,
    Brush,
    BubbleChart,
    Business,
    BusinessCenter,
    Cake,
    Call,
    CameraAlt,
    Campaign,
    CandlestickChart,
    Cases,
    Casino,
    Castle,
    Category,
    Celebration,
    Chair,
    ChildFriendly,
    Coffee,
    ColorLens,
    Construction,
    ContentCut,
    Cookie,
    Coronavirus,
    CrisisAlert,
    CurrencyBitcoin,
    Cyclone,
    Dangerous,
    Dashboard,
    DeviceThermostat,
    Diamond,
    Dining,
    DirectionsBike,
    DirectionsBoat,
    DirectionsBus,
    DirectionsCar,
    DirectionsRailway,
    DirectionsSubway,
    DirectionsTransit,
    DirectionsWalk,
    Discount,
    Domain,
    DonutSmall,
    DoubleArrow,
    ElectricBolt,
    Email,
    Equalizer,
    Euro,
    Explore,
    Extension,
    Factory,
    Fastfood,
    Favorite,
    Festival,
    FilterAlt,
    Fingerprint,
    FireHydrantAlt,
    FitnessCenter,
    FlashlightOn,
    FlashOn,
    Flight,
    FlightLand,
    FlightTakeoff,
    Forest,
    Gamepad,
    GolfCourse,
    GpsFixed,
    Grade,
    Handyman,
    Headphones,
    HeartBroken,
    Hexagon,
    Highlight,
    Hive,
    Home,
    HomeRepairService,
    Hotel,
    HourglassBottom,
    HourglassFull,
    HourglassTop,
    Icecream,
    IceSkating,
    Interests,
    JoinFull,
    Key,
    KeyboardDoubleArrowDown,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
    KeyboardDoubleArrowUp,
    KeyboardVoice,
    Kitesurfing,
    Landscape,
    Language,
    Leaderboard,
    Lens,
    Lightbulb,
    LightMode,
    LineAxis,
    LocalAirport,
    LocalAtm,
    LocalBar,
    LocalPizza,
    LocalPolice,
    LunchDining,
    Mediation,
    MedicalServices,
    MonetizationOn,
    Money,
    MonitorHeart,
    Mood,
    MoodBad,
    Navigation,
    NearMe,
    NetworkWifi2Bar,
    NewReleases,
    Notifications,
    NotificationsActive,
    NotStarted,
    OfflineBolt,
    Outbound,
    Paid,
    Paragliding,
    Park,
    Pattern,
    Percent,
    PhotoCamera,
    PinDrop,
    PlayArrow,
    Poll,
    Power,
    PriceChange,
    PriceCheck,
    PriorityHigh,
    PrivacyTip,
    Public,
    PushPin,
    QuestionMark,
    RadioButtonChecked,
    Recommend,
    Rectangle,
    RemoveRedEye,
    ReplayCircleFilled,
    Reply,
    Report,
    Rocket,
    RocketLaunch,
    Route,
    RssFeed,
    Savings,
    School,
    Security,
    Shield,
    ShoppingCart,
    SignalCellularAlt,
    Skateboarding,
    SmartDisplay,
    Snowboarding,
    Speaker,
    Spoke,
    SportsBaseball,
    SportsBasketball,
    SportsEsports,
    SportsFootball,
    SportsScore,
    SportsSoccer,
    SportsTennis,
    SportsVolleyball,
    Square,
    SsidChart,
    StackedBarChart,
    StackedLineChart,
    Stairs,
    Star,
    Storm,
    Support,
    Surfing,
    SwapHorizontalCircle,
    Thermostat,
    ThumbDown,
    ThumbUp,
    Thunderstorm,
    Token,
    Tornado,
    TrackChanges,
    Tune,
    UnfoldMore,
    Upload,
    Watch,
    WaterDrop,
    Webhook,
    Whatshot,
    Wifi,
    Work,
    Yard
} from '@mui/icons-material';

const getIcons = (size: number, color: string): JSX.Element[] => {
    const Icons = [
        <Accessibility key="Accessibility" sx={{ color: `${color}`, fontSize: size }} />,
        <AccountBalance key="AccountBalance" sx={{ color: `${color}`, fontSize: size }} />,
        <AccountBalanceWallet key="AccountBalanceWallet" sx={{ color: `${color}`, fontSize: size }} />,
        <AccountBox key="AccountBox" sx={{ color: `${color}`, fontSize: size }} />,
        <AccountCircle key="AccountCircle" sx={{ color: `${color}`, fontSize: size }} />,
        <AccountTree key="AccountTree" sx={{ color: `${color}`, fontSize: size }} />,
        <AdUnits key="AdUnits" sx={{ color: `${color}`, fontSize: size }} />,
        <Adjust key="Adjust" sx={{ color: `${color}`, fontSize: size }} />,
        <Air key="Air" sx={{ color: `${color}`, fontSize: size }} />,
        <AirlineSeatReclineExtra key="AirlineSeatReclineExtra" sx={{ color: `${color}`, fontSize: size }} />,
        <AirplanemodeActive key="AirplanemodeActive" sx={{ color: `${color}`, fontSize: size }} />,
        <AirportShuttle key="AirportShuttle" sx={{ color: `${color}`, fontSize: size }} />,
        <Alarm key="Alarm" sx={{ color: `${color}`, fontSize: size }} />,
        <Album key="Album" sx={{ color: `${color}`, fontSize: size }} />,
        <AllInclusive key="AllInclusive" sx={{ color: `${color}`, fontSize: size }} />,
        <Analytics key="Analytics" sx={{ color: `${color}`, fontSize: size }} />,
        <Apartment key="Apartment" sx={{ color: `${color}`, fontSize: size }} />,
        <ArrowBack key="ArrowBack" sx={{ color: `${color}`, fontSize: size }} />,
        <ArrowDownward key="ArrowDownward" sx={{ color: `${color}`, fontSize: size }} />,
        <ArrowForward key="ArrowForward" sx={{ color: `${color}`, fontSize: size }} />,
        <ArrowOutward key="ArrowOutward" sx={{ color: `${color}`, fontSize: size }} />,
        <ArrowUpward key="ArrowUpward" sx={{ color: `${color}`, fontSize: size }} />,
        <ArrowCircleLeft key="ArrowCircleLeft" sx={{ color: `${color}`, fontSize: size }} />,
        <ArrowCircleUp key="ArrowCircleUp" sx={{ color: `${color}`, fontSize: size }} />,
        <ArrowCircleDown key="ArrowCircleDown" sx={{ color: `${color}`, fontSize: size }} />,
        <ArrowCircleRight key="ArrowCircleRight" sx={{ color: `${color}`, fontSize: size }} />,
        <Assessment key="Assessment" sx={{ color: `${color}`, fontSize: size }} />,
        <AttachMoney key="AttachMoney" sx={{ color: `${color}`, fontSize: size }} />,
        <Attractions key="Attractions" sx={{ color: `${color}`, fontSize: size }} />,
        <AutoFixHigh key="AutoFixHigh" sx={{ color: `${color}`, fontSize: size }} />,
        <AutoFixNormal key="AutoFixNormal" sx={{ color: `${color}`, fontSize: size }} />,
        <AutoAwesome key="AutoAwesome" sx={{ color: `${color}`, fontSize: size }} />,
        <Audiotrack key="Audiotrack" sx={{ color: `${color}`, fontSize: size }} />,
        <AutoGraph key="AutoGraph" sx={{ color: `${color}`, fontSize: size }} />,
        <Autorenew key="Autorenew" sx={{ color: `${color}`, fontSize: size }} />,
        <Balance key="Balance" sx={{ color: `${color}`, fontSize: size }} />,
        <BarChart key="BarChart" sx={{ color: `${color}`, fontSize: size }} />,
        <Battery0Bar key="Battery0Bar" sx={{ color: `${color}`, fontSize: size }} />,
        <Battery4Bar key="Battery4Bar" sx={{ color: `${color}`, fontSize: size }} />,
        <BatteryFull key="BatteryFull" sx={{ color: `${color}`, fontSize: size }} />,
        <Bedtime key="Bedtime" sx={{ color: `${color}`, fontSize: size }} />,
        <Block key="Block" sx={{ color: `${color}`, fontSize: size }} />,
        <Bolt key="Bolt" sx={{ color: `${color}`, fontSize: size }} />,
        <BookmarkBorder key="BookmarkBorder" sx={{ color: `${color}`, fontSize: size }} />,
        <Brightness5 key="Brightness5" sx={{ color: `${color}`, fontSize: size }} />,
        <Brush key="Brush" sx={{ color: `${color}`, fontSize: size }} />,
        <BubbleChart key="BubbleChart" sx={{ color: `${color}`, fontSize: size }} />,
        <Business key="Business" sx={{ color: `${color}`, fontSize: size }} />,
        <BusinessCenter key="BusinessCenter" sx={{ color: `${color}`, fontSize: size }} />,
        <Cake key="Cake" sx={{ color: `${color}`, fontSize: size }} />,
        <Call key="Call" sx={{ color: `${color}`, fontSize: size }} />,
        <CameraAlt key="CameraAlt" sx={{ color: `${color}`, fontSize: size }} />,
        <Campaign key="CameraAlt" sx={{ color: `${color}`, fontSize: size }} />,
        <CandlestickChart key="CandlestickChart" sx={{ color: `${color}`, fontSize: size }} />,
        <Cases key="Cases" sx={{ color: `${color}`, fontSize: size }} />,
        <Casino key="Casino" sx={{ color: `${color}`, fontSize: size }} />,
        <Castle key="Castle" sx={{ color: `${color}`, fontSize: size }} />,
        <Category key="Category" sx={{ color: `${color}`, fontSize: size }} />,
        <Celebration key="Celebration" sx={{ color: `${color}`, fontSize: size }} />,
        <Chair key="Chair" sx={{ color: `${color}`, fontSize: size }} />,
        <ChildFriendly key="ChildFriendly" sx={{ color: `${color}`, fontSize: size }} />,
        <Coffee key="Coffee" sx={{ color: `${color}`, fontSize: size }} />,
        <ColorLens key="ColorLens" sx={{ color: `${color}`, fontSize: size }} />,
        <Construction key="Construction" sx={{ color: `${color}`, fontSize: size }} />,
        <ContentCut key="ContentCut" sx={{ color: `${color}`, fontSize: size }} />,
        <Cookie key="Cookie" sx={{ color: `${color}`, fontSize: size }} />,
        <Coronavirus key="Coronavirus" sx={{ color: `${color}`, fontSize: size }} />,
        <CrisisAlert key="CrisisAlert" sx={{ color: `${color}`, fontSize: size }} />,
        <CurrencyBitcoin key="CurrencyBitcoin" sx={{ color: `${color}`, fontSize: size }} />,
        <Cyclone key="Cyclone" sx={{ color: `${color}`, fontSize: size }} />,
        <Dangerous key="Dangerous" sx={{ color: `${color}`, fontSize: size }} />,
        <Dashboard key="Dashboard" sx={{ color: `${color}`, fontSize: size }} />,
        <DeviceThermostat key="DeviceThermostat" sx={{ color: `${color}`, fontSize: size }} />,
        <Diamond key="Diamond" sx={{ color: `${color}`, fontSize: size }} />,
        <Dining key="Dining" sx={{ color: `${color}`, fontSize: size }} />,
        <DirectionsBike key="DirectionsBike" sx={{ color: `${color}`, fontSize: size }} />,
        <DirectionsBoat key="DirectionsBoat" sx={{ color: `${color}`, fontSize: size }} />,
        <DirectionsBus key="DirectionsBus" sx={{ color: `${color}`, fontSize: size }} />,
        <DirectionsCar key="DirectionsCar" sx={{ color: `${color}`, fontSize: size }} />,
        <DirectionsRailway key="DirectionsRailway" sx={{ color: `${color}`, fontSize: size }} />,
        <DirectionsSubway key="DirectionsSubway" sx={{ color: `${color}`, fontSize: size }} />,
        <DirectionsTransit key="DirectionsTransit" sx={{ color: `${color}`, fontSize: size }} />,
        <DirectionsWalk key="DirectionsWalk" sx={{ color: `${color}`, fontSize: size }} />,
        <Discount key="Discount" sx={{ color: `${color}`, fontSize: size }} />,
        <Domain key="Domain" sx={{ color: `${color}`, fontSize: size }} />,
        <DonutSmall key="DonutSmall" sx={{ color: `${color}`, fontSize: size }} />,
        <DoubleArrow key="DoubleArrow" sx={{ color: `${color}`, fontSize: size }} />,
        <ElectricBolt key="ElectricBolt" sx={{ color: `${color}`, fontSize: size }} />,
        <Email key="Email" sx={{ color: `${color}`, fontSize: size }} />,
        <Equalizer key="Equalizer" sx={{ color: `${color}`, fontSize: size }} />,
        <Euro key="Euro" sx={{ color: `${color}`, fontSize: size }} />,
        <Explore key="Explore" sx={{ color: `${color}`, fontSize: size }} />,
        <Extension key="Extension" sx={{ color: `${color}`, fontSize: size }} />,
        <Factory key="Factory" sx={{ color: `${color}`, fontSize: size }} />,
        <Fastfood key="Fastfood" sx={{ color: `${color}`, fontSize: size }} />,
        <Favorite key="Favorite" sx={{ color: `${color}`, fontSize: size }} />,
        <Festival key="Festival" sx={{ color: `${color}`, fontSize: size }} />,
        <FilterAlt key="FilterAlt" sx={{ color: `${color}`, fontSize: size }} />,
        <Fingerprint key="Fingerprint" sx={{ color: `${color}`, fontSize: size }} />,
        <FireHydrantAlt key="FireHydrantAlt" sx={{ color: `${color}`, fontSize: size }} />,
        <FitnessCenter key="FitnessCenter" sx={{ color: `${color}`, fontSize: size }} />,
        <FlashOn key="FlashOn" sx={{ color: `${color}`, fontSize: size }} />,
        <FlashlightOn key="FlashlightOn" sx={{ color: `${color}`, fontSize: size }} />,
        <Flight key="Flight" sx={{ color: `${color}`, fontSize: size }} />,
        <FlightLand key="FlightLand" sx={{ color: `${color}`, fontSize: size }} />,
        <FlightTakeoff key="FlightTakeoff" sx={{ color: `${color}`, fontSize: size }} />,
        <Forest key="Forest" sx={{ color: `${color}`, fontSize: size }} />,
        <Gamepad key="Gamepad" sx={{ color: `${color}`, fontSize: size }} />,
        <GolfCourse key="GolfCourse" sx={{ color: `${color}`, fontSize: size }} />,
        <GpsFixed key="GpsFixed" sx={{ color: `${color}`, fontSize: size }} />,
        <Grade key="Grade" sx={{ color: `${color}`, fontSize: size }} />,
        <Handyman key="Handyman" sx={{ color: `${color}`, fontSize: size }} />,
        <Headphones key="Headphones" sx={{ color: `${color}`, fontSize: size }} />,
        <HeartBroken key="HeartBroken" sx={{ color: `${color}`, fontSize: size }} />,
        <Hexagon key="Hexagon" sx={{ color: `${color}`, fontSize: size }} />,
        <Highlight key="Highlight" sx={{ color: `${color}`, fontSize: size }} />,
        <Hive key="Hive" sx={{ color: `${color}`, fontSize: size }} />,
        <Home key="Home" sx={{ color: `${color}`, fontSize: size }} />,
        <HomeRepairService key="HomeRepairService" sx={{ color: `${color}`, fontSize: size }} />,
        <Hotel key="Hotel" sx={{ color: `${color}`, fontSize: size }} />,
        <HourglassBottom key="HourglassBottom" sx={{ color: `${color}`, fontSize: size }} />,
        <HourglassTop key="HourglassTop" sx={{ color: `${color}`, fontSize: size }} />,
        <HourglassFull key="HourglassFull" sx={{ color: `${color}`, fontSize: size }} />,
        <IceSkating key="IceSkating" sx={{ color: `${color}`, fontSize: size }} />,
        <Icecream key="Icecream" sx={{ color: `${color}`, fontSize: size }} />,
        <Interests key="Interests" sx={{ color: `${color}`, fontSize: size }} />,
        <JoinFull key="JoinFull" sx={{ color: `${color}`, fontSize: size }} />,
        <Key key="Key" sx={{ color: `${color}`, fontSize: size }} />,
        <KeyboardDoubleArrowDown key="KeyboardDoubleArrowDown" sx={{ color: `${color}`, fontSize: size }} />,
        <KeyboardDoubleArrowUp key="KeyboardDoubleArrowUp" sx={{ color: `${color}`, fontSize: size }} />,
        <KeyboardDoubleArrowLeft key="KeyboardDoubleArrowLeft" sx={{ color: `${color}`, fontSize: size }} />,
        <KeyboardDoubleArrowRight key="KeyboardDoubleArrowRight" sx={{ color: `${color}`, fontSize: size }} />,
        <KeyboardVoice key="KeyboardVoice" sx={{ color: `${color}`, fontSize: size }} />,
        <Kitesurfing key="Kitesurfing" sx={{ color: `${color}`, fontSize: size }} />,
        <Landscape key="Landscape" sx={{ color: `${color}`, fontSize: size }} />,
        <Language key="Language" sx={{ color: `${color}`, fontSize: size }} />,
        <Leaderboard key="Leaderboard" sx={{ color: `${color}`, fontSize: size }} />,
        <Lens key="Lens" sx={{ color: `${color}`, fontSize: size }} />,
        <LightMode key="LightMode" sx={{ color: `${color}`, fontSize: size }} />,
        <Lightbulb key="Lightbulb" sx={{ color: `${color}`, fontSize: size }} />,
        <LineAxis key="LineAxis" sx={{ color: `${color}`, fontSize: size }} />,
        <LocalAirport key="LocalAirport" sx={{ color: `${color}`, fontSize: size }} />,
        <LocalAtm key="LocalAtm" sx={{ color: `${color}`, fontSize: size }} />,
        <LocalBar key="LocalBar" sx={{ color: `${color}`, fontSize: size }} />,
        <LocalPizza key="LocalPizza" sx={{ color: `${color}`, fontSize: size }} />,
        <LocalPolice key="LocalPolice" sx={{ color: `${color}`, fontSize: size }} />,
        <LunchDining key="LunchDining" sx={{ color: `${color}`, fontSize: size }} />,
        <Mediation key="Mediation" sx={{ color: `${color}`, fontSize: size }} />,
        <MedicalServices key="MedicalServices" sx={{ color: `${color}`, fontSize: size }} />,
        <MonetizationOn key="MonetizationOn" sx={{ color: `${color}`, fontSize: size }} />,
        <Money key="Money" sx={{ color: `${color}`, fontSize: size }} />,
        <MonitorHeart key="MonitorHeart" sx={{ color: `${color}`, fontSize: size }} />,
        <Mood key="Mood" sx={{ color: `${color}`, fontSize: size }} />,
        <MoodBad key="MoodBad" sx={{ color: `${color}`, fontSize: size }} />,
        <Navigation key="Navigation" sx={{ color: `${color}`, fontSize: size }} />,
        <NearMe key="NearMe" sx={{ color: `${color}`, fontSize: size }} />,
        <NetworkWifi2Bar key="NetworkWifi2Bar" sx={{ color: `${color}`, fontSize: size }} />,
        <NewReleases key="NewReleases" sx={{ color: `${color}`, fontSize: size }} />,
        <NotStarted key="NotStarted" sx={{ color: `${color}`, fontSize: size }} />,
        <Notifications key="Notifications" sx={{ color: `${color}`, fontSize: size }} />,
        <NotificationsActive key="NotificationsActive" sx={{ color: `${color}`, fontSize: size }} />,
        <OfflineBolt key="OfflineBolt" sx={{ color: `${color}`, fontSize: size }} />,
        <Outbound key="Outbound" sx={{ color: `${color}`, fontSize: size }} />,
        <Paid key="Paid" sx={{ color: `${color}`, fontSize: size }} />,
        <Paragliding key="Paragliding" sx={{ color: `${color}`, fontSize: size }} />,
        <Park key="Park" sx={{ color: `${color}`, fontSize: size }} />,
        <Pattern key="Pattern" sx={{ color: `${color}`, fontSize: size }} />,
        <Percent key="Percent" sx={{ color: `${color}`, fontSize: size }} />,
        <PhotoCamera key="PhotoCamera" sx={{ color: `${color}`, fontSize: size }} />,
        <PinDrop key="PinDrop" sx={{ color: `${color}`, fontSize: size }} />,
        <PlayArrow key="PlayArrow" sx={{ color: `${color}`, fontSize: size }} />,
        <Poll key="Poll" sx={{ color: `${color}`, fontSize: size }} />,
        <Power key="Power" sx={{ color: `${color}`, fontSize: size }} />,
        <PriceChange key="PriceChange" sx={{ color: `${color}`, fontSize: size }} />,
        <PriceCheck key="PriceCheck" sx={{ color: `${color}`, fontSize: size }} />,
        <PriorityHigh key="PriorityHigh" sx={{ color: `${color}`, fontSize: size }} />,
        <PrivacyTip key="PrivacyTip" sx={{ color: `${color}`, fontSize: size }} />,
        <Public key="Public" sx={{ color: `${color}`, fontSize: size }} />,
        <PushPin key="PushPin" sx={{ color: `${color}`, fontSize: size }} />,
        <QuestionMark key="QuestionMark" sx={{ color: `${color}`, fontSize: size }} />,
        <RadioButtonChecked key="RadioButtonChecked" sx={{ color: `${color}`, fontSize: size }} />,
        <Recommend key="Recommend" sx={{ color: `${color}`, fontSize: size }} />,
        <Rectangle key="Rectangle" sx={{ color: `${color}`, fontSize: size }} />,
        <RemoveRedEye key="RemoveRedEye" sx={{ color: `${color}`, fontSize: size }} />,
        <ReplayCircleFilled key="ReplayCircleFilled" sx={{ color: `${color}`, fontSize: size }} />,
        <Reply key="Reply" sx={{ color: `${color}`, fontSize: size }} />,
        <Report key="Report" sx={{ color: `${color}`, fontSize: size }} />,
        <Rocket key="Rocket" sx={{ color: `${color}`, fontSize: size }} />,
        <RocketLaunch key="RocketLaunch" sx={{ color: `${color}`, fontSize: size }} />,
        <Route key="Route" sx={{ color: `${color}`, fontSize: size }} />,
        <RssFeed key="RssFeed" sx={{ color: `${color}`, fontSize: size }} />,
        <Savings key="Savings" sx={{ color: `${color}`, fontSize: size }} />,
        <School key="School" sx={{ color: `${color}`, fontSize: size }} />,
        <Security key="Security" sx={{ color: `${color}`, fontSize: size }} />,
        <Shield key="Shield" sx={{ color: `${color}`, fontSize: size }} />,
        <ShoppingCart key="ShoppingCart" sx={{ color: `${color}`, fontSize: size }} />,
        <SignalCellularAlt key="SignalCellularAlt" sx={{ color: `${color}`, fontSize: size }} />,
        <Skateboarding key="Skateboarding" sx={{ color: `${color}`, fontSize: size }} />,
        <SmartDisplay key="SmartDisplay" sx={{ color: `${color}`, fontSize: size }} />,
        <Snowboarding key="Snowboarding" sx={{ color: `${color}`, fontSize: size }} />,
        <Speaker key="Speaker" sx={{ color: `${color}`, fontSize: size }} />,
        <Spoke key="Spoke" sx={{ color: `${color}`, fontSize: size }} />,
        <SportsBaseball key="SportsBaseball" sx={{ color: `${color}`, fontSize: size }} />,
        <SportsBasketball key="SportsBasketball" sx={{ color: `${color}`, fontSize: size }} />,
        <SportsEsports key="SportsEsports" sx={{ color: `${color}`, fontSize: size }} />,
        <SportsFootball key="SportsFootball" sx={{ color: `${color}`, fontSize: size }} />,
        <SportsScore key="SportsScore" sx={{ color: `${color}`, fontSize: size }} />,
        <SportsSoccer key="SportsSoccer" sx={{ color: `${color}`, fontSize: size }} />,
        <SportsTennis key="SportsTennis" sx={{ color: `${color}`, fontSize: size }} />,
        <SportsVolleyball key="SportsVolleyball" sx={{ color: `${color}`, fontSize: size }} />,
        <Square key="Square" sx={{ color: `${color}`, fontSize: size }} />,
        <SsidChart key="SsidChart" sx={{ color: `${color}`, fontSize: size }} />,
        <StackedBarChart key="StackedBarChart" sx={{ color: `${color}`, fontSize: size }} />,
        <StackedLineChart key="StackedLineChart" sx={{ color: `${color}`, fontSize: size }} />,
        <Stairs key="Stairs" sx={{ color: `${color}`, fontSize: size }} />,
        <Star key="Star" sx={{ color: `${color}`, fontSize: size }} />,
        <Storm key="Storm" sx={{ color: `${color}`, fontSize: size }} />,
        <Support key="Support" sx={{ color: `${color}`, fontSize: size }} />,
        <Surfing key="Surfing" sx={{ color: `${color}`, fontSize: size }} />,
        <SwapHorizontalCircle key="SwapHorizontalCircle" sx={{ color: `${color}`, fontSize: size }} />,
        <Thermostat key="Thermostat" sx={{ color: `${color}`, fontSize: size }} />,
        <ThumbUp key="ThumbUp" sx={{ color: `${color}`, fontSize: size }} />,
        <ThumbDown key="ThumbDown" sx={{ color: `${color}`, fontSize: size }} />,
        <Thunderstorm key="Thunderstorm" sx={{ color: `${color}`, fontSize: size }} />,
        <Token key="Token" sx={{ color: `${color}`, fontSize: size }} />,
        <Tornado key="Tornado" sx={{ color: `${color}`, fontSize: size }} />,
        <TrackChanges key="TrackChanges" sx={{ color: `${color}`, fontSize: size }} />,
        <Tune key="Tune" sx={{ color: `${color}`, fontSize: size }} />,
        <UnfoldMore key="UnfoldMore" sx={{ color: `${color}`, fontSize: size }} />,
        <Upload key="Upload" sx={{ color: `${color}`, fontSize: size }} />,
        <Watch key="Watch" sx={{ color: `${color}`, fontSize: size }} />,
        <WaterDrop key="WaterDrop" sx={{ color: `${color}`, fontSize: size }} />,
        <Webhook key="Webhook" sx={{ color: `${color}`, fontSize: size }} />,
        <Whatshot key="Whatshot" sx={{ color: `${color}`, fontSize: size }} />,
        <Wifi key="Wifi" sx={{ color: `${color}`, fontSize: size }} />,
        <Work key="Work" sx={{ color: `${color}`, fontSize: size }} />,
        <Yard key="Yard" sx={{ color: `${color}`, fontSize: size }} />
    ]

    return Icons
}

export default getIcons