import { useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";

import WidgetStyles from "../WidgetStyles.module.css"
import EconomicCalendarTabMenuItem from "./tabs/economic-calendar-tab-menu-item";
import EconomicCalendarTabSwitch from "./tabs/economic-calendar-tab-switch";

type Props = {
    id: string;
    tabNames?: string[]
}

const defaultProps = {
    tabNames: ["Economy", "Earnings", "Dividends", "Upcoming IPOs"]
}

const EconomicCalendarWidgetContent: React.FC<Props> = (props) => {
    const { tabNames } = { ...defaultProps, ...props }

    const { width, ref } = useResizeDetector();

    const tabRef = useRef<HTMLDivElement | null>(null);

    const updateScroll = (e: React.WheelEvent<HTMLDivElement>) => {
        if (!tabRef.current) {
            return
        }
        if(e.deltaY > 0) {
            tabRef.current.scrollLeft += 20
        } else {
            tabRef.current.scrollLeft -= 20
        }
    }

    const [selectedTab, setSelectedTab] = useState("Economy");

    return (
        <div className={WidgetStyles.container} ref={ref}>
            <div className={WidgetStyles.tab_row} ref={tabRef} onWheel={updateScroll}>
                {tabNames?.map((tabName) => <EconomicCalendarTabMenuItem tabName={tabName} isSelected={selectedTab === tabName} setSelectedTab={setSelectedTab} key={tabName} />)}
            </div>
            <EconomicCalendarTabSwitch id={props.id} tabName={selectedTab} width={width!} />
        </div>
    )
}

export default EconomicCalendarWidgetContent