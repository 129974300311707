import React, { useEffect, useRef, useState } from "react";
import { Column } from "react-table";
import { v4 } from "uuid";

import { changeDashboardData } from "../../../../../../../../store/features/dashboard";
import { changeUserData, saveUser } from "../../../../../../../../store/features/user";
import { useAppDispatch, useAppSelector } from "../../../../../../../../store/hooks";
import { DashboardScreenerTableSettings } from "../../../../../../../../types/Dashboard";
import BootstrapTooltip from "../../../../../../../../utils/components/tooltip/bootstrap-tooltip";
import TableTopRowButtonAddedColumnRow from "./table-top-row-button-added-column-row";
import TableTopRowButtonColumnStyle from "./TableTopRowButtonColumn.module.css"

type Props = {
    settings: DashboardScreenerTableSettings;
    columns: Column<object>[];
}

const TableTopRowButtonColumnsPopupLeft: React.FC<Props> = (props) => {
    const [saveTemplateButtonClicked, setSaveTemplateButtonClicked] = useState(false);

    const [name, setName] = useState("");

    const inputRef = useRef<HTMLInputElement | null>(null);
    const inputRowRef = useRef<HTMLDivElement | null>(null);

    const dragItem = useRef<number | null>(null);
    const dragOverItem = useRef<number | null>(null);

    const [insertAfter, setInsertAfter] = useState<number | null>(null);

    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.user)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (inputRowRef.current && !inputRowRef.current.contains(event.target as HTMLElement)) {
                setSaveTemplateButtonClicked(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputRowRef]);

    const acceptButtonClick = () => {
        setSaveTemplateButtonClicked(false)
        setName("")
        const templateColumns: Column<object>[] = [];
        props.columns.forEach((column) => {
            if (!["ticker", "dummy"].includes(column.accessor as string)) {
                templateColumns.push(column)
            }
        })
        const newPresets = { ...user.userData.tableColumnPresets }
        const newId: string = v4();
        newPresets[newId] = {
            name,
            columns: templateColumns
        }
        dispatch(changeUserData({
            tableColumnPresets: newPresets
        }));
        dispatch(saveUser());
        const newSettings = {
            ...props.settings,
            columns: templateColumns,
            selectedColumnPreset: newId
        }
        dispatch(changeDashboardData({ screenerTableSettings: newSettings }));
    }

    const handleKeypress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            acceptButtonClick();
        }
    };

    const onDragStart = (index: number) => {
        dragItem.current = index
    }

    const onDragEnter = (index: number) => {
        dragOverItem.current = index
        if (dragOverItem.current !== null && dragItem.current !== null) {
            if (dragOverItem.current === dragItem.current) {
                setInsertAfter(null)
            }
            if (dragOverItem.current < dragItem.current) {
                setInsertAfter(dragOverItem.current - 1)
            }
            if (dragOverItem.current > dragItem.current) {
                setInsertAfter(dragOverItem.current)
            }
        }
    }

    const handleSort = () => {
        if (dragItem?.current !== null && dragOverItem?.current !== null) {
            const newColumns: Column<object>[] = [...props.columns]
            const draggedItemContent = newColumns.splice(dragItem.current, 1)[0]
            newColumns.splice(dragOverItem.current, 0, draggedItemContent)
            const newSettings = {
                ...props.settings,
                columns: newColumns
            }
            dispatch(changeDashboardData({ screenerTableSettings: newSettings }));
            dragItem.current = null;
            dragOverItem.current = null;
        }
    }

    return (
        <div className={TableTopRowButtonColumnStyle.column_wrapper}>
            <div className={TableTopRowButtonColumnStyle.save_as_template_row} ref={inputRowRef}>
                <div className={TableTopRowButtonColumnStyle.save_as_template_input_wrapper} style={{ width: saveTemplateButtonClicked ? "" : "0px", padding: saveTemplateButtonClicked ? "" : "0px", borderWidth: saveTemplateButtonClicked ? "" : "0px", marginRight: saveTemplateButtonClicked ? "" : "0px" }}>
                    <input className={TableTopRowButtonColumnStyle.save_as_template_input}
                        type="text"
                        placeholder="Enter a name..."
                        onChange={(event) => {if (event.target.value.length <= 50) {setName(event.target.value)}}}
                        value={name}
                        ref={inputRef}
                        onKeyDown={(e) => handleKeypress(e)}
                    />
                    {name !== "" && saveTemplateButtonClicked ? (
                        <div className={TableTopRowButtonColumnStyle.save_as_template_input_delete} onClick={() => setName("")}>
                            <svg viewBox="0 0 1024 1024" className={TableTopRowButtonColumnStyle.save_as_template_input_delete_icon}>
                                <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                            </svg>
                        </div>
                    ) : null}
                </div>
                {!saveTemplateButtonClicked ? (
                    <div className={TableTopRowButtonColumnStyle.save_as_template_button} onClick={() => {setSaveTemplateButtonClicked(true); inputRef.current?.focus();}}>
                        <span className={TableTopRowButtonColumnStyle.save_as_template_button_text}>Save as template</span>
                    </div>
                ) : null}
                {(saveTemplateButtonClicked && name.length >= 3) ? (
                    <div className={TableTopRowButtonColumnStyle.save_as_template_accept_button} onClick={() => acceptButtonClick()}>
                        <svg viewBox="0 0 16 16" className={TableTopRowButtonColumnStyle.save_as_template_accept_icon}>
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                        </svg>
                    </div>
                ) : null}
                {(saveTemplateButtonClicked && name.length < 3) ? (
                    <BootstrapTooltip title="Please enter at least 3 characters">
                        <div className={`${TableTopRowButtonColumnStyle.save_as_template_accept_button} ${TableTopRowButtonColumnStyle.blue_button_disabled}`}>
                            <svg viewBox="0 0 16 16" className={TableTopRowButtonColumnStyle.save_as_template_accept_icon} style={{ fill: "rgba(var(--dl-color-text-black), 0.7)" }}>
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                            </svg>
                        </div>
                    </BootstrapTooltip>
                ) : null}
            </div>
            <span className={TableTopRowButtonColumnStyle.text_between}>Already added:</span>
            <div className={TableTopRowButtonColumnStyle.columns_list}>
                {props.columns.map((column, index) => (
                    <React.Fragment key={index}>
                        {!["ticker", "dummy"].includes(column.accessor as string) ? (
                            <div
                                className={TableTopRowButtonColumnStyle.added_column_row_draggable}
                                draggable
                                onDragStart={() => onDragStart(index)}
                                onDragEnter={() => onDragEnter(index)}
                                onDragEnd={() => {handleSort(); setInsertAfter(null)}}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <TableTopRowButtonAddedColumnRow column={column} settings={props.settings} />
                            </div>
                        ) : null}
                        {insertAfter === index ? <div className={TableTopRowButtonColumnStyle.added_column_row_drag_line} /> : null}
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}


export default TableTopRowButtonColumnsPopupLeft