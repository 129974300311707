import { useMemo } from 'react';
import { createPortal } from 'react-dom';

import { useAppSelector } from '../../../../store/hooks';
import getDropdownDisplayValue from '../../../functions/dropdown-display-value';
import usePopperPopup from '../../../hooks/use-popper-popup';
import ConditionRow from "../ConditionRow.module.css"

type Props = {
    dropdownOptions: string[];
    transformType: string;
    value: string;
    setValue: (value: string) => void;
    innerPopup?: boolean;
}

const defaultProps = {
    dropdownOptions: [],
    transformType: "",
    value: "",
    setValue: () => {},
    innerPopup: false,
}

const CriteriaDropdown: React.FC<Props> = (props) => {
    const { dropdownOptions, transformType, value, setValue, innerPopup } = { ...defaultProps, ...props }
    
    const management = useAppSelector((state) => state.management)
    
    const { setButton, setPopup, buttonRef: wrapperRef, styles, attributes, isOpen: dropdownOpen, setIsOpen: setDropdownOpen } = usePopperPopup({ offsetY: 4 })

    const disabled = useMemo(() => dropdownOptions.length <= 1, [dropdownOptions]);

    return (
        <div className={ConditionRow.criteria_dropdown_wrapper} ref={wrapperRef}>
            <div
                className={disabled ? `${ConditionRow.criteria_dropdown_button} ${ConditionRow.criteria_dropdown_button_disabled}` : `${ConditionRow.criteria_dropdown_button}`}
                style={{ borderColor: dropdownOpen ? "rgb(var(--dl-color-primary-100))" : "" }}
                onClick={() => {setDropdownOpen(!dropdownOpen)}} ref={setButton}
            >
                <span className={ConditionRow.criteria_dropdown_button_text}>{getDropdownDisplayValue(transformType, value)}</span>
                {!disabled && (
                    <svg viewBox="0 0 1024 1024" className={ConditionRow.criteria_dropdown_button_arrow_icon} style={{ fill: dropdownOpen ? "rgb(var(--dl-color-primary-100))" : "", transform: dropdownOpen ? "rotate(180deg)" : "" }}>
                        <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                    </svg>
                )}
            </div>
            {dropdownOpen && (management.popupElement !== null || innerPopup) ? (
                <div className={ConditionRow.criteria_dropdown_menu} ref={setPopup} style={styles.popper} {...attributes.popper}>
                    <div className={ConditionRow.criteria_dropdown_menu_scroll_wrapper}>
                        {dropdownOptions.map((option, index) => (
                            <div className={ConditionRow.criteria_dropdown_menu_item_wrapper} style={{ backgroundColor: option === value ? "rgb(var(--dl-color-primary-100))" : "" }} key={index} onClick={() => {setValue(option); setDropdownOpen(false)}}>
                                <p className={ConditionRow.criteria_dropdown_menu_item} style={{ color: option === value ? "rgb(var(--dl-color-white-always))" : "" }}>{getDropdownDisplayValue(transformType, option)}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
            {dropdownOpen && management.popupElement === null && !innerPopup ? createPortal(
                <div className={ConditionRow.criteria_dropdown_menu} ref={setPopup} style={{ ...styles.popper, minWidth: wrapperRef.current?.clientWidth }} {...attributes.popper}>
                    <div className={ConditionRow.criteria_dropdown_menu_scroll_wrapper}>
                        {dropdownOptions.map((option, index) => (
                            <div className={ConditionRow.criteria_dropdown_menu_item_wrapper} style={{ backgroundColor: option === value ? "rgb(var(--dl-color-primary-100))" : "" }} key={index} onClick={() => {setValue(option); setDropdownOpen(false)}}>
                                <p className={ConditionRow.criteria_dropdown_menu_item} style={{ color: option === value ? "rgb(var(--dl-color-white-always))" : "" }}>{getDropdownDisplayValue(transformType, option)}</p>
                            </div>
                        ))}
                    </div>
                </div>,
                document.body
            ) : null}
        </div>
    )
}

export default CriteriaDropdown