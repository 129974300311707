import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import PageNotFound from './PageNotFound.module.css'

const PageNotFoundPage: React.FC = () => (
    <div className={PageNotFound.wrapper}>
        <Helmet>
            <title>Error 404 - Page Not Found | Traddle.io</title>
            <meta
                property="og:title"
                content="Error 404 - Page Not Found | Traddle.io"
            />
        </Helmet>
        <p className={PageNotFound.error_header}>Error 404</p>
        <p className={PageNotFound.error_subheader}>Page not found.</p>
        <Link to="/" className={PageNotFound.button_home_link}>
            <button type="button" className={PageNotFound.button_home}>Back to Home</button>
        </Link>
    </div>
)

export default PageNotFoundPage