import React from "react";

import WidgetStyles from "../../../../WidgetStyles.module.css"
import TableEarningsHeader from "./table-earnings-header";
import TableEarningsList from "./table-earnings-list";
import TableEarningsSeparator from "./table-earnings-separator";

type Props = {
    tickerSearch: string;
}

const TableEarnings: React.FC<Props> = (props) => (
    <div className={WidgetStyles.simple_table_wrapper}>
        <TableEarningsHeader />
        <TableEarningsSeparator />
        <TableEarningsList tickerSearch={props.tickerSearch} />
    </div>
)

export default TableEarnings