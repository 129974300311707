import { useMemo } from 'react';
import { createPortal } from 'react-dom';

import { useAppSelector } from '../../../../../../store/hooks';
import { IndicatorParameters } from '../../../../../../types/Screener';
import usePopperPopup from '../../../../../hooks/use-popper-popup';
import ConditionRow from "../../../ConditionRow.module.css"
import CriteriaInputNumber from '../../criteria-input-number';
import checkUnusedParams from '../../functions';
import CriteriaParameterAverageVolumePopup from './criteria-parameter-average-volume-popup';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
    titleWidth: string;
    fieldWidth: string
}

const defaultProps: Props = {
    parameters: {
        timeframe: "1 minute",
        length: "50",
        multiplicator: "1"
    },
    setParameters: () => {},
    titleWidth: "80px",
    fieldWidth: "70px"
}

const CriteriaParameterAverageVolume: React.FC<Props> = (props) => {
    const timeframe = useMemo(() => props.parameters.timeframe ? props.parameters.timeframe : defaultProps.parameters.timeframe!, [props.parameters.timeframe])
    const length = useMemo(() => props.parameters.length ? props.parameters.length : defaultProps.parameters.length!, [props.parameters.length])
    const multiplicator = useMemo(() => props.parameters.multiplicator ? props.parameters.multiplicator : defaultProps.parameters.multiplicator!, [props.parameters.multiplicator])

    const management = useAppSelector((state) => state.management)

    const { setButton, setPopup, buttonRef: wrapperRef, styles, attributes, isOpen: dropdownOpen, setIsOpen: setDropdownOpen } = usePopperPopup({ offsetY: 4 })

    const setTimeframe = (newTimeframe: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, timeframe: newTimeframe }, defaultProps.parameters))
    }

    const setLength = (newLength: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, length: newLength }, defaultProps.parameters))
    }

    const setMultiplicator = (newMultiplicator: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, multiplicator: newMultiplicator }, defaultProps.parameters))
    }

    return (
        <>
            <div className={ConditionRow.criteria_dropdown_wrapper} ref={wrapperRef}>
                <div
                    className={ConditionRow.criteria_dropdown_button}
                    style={{ borderColor: dropdownOpen ? "rgb(var(--dl-color-primary-100))" : "", padding: "0px 8px" }}
                    onClick={() => {setDropdownOpen(!dropdownOpen)}} ref={setButton}
                >
                    <span className={ConditionRow.criteria_dropdown_button_text}>{`${timeframe} | ${length}`}</span>
                </div>
                {dropdownOpen && management.popupElement !== null ? (
                    <div className={`${ConditionRow.criteria_dropdown_menu} ${ConditionRow.dropdown_parameter}`} ref={setPopup} style={styles.popper} {...attributes.popper}>
                        <CriteriaParameterAverageVolumePopup titleWidth={props.titleWidth} fieldWidth={props.fieldWidth} timeframe={timeframe} setTimeframe={setTimeframe} length={length} setLength={setLength} />
                    </div>
                ) : null}
                {dropdownOpen && management.popupElement === null ? createPortal(
                    <div className={`${ConditionRow.criteria_dropdown_menu} ${ConditionRow.dropdown_parameter}`} ref={setPopup} style={{ ...styles.popper, minWidth: wrapperRef.current?.clientWidth }} {...attributes.popper}>
                        <CriteriaParameterAverageVolumePopup titleWidth={props.titleWidth} fieldWidth={props.fieldWidth} timeframe={timeframe} setTimeframe={setTimeframe} length={length} setLength={setLength} />
                    </div>,
                    document.body
                ) : null}
            </div>
            <span className={ConditionRow.criteria_text}>x</span>
            <CriteriaInputNumber number={multiplicator} setNumber={setMultiplicator} min={-99.99} max={99.99} step="0.01" width={props.fieldWidth} />
        </>
    )
}

export default CriteriaParameterAverageVolume