import SignupLogin from './SignupLogin.module.css'

export const getInputClasses = (inputName: string, inputNameComparison: string, errorName: string): string => {
    if (inputName === inputNameComparison) {
        if (errorName !== "") {
            return `${SignupLogin.textinput} ${SignupLogin.textinput_content} ${SignupLogin.border_error}`
        }
        return `${SignupLogin.textinput} ${SignupLogin.textinput_content}`
    }
    if (errorName !== "") {
        return `${SignupLogin.textinput} ${SignupLogin.border_error}`
    }
    return `${SignupLogin.textinput}`
}