import _ from 'lodash';
import { createRef, RefObject, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { login, resetUserError, signInSSO } from '../../store/features/user';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { isText } from '../../types/utils/is-text';
import LoadingSpinner from '../../utils/components/loading-spinner/loading-spinner';
import SignupLoginBackground from './components/signup-login-bg';
import SignUpLoginInfoMessage from './components/signup-login-info';
import SignUpLoginWarning from './components/signup-login-warning';
import { getInputClasses } from './get-input-classes';
import SignupLogin from './SignupLogin.module.css'

const initialValueErrors = {
    "email": "",
    "password": "",
    "unknown": "",
}

const Login: React.FC = () => {
    const [searchParams] = useSearchParams();
  
    const [email, setEmail] = useState(searchParams.get('email') ? searchParams.get('email')! : "");
    const [password, setPassword] = useState("");
    const [ssoSelected, setSsoSelected] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordClicked, setPasswordClicked] = useState(false);

    const navigate = useNavigate();

    const [errors, setErrors] = useState(_.clone(initialValueErrors));

    const [clickType, setClickType] = useState("");

    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user)

    const [activeInput, setActiveInput] = useState("");
    const emailRef = createRef<HTMLInputElement>();
    const passwordRef = createRef<HTMLInputElement>();

    useEffect(() => {
        dispatch(resetUserError());
    }, []);

    useEffect(() => {
        if (!passwordClicked) {
            return emailRef.current?.focus();
        }
        passwordRef.current?.focus();
        passwordRef.current?.setSelectionRange(passwordRef.current.value.length, passwordRef.current.value.length)
    }, [showPassword]);

    document.addEventListener("mousedown", () => setActiveInput(""));

    const handleMouseOut = (currentRef: RefObject<HTMLInputElement>) => {
        if (document.activeElement === currentRef.current) {
            if (emailRef === currentRef) {
                setActiveInput("email")
            } else if (passwordRef === currentRef) {
                setActiveInput("password")
            }
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        const emailTrim = event.target.value.trim();

        if (clickType !== "password") {
            return
        }

        const emailSplit = emailTrim.split("@");
        let emailEnding = "";
        if (emailSplit[emailSplit.length - 1].includes(".")) {
            const emailEndingSplit = emailSplit[emailSplit.length - 1].split(".")
            emailEnding = emailEndingSplit[emailEndingSplit.length - 1]
        }
    
        const newErrors = errors;
        if (emailTrim.length === 0 || !emailTrim.includes("@") || emailEnding.length === 0 || emailTrim.includes(" ")) {
            newErrors.email = "Please enter a valid e-mail address.";
        } else {
            newErrors.email = "";
        }
        setErrors(newErrors);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value
        setPassword(newPassword);

        if (clickType !== "password") {
            return
        }
        
        const newErrors = errors;
        if (password.includes(" ")) {
            newErrors.password = "A password cannot contain white spaces.";
        }
        if (password.length === 0) {
            newErrors.password = "This field is required.";
        } else {
            newErrors.password = "";
        }
        setErrors(newErrors);
    }

    const handleLoginPassword = async () => {
        setClickType("password");

        const emailTrim = email.trim()

        const emailSplit = emailTrim.split("@");
        let emailEnding = "";
        if (emailSplit[emailSplit.length - 1].includes(".")) {
            const emailEndingSplit = emailSplit[emailSplit.length - 1].split(".")
            emailEnding = emailEndingSplit[emailEndingSplit.length - 1]
        }
    
        const newErrors = _.clone(initialValueErrors);

        if (emailTrim.length === 0 || !emailTrim.includes("@") || emailEnding.length === 0 || emailTrim.includes(" ")) {
            newErrors.email = "Please enter a valid e-mail address.";
        }

        if (password.includes(" ")) {
            newErrors.password = "A password cannot contain white spaces.";
        }
        if (password.length === 0) {
            newErrors.password = "This field is required.";
        }
    
        if (_.isEqual(newErrors, initialValueErrors) && errors.email === "" && errors.password === "") {
            try {
                const res = await dispatch(login({
                    email: emailTrim,
                    password
                })).unwrap();
                if (res) {
                    navigate(res.route);
                }
            } catch (error) {
                if (isText(error)) {
                    if (!error.includes("Popup error")) {
                        if (error === "Google" || error === "Facebook") {
                            setSsoSelected(error);
                            newErrors.unknown = `This e-mail address is linked with a ${  error  } account. Please use the ${  error  } login below to access your account.`
                        } else if (error.includes("e-mail")) {
                            newErrors.email = error;
                        } else if (error.includes("password")) {
                            newErrors.password = error;
                        } else {
                            newErrors.unknown = error;
                        }
                    } else {
                        newErrors.unknown = "";
                    }
                }
            }
        }
        setErrors(newErrors);
    }

    const handleLoginSSO = async () => {
        setClickType("sso");

        const newErrors = _.clone(initialValueErrors);
        setErrors(newErrors);

        try {
            let method = ""
            if (ssoSelected === "Google") {
                method = "google.com"
            } else if (ssoSelected === "Facebook") {
                method = "facebook.com"
            }
            const res = await dispatch(signInSSO(method)).unwrap();
            if (res) {
                navigate(res.route);
            }
        } catch (error) {
            if (isText(error)) {
                if (!error.includes("Popup error")) {
                    if (error.includes("account-exists-with-different-credential")) {
                        newErrors.unknown = `This e-mail address is not linked with your ${  ssoSelected  } account. Please login with another provider.`
                    } else {
                        newErrors.unknown = error
                    }
                } else {
                    newErrors.unknown = "";
                }
            }
        }
        setErrors(newErrors);
    }
  
    const handleKeypress = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter" && ["email", "password"].includes(activeInput)) {
            await handleLoginPassword();
        }
    };

    return (
        <div className={SignupLogin.page_wrapper}>
            <Helmet>
                <title>Login | Traddle.io</title>
                <meta property="og:title" content="Login | Traddle.io" />
            </Helmet>
            <div className={SignupLogin.top_bar_wrapper}>
                <div className={SignupLogin.top_bar_left}>
                    <img
                        alt="Traddle Logo"
                        src="/logos/traddle_logo_comp-200h.png"
                        loading="eager"
                        className={SignupLogin.logo}
                    />
                    <span className={SignupLogin.logo_text}>Traddle</span>
                </div>
                <div className={SignupLogin.top_bar_right}>
                    <span className={SignupLogin.top_bar_right_text}>Don't have an account?</span>
                    <Link to={!email ? "/sign-up" : `/sign-up?email=${  email}`}>
                        <button type="button" className={SignupLogin.top_bar_right_button}>Sign Up</button>
                    </Link>
                </div>
            </div>
            <SignupLoginBackground />
            <div className={SignupLogin.form_wrapper}>
                <div className={SignupLogin.form_container}>
                    <h1 className={SignupLogin.header}>Login to your account.</h1>
                    <p className={SignupLogin.subheader}>Continue trading the edge after logging in to your account.</p>
                    <form className={SignupLogin.password_input_container}>
                        <div className={SignupLogin.input_row}>
                            <div className={activeInput === "email" ? `${SignupLogin.input_label} ${SignupLogin.input_label_focus}` : `${SignupLogin.input_label}`}>{email === "" || activeInput === "email" ? "E-Mail" : ""}</div>
                            <div className={SignupLogin.input_field}>
                                <input
                                    type="text"
                                    required
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    className={getInputClasses(activeInput, "email", errors.email)}
                                    onChange={(event) => handleEmailChange(event)}
                                    value={email}
                                    onKeyDown={handleKeypress}
                                    ref={emailRef}
                                    onFocus={() => handleMouseOut(emailRef)}
                                />
                            </div>
                            <SignUpLoginWarning visible={errors.email !== ""} text={errors.email} />
                        </div>
                        <div className={SignupLogin.input_row}>
                            <div className={activeInput === "password" ? `${SignupLogin.input_label} ${SignupLogin.input_label_focus}` : `${SignupLogin.input_label}`}>{password === "" || activeInput === "password" ? "Password" : ""}</div>
                            <div className={SignupLogin.input_field}>
                                <input
                                    type={ showPassword ? "text" : "password" }
                                    required
                                    id="current-password"
                                    name="current-password"
                                    autoComplete="current-password"
                                    className={getInputClasses(activeInput, "password", errors.password)}
                                    onChange={(event) => handlePasswordChange(event)}
                                    value={password}
                                    onKeyDown={handleKeypress}
                                    ref={passwordRef}
                                    onFocus={() => handleMouseOut(passwordRef)}
                                />
                                <div className={SignupLogin.show_password_wrapper} onClick={() => {setPasswordClicked(true); setShowPassword(!showPassword)}}>
                                    {showPassword && (
                                        <p className={SignupLogin.show_password}>Hide</p>
                                    )}
                                    {!showPassword && (
                                        <p className={SignupLogin.show_password}>Show</p>
                                    )}
                                </div>
                            </div>
                            <SignUpLoginWarning visible={errors.password !== ""} text={errors.password} />
                            <div className={errors.password !== "" ? `${SignupLogin.forgot_password} ${SignupLogin.forgot_password_move_up}` : `${SignupLogin.forgot_password}`}>
                                <Link to={!email ? "/forgot-password" : `/forgot-password?email=${  email}`} className={SignupLogin.forgot_password_text}>Forgot password?</Link>
                            </div>
                        </div>
                        <SignUpLoginInfoMessage visible={errors.unknown !== "" && clickType === "password"} color="red" text={errors.unknown} />
                        <button type="button" className={clickType !== "password" || (errors.email === "" && errors.password === "" && email !== "" && password !== "") ? `${SignupLogin.submit_button}` : `${SignupLogin.submit_button} ${SignupLogin.button_error}`} onClick={handleLoginPassword} disabled={!(clickType !== "password" || (errors.email === "" && errors.password === "" && email !== "" && password !== ""))}>
                            {(user.loading && clickType === "password") ? (
                                <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                            ) : (
                                <>Trade with Traddle</>
                            )}
                        </button>
                        <div className={SignupLogin.page_switch}>
                            <span>Don't have an account?</span>
                            <Link to={!email ? "/sign-up" : `/sign-up?email=${  email}`} className={SignupLogin.page_switch_link}>Sign up for free</Link>
                        </div>
                    </form>
                    <div className={SignupLogin.separator_container}>
                        <div className={SignupLogin.separator_line} />
                        <span className={SignupLogin.separator_text}>or login with</span>
                        <div className={SignupLogin.separator_line} />
                    </div>
                    <div className={SignupLogin.sso_input_container}>
                        <div className={SignupLogin.sso_icon_row}>
                            <div className={ssoSelected === "Google" ? `${SignupLogin.sso_icon_wrapper} ${SignupLogin.border_primary}` : `${SignupLogin.sso_icon_wrapper}`} onClick={() => setSsoSelected("Google")}>
                                <img
                                    alt="Google Logo"
                                    src='/icons/google_icon-200w.png'
                                    className={SignupLogin.sso_icon}
                                />
                            </div>
                            <div className={ssoSelected === "Facebook" ? `${SignupLogin.sso_icon_wrapper} ${SignupLogin.border_primary}` : `${SignupLogin.sso_icon_wrapper}`} onClick={() => setSsoSelected("Facebook")}>
                                <img
                                    alt="Facebook Logo"
                                    src='/icons/facebook_icon-200h.png'
                                    className={SignupLogin.sso_icon}
                                />
                            </div>
                        </div>
                        {ssoSelected !== "" && (
                            <div className={SignupLogin.sso_submit_wrapper}>
                                <SignUpLoginInfoMessage visible={errors.unknown !== "" && clickType === "sso"} color="red" text={errors.unknown} />
                                <button type="button" className={SignupLogin.submit_button} onClick={handleLoginSSO}>
                                    {(user.loading && clickType === "sso") ? (
                                        <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                                    ) : (
                                        <>{`Login with ${  ssoSelected}`}</>
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login