import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../types/Screener';
import ConditionRow from '../../ConditionRow.module.css';
import CriteriaDropdown from '../criteria-dropdown';
import CriteriaInputNumber from '../criteria-input-number';
import checkUnusedParams from '../functions';

type Props = {
    unit: string;
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    unit: "",
    parameters: {
        number: "10",
        factor: "million"
    },
    setParameters: () => {},
}

const CriteriaParameterNumber: React.FC<Props> = (props) => {
    const number = useMemo(() => props.parameters.number ? props.parameters.number : defaultProps.parameters.number!, [props.parameters.number])
    const factor = useMemo(() => props.parameters.factor ? props.parameters.factor : defaultProps.parameters.factor!, [props.parameters.factor])

    const setNumber = (newNumber: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, number: newNumber }, defaultProps.parameters))
    }

    const setFactor = (newFactor: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, factor: newFactor }, defaultProps.parameters))
    }

    return (
        <>
            <CriteriaInputNumber number={number} setNumber={setNumber} min={1} max={999} step="1" width="70px" />
            <CriteriaDropdown dropdownOptions={["thousand", "million", "billion"]} transformType="" value={factor} setValue={setFactor} />
            <span className={ConditionRow.criteria_text}>{props.unit}</span>
        </>
    )
}

export default CriteriaParameterNumber