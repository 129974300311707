import usePopperPopup from "../../../../../utils/hooks/use-popper-popup";
import AppTopNavMiddleStyles from "../AppTopNavMiddleStyles.module.css";
import AppTopNavButtonScreenerMenu from "./app-top-nav-button-screener-menu";

const AppTopNavButtonScreener: React.FC = () => {
    const { setButton, setPopup, buttonRef, styles, attributes, isOpen, setIsOpen } = usePopperPopup({});
    
    return (
        <div className={AppTopNavMiddleStyles.middle_item_wrapper} ref={buttonRef} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
            <div className={AppTopNavMiddleStyles.middle_item} style={{ backgroundColor: isOpen ? "rgb(var(--dl-color-backgrounds-hover1))" : "" }} ref={setButton}>
                <span className={AppTopNavMiddleStyles.middle_item_text} style={{ color: isOpen ? "rgb(var(--dl-color-black))" : "" }}>Screener</span>
            </div>
            <AppTopNavButtonScreenerMenu isOpen={isOpen} setIsOpen={setIsOpen} setPopup={setPopup} styles={styles} attributes={attributes} />
        </div>
    )
}

export default AppTopNavButtonScreener