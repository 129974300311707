import { useEffect, useRef, useState } from 'react';

import ConditionRow from "../ConditionRow.module.css"
import CriteriaInputKeywordItem from './criteria-input-keyword-item';

type Props = {
    keywords: string[];
    setKeywords: (keywords: string[]) => void;
}

const CriteriaInputKeywords: React.FC<Props> = (props) => {
    const [value, setValue] = useState("");
    const [inputActive, setInputActive] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const widthRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (value === "" && scrollRef.current && widthRef.current) {
            scrollRef.current.scrollLeft += (widthRef.current.clientWidth + 1000)
        }
    }, [value])

    useEffect(() => {
        if (scrollRef.current && widthRef.current) {
            scrollRef.current.scrollLeft += (widthRef.current.clientWidth + 1000)
            setValue("")
        }
    }, [inputActive])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
        setValue(newValue)
    }

    const handleKeypress = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            if (inputActive) {
                const newValue = value.trim();
                if (!props.keywords.includes(newValue) && newValue !== "") {
                    props.setKeywords([
                        ...props.keywords,
                        newValue
                    ])
                }
                setValue("")
            }
        }
    };

    return (
        <div
            className={ConditionRow.keyword_input_wrapper}
            style={{ backgroundColor: inputActive ? "rgba(var(--dl-color-text-black), 0.02)" : "" }}
            onClick={() => {inputRef.current?.focus();}}
            ref={scrollRef}
        >
            <div className={ConditionRow.keyword_input_list} ref={widthRef}>
                {[...props.keywords].map((keyword, index) => <CriteriaInputKeywordItem keywords={props.keywords} setKeywords={props.setKeywords} position={index} key={index} />)}
            </div>
            <div className={ConditionRow.keyword_input_container}>
                <input
                    type="text"
                    lang="en"
                    className={ConditionRow.keyword_input}
                    onChange={(event) => handleInputChange(event)}
                    value={value}
                    ref={inputRef}
                    onKeyDown={handleKeypress}
                    onFocus={() => setInputActive(true)}
                    onBlur={() => { setInputActive(false)}}
                />
                {!inputActive && (<span className={ConditionRow.keyword_input_placeholder}>+ Add Keyword</span>)}
            </div>
        </div>
    )
}

export default CriteriaInputKeywords