import { useEffect, useRef, useState } from 'react';

import ConditionRow from "../ConditionRow.module.css"

type Props = {
    number: string;
    setNumber: (number: string) => void;
    min: number;
    max: number;
    step: string;
    width: string;
}

const CriteriaInputNumber: React.FC<Props> = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    const [value, setValue] = useState(props.number);
    const [inputActive, setInputActive] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const precision = props.step.split(".").length > 1 ? props.step.split(".")[1].length : 0
    
    useEffect(() => {
        if (value !== props.number) {
            setValue(props.number)
        }
    }, [props.number])

    useEffect(() => {
        if (!inputActive) {
            if (value === "" || parseFloat(value) < props.min || parseFloat(value) > props.max) {
                props.setNumber(props.number)
                setValue(props.number)
            } else {
                props.setNumber(value)
                setValue(value)
            }
        }
    }, [inputActive])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
        if (newValue.length === 0) {
            setValue("")
        } else {
            const newPrecision = newValue.split(".").length > 1 ? newValue.split(".")[1].length : 0
            if (newPrecision <= precision && parseFloat(newValue) >= props.min && parseFloat(newValue) <= props.max) {
                setValue(newValue)
            }
        }
    }

    const handleKeypress = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            if (inputActive) {
                inputRef.current?.blur();
            }
        }
        if (["e", "E", "+", "-", ".", ","].includes(e.key)) {
            if ((props.min < 0 && e.key === "-") || (props.step.includes(".") && e.key === ".")) {
                return
            }
            e.preventDefault();
        }
    };

    const arrowClick = (direction: string) => {
        const newValue = direction === "up" ? parseInt(value) + 1 : parseInt(value) - 1
        if (newValue >= props.min && newValue <= props.max) {
            setValue(newValue.toString())
            props.setNumber(newValue.toString())
        }
    }

    return (
        <div className={ConditionRow.criteria_input_wrapper} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            {isHovered && (
                <div className={ConditionRow.up_down_icons}>
                    <div className={ConditionRow.up_down_icon_wrapper}>
                        <svg viewBox="0 0 1024 1024" className={ConditionRow.up_down_icon} onClick={() => arrowClick("up")}>
                            <path d="M512 342l256 256-60 60-196-196-196 196-60-60z" />
                        </svg>
                    </div>
                    <div className={ConditionRow.up_down_icon_wrapper}>
                        <svg viewBox="0 0 1024 1024" className={ConditionRow.up_down_icon} onClick={() => arrowClick("down")}>
                            <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                        </svg>
                    </div>
                </div>
            )}
            <input
                type="number"
                lang="en"
                step={props.step}
                className={ConditionRow.criteria_input}
                style={{ width: props.width }}
                onChange={(event) => handleInputChange(event)}
                value={value}
                ref={inputRef}
                onKeyDown={handleKeypress}
                onFocus={() => setInputActive(true)}
                onBlur={() => setInputActive(false)}
            />
        </div>
    )
}

export default CriteriaInputNumber