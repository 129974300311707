import _ from 'lodash';
import { createRef, RefObject, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom'

import { forgotPassword, resetUserError } from '../../store/features/user';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { isText } from '../../types/utils/is-text';
import LoadingSpinner from '../../utils/components/loading-spinner/loading-spinner';
import SignupLoginBackground from './components/signup-login-bg';
import SignUpLoginInfoMessage from './components/signup-login-info';
import SignUpLoginWarning from './components/signup-login-warning';
import { getInputClasses } from './get-input-classes';
import SignupLogin from './SignupLogin.module.css'

const initialValueErrors = {
    "email": "",
    "unknown": "",
}

const ForgotPassword: React.FC = () => {
    const [searchParams] = useSearchParams();

    const [email, setEmail] = useState(searchParams.get('email') ? searchParams.get('email')! : "");

    const [errors, setErrors] = useState(_.clone(initialValueErrors));

    const [clickType, setClickType] = useState("");
    const [resetEmailSent, setResetEmailSent] = useState(false);

    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user)

    const [activeInput, setActiveInput] = useState("email");
    const emailRef = createRef<HTMLInputElement>();

    document.addEventListener("mousedown", () => setActiveInput(""));

    const handleMouseOut = (currentRef: RefObject<HTMLInputElement>) => {
        if (document.activeElement === currentRef.current) {
            if (emailRef === currentRef) {
                setActiveInput("email")
            }
        }
    };

    useEffect(() => {
        dispatch(resetUserError());
    }, []);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        const emailTrim = event.target.value.trim();

        if (clickType !== "resetPassword") {
            return
        }

        const emailSplit = emailTrim.split("@");
        let emailEnding = "";
        if (emailSplit[emailSplit.length - 1].includes(".")) {
            const emailEndingSplit = emailSplit[emailSplit.length - 1].split(".")
            emailEnding = emailEndingSplit[emailEndingSplit.length - 1]
        }

        const newErrors = errors;
        if (emailTrim.length === 0 || !emailTrim.includes("@") || emailEnding.length === 0 || emailTrim.includes(" ")) {
            newErrors.email = "Please enter a valid e-mail address.";
        } else {
            newErrors.email = "";
        }
        setErrors(newErrors);
    }

    const handleForgotPassword = async () => {
        setClickType("resetPassword");

        const newErrors = _.clone(initialValueErrors);
        const emailTrim = email?.trim()

        if (!emailTrim) {
            newErrors.email = "This field is required."
        } else {
            try {
                await dispatch(forgotPassword(emailTrim)).unwrap();
                setResetEmailSent(true);
            } catch (error) {
                if (isText(error)) {
                    if (error && error !== "") {
                        if (error === "No account exists with that e-mail address.") {
                            setResetEmailSent(true);
                        } else if (error.includes("email")) {
                            newErrors.email = error;
                        } else {
                            newErrors.unknown = error;
                        }
                    } else {
                        newErrors.unknown = "";
                    }
                }
            }
        }
        setErrors(newErrors);
    }

    const handleKeypress = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            await handleForgotPassword();
        }
    };

    return (
        <div className={SignupLogin.page_wrapper}>
            <Helmet>
                <title>Forgot Password | Traddle.io</title>
                <meta property="og:title" content="Login | Traddle.io" />
            </Helmet>
            <div className={SignupLogin.top_bar_wrapper}>
                <div className={SignupLogin.top_bar_left}>
                    <img
                        alt="Traddle Logo"
                        src="/logos/traddle_logo_comp-200h.png"
                        loading="eager"
                        className={SignupLogin.logo}
                    />
                    <span className={SignupLogin.logo_text}>Traddle</span>
                </div>
                <div className={SignupLogin.top_bar_right}>
                    <span className={SignupLogin.top_bar_right_text}>No account yet?</span>
                    <Link to={!email ? "/sign-up" : `/sign-up?email=${  email}`}>
                        <button type="button" className={SignupLogin.top_bar_right_button}>Sign Up</button>
                    </Link>
                </div>
            </div>
            <SignupLoginBackground />
            <div className={SignupLogin.form_wrapper}>
                <div className={SignupLogin.form_container}>
                    <h1 className={SignupLogin.header}>Reset your password.</h1>
                    <p className={SignupLogin.subheader}>Enter the e-mail address of your account.</p>
                    <div className={SignupLogin.password_input_container}>
                        <div className={SignupLogin.input_row}>
                            <div className={activeInput === "email" ? `${SignupLogin.input_label} ${SignupLogin.input_label_focus}` : `${SignupLogin.input_label}`}>{email === "" || activeInput === "email" ? "E-Mail" : ""}</div>
                            <div className={SignupLogin.input_field}>
                                <input
                                    type="text"
                                    required
                                    autoComplete="email"
                                    autoFocus
                                    className={getInputClasses(activeInput, "email", errors.email)}
                                    onChange={(event) => handleEmailChange(event)}
                                    value={email}
                                    onKeyDown={handleKeypress}
                                    ref={emailRef}
                                    onFocus={() => handleMouseOut(emailRef)}
                                />
                            </div>
                            <SignUpLoginWarning visible={errors.email !== ""} text={errors.email} />
                        </div>
                        <SignUpLoginInfoMessage visible={errors.unknown !== ""} color="red" text={errors.unknown} />
                        <SignUpLoginInfoMessage visible={resetEmailSent} text="If your e-mail address exists in our database, you will receive a password recovery link at your e-mail address in a few minutes." color="green" />
                        {resetEmailSent ? (
                            <Link to={!email ? "/login" : `/login?email=${  email}`} style={{ width: "100%" }}>
                                <button type="button" className={`${SignupLogin.submit_button}`}>
                                    {user.loading ? (
                                        <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                                    ) : (
                                        <>Back To Login</>
                                    )}
                                </button>
                            </Link>
                        ) : (
                            <button type="button" className={`${SignupLogin.submit_button}`} onClick={() => handleForgotPassword()}>
                                {user.loading ? (
                                    <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                                ) : (
                                    <>Send Password Reset E-Mail</>
                                )}
                            </button>
                        )}
                        <div className={SignupLogin.page_switch}>
                            <span>Want to use another account?</span>
                            <Link to={!email ? "/login" : `/login?email=${  email}`} className={SignupLogin.page_switch_link}>Back to login</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword