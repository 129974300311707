import React from "react";

import WidgetStyles from "../../../../WidgetStyles.module.css"

const TableDividendsHeader: React.FC = () => (
    <div className={WidgetStyles.simple_table_header}>
        <span className={WidgetStyles.simple_table_header_item_left} style={{ maxWidth: "110px" }}>Ticker</span>
        <div className={WidgetStyles.simple_table_header_right}>
            <span className={WidgetStyles.simple_table_header_item} style={{ width: "60%" }}>Company</span>
            <span className={WidgetStyles.simple_table_header_item} style={{ width: "40%" }}>Dividend</span>
        </div>
    </div>
)

export default TableDividendsHeader