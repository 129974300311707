import React from "react";

import WidgetStyles from "../../../../WidgetStyles.module.css"
import TableEconomyRowItem from "./table-economy-row-item";

type Props = {
    width: number
}

const TableEconomyList: React.FC<Props> = (props) => (
    <div className={WidgetStyles.simple_table_wrapper_content}>
        <div className={WidgetStyles.simple_table_wrapper_scroll}>
            <TableEconomyRowItem width={props.width} />
            <TableEconomyRowItem width={props.width} />
            <TableEconomyRowItem width={props.width} />
        </div>
    </div>
)

export default TableEconomyList