import { useMemo } from "react";

import { changeScreenerData, saveScreener } from "../../../../../../store/features/screener";
import { useAppDispatch } from "../../../../../../store/hooks";
import { Screener } from "../../../../../../types/Screener";
import BootstrapTooltip from "../../../../../../utils/components/tooltip/bootstrap-tooltip";
import DashboardScreenerSourceStyles from "./DashboardScreenerSource.module.css"

type Props = {
    screener: Screener;
    id: string;
    name: string;
    type: string;
}

const DashboardScreenerSourceSelectedItem: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const sources = useMemo(() => ({ ...props.screener.sources }), [props.screener.sources])
    const indices = useMemo(() => ([...sources.indices]), [sources])
    const screeners = useMemo(() => ([...sources.screeners]), [sources])
    const watchlists = useMemo(() => ([...sources.watchlists]), [sources])

    const notAllowed = useMemo(() => ((indices.length + screeners.length + watchlists.length) === 1), [indices, screeners, watchlists])

    const removeItem = () => {
        if (notAllowed) {
            return
        }
        let flag = false;
        if (props.type === "Indices") {
            const index = indices.findIndex((i) => i === props.id)
            if (index > -1) {
                indices.splice(index, 1)
                sources.indices = indices
                flag = true;
            }
        }
        if (props.type === "Screeners") {
            const index = screeners.findIndex((s) => s.stateId === props.id)
            if (index > -1) {
                screeners.splice(index, 1)
                sources.screeners = screeners
                flag = true;
            }
        }
        if (props.type === "Watchlists") {
            const index = watchlists.findIndex((w) => w === props.id)
            if (index > -1) {
                watchlists.splice(index, 1)
                sources.watchlists = watchlists
                flag = true;
            }
        }
        if (flag) {
            dispatch(changeScreenerData({
                ...props.screener,
                sources
            }));
            dispatch(saveScreener());
        }
    }

    return (
        <BootstrapTooltip title="You have to select at least one item" disableHoverListener={!notAllowed}>
            <div className={DashboardScreenerSourceStyles.selected_sources_list_item} >
                <div className={DashboardScreenerSourceStyles.selected_sources_list_item_logo_wrapper}>
                    <img alt="Input List" src="/logos/traddle_logo_black_on_white_comp.png" className={DashboardScreenerSourceStyles.selected_sources_list_item_logo} />
                </div>
                <span className={DashboardScreenerSourceStyles.selected_sources_list_item_text}>{props.name}</span>
                <div className={DashboardScreenerSourceStyles.selected_sources_list_item_delete_icon_wrapper} style={{ cursor: notAllowed ? "not-allowed" : "default" }} onClick={() => removeItem()}>
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerSourceStyles.selected_sources_list_item_delete_icon}>
                        <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                    </svg>
                </div>
            </div>
        </BootstrapTooltip>
    )
}
    
export default DashboardScreenerSourceSelectedItem