import { useState } from "react";

import { changeDashboardData, getDashboard, saveDashboard } from "../../../../../../../../store/features/dashboard";
import { setPopupElement } from "../../../../../../../../store/features/management";
import { useAppDispatch, useAppSelector } from "../../../../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../../../../types/changeHandlers/change-dashboard-widget";
import { EconomicCalendarSettings, Widget } from "../../../../../../../../types/Dashboard";
import { allContinents, getAllCountries, sortCountriesByName } from "../../../../../../../constants/country-map";
import useClickOutsidePopup from "../../../../../../../hooks/use-click-outside-popup";
import WidgetTopRowStyles from "../../../../../WidgetTopRowStyles.module.css"

type Props = {
    id: string;
}

const allCountries = getAllCountries()

const EconomyButtonCountryPopup: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const dashboard = useAppSelector((state) => state.dashboard)
    const widget: Widget = dashboard.data.widgets.find((w) => w.id === props.id)!
        
    const settings = widget.settings as EconomicCalendarSettings

    const { countries } = settings.economy    

    const [search, setSearch] = useState("");
    
    const refClickOutside = useClickOutsidePopup();
    
    const changeValues = (countryId: string) => {
        const newEconomy = { ...settings.economy }
        if (countryId === "") {
            newEconomy.countries = []
        } else {
            const newCountries = [ ...countries ]
            const index = newCountries.findIndex((c) => c === countryId)
            if(index > -1) {
                newCountries.splice(index, 1)
            } else {
                newCountries.push(countryId)
            }
            newEconomy.countries = newCountries
        }
        const newSettings = {
            ...settings,
            economy: newEconomy
        }
        const newWidgets = changeDashboardWidgetData(props.id, dashboard.data.widgets, { settings: newSettings })
        dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
    }

    const saveButtonClick = () => {
        dispatch(setPopupElement(null));
        dispatch(saveDashboard());
    }

    const cancelButtonClick = async () => {
        dispatch(setPopupElement(null));
        const dashboardDb = await dispatch(getDashboard(dashboard.data.id)).unwrap();
        if (dashboardDb) {
            dispatch(changeDashboardData(dashboardDb));
        }
    }

    return (
        <div className="app_popup_wrapper_middle" ref={refClickOutside}>
            <div className={WidgetTopRowStyles.popup_middle_top_row}>
                <span className={WidgetTopRowStyles.popup_middle_top_row_title}>Countries</span>
                <div className={WidgetTopRowStyles.popup_middle_top_row_icon_x_wrapper} onClick={() => dispatch(setPopupElement(null))}>
                    <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.popup_middle_top_row_icon_x}>
                        <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                    </svg>
                </div>
            </div>
            <div className={WidgetTopRowStyles.popup_middle_middle_row}>
                <div className={WidgetTopRowStyles.popup_middle_item} style={{ backgroundColor: countries.length === 0 ? "rgb(var(--dl-color-primary-100))" : "" }} onClick={() => {changeValues("")}}>
                    <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.popup_middle_item_logo_wrapper} style={{ fill: countries.length === 0 ? "rgb(var(--dl-color-white-always))" : "rgb(var(--dl-color-text-black))" }}>
                        <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z" />
                    </svg>
                    <span className={WidgetTopRowStyles.popup_item_text} style={{ color: countries.length === 0 ? "rgb(var(--dl-color-white-always))" : "" }}>All Countries</span>
                </div>
                <div className={WidgetTopRowStyles.popup_middle_search_wrapper}>
                    <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.popup_middle_search_icon}>
                        <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z" />
                    </svg>
                    <input className={WidgetTopRowStyles.popup_middle_search_input}
                        type="text"
                        placeholder="Search..."
                        onChange={(event) => {if (event.target.value.length <= 50) {setSearch(event.target.value)}}}
                        value={search}
                    />
                    {search !== "" && (
                        <div className={WidgetTopRowStyles.popup_middle_search_delete} onClick={() => setSearch("")}>
                            <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.popup_middle_search_delete_icon}>
                                <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                            </svg>
                        </div>
                    )}
                </div>
            </div>
            <div className={WidgetTopRowStyles.popup_middle_selected_row} style={{ paddingBottom: countries.length === 0 ? "" : "8px" }}>
                {countries.map((countryId) => <div key={countryId} className={WidgetTopRowStyles.popup_middle_selected_item}>
                    <div className={WidgetTopRowStyles.popup_middle_selected_item_wrapper}>
                        <img src={`/flags/${countryId}.webp`} alt={`Flag ${allCountries[countryId]}`} className={WidgetTopRowStyles.popup_middle_selected_item_logo} />
                    </div>
                    <span className={WidgetTopRowStyles.popup_middle_selected_item_text}>{countryId}</span>
                    <div className={WidgetTopRowStyles.popup_middle_selected_item_icon_x_wrapper} onClick={() => {changeValues(countryId)}}>
                        <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.popup_middle_selected_item_icon_x}>
                            <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                        </svg>
                    </div>
                </div>)}
            </div>
            <div className={WidgetTopRowStyles.popup_middle_content_wrapper} style={{ maxWidth: "860px", maxHeight: "460px" }}>
                <div className={WidgetTopRowStyles.popup_middle_wrapper_scroll}>
                    {Object.keys(allContinents).map((continentId) => <div key={continentId} className={WidgetTopRowStyles.popup_middle_category}>
                        <span
                            className={WidgetTopRowStyles.popup_middle_category_title}
                            style={{ display: Object.keys(allContinents[continentId]).filter((countryId) => {
                                const countryName = allCountries[countryId]
                                return countryName.toLowerCase().replace(" ", "").includes(search.toLowerCase().replace(" ", ""))
                            }).length === 0 ? "none" : "" }}>
                            {continentId}
                        </span>
                        <div className={WidgetTopRowStyles.popup_middle_category_content}>
                            {sortCountriesByName(allContinents[continentId]).filter((countryId) => {
                                const countryName = allCountries[countryId]
                                return countryName.toLowerCase().replace(" ", "").includes(search.toLowerCase().replace(" ", ""))
                            }).map((countryId) => (
                                <div key={countryId} className={WidgetTopRowStyles.popup_middle_item} style={{ backgroundColor: countries.includes(countryId) ? "rgb(var(--dl-color-primary-100))" : "" }} onClick={() => {changeValues(countryId)}}>
                                    <div className={WidgetTopRowStyles.popup_middle_item_logo_wrapper}>
                                        <img src={`/flags/${countryId}.webp`} alt={`Flag ${allCountries[countryId]}`} className={WidgetTopRowStyles.popup_middle_item_logo} />
                                    </div>
                                    <span className={WidgetTopRowStyles.popup_item_text} style={{ color: countries.includes(countryId) ? "rgb(var(--dl-color-white-always))" : "" }}>{allCountries[countryId]}</span>
                                </div>
                            ))}
                        </div>
                    </div>)}
                </div>
            </div>
            <div className={WidgetTopRowStyles.popup_middle_bottom_row}>
                <div className={WidgetTopRowStyles.popup_middle_bottom_row_split}>
                    <div className={WidgetTopRowStyles.popup_middle_bottom_row_button} onClick={() => {changeValues("")}}>
                        <span className={WidgetTopRowStyles.popup_middle_bottom_row_button_text}>Reset to default</span>
                    </div>
                </div>
                <div className={WidgetTopRowStyles.popup_middle_bottom_row_split}>
                    <div className={WidgetTopRowStyles.popup_middle_bottom_row_button} onClick={cancelButtonClick}>
                        <span className={WidgetTopRowStyles.popup_middle_bottom_row_button_text}>Cancel</span>
                    </div>
                    <div className={WidgetTopRowStyles.popup_middle_bottom_row_button_blue} onClick={saveButtonClick}>
                        <span className={WidgetTopRowStyles.popup_middle_bottom_row_button_text}>Save</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EconomyButtonCountryPopup