import { useEffect, useState } from "react";

import { ColumnBuilderParameter, ColumnBuilderParameterOption } from "../../../../../../../../utils/constants/table-columns";
import usePopperPopup from "../../../../../../../../utils/hooks/use-popper-popup";
import TableTopRowButtonColumnStyle from "./TableTopRowButtonColumn.module.css"

type Props = {
    header: string;
    setHeader: React.Dispatch<React.SetStateAction<string>>;
    id: string;
    setId: React.Dispatch<React.SetStateAction<string>>;
    parameter: ColumnBuilderParameter;
    index: number;
}

const TableTopRowButtonColumnRowOption: React.FC<Props> = (props) => {
    const { parameter } = props

    const [selectedOption, setSelectedOption] = useState<ColumnBuilderParameterOption>({ name: "", id: "" });

    const { setButton, setPopup, buttonRef, styles, attributes, isOpen, setIsOpen } = usePopperPopup({ offsetY: 4 });

    useEffect(() => {
        const newSelectedOption = parameter.options.find((option) => option.id === props.id.split("-")[props.index + 1])
        if (typeof newSelectedOption !== "undefined") {
            setSelectedOption(newSelectedOption)
        }
    }, [props.id]);

    const changeParameter = (option: ColumnBuilderParameterOption) => {
        if (option !== selectedOption) {
            const newId = props.id.replace(`-${selectedOption.id}`, `-${option.id}`)
            props.setId(newId);
            let newHeaderOptions = props.header.split("(")[1].split(")")[0]
            newHeaderOptions = `(${newHeaderOptions.replace(selectedOption.name, option.name)})`
            props.setHeader(props.header.split("(")[0] + newHeaderOptions)
        }
        setIsOpen(false)
    }

    return (
        <div className="popper_wrapper" ref={buttonRef}>
            <div className={TableTopRowButtonColumnStyle.added_column_button_option_dropdown} ref={setButton} onClick={() => setIsOpen(!isOpen)}>
                <span className={TableTopRowButtonColumnStyle.added_column_button_option_dropdown_text}>{selectedOption.name}</span>
                <svg viewBox="0 0 1024 1024" className={TableTopRowButtonColumnStyle.added_column_button_option_dropdown_arrow_icon}>
                    <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                </svg>
            </div>
            {isOpen ? (
                <div className={TableTopRowButtonColumnStyle.added_column_button_option_popup} ref={setPopup} style={styles.popper} {...attributes.popper}>
                    {parameter.options.map((option) => <div className={TableTopRowButtonColumnStyle.added_column_button_option_popup_item} style={{ backgroundColor: option === selectedOption ? "rgb(var(--dl-color-primary-100))" : "", color: option === selectedOption ? "rgb(var(--dl-color-white-always))" : "" }} onClick={() => changeParameter(option)} key={option.id}>{option.name}</div>)}
                </div>
            ) : null}
        </div>
    )
}

export default TableTopRowButtonColumnRowOption