import React from "react";

import WidgetStyles from "../../../../WidgetStyles.module.css"

const TableEarningsHeader: React.FC = () => (
    <div className={WidgetStyles.simple_table_header}>
        <span className={WidgetStyles.simple_table_header_item_left} style={{ maxWidth: "110px" }}>Ticker</span>
        <div className={WidgetStyles.simple_table_header_right}>
            <span className={WidgetStyles.simple_table_header_item} style={{ width: "50%" }}>Company</span>
            <span className={WidgetStyles.simple_table_header_item} style={{ width: "25%" }}>Current</span>
            <span className={WidgetStyles.simple_table_header_item} style={{ width: "25%" }}>Forecast</span>
        </div>
    </div>
)

export default TableEarningsHeader