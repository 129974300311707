import { v4 } from "uuid"

import { CriteriaGroup, CriteriaRow, Settings } from "../Screener"

export const createRowSettings = (overrides?: Partial<Settings>): Settings => ({
    idA: "price-current",
    idB1: "dollar-value",
    idB2: "",
    operator: "below",
    parametersA: {},
    parametersB1: {
        dollarValue: "100"
    },
    parametersB2: {},
    ...overrides,
})

export const createCriteriaRow = (overrides?: Partial<CriteriaRow>): CriteriaRow => ({
    id: v4(),
    type: "row",
    settings: createRowSettings(),
    ...overrides
})

export const createCriteriaGroup = (overrides?: Partial<CriteriaGroup>): CriteriaGroup => ({
    id: v4(),
    type: "group",
    connection: "or",
    criteriaList: [createCriteriaRow()],
    ...overrides
})