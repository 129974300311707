import { Placement } from "@popperjs/core";
import { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";

type Props = {
    padding?: number;
    offsetX?: number;
    offsetY?: number;
    initialPlacement?: Placement;
    fallbackPlacements?: Placement[];
}

const defaultProps = {
    padding: 6,
    offsetX: 0,
    offsetY: 0,
    initialPlacement: "bottom-start",
    fallbackPlacements: ["bottom-start", "bottom-end", "top-start", "top-end", "right", "right-start", "right-end", "left", "left-start", "left-end"],
}

const usePopperPopup = (props: Props) => {    
    const { padding, offsetX, offsetY, initialPlacement, fallbackPlacements } = { ...defaultProps, ...props }

    const [isOpen, setIsOpen] = useState(false);
    const [button, setButton] = useState<HTMLDivElement | SVGSVGElement | null>(null);
    const [popup, setPopup] = useState<HTMLDivElement | null>(null);

    const buttonRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (buttonRef.current && !buttonRef.current.contains(event.target as HTMLElement) && popup && !popup.contains(event.target as HTMLElement)) {
                if (setIsOpen) {
                    setIsOpen(false)
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [buttonRef, popup]);
    
    const { styles, attributes } = usePopper(button, popup, {
        placement: initialPlacement as Placement,
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [offsetX, offsetY],
                }
            },
            {
                name: "preventOverflow",
                options: {
                    padding,
                }
            },
            {
                name: "flip",
                options: {
                    fallbackPlacements: fallbackPlacements as Placement[]
                },
            }
        ]
    });

    return { setButton, setPopup, buttonRef, styles, attributes, isOpen, setIsOpen }
}

export default usePopperPopup