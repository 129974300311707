import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteAccount, updateAuthEmail } from '../../../../store/features/user';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { isText } from '../../../../types/utils/is-text';
import LoadingSpinner from '../../../../utils/components/loading-spinner/loading-spinner';
import SignUpLoginInfoMessage from '../../../signup-login/components/signup-login-info';
import SignUpLoginWarning from '../../../signup-login/components/signup-login-warning';
import SignupLogin from '../../../signup-login/SignupLogin.module.css'
import AppProfile from '../AppProfile.module.css'

const ProfileChangeEmail: React.FC = () => {
    const [errors, setErrors] = useState({
        "currentPassword": "",
        "newEmail": "",
        "unknown": ""
    });

    const [currentPassword, setCurrentPassword] = useState("");
    const [newEmail, setNewEmail] = useState("");

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);

    const [emailSuccess, setEmailSuccess] = useState(false);

    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user)

    const navigate = useNavigate();

    const changeErrors = (errorMessage: string) => {
        const newErrors = {
            "currentPassword": "",
            "newEmail": "",
            "unknown": ""
        };
        if (errorMessage !== "" && !errorMessage.includes("popup-closed")) {
            if (user.errorMessage.includes("auth/email-already-in-use")) {
                newErrors.newEmail = "This e-mail is already in use.";
            } else if (user.errorMessage.includes("auth/invalid-email")) {
                newErrors.newEmail = "Please enter a valid e-mail address.";
            } else if (user.errorMessage.includes("same-email")) {
                newErrors.newEmail = "This is already the current e-mail address of this account.";
            } else if (errorMessage.includes("auth/wrong-password")) {
                newErrors.currentPassword = "Wrong password.";
            } else if (errorMessage.includes("auth/too-many-requests")) {
                newErrors.currentPassword = "Wrong password.";
                newErrors.unknown = "Too many failed attempts. Please try again later.";
            } else {
                newErrors.unknown = errorMessage;
            }
        }
        setErrors(newErrors);
    }

    const handleEmailChange = async () => {
        const newErrors = {
            "currentPassword": "",
            "newEmail": "",
            "unknown": ""
        };
        const currentPasswordTrim = currentPassword.trim()
        const newEmailTrim = newEmail.trim()
        let errorFound = false;
        if (currentPasswordTrim.length === 0) {
            newErrors.currentPassword = "Please enter your current password.";
            errorFound = true;
        }
        if (newEmailTrim.length === 0 || !newEmailTrim.includes("@") || !newEmailTrim.includes(".") || newEmailTrim.includes(" ")) {
            newErrors.newEmail = "Please enter a valid e-mail address.";
            errorFound = true;
        }
        if (!errorFound) {
            try {
                await dispatch(updateAuthEmail({
                    currentPassword: currentPasswordTrim,
                    newEmail: newEmailTrim
                })).unwrap()
                setErrors(newErrors);
                setEmailSuccess(true);
                setCurrentPassword("");
                setNewEmail("");
            } catch (error) {
                if (isText(error)) {
                    changeErrors(error);
                }
            }
        } else {
            setErrors(newErrors);
        }
    }

    const handleDeleteAccount = async () => {
        await dispatch(deleteAccount(currentPassword)).unwrap();
        navigate("/sign-up")
    }
  
    const handleKeypress = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            await handleEmailChange();
        }
    };

    return (
        <>
            {user.userData.providers.includes("password") ? (
                <div className={AppProfile.password_change_container}>
                    <div className={SignupLogin.input_row}>
                        <span>Current Password</span>
                        <div className={SignupLogin.input_field}>
                            <input
                                type={ showCurrentPassword ? "text" : "password" }
                                required
                                placeholder="Type in your old password"
                                className={SignupLogin.textinput}
                                onChange={(event) => {
                                    setCurrentPassword(event.target.value)}
                                }
                                value={currentPassword}
                                onKeyDown={handleKeypress}
                            />
                            <div className={SignupLogin.show_password_wrapper} onClick={() => {setShowCurrentPassword(true)}}>
                                {showCurrentPassword && (
                                    <p className={SignupLogin.show_password}>Hide</p>
                                )}
                                {!showCurrentPassword && (
                                    <p className={SignupLogin.show_password}>Show</p>
                                )}
                            </div>
                        </div>
                        <SignUpLoginWarning visible={errors.currentPassword !== ""} text={errors.currentPassword} />
                    </div>
                    <div className={SignupLogin.input_row}>
                        <span>New E-Mail</span>
                        <div className={SignupLogin.input_field}>
                            <input
                                type="text"
                                required
                                placeholder="example@site.com"
                                autoComplete="email"
                                className={SignupLogin.textinput}
                                onChange={(event) => {
                                    setNewEmail(event.target.value)}
                                }
                                value={newEmail}
                                onKeyDown={handleKeypress}
                            />
                        </div>
                        <SignUpLoginWarning visible={errors.newEmail !== ""} text={errors.newEmail} />
                    </div>
                    <SignUpLoginInfoMessage visible={errors.unknown.length !== 0} color="red" text={errors.unknown} />
                    <SignUpLoginInfoMessage visible={emailSuccess} text="Please check the inbox of the new e-mail address and click the link in the verification mail to activate the new e-mail." color="green" />
                    <button type="button" className={`${SignupLogin.submit_button}`} onClick={() => handleEmailChange()}>
                        <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                        {user.loading ? "" : "Change E-Mail Address"}
                    </button>
                    <button type="button" onClick={() => handleDeleteAccount()}>Delete Account</button>
                </div>
            ) : (
                <span>Your are logged in with your social profile and not with e-mail and password.</span>
            )}
        </>
    )
}

export default ProfileChangeEmail