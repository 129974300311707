import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import CriteriaDropdownSelection from '../../criteria-dropdown-selection';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        marketStatus: [],
    },
    setParameters: () => {},
}

const CriteriaParameterMarketStatus: React.FC<Props> = (props) => {
    const marketStatus = useMemo(() => props.parameters.marketStatus ? props.parameters.marketStatus : defaultProps.parameters.marketStatus!, [props.parameters.marketStatus])

    const setMarketStatus = (newMarketStatus: string[]) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, marketStatus: newMarketStatus }, defaultProps.parameters))
    }

    return (
        <>
            <CriteriaDropdownSelection dropdownOptions={["Open", "Pre-Market", "After-Hours", "Closed"]} withSearch={false} transformType="" values={marketStatus} setValues={setMarketStatus} />
        </>
    )
}

export default CriteriaParameterMarketStatus