export type WidgetConnections = {
    [color: string]: string
}

export const widgetConnections: WidgetConnections = {
    "blue": "--dl-color-widget-connection-blue",
    "red": "--dl-color-widget-connection-red",
    "orange": "--dl-color-widget-connection-orange",
    "pink": "--dl-color-widget-connection-pink",
    "green": "--dl-color-widget-connection-green",
    "none": "--dl-color-widget-connection-none"
}