import { CriteriaGroup, CriteriaRow, isCriteriaGroup, Settings } from "../../types/Screener";

export const findRow = (array: (CriteriaGroup | CriteriaRow)[], id: string): CriteriaRow | null | undefined => {
    const find = (array: (CriteriaGroup | CriteriaRow)[]): CriteriaRow | null | undefined => {
        if (!array) return null;
        let inner = null;
        const row = array.find(o => {
            if (o.id === id) return o;
            if (Array.isArray(o)) {
                inner = find(o);
                return inner
            }
            if (isCriteriaGroup(o)) {
                inner = find(o.criteriaList);
                return inner
            }
            return null
        });
        if (inner !== null) {
            return inner as CriteriaRow;
        }
        return row as CriteriaRow | undefined;
    }
    return find(array);
}

export const findIdx = (array: (CriteriaGroup | CriteriaRow)[], id: string): false | number[] | undefined => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const find = (array: (CriteriaGroup | CriteriaRow)[]) => {
        if (!array) return;
        let inner;
        const index = array.findIndex(o => {
            if (o.id === id) return true;
            if (Array.isArray(o)) {
                inner = find(o);
                return inner
            }
            if (isCriteriaGroup(o)) {
                inner = find(o.criteriaList);
                return inner
            }
            return false
        });
        return index !== -1 && [index, ...(inner || [])];
    }
    return find(array);
}

function deepSplice(crit: CriteriaGroup, indices: number[], newRow: CriteriaGroup | CriteriaRow): CriteriaGroup {
    let newCrit: CriteriaGroup = { ...crit }
    while (indices.length > 1) {
        const last = indices.shift()!;
        newCrit = newCrit.criteriaList[last] as CriteriaGroup
    }
    const newList = [...newCrit.criteriaList]
    newList.splice(indices[0], 1, newRow)
    newCrit = {
        ...newCrit,
        criteriaList: newList
    }
    return newCrit
}

const deepSpliceCriteria = (criteria: CriteriaGroup, row: CriteriaRow, unsavedSettings: Settings): CriteriaGroup => {
    const indexList = findIdx(criteria.criteriaList, row.id)
    let newRow: CriteriaGroup | CriteriaRow = {
        ...row,
        settings: unsavedSettings
    }
    if (indexList) {
        while (indexList.length > 0) {
            newRow = deepSplice({ ...criteria }, [...indexList], newRow)
            indexList.pop()
        }
        return newRow as CriteriaGroup
    }
    return criteria
}

export default deepSpliceCriteria