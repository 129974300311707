/* eslint-disable @typescript-eslint/no-unused-expressions, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collection, deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import _ from 'lodash';

import { firestoreDB } from "../../firebase/firebase-config";
import { createScreener } from "../../types/creators/create-screener";
import { Screener } from "../../types/Screener";
import { UserState } from "./user";

const namespace = "screener"

export type DashboardScreenerStyles = {
    loading: boolean;
    unsavedChanges: boolean;
    saveLoading: boolean;
    getLoading: boolean;
    errorMessage: string;
    data: Screener;
}

const initialStateValue: DashboardScreenerStyles = {
    loading: false,
    unsavedChanges: false,
    saveLoading: false,
    getLoading: false,
    errorMessage: "",
    data: {
        id: "",
        name: "",
        description: "",
        active: true,
        adminUid: "",
        access: [],
        sources: {
            indices: [],
            screeners: [],
            watchlists: []
        },
        states: [],
    }
}

export const setScreener = createAsyncThunk(`${namespace}/setScreener`, async (id: string, { getState, rejectWithValue }) => {
    try {
        const { user } = getState() as { user: UserState };
        const userId: string = user.userData.uid
        const docRef = await getDoc(doc(firestoreDB, "users", userId, "screeners", id));
        if (docRef.exists()) {
            return {
                ...docRef.data(),
                id: docRef.id
            }
        } 
        return initialStateValue.data
        
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
})

export const getScreener = createAsyncThunk(`${namespace}/getScreener`, async (id: string, { getState, rejectWithValue }) => {
    try {
        const { user } = getState() as { user: UserState };
        const docRef = await getDoc(doc(firestoreDB, "users", user.userData.uid, "screeners", id));
        if (docRef.exists()) {
            return {
                ...docRef.data(),
                id: docRef.id
            } as Screener
        } 
        return initialStateValue.data
        
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
})

export const createNewScreener = createAsyncThunk(`${namespace}/createNewScreener`, async (data: Partial<Screener>, { getState, rejectWithValue }) => {
    try {
        const { user } = getState() as { user: UserState };
        const docRef = doc(collection(firestoreDB, "users", user.userData.uid, "screeners"));
        const newData = {
            ...initialStateValue.data,
            ...createScreener(docRef.id, user.userData.uid, data)
        }
        await setDoc(docRef, newData);
        return newData
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
})

export const deleteScreener = createAsyncThunk(`${namespace}/deleteScreener`, async (id: string, { getState, rejectWithValue }) => {
    try {
        const { user } = getState() as { user: UserState };
        await deleteDoc(doc(firestoreDB, "users", user.userData.uid, "screeners", id));
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
})

export const saveScreener = createAsyncThunk(`${namespace}/saveScreener`, async (data, { getState, rejectWithValue }) => {
    try {
        const { screener } = getState() as { screener: DashboardScreenerStyles };
        const { user } = getState() as { user: UserState };
        const newData: Partial<Screener> = _.cloneDeep(screener.data);
        await updateDoc(doc(firestoreDB, "users", user.userData.uid, "screeners", screener.data.id), { ...newData });
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
})

export const screenerSlice = createSlice({
    name: namespace,
    initialState: initialStateValue,
    reducers: {
        changeScreenerData: (state, action: PayloadAction<Partial<Screener>>) => {
            state.loading = false
            state.errorMessage = ""
            state.unsavedChanges = true
            state.data = { ...state.data, ...action.payload }
        },
        resetScreener: (state) => {
            state.loading = false
            state.errorMessage = ""
            state.unsavedChanges = false
            state.data = initialStateValue.data
        },
        resetScreenerError: (state) => {
            state.errorMessage = ""
        },
        resetScreenerLoading: (state) => {
            state.loading = false
            state.unsavedChanges = false
            state.saveLoading = false
            state.getLoading = false
        }
    },
    extraReducers: (builder) => { builder
        // setScreener
        .addCase(setScreener.pending, (state) => {
            state.loading = true
        })
        .addCase(setScreener.fulfilled, (state, action) => {
            state.loading = false
            state.unsavedChanges = false
            state.data = { ...initialStateValue.data, ...action.payload }
        })
        .addCase(setScreener.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // getScreener
        .addCase(getScreener.pending, (state) => {
            state.getLoading = true
        })
        .addCase(getScreener.fulfilled, (state) => {
            state.getLoading = false
        })
        .addCase(getScreener.rejected, (state, action: PayloadAction<any>) => {
            state.getLoading = false
            state.errorMessage = action.payload
        })
        // createNewScreener
        .addCase(createNewScreener.pending, (state) => {
            state.loading = true
        })
        
        .addCase(createNewScreener.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data = action.payload
        })
        .addCase(createNewScreener.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.unsavedChanges = false
            state.errorMessage = action.payload ? action.payload : initialStateValue.errorMessage
        })
        
        // deleteScreener
        .addCase(deleteScreener.pending, (state) => {
            state.loading = true
        })
        .addCase(deleteScreener.fulfilled, (state) => {
            state.loading = false
            state.unsavedChanges = false
            state.data = initialStateValue.data
        })
        .addCase(deleteScreener.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // saveScreener
        .addCase(saveScreener.pending, (state) => {
            state.saveLoading = true
        })
        .addCase(saveScreener.fulfilled, (state) => {
            state.saveLoading = false
            state.unsavedChanges = false
        })
        .addCase(saveScreener.rejected, (state, action: PayloadAction<any>) => {
            state.saveLoading = false
            state.errorMessage = action.payload
        })
    }
})

export const { changeScreenerData, resetScreener, resetScreenerError, resetScreenerLoading } = screenerSlice.actions;

export default screenerSlice.reducer;