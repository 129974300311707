import { HeaderGroup } from "react-table";

import TableFilterSearch from "../filters/table-filter-search";
import TableSortIcon from "../table-sort-icon";
import TableTableStyles from "../TableTableStyles.module.css";

type Props = {
    width: number;
    column: HeaderGroup<object>;
}

const HeaderCellTicker: React.FC<Props> = (props) => (
    <div className={TableTableStyles.header_cell_wrapper} style={{ justifyContent: "space-between", padding: props.width > 140 ? "0px 12px" : "", gap: props.width > 140 ? "16px" : "8px" }}>
        <div className={TableTableStyles.header_text_wrapper}>
            <span className={TableTableStyles.header_text}>{props.column.render("Header")}</span>
            <span className={TableTableStyles.header_subtext}>{props.column.filteredRows.length} {props.column.filteredRows.length === 1 ? "match" : "matches"}</span>
        </div>
        <div className={TableTableStyles.header_ticker_right}>
            <TableFilterSearch column={props.column} />
            <TableSortIcon column={props.column} />
        </div>
    </div>
)

export default HeaderCellTicker