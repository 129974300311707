import useResizeTopScreener from "../../../../utils/hooks/use-resize-top-screener";
import DashboardScreenerList from "./dashboard-screener-list/dashboard-screener-list";
import DashboardScreenerStyles from "./DashboardScreener.module.css"
import DashboardScreenerDetails from "./details/dashboard-screener-details";

const DashboardScreener: React.FC = () => {
    const { resizableRef, resizeHandlerRef } = useResizeTopScreener();

    return (
        <div className={DashboardScreenerStyles.container} id="dashboard-screener" ref={resizableRef}>
            <div className={DashboardScreenerStyles.separator_wrapper} ref={resizeHandlerRef}>
                <div className={DashboardScreenerStyles.separator} />
            </div>
            <div className={DashboardScreenerStyles.wrapper}>
                <DashboardScreenerList />
                <DashboardScreenerDetails />
            </div>
        </div>
    )
}

export default DashboardScreener