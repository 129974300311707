import { createPortal } from "react-dom";
import { v4 } from "uuid";

import { setPopupElement } from "../../../../../store/features/management";
import { changeScreenerData, createNewScreener, saveScreener } from "../../../../../store/features/screener";
import { useAppDispatch } from "../../../../../store/hooks";
import { Screener } from "../../../../../types/Screener";
import usePopperPopup from "../../../../../utils/hooks/use-popper-popup";
import DashboardScreenerStyles from "../DashboardScreener.module.css"
import DashboardScreenerListItemSettingsDelete from "./dashboard-screener-list-item-settings-delete";
import DashboardScreenerListItemSettingsDescription from "./dashboard-screener-list-item-settings-description";
import DashboardScreenerListItemSettingsName from "./dashboard-screener-list-item-settings-name";

type Props = {
    screener: Screener;
    setIsHovered: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardScreenerListItemSettings: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const { setButton, setPopup, buttonRef, styles, attributes, isOpen, setIsOpen } = usePopperPopup({ offsetY: 2 });

    const startStopScreener = async () => {
        dispatch(changeScreenerData({ ...props.screener, active: !props.screener.active }));
        dispatch(saveScreener()).unwrap();
        setIsOpen(false);
        props.setIsHovered(false);
    }

    const nameButtonClick = () => {
        dispatch(setPopupElement(<DashboardScreenerListItemSettingsName screener={props.screener} />));
        setIsOpen(false);
    }

    const descriptionButtonClick = () => {
        dispatch(setPopupElement(<DashboardScreenerListItemSettingsDescription screener={props.screener} />));
        setIsOpen(false);
    }

    const deleteButtonClick = () => {
        dispatch(setPopupElement(<DashboardScreenerListItemSettingsDelete screener={props.screener} />));
        setIsOpen(false);
    }    

    const duplicateScreener = () => {
        const newScreener: Partial<Screener> = { ...props.screener };
        delete newScreener.id;
        delete newScreener.adminUid;
        delete newScreener.access;
        const newStates = newScreener.states?.map((state) => ({
            ...state,
            id: v4()
        }));
        newScreener.states = newStates;
        newScreener.name += " Copy"
        dispatch(createNewScreener(newScreener));
        setIsOpen(false);
    }

    return (
        <div className="popper_wrapper" ref={buttonRef} style={{ marginRight: "-4px" }}>
            <div className={DashboardScreenerStyles.screener_settings_container} ref={setButton} onClick={(e) => {e.stopPropagation(); setIsOpen(!isOpen)}}>
                <svg viewBox="0 0 1024 1024" className={DashboardScreenerStyles.screener_settings_icon}>
                    <path d="M597.333 512c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331zM896 512c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331zM298.667 512c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331z" />
                </svg>
            </div>
            {isOpen ? createPortal(
                <div className={DashboardScreenerStyles.screener_settings_popup} ref={setPopup} style={styles.popper} {...attributes.popper}>
                    <div className={DashboardScreenerStyles.screener_settings_popup_item} onClick={() => startStopScreener()}>
                        <span className={DashboardScreenerStyles.screener_settings_popup_item_text}>{props.screener.active ? "Stop" : "Start"} Screener</span>
                    </div>
                    <div className={DashboardScreenerStyles.screener_settings_popup_item} onClick={() => nameButtonClick()}>
                        <span className={DashboardScreenerStyles.screener_settings_popup_item_text}>Edit name</span>
                    </div>
                    <div className={DashboardScreenerStyles.screener_settings_popup_item} onClick={() => descriptionButtonClick()}>
                        <span className={DashboardScreenerStyles.screener_settings_popup_item_text}>Edit description</span>
                    </div>
                    <div className={DashboardScreenerStyles.screener_settings_popup_item} onClick={() => duplicateScreener()}>
                        <span className={DashboardScreenerStyles.screener_settings_popup_item_text}>Duplicate</span>
                    </div>
                    <div className={`${DashboardScreenerStyles.screener_settings_popup_item} ${DashboardScreenerStyles.screener_settings_popup_item_delete}`} onClick={() => deleteButtonClick()}>
                        <span className={DashboardScreenerStyles.screener_settings_popup_item_text}>Delete screener</span>
                    </div>
                </div>,
                document.body
            ) : null}
        </div>
    )
}

export default DashboardScreenerListItemSettings