/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument */

import { createPortal } from "react-dom";

import { changeDashboardData, saveDashboard } from "../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { createWidget } from "../../../types/creators/create-widget";
import { isOfTypeComponentName } from "../../../types/Dashboard";
import getAllWidgetComponents, { WidgetComponents } from "../../../utils/functions/get-widget-component";
import usePopperPopup from "../../../utils/hooks/use-popper-popup";
import DashboardStyle from './Dashboard.module.css';

const allWidgetComponents: WidgetComponents = getAllWidgetComponents("", "none", 14, true)

const DashboardAddWidget: React.FC = () => {
    const dispatch = useAppDispatch();
    
    const dashboard = useAppSelector((state) => state.dashboard)

    const { setButton, setPopup, buttonRef, styles, attributes, isOpen, setIsOpen } = usePopperPopup({ offsetY: 8 });

    const addWidgetClick = (widgetName: string) => {
        if (isOfTypeComponentName(widgetName)) {
            const newWidgets = [ ...dashboard.data.widgets ]
            newWidgets.push(createWidget(widgetName));
            setIsOpen(false);
            dispatch(changeDashboardData({ widgets: newWidgets }));
            dispatch(saveDashboard());
        }
    }

    return (
        <div className="popper_wrapper" ref={buttonRef}>
            <div className={DashboardStyle.gray_icon_wrapper} ref={setButton} onClick={() => setIsOpen(!isOpen)}>
                <svg viewBox="0 0 1024 1024" className={DashboardStyle.top_row_icon}>
                    <path d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM554 298v172h172v84h-172v172h-84v-172h-172v-84h172v-172h84z" />
                </svg>
            </div>
            {isOpen ? createPortal(
                <div className={DashboardStyle.add_widget_popup} ref={setPopup} style={styles.popper} {...attributes.popper}>
                    {Object.keys(allWidgetComponents).map((widgetName) => {
                        const { displayName, icon } = allWidgetComponents[widgetName]
                        return (
                            <div key={widgetName} className={DashboardStyle.add_widget_popup_item} onClick={() => addWidgetClick(widgetName)}>
                                {icon}
                                <span className={DashboardStyle.add_widget_popup_item_text}>{displayName}</span>
                            </div>
                        )
                    })}
                </div>,
                document.body
            ) : null
            }
        </div>
    )
}

export default DashboardAddWidget
