interface IndicatorComparisons {
    [key: string]: string;
}

export const comparisons: IndicatorComparisons = {
    "number": "Value",
    "dollar-value": "$ Value",
    "dollar-change": "$ Value",
    "percent-value": "% Value",
    "percent-change": "% Value",
    "range-value": "Value",
    "ratio": "Value",
    "time-range": "Time Range",
    "time-of-day": "Time",
    "market-status": "Values",
    "price-current": "Current Price",
    "price-last-full-candle": "Last Full Candle's",
    "price-pm-ohlc": "Pre-Market",
    "price-ah-ohlc": "After-Hours",
    "price-change": "Price Change",
    "price-change-ah": "After-Hours Change",
    "price-change-pm": "Pre-Market Change",
    "price-overnight-gap": "Overnight Gap",
    "price-change-from-open": "Change from Open",
    "price-change-from-ldc": "Change from Last Day's Close",
    "indicators-sma": "Simple Moving Average",
    "indicators-ema": "Exponential Moving Average",
    "indicators-bollinger-bands": "Bollinger Bands",
    "indicators-macd": "MACD",
    "indicators-rsi": "RSI",
    "indicators-stochastic": "Stochastic",
    "indicators-vwap": "VWAP",
    "indicators-atr": "Average True Range",
    "indicators-volatility": "Volatility",
    "volume-candle": "Last Candle's Volume",
    "volume-average-volume": "Average Volume",
    "volume-pm": "Pre-Market Volume",
    "volume-ah": "After-Hours Volume",
    "volume-today": "Today's Volume",
}

export const getComparisonsOrdered = (comparisonsInput: string[]): string[] => {
    const sortedComparisons = comparisonsInput.sort((a, b): number => {
        const comp: number = Object.keys(comparisons).indexOf(a) - Object.keys(comparisons).indexOf(b);
        if (comp !== 0) {
            return comp;
        }
        return 1
    })
    return sortedComparisons
}