import { Widget } from "../Dashboard";

export const changeDashboardWidgetData = (id: string, dashboardWidgets: Widget[], data: Partial<Widget>): Widget[] => {
    const newWidgets = [...dashboardWidgets]
    const index = dashboardWidgets.findIndex((widget) => widget.id === id)
    if (index > -1) {
        const newWidget = {
            ...dashboardWidgets[index],
            ...data
        }
        newWidgets.splice(index, 1, newWidget)
    }
    return newWidgets
}