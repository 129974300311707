import { useNavigate } from "react-router-dom";

import { createNewScreener } from "../../../../../store/features/screener";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import AppTopNavMiddleStyles from "../AppTopNavMiddleStyles.module.css";

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setPopup: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
    styles: { [key: string]: React.CSSProperties };
    attributes: {
        [key: string]: {
            [key: string]: string;
        } | undefined;
    }
}

const AppTopNavButtonScreenerMenu: React.FC<Props> = (props) => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const allScreeners = useAppSelector((state) => state.allScreeners)

    const buttonClickScreener = () => {
        navigate(`/app/screeners`);
        props.setIsOpen(false);
    }

    const newScreenerClick = async () => {
        try {
            const res = await dispatch(createNewScreener({
                name: `Screener ${Object.keys(allScreeners.screeners).length + 1}`,
            })).unwrap();
            props.setIsOpen(false);
            if (res) {
                navigate(`/app/screeners/${res.id}`);
            }
        } catch (error) {
            navigate(`/app/screeners`);
        }
    }

    if (props.isOpen) {
        return (
            <div className={AppTopNavMiddleStyles.middle_item_popup_container} ref={props.setPopup} style={props.styles.popper} {...props.attributes.popper}>
                <div className={AppTopNavMiddleStyles.middle_item_popup}>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_top} onClick={buttonClickScreener}>
                        <div className={AppTopNavMiddleStyles.middle_item_popup_top_title_wrapper}>
                            <span className={AppTopNavMiddleStyles.middle_item_popup_top_title}>Screener</span>
                            <div className={AppTopNavMiddleStyles.middle_item_popup_top_info}>
                                <span className={AppTopNavMiddleStyles.middle_item_popup_top_info_text}>unique feature</span>
                            </div>
                        </div>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_top_text}>Ever thought of creating alerts and filters on top of alerted stocks? With our Screeners this is possible!</span>
                        <div className={AppTopNavMiddleStyles.middle_item_popup_top_button} onClick={() => newScreenerClick()}>
                            <span className={AppTopNavMiddleStyles.middle_item_popup_top_button_title}>Create Screener</span>
                        </div>
                    </div>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_separator} />
                    <div className={AppTopNavMiddleStyles.middle_item_popup_button}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>Screener</span>
                    </div>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_button}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>Hot Stocks</span>
                    </div>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_button}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>Performancematrix</span>
                    </div>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_button}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>Heatmap</span>
                    </div>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_separator} />
                    <div className={AppTopNavMiddleStyles.middle_item_popup_button}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>Template Center</span>
                        <svg viewBox="0 0 1024 1024" className={AppTopNavMiddleStyles.middle_item_popup_button_icon}>
                            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z" />
                        </svg>
                    </div>
                </div>
            </div>
        )
    }
    return null
}

export default AppTopNavButtonScreenerMenu