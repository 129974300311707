import dayjs from "dayjs";
import { ManualReset } from "../../types/Screener";

const sortManualResetTimes = (a: ManualReset, b: ManualReset): number => {
    if (b.date === "" && b.time === "") {
        return -1;
    }
    if (a.date === "" && a.time === "") {
        return 1;
    }
    const fa = dayjs(`${a.date} / ${a.time}`, "MMMM D, YYYY / HH:mm");
    const fb = dayjs(`${b.date} / ${b.time}`, "MMMM D, YYYY / HH:mm");
    if (fb.isAfter(fa)) {
        return -1;
    }
    return 1;
}

export default sortManualResetTimes