import React from "react";

import WidgetStyles from "../../../../WidgetStyles.module.css"
import TableDividendsRowItem from "./table-dividends-row-item";

type Props = {
    tickerSearch: string;
}


const TableDividendsList: React.FC<Props> = (props) => (
    <div className={WidgetStyles.simple_table_wrapper_content}>
        <div className={WidgetStyles.simple_table_wrapper_scroll}>
            <TableDividendsRowItem tickerSearch={props.tickerSearch} />
            <TableDividendsRowItem tickerSearch={props.tickerSearch} />
            <TableDividendsRowItem tickerSearch={props.tickerSearch} />
        </div>
    </div>
)

export default TableDividendsList