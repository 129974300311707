interface Indices {
    [category: string]: string[];
}

const indices: Indices = {
    "Main": [
        "S&P 500",
        "Nasdaq 100",
        "Russell 2000"
    ]
}

export const getAllIndices = (): string[] => {
    const allIndices: string[] = [];
    Object.values(indices).forEach((indexCategory) => indexCategory.forEach((index) => allIndices.push(index)))
    return allIndices
}

export default indices