import { useMemo } from "react";

import { changeScreenerData, saveScreener } from "../../../../../../store/features/screener";
import { useAppDispatch } from "../../../../../../store/hooks";
import { Screener, State } from "../../../../../../types/Screener";
import BootstrapTooltip from "../../../../../../utils/components/tooltip/bootstrap-tooltip";
import DashboardScreenerSourceStyles from "./DashboardScreenerSource.module.css"

type Props = {
    screener: Screener;
    screenerRowId: string;
    index: number;
    state: State;
    isExpanded: boolean;
    screenerSelected: boolean;
}

const DashboardScreenerSourceTabScreenersState: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const sources = useMemo(() => ({ ...props.screener.sources }), [props.screener.sources])

    const indices = useMemo(() => ([...sources.indices]), [sources])
    const screeners = useMemo(() => ([...sources.screeners]), [sources])
    const watchlists = useMemo(() => ([...sources.watchlists]), [sources])

    const index = useMemo(() => (screeners.findIndex((w) => w.stateId === props.state.id)), [sources, screeners, props.state])

    const notAllowed = useMemo(() => (indices.length + screeners.length + watchlists.length === 1), [indices, screeners, watchlists])

    const addScreener = () => {
        if (index === -1) {
            const newScreeners = screeners.filter((s) => s.screenerId !== props.screenerRowId)
            newScreeners.push({
                screenerId: props.screenerRowId,
                stateId: props.state.id
            });
            sources.screeners = newScreeners;
        } else {
            if (notAllowed) {
                return
            }
            screeners.splice(index, 1)
            sources.screeners = screeners
        }
        dispatch(changeScreenerData({
            ...props.screener,
            sources
        }));
        dispatch(saveScreener());
    }

    return (
        <BootstrapTooltip title="You have to select at least one item" followCursor={true} disableHoverListener={!notAllowed || index === -1}>
            <div className={DashboardScreenerSourceStyles.stocklist_row} style={{ cursor: notAllowed && index > -1 ? "not-allowed" : "default", borderWidth: !props.isExpanded ? "0px" : "", height: !props.isExpanded ? "0px" : "42px", padding: "0px 24px 0px 32px", backgroundColor: index > -1 ? "rgba(var(--dl-color-colors-green-dark), 0.18)" : "" }} onClick={() => addScreener()}>
                <div className={DashboardScreenerSourceStyles.stocklist_row_absolute}>
                    <div className={DashboardScreenerSourceStyles.stocklist_row_left}>
                        <div className={DashboardScreenerSourceStyles.filter_alert_icon_wrapper}>
                            {props.state.type === "filter" ? (
                                <svg viewBox="0 0 1024 1024" className={DashboardScreenerSourceStyles.filter_icon}>
                                    <path d="M846.72 170.667l-281.984 333.397c-6.272 7.381-10.069 17.024-10.069 27.563v295.339l-85.333-42.667v-252.672c0.043-9.685-3.285-19.499-10.069-27.563l-281.984-333.397zM938.667 85.333h-853.333c-23.552 0-42.667 19.115-42.667 42.667 0 10.539 3.797 20.181 10.069 27.563l331.264 391.68v263.424c0 16.597 9.472 31.019 23.595 38.144l170.667 85.333c21.077 10.539 46.72 2.005 57.259-19.072 3.072-6.229 4.523-12.843 4.48-19.072v-348.757l331.264-391.68c15.232-18.005 12.971-44.928-5.035-60.117-8.064-6.827-17.877-10.155-27.563-10.112z" />
                                </svg>
                            ) : (
                                <svg viewBox="0 0 1024 1024" className={DashboardScreenerSourceStyles.alert_icon}>
                                    <path d="M554 384v128h128v86h-128v128h-84v-128h-128v-86h128v-128h84zM512 854q124 0 211-88t87-212-87-211-211-87-211 87-87 211 87 212 211 88zM512 170q160 0 272 113t112 271-112 271-272 113-272-113-112-271 112-271 272-113zM938 244l-54 66-196-166 54-64zM336 144l-196 164-54-64 196-164z" />
                                </svg>
                            )}
                        </div>
                        <span className={DashboardScreenerSourceStyles.stocklist_row_text}>{props.index + 2}. {props.state.name}</span>
                    </div>
                    <div className={DashboardScreenerSourceStyles.stocklist_row_middle} style={{ flexShrink: props.state.description === "" ? "1" : "" }}>
                        <span className={DashboardScreenerSourceStyles.stocklist_row_text}>{props.state.description}</span>
                    </div>
                </div>
            </div>
        </BootstrapTooltip>
    )
}

export default DashboardScreenerSourceTabScreenersState