import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { useAppSelector } from '../../../../store/hooks';
import getDropdownDisplayValue from '../../../functions/dropdown-display-value';
import usePopperPopup from '../../../hooks/use-popper-popup';
import ConditionRow from "../ConditionRow.module.css"
import CriteriaDropdownSelectionPopup from './criteria-dropdown-selection-popup';

type Props = {
    dropdownOptions: string[];
    transformType: string;
    withSearch: boolean;
    values: string[];
    setValues: (values: string[]) => void;
}

const CriteriaDropdownSelection: React.FC<Props> = (props) => {
    const management = useAppSelector((state) => state.management)

    const [search, setSearch] = useState("");

    const { setButton, setPopup, buttonRef: wrapperRef, styles, attributes, isOpen: dropdownOpen, setIsOpen: setDropdownOpen } = usePopperPopup({ offsetY: 4 });

    const disabled = useMemo(() => props.dropdownOptions.length <= 1, [props.dropdownOptions]);

    useEffect(() => {
        setSearch("");
    }, [dropdownOpen]);

    return (
        <div className={ConditionRow.criteria_dropdown_wrapper} ref={wrapperRef}>
            <div
                className={disabled ? `${ConditionRow.criteria_dropdown_button} ${ConditionRow.criteria_dropdown_button_disabled}` : `${ConditionRow.criteria_dropdown_button}`}
                style={{ borderColor: dropdownOpen ? "rgb(var(--dl-color-primary-100))" : "", width: "500px" }}
                onClick={() => {setDropdownOpen(!dropdownOpen)}} ref={setButton}
            >
                {props.values.length === 0 && (
                    <span className={ConditionRow.criteria_dropdown_button_text} style={{ color: "rgba(var(--dl-color-text-gray2), 0.85)" }}>Select Options</span>
                )}
                <div className={ConditionRow.criteria_dropdown_display_wrapper} style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {props.values.map((value, index) => (
                        <React.Fragment key={index}>
                            {props.values.findIndex((val) => val === value) !== 0 && (<span className={ConditionRow.criteria_dropdown_button_text} >|</span>)}
                            <span className={ConditionRow.criteria_dropdown_button_text}>{getDropdownDisplayValue(props.transformType, value)}</span>
                        </React.Fragment>
                    ))}
                </div>
                {!disabled && (
                    <svg viewBox="0 0 1024 1024" className={ConditionRow.criteria_dropdown_button_arrow_icon} style={{ fill: dropdownOpen ? "rgb(var(--dl-color-primary-100))" : "", transform: dropdownOpen ? "rotate(180deg)" : "" }}>
                        <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                    </svg>
                )}
            </div>
            {dropdownOpen && management.popupElement !== null ? (
                <div className={ConditionRow.criteria_dropdown_menu} ref={setPopup} {...attributes.popper}>
                    <CriteriaDropdownSelectionPopup dropdownOptions={props.dropdownOptions} transformType={props.transformType} withSearch={props.withSearch} values={props.values} setValues={props.setValues} search={search} setSearch={setSearch} />
                </div>
            ) : null}
            {dropdownOpen && management.popupElement === null ? createPortal(
                <div className={ConditionRow.criteria_dropdown_menu} ref={setPopup} style={{ ...styles.popper, minWidth: "500px" }} {...attributes.popper}>
                    <CriteriaDropdownSelectionPopup dropdownOptions={props.dropdownOptions} transformType={props.transformType} withSearch={props.withSearch} values={props.values} setValues={props.setValues} search={search} setSearch={setSearch} />
                </div>,
                document.body
            ) : null}
        </div>
    )
}

export default CriteriaDropdownSelection