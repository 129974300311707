import React from "react";

import indices from "../../../../../constants/indices";
import TableTopRowButtonSourceTabIndicesItem from "./dashboard-screener-source-tab-indices-item";
import WidgetTableSource from "./WidgetTableSource.module.css"

type Props = {
    widgetId: string;
    search: string
}

const TableTopRowButtonSourceTabIndices: React.FC<Props> = (props) => (
    <div className={WidgetTableSource.stocklist_wrapper}>
        <div className={WidgetTableSource.dropdown_wrapper}>
            <div className={WidgetTableSource.dropdown_button}>
                <svg viewBox="0 0 1024 1024" className={WidgetTableSource.dropdown_button_arrow_icon}>
                    <path d="M316 366l196 196 196-196 60 60-256 256-256-256z" />
                </svg>
                <span className={WidgetTableSource.dropdown_button_text}>All indices</span>
            </div>
        </div>
        <div className={WidgetTableSource.stocklist_list}>
            <div className={WidgetTableSource.stocklist_list_scroll}>
                {Object.keys(indices).map((indexCategory, index1) => {
                    const indexList = indices[indexCategory]
                    return (
                        <React.Fragment key={index1}>
                            {indexList.map((index2) => <TableTopRowButtonSourceTabIndicesItem widgetId={props.widgetId} indexCategory={indexCategory} index={index2} search={props.search} key={`${index1}-${index2}`}/>)}
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    </div>
)
    
export default TableTopRowButtonSourceTabIndices