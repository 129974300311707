import React from "react";

import WidgetStyles from "../../../WidgetStyles.module.css"
import TableIpos from "./table/table-ipos";
import TabIposTopRow from "./top-row/tab-ipos-top-row"

type Props = {
    id: string;
}

const TabIpos: React.FC<Props> = (props) => (
    <div className={WidgetStyles.tab_content}>
        <TabIposTopRow id={props.id} />
        <TableIpos />
    </div>
)

export default TabIpos