import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import CriteriaDropdown from '../../criteria-dropdown';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
    calculationPeriodOptions: string[];
}

const defaultProps: Props = {
    parameters: {
        calculationPeriod: "Quarter over Quarter",
    },
    setParameters: () => {},
    calculationPeriodOptions: ["Quarter over Quarter", "Year over Year", "Over the last 5 years"],
}

const CriteriaParameterEPSGrowth: React.FC<Props> = (props) => {
    const calculationPeriod = useMemo(() => props.parameters.calculationPeriod && props.calculationPeriodOptions.includes(props.parameters.calculationPeriod) ? props.parameters.calculationPeriod : defaultProps.parameters.calculationPeriod!, [props.parameters.calculationPeriod])

    const setCalculationPeriod = (newCalculationPeriod: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, calculationPeriod: newCalculationPeriod }, defaultProps.parameters))
    }

    return (
        <>
            <CriteriaDropdown dropdownOptions={props.calculationPeriodOptions} transformType="" value={calculationPeriod} setValue={setCalculationPeriod} />
        </>
    )
}

export default CriteriaParameterEPSGrowth