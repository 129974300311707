import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./GridStyles.css";

import { useMemo, useState } from "react";
import RGL, { Layout, WidthProvider } from "react-grid-layout";

import { changeDashboardData, saveDashboard } from "../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getWidgetComponent } from "../../../../utils/functions/get-widget-component";
import getNewWidgets from "./get-new-widgets";
import GridWidget from "./grid-widget";
import GridStyles from "./GridStyles.module.css"
import useGridLayouts from "./hooks/use-grid-layouts";

const GridLayout: React.FC = () => {
    const [isHovered, setIsHovered] = useState("");
    const [isDragging, setIsDragging] = useState(false);

    const dashboard = useAppSelector((state) => state.dashboard)

    const dispatch = useAppDispatch();

    const ReactGridLayout = useMemo(() => WidthProvider(RGL), []);

    const gridLayout = useGridLayouts();
    
    const mouseEnter = (id: string) => {
        if (isHovered !== "none") {
            setIsHovered(id)
        }
    }

    const mouseLeave = () => {
        if (isHovered !== "none") {
            setIsHovered("")
        }
    }

    const onLayoutChange = (layoutNew: Layout[]) => {
        const newWidgets = getNewWidgets(layoutNew, [ ...dashboard.data.widgets ]);
        dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
        dispatch(saveDashboard());
    }

    const onDragResizeStart = () => {
        setIsHovered("none");
        setIsDragging(true)
    }
    
    const onDragResize = (layout: Layout[], newItem: Layout) => {
        const xRight = newItem.x + newItem.w
        const numCols = dashboard.data.width / 100
        if (numCols === xRight) {
            dispatch(changeDashboardData({ width: dashboard.data.width + 100 }));
        }
    }

    const onDragResizeStop = (layout: Layout[], newItem: Layout) => {
        setIsHovered(newItem.i);
        setIsDragging(false)
        let biggestX = 0;
        layout.forEach(l => {
            const currentX = l.x + l.w
            biggestX = Math.max(biggestX, currentX)
        })
        const newWidth = biggestX * 100
        if (dashboard.data.width !== newWidth) {
            dispatch(changeDashboardData({ width: biggestX * 100 }));
        }
    }

    if (gridLayout) {
        return (
            <ReactGridLayout
                className="layout"
                layout={gridLayout}
                cols={50}
                rowHeight={10}
                margin={[4, 4]}
                containerPadding={[0, 0]}
                compactType={null}
                autoSize={true}
                resizeHandles={["se"]}
                isDraggable={true}
                isResizable={true}
                draggableHandle='.WidgetDraggableHandle'
                onLayoutChange={(layoutNew) => onLayoutChange(layoutNew)}
                onDragStart={() => onDragResizeStart()}
                onResizeStart={() => onDragResizeStart()}
                onDrag={(layout, oldItem, newItem) => onDragResize(layout, newItem)}
                onResize={(layout, oldItem, newItem) => onDragResize(layout, newItem)}
                onDragStop={(layout, oldItem, newItem) => onDragResizeStop(layout, newItem)}
                onResizeStop={(layout, oldItem, newItem) => onDragResizeStop(layout, newItem)}
            >   
                {gridLayout.map((widgetLayout) => {
                    if (widgetLayout) {
                        const widget = dashboard.data.widgets.find((w) => w.id === widgetLayout.i);
                        if (widget) {
                            const widgetComponent = getWidgetComponent(widget.id, widget.componentName, widget.connection, 14, (isHovered === widgetLayout.i || isHovered === "none"));
                            return (
                                <div key={widgetLayout.i} className={GridStyles.widget_container} style={{ zIndex: isHovered === widgetLayout.i ? "1" : "" }} onMouseEnter={() => mouseEnter(widgetLayout.i)} onMouseLeave={() => mouseLeave()}>
                                    <div className="WidgetDraggableHandle" style={{ opacity: isHovered === widgetLayout.i || isDragging ? "1" : "0" }}>
                                        <svg viewBox="0 0 1024 1024" className={GridStyles.drag_icon}>
                                            <path d="M1024 512c0 9.714-4 18.857-10.857 25.714l-146.286 146.286c-6.857 6.857-16 10.857-25.714 10.857-20 0-36.571-16.571-36.571-36.571v-73.143h-219.429v219.429h73.143c20 0 36.571 16.571 36.571 36.571 0 9.714-4 18.857-10.857 25.714l-146.286 146.286c-6.857 6.857-16 10.857-25.714 10.857s-18.857-4-25.714-10.857l-146.286-146.286c-6.857-6.857-10.857-16-10.857-25.714 0-20 16.571-36.571 36.571-36.571h73.143v-219.429h-219.429v73.143c0 20-16.571 36.571-36.571 36.571-9.714 0-18.857-4-25.714-10.857l-146.286-146.286c-6.857-6.857-10.857-16-10.857-25.714s4-18.857 10.857-25.714l146.286-146.286c6.857-6.857 16-10.857 25.714-10.857 20 0 36.571 16.571 36.571 36.571v73.143h219.429v-219.429h-73.143c-20 0-36.571-16.571-36.571-36.571 0-9.714 4-18.857 10.857-25.714l146.286-146.286c6.857-6.857 16-10.857 25.714-10.857s18.857 4 25.714 10.857l146.286 146.286c6.857 6.857 10.857 16 10.857 25.714 0 20-16.571 36.571-36.571 36.571h-73.143v219.429h219.429v-73.143c0-20 16.571-36.571 36.571-36.571 9.714 0 18.857 4 25.714 10.857l146.286 146.286c6.857 6.857 10.857 16 10.857 25.714z" />
                                        </svg>
                                    </div>
                                    <GridWidget id={widgetLayout.i} widgetComponent={widgetComponent} connection={widget.connection} />
                                </div>
                            )
                        }
                    }
                    return null
                })}
            </ReactGridLayout >
        );
    }

    return null
}

export default GridLayout