import { setPopupElement } from "../../../../../../store/features/management";
import { useAppDispatch } from "../../../../../../store/hooks";
import LayoutIcon from "../../../../icons/layout-icon";
import WidgetTopRowStyles from "../../../WidgetTopRowStyles.module.css"
import TableTopRowButtonColumnsPopup from "./table-top-row-button-columns-popup";

type Props = {
    id: string;
}

const TableTopRowButtonColumns: React.FC<Props> = (props) => {  
    const dispatch = useAppDispatch();

    return (
        <div className={WidgetTopRowStyles.dropdown_button} onClick={() => dispatch(setPopupElement(<TableTopRowButtonColumnsPopup id={props.id} />))}>
            <LayoutIcon colored={false} />
            <span className={WidgetTopRowStyles.dropdown_button_text}>Columns</span>
        </div>
    )
}
    
export default TableTopRowButtonColumns