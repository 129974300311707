import { useMemo, useRef, useState } from "react";

import { changeScreenerData, saveScreener } from "../../../../../../../store/features/screener";
import { useAppDispatch, useAppSelector } from "../../../../../../../store/hooks";
import { State } from "../../../../../../../types/Screener";
import BootstrapTooltip from "../../../../../../../utils/components/tooltip/bootstrap-tooltip"
import DashboardScreenerDetailsStyles from "../../DashboardScreenerDetails.module.css"

type Props = {
    selectedState: State;
    small: boolean;
}

const BuilderSettingsName: React.FC<Props> = (props) => {
    const [nameInput, setNameInput] = useState(props.selectedState.name);

    const dispatch = useAppDispatch();

    const screener = useAppSelector((state) => state.screener)   

    const stateIndex = useMemo(() => screener.data.states.findIndex((state) => state.id === props.selectedState.id), [screener.data.states, props.selectedState.id])

    const inputRef = useRef<HTMLInputElement | null>(null);

    const setNewName = () => {
        if (stateIndex > -1 && nameInput !== props.selectedState.name) {
            const newState: State = {
                ...props.selectedState,
                name: nameInput
            }
            const states = [...screener.data.states];
            states.splice(stateIndex, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
    }

    const handleKeypress = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            inputRef.current?.blur();
        }
    };

    return (
        <div className={DashboardScreenerDetailsStyles.settings_content_row} style={{ flexDirection: props.small ? "column" : "row" }}>
            <div className={DashboardScreenerDetailsStyles.settings_row_left}>
                <span className={DashboardScreenerDetailsStyles.settings_row_title} style={{ maxWidth: props.small ? "none" : "120px" }}>Name:</span>
                <BootstrapTooltip title="Learn more about state names">
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.settings_help_icon}>
                        <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                    </svg>
                </BootstrapTooltip>
            </div>
            <div className={DashboardScreenerDetailsStyles.settings_row_right}>
                <input
                    type="text"
                    className={DashboardScreenerDetailsStyles.name_input}
                    placeholder="Name..."
                    onChange={(event) => {setNameInput(event.target.value)}}
                    onKeyDown={async (event) => await handleKeypress(event)}
                    value={nameInput}
                    onBlur={() => setNewName()}
                    ref={inputRef}
                />
                <span className={DashboardScreenerDetailsStyles.settings_row_subtext}>Give your state a meaningful name to quickly know what it is for.</span>
            </div>
        </div>
    )
}

export default BuilderSettingsName