import React from "react";

import WidgetStyles from "../../../../WidgetStyles.module.css"

const TableEconomySeparator: React.FC = () => (
    <div className={WidgetStyles.simple_table_separator}>
        <div className={WidgetStyles.simple_table_separator_bar} />
        <div className={WidgetStyles.simple_table_separator_text}>
            01. September
        </div>
    </div>
)

export default TableEconomySeparator