import ToggleSwitchStyles from "./ToggleSwitch.module.css";

type Props = {
    isToggled: boolean;
    onClick?: () => void;
}

const ToggleSwitchBig: React.FC<Props> = (props) => (
    <div
        className={ToggleSwitchStyles.toggle_switch_big}
        onClick={() => {if (props.onClick) {props.onClick()}}}
    >
        <div className={props.isToggled ? `${ToggleSwitchStyles.toggle_big_background} ${ToggleSwitchStyles.toggle_big_background_toggled}` : `${ToggleSwitchStyles.toggle_big_background}`} />
        <span className={ToggleSwitchStyles.toggle_big_text}>OFF</span>
        <span className={ToggleSwitchStyles.toggle_big_text} style={{ color: props.isToggled ? "rgb(var(--dl-color-white-always))" : "" }}>ON</span>
    </div>
)

export default ToggleSwitchBig