interface Operators {
    [key: string]: string[];
}

export const operators: Operators = {
    filter: [
        "below",
        "below or equal",
        "above",
        "above or equal",
        "equal",
        "not equal",
        "between",
        "outside of",
    ],
    alert: [
        "crosses up",
        "crosses down",
        "crosses"
    ],
    volume: [
        "crosses up"
    ],
    highLow: [
        "new high low"
    ],
    latestNews: [
        "older",
        "not older"
    ],
    newNews: [
        "new news"
    ],
    is: [
        "is"
    ]
}