/* eslint-disable @typescript-eslint/no-explicit-any */
import { HeaderGroup } from "react-table";

import HeaderCellDummy from "./header/header-cell-dummy";
import HeaderCellStandard from "./header/header-cell-standard";
import HeaderCellTicker from "./header/header-cell-ticker";

type Props = {
    width: number;
    column: HeaderGroup<object>;
}

const HeaderCellSwitch: React.FC<Props> = (props) => {
    switch(props.column.id) {
    case "ticker": return <HeaderCellTicker width={props.width} column={props.column} />;
    case "dummy": return <HeaderCellDummy />
    default: return <HeaderCellStandard width={props.width} column={props.column} />
    }
}

export default HeaderCellSwitch