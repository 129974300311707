import WidgetTopRowStyles from "../../../../WidgetTopRowStyles.module.css"
import DividendsButtonCountry from "./countries/dividends-button-country"
import DividendsButtonDate from "./dividends-button-date"
import DividendsButtonSearch from "./dividends-button-search";

type Props = {
    id: string;
    tickerSearch: string;
    setTickerSearch: React.Dispatch<React.SetStateAction<string>>;
}

const TabDividendsTopRow: React.FC<Props> = (props) => (
    <div className={WidgetTopRowStyles.container}>
        <div className={WidgetTopRowStyles.group_container}>
            <DividendsButtonDate id={props.id} />
        </div>
        <div className={WidgetTopRowStyles.group_container}>
            <DividendsButtonCountry id={props.id} />
            <DividendsButtonSearch id={props.id} tickerSearch={props.tickerSearch} setTickerSearch={props.setTickerSearch} />
        </div>
    </div>
)

export default TabDividendsTopRow