import { useEffect } from "react";
import { createPortal } from "react-dom";

import usePopperPopup from "../../../../../../utils/hooks/use-popper-popup";
import DashboardWidgetStyles from "../DashboardWidgetStyles.module.css";
import GridWidgetSettingsOptionsPopup from "./grid-widget-settings-options-popup";

type Props = {
    id: string;
    visible: boolean;
}

const GridWidgetSettingsOptions: React.FC<Props> = (props) => {
    const { setButton, setPopup, buttonRef, styles, attributes, isOpen, setIsOpen } = usePopperPopup({ initialPlacement: "bottom-end", fallbackPlacements: ["top-end", "right", "right-start", "right-end", "left", "left-start", "left-end"] });

    useEffect(() => {
        if (!props.visible) {
            setIsOpen(false);
        }
    }, [props.visible])

    return (
        <div className={DashboardWidgetStyles.settings_icon_wrapper} ref={buttonRef}>
            <div className={DashboardWidgetStyles.settings_icon_container} ref={setButton} onClick={() => {setIsOpen(!isOpen)}}>
                <svg viewBox="0 0 1024 1024" className={DashboardWidgetStyles.settings_icon}>
                    <path d="M512 682q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM512 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM512 342q-34 0-60-26t-26-60 26-60 60-26 60 26 26 60-26 60-60 26z" />
                </svg>
            </div>
            {isOpen ? createPortal(
                <div className={DashboardWidgetStyles.settings_popup} ref={setPopup} style={styles.popper} {...attributes.popper}>
                    <GridWidgetSettingsOptionsPopup id={props.id} />
                </div>,
                document.body
            ) : null}
        </div>
    )
}

export default GridWidgetSettingsOptions