import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import ConditionRow from "../../../ConditionRow.module.css"
import CriteriaDropdown from '../../criteria-dropdown';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
    typeOptions: string[];
    reportPeriodOptions: string[];
}

const defaultProps: Props = {
    parameters: {
        type: "Total",
        reportPeriod: "Quarterly Report",
    },
    setParameters: () => {},
    typeOptions: ["Total", "Current", "Non-Current"],
    reportPeriodOptions: ["Quarterly Report", "Annual Report", "Trailing 12 Months"],
}

const CriteriaParameterLiabilities: React.FC<Props> = (props) => {
    const type = useMemo(() => props.parameters.type && props.typeOptions.includes(props.parameters.type) ? props.parameters.type : defaultProps.parameters.type!, [props.parameters.type])
    const reportPeriod = useMemo(() => props.parameters.reportPeriod && props.reportPeriodOptions.includes(props.parameters.reportPeriod) ? props.parameters.reportPeriod : defaultProps.parameters.reportPeriod!, [props.parameters.reportPeriod])

    const setType = (newType: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, type: newType }, defaultProps.parameters))
    }

    const setReportPeriod = (newReportPeriod: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, reportPeriod: newReportPeriod }, defaultProps.parameters))
    }

    return (
        <>
            <CriteriaDropdown dropdownOptions={props.typeOptions} transformType="" value={type} setValue={setType} />
            <span className={ConditionRow.criteria_text}>of last</span>
            <CriteriaDropdown dropdownOptions={props.reportPeriodOptions} transformType="" value={reportPeriod} setValue={setReportPeriod} />
        </>
    )
}

export default CriteriaParameterLiabilities