import DashboardsListener from './individual-hooks/dashboards-listener';
import ScreenersListener from './individual-hooks/screeners-listener';
import UserDataListener from './individual-hooks/user-data-listener';
import UserListener from './individual-hooks/user-listener';
import WindowListener from './individual-hooks/window-listener';

const AllListeners: React.FC = () => (
    <>  
        <DashboardsListener />
        <ScreenersListener />
        <UserListener />
        <UserDataListener />
        <WindowListener />
    </>
);

export default AllListeners