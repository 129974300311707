import { Dashboards } from "../Dashboard"

const sortDashboardsByName = (dashboards: Dashboards): string[] => Object.keys(dashboards).sort((a, b) => {
    const fa = dashboards[a].name.toLowerCase();
    const fb = dashboards[b].name.toLowerCase();
    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
})

export default sortDashboardsByName