import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "rgb(var(--dl-color-backgrounds-tooltip))",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        color: "rgb(var(--dl-color-white-always))",
        backgroundColor: "rgb(var(--dl-color-backgrounds-tooltip))",
        margin: "8px",
        padding: "6px 8px 4px",
        fontSize: "11px",
        fontWeight: 300,
        maxWidth: "340px",
        lineHeight: 1.2
    },
}));

export default BootstrapTooltip