import { useMemo } from "react";
import { Cell } from "react-table";

import TableTableStyles from "../TableTableStyles.module.css"

type Props = {
    cell: Cell<object>;
    width: number;
}

const CellPercent: React.FC<Props> = (props) => {
    const number = useMemo(() => parseFloat(props.cell.value as string), [])

    const numberString = useMemo(() => {
        if (typeof number === "undefined" || number.toString() === "NaN") {
            return "-"
        }
        return number.toFixed(2)
    }, [])

    return (
        <div className={TableTableStyles.cell_wrapper} style={{ padding: props.width > 140 ? "6px 16px" : "" }}>
            <span className={TableTableStyles.cell_text}>{numberString === "-" ? "-" : `${numberString}%`}</span>
        </div>
    )
}

export default CellPercent