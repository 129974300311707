import _ from 'lodash';

import { changeScreenerData, DashboardScreenerStyles } from "../../../../store/features/screener";
import { AppDispatch } from "../../../../store/store";
import { CriteriaRow, Settings, State } from "../../../../types/Screener";
import { operators } from "../../../constants/operators";
import deepSpliceCriteria from "../../../functions/deep-splice-criteria";

export const checkIdB = (
    type: string,
    comparisons: string[],
    settings: Settings
): Settings => {
    let newSettings = { ...settings }
    if (!comparisons.includes(settings.idB1) || (!comparisons.includes(settings.idB2)) && settings.idB2 !== "") {
        newSettings = { 
            ...newSettings,
            idB1: comparisons[0],
            idB2: "",
            parametersB1: {},
            parametersB2: {}
        }
    }
    let operatorType = type
    if (type === "alert" && ["volume-ah", "volume-average-volume", "volume-candle", "volume-dollar-volume", "volume-pm", "volume-today", "news-volume-since-news"].includes(settings.idA)) {
        operatorType = "volume"
    }
    if (["price-new-high", "price-new-low"].includes(settings.idA)) {
        operatorType = "highLow"
    }
    if (["news-latest-news"].includes(settings.idA)) {
        operatorType = "latestNews"
    }
    if (["news-new-news"].includes(settings.idA)) {
        operatorType = "newNews"
    }
    if (["time-market-status", "descriptive-country", "descriptive-sector-industry"].includes(settings.idA)) {
        operatorType = "is"
    }
    if (!operators[operatorType].includes(settings.operator)) {
        newSettings = {
            ...newSettings,
            operator: operators[operatorType][0]
        }
    }
    return newSettings
}

export const checkOperator = (settings: Settings): Settings => {
    if (!["between", "outside of"].includes(settings.operator) && settings.idB2 !== "") {
        return { 
            ...settings,
            idB2: "",
            parametersB2: {}
        }
    }
    if (["between", "outside of"].includes(settings.operator) && settings.idB2 === "") {
        return {
            ...settings,
            idB2: settings.idB1,
            parametersB2: settings.parametersB1,
        }
    }
    return { ...settings }
}

const parameterChange = (
    dispatch: AppDispatch,
    overrides: Partial<Settings>,
    screener: DashboardScreenerStyles,
    state: State,
    row: CriteriaRow
): void => {
    const newSettings: Settings = {
        ...row.settings,
        ...overrides
    }
    if (!_.isEqual(newSettings, row.settings)) {
        const newState = _.cloneDeep(state);
        newState.criteria = deepSpliceCriteria(state.criteria, row, newSettings)
        const states = _.cloneDeep(screener.data.states);
        const position = screener.data.states.findIndex(((op: State) => op.id === state.id))
        states.splice(position, 1, newState);
        dispatch(changeScreenerData({
            ...screener.data,
            states,
        }));
    }
}

export default parameterChange