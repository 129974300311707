import { deleteDoc, doc } from 'firebase/firestore';
import { useState } from 'react';
import { useNavigate } from "react-router-dom"

import { firestoreDB } from '../../../firebase/firebase-config';
import { setPopupElement } from '../../../store/features/management';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Dashboard } from '../../../types/Dashboard';
import Avatar from '../../../utils/components/avatar/avatar';
import LoadingSpinner from '../../../utils/components/loading-spinner/loading-spinner';
import useClickOutsidePopup from '../../../utils/hooks/use-click-outside-popup';
import DashboardScreenerStyles from './screener/DashboardScreener.module.css';

type Props = {
    dashboard: Dashboard
}

const DashboardDeletePopup: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const dashboard = useAppSelector((state) => state.dashboard)
    const user = useAppSelector((state) => state.user)

    const [nameInput, setNameInput] = useState("");
    const [nameInputError, setNameInputError] = useState("");

    const navigate = useNavigate();

    const refClickOutside = useClickOutsidePopup();

    const removeDashboard = async () => {
        if (nameInput.trim() === props.dashboard.name.trim()) {
            try {
                await deleteDoc(doc(firestoreDB, "users", user.userData.uid, "dashboards", props.dashboard.id));
                navigate("/app/dashboards")
            } catch (error) {
                setNameInputError("An error occured. Please try again.")
            }
        } else {
            setNameInputError("Please enter the correct name of the dashboard.")
        }
        dispatch(setPopupElement(null));
    }

    return (
        <div className="app_popup_wrapper_middle" ref={refClickOutside}>
            <div className={DashboardScreenerStyles.delete_container} style={{ maxWidth: "600px" }}>
                <span className={DashboardScreenerStyles.delete_header} style={{ color: "rgba(var(--dl-color-text-black), 0.83)" }}>
                    Delete Dashboard
                </span>
                <div className={DashboardScreenerStyles.delete_avatar_wrapper}>
                    <Avatar name={props.dashboard.name} size={200} borderRadius={16} />
                </div>
                <span className={DashboardScreenerStyles.delete_header} style={{ alignSelf: "center" }}>
                    Delete:
                </span>
                <span className={DashboardScreenerStyles.delete_state_name} style={{ alignSelf: "center" }}>
                    {props.dashboard.name} ({props.dashboard.widgets.length} Widget{props.dashboard.widgets.length !== 1 ? "s" : ""})
                </span>
                <span className={DashboardScreenerStyles.delete_state_warning}>
                    <span className={DashboardScreenerStyles.text_bold_500}>Warning:</span>
                    <span> All widgets in this dashboard will be deleted. If you still want to delete this dashboard together with all widgets, please type in the name of the dashboard.</span>
                </span>
                <div className={DashboardScreenerStyles.delete_confirmation_wrapper}>
                    <span className={DashboardScreenerStyles.delete_state_warning} style={{ marginTop: "0px" }}>
                        <span className={DashboardScreenerStyles.delete_confirmation_text}>Type in </span>
                        <span className={DashboardScreenerStyles.delete_confirmation_text} style={{ fontWeight: "800" }}>{props.dashboard.name}</span>
                    </span>
                    <input
                        type="text"
                        className={DashboardScreenerStyles.delete_confirmation_input}
                        style={{ borderColor: nameInputError !== "" ? "rgb(var(--dl-color-colors-red-error))" : "" }}
                        placeholder={props.dashboard.name}
                        onChange={(event) => {setNameInputError(""); setNameInput(event.target.value)}}
                        value={nameInput}
                    />
                    {nameInputError && (
                        <span className={DashboardScreenerStyles.delete_confirmation_text} style={{ color: "rgb(var(--dl-color-colors-red-error))" }}>{nameInputError}</span>
                    )}
                </div>
                <div className={DashboardScreenerStyles.delete_state_button_row} style={{ margin: "8px 0px" }}>
                    <div className={DashboardScreenerStyles.delete_state_button} onClick={() => dispatch(setPopupElement(null))}>
                        <span className={DashboardScreenerStyles.delete_state_button_text}>Cancel</span>
                    </div>
                    {dashboard.loading ? (
                        <div className={`${DashboardScreenerStyles.delete_state_button} ${DashboardScreenerStyles.delete_state_button_delete}`} style={{ pointerEvents: "none" }}>
                            <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                        </div>
                    ) : (
                        <div className={`${DashboardScreenerStyles.delete_state_button} ${DashboardScreenerStyles.delete_state_button_delete}`} onClick={() => {removeDashboard()}}>
                            <span className={DashboardScreenerStyles.delete_state_button_text} style={{ color: "rgb(var(--dl-color-white-always))" }}>Delete</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DashboardDeletePopup