
import ReplayIcon from '@mui/icons-material/Replay';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

import { auth, firebaseStorage } from '../../firebase/firebase-config';
import { createNewScreener } from '../../store/features/screener';
import { logout, submitOnboarding } from '../../store/features/user';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import getIcons from '../../utils/components/icons/icons';
import LoadingSpinner from '../../utils/components/loading-spinner/loading-spinner';
import { colorOptions } from '../../utils/constants/color-options';
import useIntersection from '../../utils/hooks/use-intersection';
import AvatarColorOption from './avatar-color-option';
import Onboarding from './Onboarding.module.css'

const usageOptions = [
    "Investing",
    "Swingtrading",
    "Daytrading",
    "I do not know yet"
]

const timeOptions = [
    "Less than 1 year",
    "1-2 years",
    "3-5 years",
    "5-10 years",
    "More than 10 years"
]

const findOptions = [
    "Twitter",
    "TikTok",
    "Instagram",
    "YouTube",
    "Stocktwits",
    "Search Engines",
    "Others"
]

const smallIcons = getIcons(18, "currentColor")
const bigIcons = getIcons(54, "currentColor")

const OnboardingPage: React.FC = () => {
    const user = useAppSelector((state) => state.user)
    const screener = useAppSelector((state) => state.screener)

    const dispatch = useAppDispatch();

    const scrollPageRef = useRef<HTMLDivElement | null>(null);
    const startRef = useRef<HTMLButtonElement | null>(null);
    const avatarRef = useRef<HTMLButtonElement | null>(null);
    const themeRef = useRef<HTMLButtonElement | null>(null);
    const usageRef = useRef<HTMLButtonElement | null>(null);
    const timeRef = useRef<HTMLButtonElement | null>(null);
    const findRef = useRef<HTMLButtonElement | null>(null);
    const screenerRef = useRef<HTMLButtonElement | null>(null);
    const endRef = useRef<HTMLButtonElement | null>(null);

    const themeInViewport = useIntersection(themeRef, "0px 0px -200px 0px");
    const usageInViewport = useIntersection(usageRef, "0px 0px -200px 0px");
    const timeInViewport = useIntersection(timeRef, "0px 0px -200px 0px");
    const findInViewport = useIntersection(findRef, "0px 0px -200px 0px");
    const screenerInViewport = useIntersection(screenerRef, "0px 0px -200px 0px");
    const avatarInViewport = useIntersection(avatarRef, "0px 0px -200px 0px");
    const endInViewport = useIntersection(endRef, "0px 0px -200px 0px");

    const [section, setSection] = useState("start");

    const [avatarColor, setAvatarColor] = useState(colorOptions[0][0]);
    const [avatarImage, setAvatarImage] = useState("");
    const [avatarType, setAvatarType] = useState("icon");
    const [avatarUploadError, setAvatarUploadError] = useState("");
    const [selectedIcon, setSelectedIcon] = useState("Letter");
    const [iconSearch, setIconSearch] = useState("");

    const [selectedUsageOptions, setSelectedUsageOptions] = useState<string[]>([])
    const [usageOptionsError, setUsageOptionsError] = useState("")

    const [timeOption, setTimeOption] = useState("")

    const [findOption, setFindOption] = useState("")
    const [findOthersValue, setFindOthersValue] = useState("")
    const [findOptionOthersError, setFindOptionOthersError] = useState("")
  
    const [screenerName, setScreenerName] = useState("")
    const [screenerNameError, setScreenerNameError] = useState("")
  
    const [buttonFinishError, setButtonFinishError] = useState("")
  
    const navigate = useNavigate();

    useEffect(() => {
        if (endInViewport) {
            setSection("end")
        } else if (avatarInViewport) {
            setSection("avatar")
        } else if (screenerInViewport) {
            setSection("screener")
        } else if (findInViewport) {
            setSection("find")
        } else if (timeInViewport) {
            setSection("time")
        } else if (usageInViewport) {
            setSection("usage")
        } else if (themeInViewport) {
            setSection("theme")
        } else {
            setSection("start")
        }
    }, [endInViewport, avatarInViewport, screenerInViewport, findInViewport, timeInViewport, usageInViewport, themeInViewport]);

    useEffect(() => {
        setAvatarUploadError("");
    }, [avatarColor]);
  
    const handleLogout = async () => {
        try {
            await dispatch(logout()).unwrap();
            navigate("/login")
        } catch (error) {
            navigate("/login")
        }
    }

    const uploadAvatarFile = async (file: File) => {
        if (!file) {
            return setAvatarUploadError("Upload aborted.")
        }
        if (!file.name.endsWith(".png") && !file.name.endsWith(".jpg") && !file.name.endsWith(".jpeg")) {
            return setAvatarUploadError("Please select an image of .png, .jpg or .jpeg file type.")
        }
        if (auth.currentUser) {
            const avatarFileRef = ref(firebaseStorage, `/${auth.currentUser.uid}/avatars/${`${v4()  }_${  file.name}`}`);
            try {
                const res = await uploadBytes(avatarFileRef, file);
                const imageUrl = await getDownloadURL(res.ref);
                setAvatarImage(imageUrl);
                setAvatarType("image");
                return setAvatarUploadError("");
            } catch (error: unknown) {
                if (error instanceof Error) {
                    return setAvatarUploadError(error.message);
                }
            }
        }
    }

    const usageOptionClickHandler = (option: string) => {
        setUsageOptionsError("");
        if (option === "I do not know yet") {
            setSelectedUsageOptions([option]);
        } else {
            const newOptions = _.clone(selectedUsageOptions);
            if (newOptions.includes("I do not know yet")) {
                const index = newOptions.indexOf("I do not know yet")
                if (index > -1) {
                    newOptions.splice(index, 1);
                }
            }
            if (newOptions.includes(option)) {
                if (newOptions.length > 1) {
                    const index = newOptions.indexOf(option);
                    newOptions.splice(index, 1);
                }
            } else {
                newOptions.push(option);
            }
            setSelectedUsageOptions(newOptions);
        }
    }

    const usageClickNext = () => {
        if (selectedUsageOptions.length !== 0) {
            usageRef.current?.scrollIntoView({ behavior: 'smooth' });
        } else {
            setUsageOptionsError("Please select at least one option.");
        }
    }

    const timeClickNext = () => {
        if (timeOption !== "" && timeOption !== "error") {
            timeRef.current?.scrollIntoView({ behavior: 'smooth' });
        } else {
            setTimeOption("error");
        }
    }

    const findOtherHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFindOthersValue(event.target.value);
        if (event.target.value.length <= 100) {
            setFindOptionOthersError("")
        } else {
            setFindOptionOthersError("Please enter maxium 100 characters.")
        }
    }

    const findClickNext = () => {
        if (findOption !== "" && findOption !== "error") {
            if (findOption === "Others" && findOthersValue.trim() === "") {
                setFindOptionOthersError("Please let us know how where you heard about us.");
            } else if (findOptionOthersError === "") {
                findRef.current?.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            setFindOption("error");
        }
    }  

    const screenerNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScreenerName(event.target.value);
        if (event.target.value.length <= 50) {
            setScreenerNameError("");
        } else {
            setScreenerNameError("Please enter maxium 50 characters.")
        }
    }

    const screenerClickNext = () => {
        if (screenerNameError !== "") {
            return
        }
        if (screenerName.trim().length === 0) {
            if (user.userData.displayName !== "") {
                setScreenerName(`${user.userData.displayName.split(" ")[0]}'s First Screener`)
            } else {
                setScreenerName("Screener 1")
            }
        }
        screenerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const buttonFinishHandler = async () => {
        let errorFlag = false;
        if (selectedUsageOptions.length === 0) {
            setUsageOptionsError("Please select at least one option.");
            errorFlag = true;
        }
        if (timeOption === "" || timeOption === "error") {
            setTimeOption("error");
            errorFlag = true;
        }
        if (findOption === "" || findOption === "error") {
            setFindOption("error");
            errorFlag = true;
        }
        if (findOptionOthersError !== "") {
            errorFlag = true;
        }
        if (findOption === "Others" && findOthersValue.trim() === "") {
            setFindOptionOthersError("Please let us know how where you heard about us.");
            errorFlag = true;
        }
        if (screenerNameError !== "") {
            errorFlag = true;
        }
        let newName = screenerName.trim();
        if (newName.length === 0) {
            if (user.userData.displayName !== "") {
                newName = `${user.userData.displayName.split(" ")[0]}'s First Screener`
                setScreenerName(newName)
            } else {
                newName = "Screener 1"
                setScreenerName(newName)
            }
        }
        if (errorFlag) {
            setButtonFinishError("Form still incomplete. Please fill in any missing information.")
        } else {
            setButtonFinishError("");
            try {
                await dispatch(submitOnboarding({
                    onboardingCompleted: true,
                    theme: {
                        dark: false
                    },
                    onboardingData: {
                        usageOptions,
                        timeOption,
                        findOption
                    }
                })).unwrap();
                await dispatch(createNewScreener({
                    name: newName
                })).unwrap();
                navigate("/app");
            } catch (error) {
                setButtonFinishError("An error occured.");
            }
        }
    }

    const getScreenerName = (): string => {
        if (screenerName !== "") {
            return screenerName
        }
        if (user.userData.displayName) {
            return `${user.userData.displayName.split(" ")[0]}'s First Screener`
        }
        return "Screener 1"
    }

    const getAvatarLetter = (): string => {
        if (screenerName !== "") {
            return screenerName[0]
        }
        if (user.userData.displayName !== "") {
            return user.userData.displayName[0]
        }
        return "W"
    }

    if (!user.userData.onboardingCompleted) {
        return (
            <div className={Onboarding.page_wrapper} id="onboarding_page">
                <Helmet>
                    <title>Welcome | Traddle.io</title>
                    <meta
                        property="og:title"
                        content="Welcome | Traddle.io"
                    />
                </Helmet>
                <div className={Onboarding.full_width_bar_fixed_top}>
                    <button type="button" className={Onboarding.logout_button} onClick={() => {handleLogout()}}>Logout</button>
                </div>
                <div className={Onboarding.page_content} ref={scrollPageRef}>
                    <div className={Onboarding.content_section} style={{ opacity: section === "start" ? "1" : "0.4", pointerEvents: section === "start" ? "auto" : "none" }}>
                        <img src="/logos/traddle_logo_black_on_white_comp.png" alt="Traddle Logo" className={Onboarding.content_section_traddle_logo} />
                        <div className={Onboarding.content_section_wrapper}>
                            <div className={Onboarding.section_start_wrapper}>
                                <span className={Onboarding.text_big}>Welcome, {user.userData.displayName ? user.userData.displayName.split(" ")[0] : "Trader"} 👋</span>
                                <span className={Onboarding.text_big}>Let´s make Traddle yours.</span>
                                <span className={`${Onboarding.text_big} ${Onboarding.margin_top_8}`}>This will only take about one minute.</span>
                            </div>
                            <button type="button" ref={startRef} className={Onboarding.next_button} onClick={() => {startRef.current?.scrollIntoView({ behavior: 'smooth' });}}>Let&apos;s go!</button>
                        </div>
                    </div>
                    <div className={Onboarding.content_section} style={{ opacity: section === "usage" ? "1" : "0.4", pointerEvents: section === "usage" ? "auto" : "none" }}>
                        <img src="/logos/traddle_logo_black_on_white_comp.png" alt="Traddle Logo" className={Onboarding.content_section_traddle_logo} />
                        <div className={Onboarding.content_section_wrapper}>
                            <span className={Onboarding.text_big}>What do you mainly want to use Traddle for?</span>
                            <div className={Onboarding.usage_options_container}>
                                {usageOptions.map((option, index) => (
                                    <div key={index} className={Onboarding.usage_option} style={{ color: selectedUsageOptions.includes(option) ? `rgb(var(--dl-color-primary-100))` : "", backgroundColor: selectedUsageOptions.includes(option) ? `rgba(var(--dl-color-primary-100), 0.1)` : "", borderColor: selectedUsageOptions.includes(option) ? `rgb(var(--dl-color-primary-100))` : "" }} onClick={() => usageOptionClickHandler(option)}>
                                        <span className={Onboarding.usage_option_text}>{option}</span>
                                    </div>
                                ))}
                            </div>
                            {(usageOptionsError !== "") && (
                                <p className={Onboarding.error_text}>{usageOptionsError}</p>
                            )}
                            <div className={Onboarding.usage_button_container}>
                                <button ref={usageRef} type="button" className={`${Onboarding.next_button} ${Onboarding.button_no_margin}`} onClick={() => usageClickNext()}>Next</button>
                                <span className={Onboarding.usage_notice_text}>Notice: We use this information solely to suggest you suitable templates.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={Onboarding.content_section} style={{ opacity: section === "time" ? "1" : "0.4", pointerEvents: section === "time" ? "auto" : "none" }}>
                        <img src="/logos/traddle_logo_black_on_white_comp.png" alt="Traddle Logo" className={Onboarding.content_section_traddle_logo} />
                        <div className={Onboarding.content_section_wrapper}>
                            <span className={Onboarding.text_big}>How long have you been trading or investing already?</span>
                            <div className={Onboarding.usage_options_container}>
                                {timeOptions.map((option, index) => (
                                    <div key={index} className={Onboarding.usage_option} style={{ color: option === timeOption ? `rgb(var(--dl-color-primary-100))` : "", backgroundColor: option === timeOption ? `rgba(var(--dl-color-primary-100), 0.1)` : "", borderColor: option === timeOption ? `rgb(var(--dl-color-primary-100))` : "" }} onClick={() => {setTimeOption(option)}}>
                                        <span className={Onboarding.usage_option_text}>{option}</span>
                                    </div>
                                ))}
                            </div>
                            {timeOption === "error" && (
                                <p className={Onboarding.error_text}>Please select an option.</p>
                            )}
                            <button type="button" ref={timeRef} className={Onboarding.next_button} onClick={() => timeClickNext()}>Next</button>
                        </div>
                    </div>
                    <div className={Onboarding.content_section} style={{ opacity: section === "find" ? "1" : "0.4", pointerEvents: section === "find" ? "auto" : "none" }}>
                        <img src="/logos/traddle_logo_black_on_white_comp.png" alt="Traddle Logo" className={Onboarding.content_section_traddle_logo} />
                        <div className={Onboarding.content_section_wrapper}>
                            <span className={Onboarding.text_big}>How did you find us?</span>
                            <div className={Onboarding.usage_options_container}>
                                {findOptions.map((option, index) => (
                                    <div key={index} className={Onboarding.usage_option} style={{ color: option === findOption ? `rgb(var(--dl-color-primary-100))` : "", backgroundColor: option === findOption ? `rgba(var(--dl-color-primary-100), 0.1)` : "", borderColor: option === findOption ? `rgb(var(--dl-color-primary-100))` : "" }} onClick={() => {setFindOption(option); if (option !== "Others") {setFindOptionOthersError("")}}}>
                                        <span className={Onboarding.usage_option_text}>{option}</span>
                                    </div>
                                ))}
                            </div>
                            {findOption === "Others" && (
                                <input
                                    placeholder="Let us know where you heard about us 😊"
                                    type="text"
                                    className={Onboarding.find_textinput}
                                    onChange={(event) => {findOtherHandler(event)}}
                                    value={findOthersValue}
                                />
                            )}
                            {findOption === "error" && (
                                <p className={Onboarding.error_text}>Please select an option.</p>
                            )}
                            {(findOptionOthersError !== "") && (
                                <p className={Onboarding.error_text}>{findOptionOthersError}</p>
                            )}
                            <button type="button" ref={findRef} className={Onboarding.next_button} onClick={() => findClickNext()}>Next</button>
                        </div>
                    </div>
                    <div className={Onboarding.content_section} style={{ opacity: section === "screener" ? "1" : "0.4", pointerEvents: section === "screener" ? "auto" : "none" }}>
                        <img src="/logos/traddle_logo_black_on_white_comp.png" alt="Traddle Logo" className={Onboarding.content_section_traddle_logo} />
                        <div className={Onboarding.content_section_wrapper}>
                            <div className={Onboarding.flex_column}>
                                <span className={Onboarding.text_big}>With Traddle Screeners you can create and order filters and alerts.</span>
                                <span className={Onboarding.text_big}>Enter the name of your first Screener:</span>
                            </div>
                            <div className={Onboarding.flex_column}>
                                <input
                                    placeholder={user.userData.displayName ? `${user.userData.displayName.split(" ")[0]}'s First Screener` : "Screener 1"}
                                    type="text"
                                    className={`${Onboarding.find_textinput} ${Onboarding.screener_textinput}`}
                                    onChange={(event) => {screenerNameHandler(event)}}
                                    value={screenerName}
                                />
                                <span className={Onboarding.screener_input_hint}>This can be the name of your trading strategy or what stocks you want to find with this Screener.</span>
                            </div>
                            {screenerNameError !== "" && (
                                <p className={Onboarding.error_text}>{screenerNameError}</p>
                            )}
                            <button type="button" ref={screenerRef} className={Onboarding.next_button} onClick={() => screenerClickNext()}>Next</button>
                        </div>
                    </div>
                    <div className={Onboarding.content_section} style={{ opacity: section === "avatar" ? "1" : "0.4", pointerEvents: section === "avatar" ? "auto" : "none" }}>
                        <img src="/logos/traddle_logo_black_on_white_comp.png" alt="Traddle Logo" className={Onboarding.content_section_traddle_logo} />
                        <div className={Onboarding.content_section_wrapper}>
                            <span className={Onboarding.text_big}>Customize the icon for {getScreenerName()}</span>
                            <div className={Onboarding.avatar_container}>
                                <div className={Onboarding.avatar_selection_switch}>
                                    <label className={Onboarding.upload_image} style={{ backgroundImage: avatarImage !== "" ? `url(${avatarImage})` : "", opacity: avatarType === "image" || avatarImage === "" ? "" : "0.5" }}>
                                        {(avatarType === "icon" && avatarImage !== "") ? (
                                            <div className={Onboarding.upload_image_input_wrapper} onClick={() => {setAvatarType("image")}} />
                                        ) : (
                                            <div className={Onboarding.upload_image_input_wrapper}>
                                                <input type="file" className={Onboarding.upload_image_input}
                                                    accept=".png, .jpg, .jpeg"
                                                    onChange={(event) => {if (event.target.files) {uploadAvatarFile(event.target.files[0])}}}
                                                    onClick={(event) => {if (avatarImage !== "") {event.preventDefault()}}}
                                                />
                                                <div className={Onboarding.upload_image_content} style={avatarImage !== "" ? { display: "none" } : {}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                                                        <path fillRule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                                    </svg>
                                                    <div className={Onboarding.upload_text_wrapper}>
                                                        <span className={Onboarding.upload_text}>Upload</span>
                                                        <span className={Onboarding.upload_text}>Picture</span>
                                                    </div>
                                                </div>
                                                {(avatarImage !== "" && avatarType === "image") && (
                                                    <div className={Onboarding.upload_overlay}>
                                                        <input type="file" className={Onboarding.upload_image_input}
                                                            accept=".png, .jpg, .jpeg"
                                                            onChange={(event) => {if (event.target.files) {uploadAvatarFile(event.target.files[0])}}}
                                                        />
                                                        <ReplayIcon fontSize="large" sx={{ color: "rgb(var(--dl-color-white))" }}/>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </label>
                                    <div className={Onboarding.avatar_separator_container}>
                                        <div className={Onboarding.avatar_separator_line} />
                                        <span className={Onboarding.avatar_separator_text}>or</span>
                                        <div className={Onboarding.avatar_separator_line} />
                                    </div>
                                    <div className={Onboarding.avatar_color} style={{ backgroundColor: `rgb(${avatarColor}`, cursor: avatarType !== "icon" ? "pointer" : "default" }} onClick={() => {if (avatarImage !== "") {setAvatarType("icon"); setAvatarUploadError("")}}}>
                                        {selectedIcon === "Letter" ? (
                                            <span className={Onboarding.avatar_color_text}>{getAvatarLetter()}</span>
                                        ) : (
                                            bigIcons.find(Icon => Icon.key === selectedIcon)
                                        )}
                                        {(avatarType === "image") && (
                                            <div className={Onboarding.avatar_color_overlay} />
                                        )}
                                    </div>
                                </div>
                                <div className={Onboarding.avatar_icon_wrapper}>
                                    <div className={Onboarding.avatar_color_options}>
                                        {colorOptions.map((color, index) => (
                                            <AvatarColorOption key={index} color={color} avatarColor={avatarColor} setAvatarType={setAvatarType} setAvatarColor={setAvatarColor} />
                                        ))}
                                    </div>
                                    <div className={Onboarding.avatar_icon_separator} />
                                    <div className={Onboarding.icon_selector_wrapper}>
                                        <div className={Onboarding.icon_search_input_wrapper}>
                                            <input
                                                type="text"
                                                className={Onboarding.icon_search_input}
                                                placeholder='Search Icons'
                                                onChange={(event) => setIconSearch(event.target.value)}
                                                value={iconSearch}
                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={Onboarding.icon_search_input_icon} viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                            </svg>
                                            {iconSearch !== "" && (
                                                <div className={Onboarding.icon_search_delete} onClick={() => setIconSearch("")}>
                                                    <svg viewBox="0 0 1024 1024" className={Onboarding.icon_search_delete_icon}>
                                                        <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                        <div className={Onboarding.icons_list_wrapper}>
                                            <div className={Onboarding.avatar_icon_list_item} style={{ color: selectedIcon === "Letter" ? "rgb(var(--dl-color-white-always))" : "rgba(var(--dl-color-black), 0.4)", transform: selectedIcon === "Letter" ? "scale(1.2)" : "", backgroundColor: selectedIcon === "Letter" ? `rgb(${avatarColor})` : "" }} onClick={() => setSelectedIcon("Letter")}>
                                                {screenerName !== "" ? screenerName[0] : user.userData.displayName[0]}
                                            </div>
                                            {smallIcons.map((Icon, index) => {
                                                if (Icon.key?.toString().toLowerCase().includes(iconSearch.toLowerCase().replace(" ", ""))) {
                                                    return (
                                                        <div key={index} className={Onboarding.avatar_icon_list_item} style={{ color: selectedIcon === Icon.key ? "rgb(var(--dl-color-white-always))" : "rgba(var(--dl-color-black), 0.4)", transform: selectedIcon === Icon.key ? "scale(1.2)" : "", backgroundColor: selectedIcon === Icon.key ? `rgb(${avatarColor})` : "" }} onClick={() => {if (Icon.key) {setSelectedIcon(Icon.key.toString())} setAvatarType("icon")}}>
                                                            {Icon}
                                                        </div>
                                                    )
                                                }
                                                return <></>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {avatarUploadError !== "" && (
                                <p className={Onboarding.error_text}>{avatarUploadError}</p>
                            )}
                            <button type="button" ref={avatarRef} className={Onboarding.next_button} onClick={() => {avatarRef.current?.scrollIntoView({ behavior: 'smooth' });}}>I like that</button>
                        </div>
                    </div>
                    <div className={Onboarding.content_section} style={{ opacity: section === "end" ? "1" : "0.4", pointerEvents: section === "end" ? "auto" : "none" }}>
                        <img src="/logos/traddle_logo_black_on_white_comp.png" alt="Traddle Logo" className={Onboarding.content_section_traddle_logo} />
                        <div className={Onboarding.content_section_wrapper}>
                            <div className={Onboarding.flex_column}>
                                <span className={Onboarding.text_big}>We are finished! 🥳</span>
                                <span className={Onboarding.text_big}>We wish you a lot of fun and good trades!</span>
                            </div>
                            {buttonFinishError !== "" && (
                                <p className={Onboarding.error_text}>{buttonFinishError}</p>
                            )}
                            <button type="button" className={`${Onboarding.next_button} ${Onboarding.button_start}`} onClick={() => {buttonFinishHandler()}} ref={endRef}>
                                {(user.loading || screener.loading) ? (
                                    <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                                ) : (
                                    <>Start with Traddle!</>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={Onboarding.full_width_bar_fixed_bottom}>
                    <div className={Onboarding.scroll_top} onClick={() => {scrollPageRef.current?.scrollIntoView({ behavior: 'smooth' });}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                        </svg>
                    </div>
                </div>
            </div>
        )
    }

    return null
}

export default OnboardingPage