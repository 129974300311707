import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { logout } from "../../../../../store/features/user";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import ProfileAvatar from "../../../../../utils/components/profile-avatar/profile-avatar";
import usePopperPopup from "../../../../../utils/hooks/use-popper-popup";
import AppTopNavProfileMenuStyles from './AppTopNavProfileMenuStyles.module.css';
import ThemeSwitch from "./theme-switch";

const AppTopNavProfileMenu: React.FC = () => {
    const [helpCenterIsHovered, setHelpCenterIsHovered] = useState(false);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    
    const { setButton, setPopup, buttonRef, styles, attributes, isOpen: profileMenuOpen, setIsOpen: setProfileMenuOpen } = usePopperPopup({ offsetY: 8 });
    
    const handleLogout = async () => {
        try {
            await dispatch(logout()).unwrap();
            navigate("/login")
        } catch (error) {
            navigate("/login")
        }
    }

    return (
        <div className={AppTopNavProfileMenuStyles.profile_menu_wrapper} ref={buttonRef}>
            <div
                onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                className={AppTopNavProfileMenuStyles.profile_menu_container}
                ref={setButton}
            >
                <ProfileAvatar size={28} avatar={user.userData.avatar} displayName={user.userData.displayName} loading={user.loading} />
            </div>
            {profileMenuOpen ? (
                <div className={AppTopNavProfileMenuStyles.profile_menu} ref={setPopup} style={styles.popper} {...attributes.popper}>
                    <div className={AppTopNavProfileMenuStyles.profile_menu_top}>
                        <div className={AppTopNavProfileMenuStyles.profile_menu_top_header}>
                            <div className={AppTopNavProfileMenuStyles.profile_menu_top_header_left}>
                                <span className={AppTopNavProfileMenuStyles.profile_menu_top_header_states}>States</span>
                                <svg viewBox="0 0 1024 1024" className={AppTopNavProfileMenuStyles.profile_menu_top_header_states_icon}>
                                    <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                                </svg>
                            </div>
                            <div className={AppTopNavProfileMenuStyles.profile_menu_top_header_upgrade}>
                                <span className={AppTopNavProfileMenuStyles.profile_menu_top_header_upgrade_text}>Get more</span>
                                <svg viewBox="0 0 1024 1024" className={AppTopNavProfileMenuStyles.profile_menu_top_header_upgrade_icon}>
                                    <path d="M567.168 311.168c-16.683 16.683-16.683 43.648 0 60.331l97.835 97.835h-323.669c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h323.669l-97.835 97.835c-16.683 16.683-16.683 43.648 0 60.331 8.32 8.32 19.243 12.501 30.165 12.501s21.845-4.181 30.165-12.501l200.832-200.832-200.832-200.832c-16.683-16.683-43.648-16.683-60.331 0z" />
                                </svg>
                            </div>
                        </div>
                        <span className={AppTopNavProfileMenuStyles.profile_menu_top_states_count}>18 of 25 used</span>
                        <div className={AppTopNavProfileMenuStyles.profile_menu_top_states_bar}>
                            <div className={AppTopNavProfileMenuStyles.profile_menu_top_states_bar_fill} />
                        </div>
                    </div>
                    <div className={AppTopNavProfileMenuStyles.profile_menu_bottom}>
                        <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_name}>
                            <ProfileAvatar size={28} avatar={user.userData.avatar} displayName={user.userData.displayName} loading={user.loading} />
                            <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_name_text}>{user.userData.displayName}</span>
                        </div>
                        <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_options_container}>
                            <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_left}>
                                <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_item}>
                                    <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_text}>Profile Settings</span>
                                </div>
                                <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_item}>
                                    <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_text}>Notifications</span>
                                </div>
                                <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_item}>
                                    <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_text}>Layout</span>
                                </div>
                                <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_upgrade_button}>
                                    <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_upgrade_text}>Upgrade</span>
                                </div>
                                <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_item}>
                                    <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_text}>Screeners</span>
                                </div>
                                <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_item}>
                                    <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_text}>Trash</span>
                                </div>
                                <ThemeSwitch />
                            </div>
                            <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_separator_vertical} />
                            <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_right}>
                                <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_items_wrapper}>
                                    <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_item}>
                                        <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_text}>Friends</span>
                                        <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_invite_button}>
                                            <svg viewBox="0 0 1024 1024" className={AppTopNavProfileMenuStyles.profile_menu_bottom_invite_button_icon}>
                                                <path d="M725.333 896v-85.333c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464h-298.667c-58.88 0-112.299 23.936-150.869 62.464s-62.464 91.989-62.464 150.869v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-85.333c0-35.371 14.293-67.285 37.504-90.496s55.125-37.504 90.496-37.504h298.667c35.371 0 67.285 14.293 90.496 37.504s37.504 55.125 37.504 90.496v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM576 298.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM490.667 298.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496zM981.333 426.667h-85.333v-85.333c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v85.333h-85.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h85.333v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-85.333h85.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667z" />
                                            </svg>
                                            <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_invite_button_text}>Invite</span>
                                        </div>
                                    </div>
                                    <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_item}>
                                        <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_text}>Rewards</span>
                                        <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_rewards_count}>
                                            <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_rewards_count_text}>3</span>
                                        </div>
                                    </div>
                                    <div className={`${AppTopNavProfileMenuStyles.profile_menu_bottom_item} ${AppTopNavProfileMenuStyles.profile_menu_bottom_item_delete}`} onClick={() => handleLogout()}>
                                        <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_text}>Logout</span>
                                    </div>
                                </div>
                                <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_item} style={{ width: "100%", cursor: "default" }}>
                                    <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_separator_horizontal} />
                                </div>
                                <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_items_wrapper}>
                                    <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_item}>
                                        <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_text}>Template Center</span>
                                    </div>
                                    <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_item} onMouseEnter={() => setHelpCenterIsHovered(true)} onMouseLeave={() => setHelpCenterIsHovered(false)}>
                                        <div className={AppTopNavProfileMenuStyles.profile_menu_bottom_help_center}>
                                            <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_text} style={{ color: helpCenterIsHovered ? "rgb(var(--dl-color-primary-100))" : "" }}>Help Center</span>
                                            <svg viewBox="0 0 1024 1024" className={AppTopNavProfileMenuStyles.profile_menu_bottom_icon_help} style={{ fill: helpCenterIsHovered ? "rgb(var(--dl-color-primary-100))" : "" }}>
                                                <path d="M725.333 554.667v256c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-469.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-469.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v469.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h469.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM456.832 627.499l396.501-396.501v153.003c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h153.003l-396.501 396.501c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default AppTopNavProfileMenu