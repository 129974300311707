import { Column } from "react-table";

import { Sources } from "./Screener";

export interface Dashboards {
    [id: string]: Dashboard
}

export interface Dashboard {
    id: string;
    name: string;
    description: string;
    width: number;
    adminUid: string;
    access: string[];
    widgets: Widget[];
    screenerTableSettings: DashboardScreenerTableSettings
}

export interface Widget {
    id: string;
    layout: ReactGridLayout.Layout;
    componentName: WidgetComponentName;
    connection: WidgetConnectionColorName;
    settings: TableSettings | EconomicCalendarSettings | null ;
}

export type WidgetConnectionColorName = "blue" | "red" | "orange" | "pink" | "green" | "none"

export const isOfTypeWidgetConnection = (keyInput: string): keyInput is WidgetConnectionColorName => ["blue", "red", "orange", "pink", "green", "none"].includes(keyInput)

export type WidgetComponentName = "table" | "economic-calendar"

export const isOfTypeComponentName = (keyInput: string): keyInput is WidgetComponentName => ["table", "economic-calendar"].includes(keyInput)

export interface TableSettings {
    widgetName: WidgetComponentName;
    tableType: TableType;
    sources: Sources;
    columns: Column<object>[];
    filters: TableFilters;
    selectedColumnPreset: string;
    playSound: boolean;
    showTickerDetails: boolean;
    showNews: boolean;
}

export type TableType = "filter" | "alert";

export interface TableFilters {
    [id: string]: TableFilterSettings
}

export interface TableFilterSettings {
    min?: number | undefined | null;
    max?: number | undefined | null;
}

export interface EconomicCalendarSettings {
    widgetName: WidgetComponentName;
    selectedTab: string;
    economy: {
        countries: string[];
        priorities: string[];
        categories: string[];
    },
    earnings: {
        countries: string[];
    },
    dividends: {
        countries: string[];
    }
}

export interface DashboardScreenerTableSettings {
    columns: Column<object>[];
    filters: TableFilters;
    selectedColumnPreset: string;
}