export type ThemeColorType = {
    [key: string]: string;
}

export const lightTheme: ThemeColorType = {
    "--dl-fontweight-300": "300",
    "--dl-fontweight-400": "400",
    "--dl-fontweight-500": "500",
    "--dl-fontweight-600": "600",
    "--dl-fontweight-700": "700",
    "--dl-fontweight-800": "800",
    "--dl-fontweight-900": "900",
    "--dl-color-white": "255, 255, 255",
    "--dl-color-white-always": "255, 255, 255",
    "--dl-color-black": "0, 0, 0",
    "--dl-color-black-always": "0, 0, 0",
    "--dl-color-shadow": "200, 200, 200",
    "--dl-color-colors-red-error": "214, 15, 15",
    "--dl-color-colors-red": "173, 5, 25",
    "--dl-color-colors-red-bright": "227, 39, 53",
    "--dl-color-colors-red-pink": "255, 60, 125",
    "--dl-color-colors-red-pink-hover": "232, 46, 108",
    "--dl-color-colors-blue": "53, 114, 250",
    "--dl-color-colors-pink": "150, 53, 250",
    "--dl-color-colors-violet": "116, 94, 238",
    "--dl-color-colors-orange": "235, 132, 19",
    "--dl-color-colors-green": "34, 177, 148",
    "--dl-color-colors-green-hover": "26, 161, 133",
    "--dl-color-colors-green-dark": "49, 125, 124",
    "--dl-color-text-black": "25, 22, 34",
    "--dl-color-text-gray1": "86, 89, 97",
    "--dl-color-text-gray2": "97, 99, 101",
    "--dl-color-text-gray3": "103, 111, 123",
    "--dl-color-text-gray4": "115, 118, 127",
    "--dl-color-text-gray5": "89, 89, 89",
    "--dl-color-widget-connection-none": "224, 224, 224",
    "--dl-color-backgrounds-logo": "66, 69, 77",
    "--dl-color-backgrounds-white": "255, 255, 255",
    "--dl-color-backgrounds-white-on-white": "255, 255, 255",
    "--dl-color-backgrounds-topnav": "255, 255, 255",
    "--dl-color-backgrounds-gray-light": "250, 249, 254",
    "--dl-color-backgrounds-gray-dark": "50, 50, 46",
    "--dl-color-backgrounds-page-back": "222, 229, 231",
    "--dl-color-backgrounds-page": "246, 247, 249",
    "--dl-color-backgrounds-hover-light": "247, 248, 242",
    "--dl-color-backgrounds-hover1": "245, 246, 247",
    "--dl-color-backgrounds-hover2": "242, 243, 244",
    "--dl-color-backgrounds-hover3": "232, 233, 234",
    "--dl-color-backgrounds-hover-header": "247, 248, 249",
    "--dl-color-backgrounds-tooltip": "43, 46, 48",
    "--dl-color-backgrounds-skeleton": "224, 224, 224",
    "--dl-color-backgrounds-skeleton-dark": "97, 97, 97",
    "--dl-color-backgrounds-dashboard": "160, 160, 160",
    "--dl-color-borders-separator": "217, 224, 226",
    "--dl-color-borders-separator-popup": "140, 140, 140",
    "--dl-color-borders-separator-top-nav": "217, 224, 226",
    "--dl-color-borders-toggle": "184, 185, 186",
}

export const darkTheme: ThemeColorType = {
    "--dl-fontweight-300": "200",
    "--dl-fontweight-400": "300",
    "--dl-fontweight-500": "400",
    "--dl-fontweight-600": "500",
    "--dl-fontweight-700": "600",
    "--dl-fontweight-800": "700",
    "--dl-fontweight-900": "800",
    "--dl-color-white": "0, 0, 0",
    "--dl-color-white-always": "255, 255, 255",
    "--dl-color-black": "255, 255, 255",
    "--dl-color-black-always": "0, 0, 0",
    "--dl-color-shadow": "0, 0, 0",
    "--dl-color-colors-red-error": "214, 15, 15",
    "--dl-color-colors-red": "173, 5, 25",
    "--dl-color-colors-red-bright": "255, 54, 69",
    "--dl-color-colors-red-pink": "255, 60, 125",
    "--dl-color-colors-red-pink-hover": "232, 46, 108",
    "--dl-color-colors-blue": "53, 114, 250",
    "--dl-color-colors-pink": "150, 53, 250",
    "--dl-color-colors-violet": "116, 94, 238",
    "--dl-color-colors-orange": "235, 132, 19",
    "--dl-color-colors-green": "34, 177, 148",
    "--dl-color-colors-green-hover": "26, 161, 133",
    "--dl-color-colors-green-dark": "49, 125, 124",
    "--dl-color-text-black": "209, 211, 220",
    "--dl-color-text-gray1": "158, 161, 169",
    "--dl-color-text-gray2": "154, 156, 158",
    "--dl-color-text-gray3": "134, 137, 148",
    "--dl-color-text-gray4": "129, 132, 141",
    "--dl-color-text-gray5": "87, 92, 107",
    "--dl-color-widget-connection-none": "54, 58, 70",
    "--dl-color-backgrounds-logo": "255, 255, 255",
    "--dl-color-backgrounds-white": "14, 14, 14",
    "--dl-color-backgrounds-white-on-white": "42, 46, 58",
    "--dl-color-backgrounds-topnav": "21, 23, 31",
    "--dl-color-backgrounds-gray-light": "21, 23, 31",
    "--dl-color-backgrounds-gray-dark": "206, 206, 210",
    "--dl-color-backgrounds-page-back": "46, 48, 55",
    "--dl-color-backgrounds-page": "14, 14, 14",
    "--dl-color-backgrounds-hover-light": "27, 29, 39",
    "--dl-color-backgrounds-hover1": "30, 34, 47",
    "--dl-color-backgrounds-hover2": "42, 46, 58",
    "--dl-color-backgrounds-hover3": "54, 58, 70",
    "--dl-color-backgrounds-hover-header": "44, 48, 60",
    "--dl-color-backgrounds-tooltip": "55, 57, 67",
    "--dl-color-backgrounds-skeleton": "54, 58, 70",
    "--dl-color-backgrounds-skeleton-dark": "67, 70, 82",
    "--dl-color-backgrounds-dashboard": "14, 14, 14",
    "--dl-color-borders-separator": "43, 47, 49",
    "--dl-color-borders-separator-popup": "72, 75, 87",
    "--dl-color-borders-separator-top-nav": "23, 23, 27",
    "--dl-color-borders-toggle": "255, 255, 255",
}