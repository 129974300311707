import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import usePopperPopup from '../../../../../hooks/use-popper-popup';
import BootstrapTooltip from '../../../../tooltip/bootstrap-tooltip';
import ConditionRow from "../../../ConditionRow.module.css"
import CriteriaInputNumber from '../../criteria-input-number';
import CriteriaInputTimeframe from '../../criteria-input-timeframe';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
    titleWidth: string;
    fieldWidth: string
}

const defaultProps: Props = {
    parameters: {
        length: "14",
        timeframe: "1 minute",
        offset: "0",
        multiplicator: "1"
    },
    setParameters: () => {},
    titleWidth: "80px",
    fieldWidth: "70px"
}

const CriteriaParameterATR: React.FC<Props> = (props) => {
    const timeframe = useMemo(() => props.parameters.timeframe ? props.parameters.timeframe : defaultProps.parameters.timeframe!, [props.parameters.timeframe])
    const length = useMemo(() => props.parameters.length ? props.parameters.length : defaultProps.parameters.length!, [props.parameters.length])
    const offset = useMemo(() => props.parameters.offset ? props.parameters.offset : defaultProps.parameters.offset!, [props.parameters.offset])
    const multiplicator = useMemo(() => props.parameters.multiplicator ? props.parameters.multiplicator : defaultProps.parameters.multiplicator!, [props.parameters.multiplicator])
    
    const { setButton, setPopup, buttonRef: wrapperRef, styles, attributes, isOpen: dropdownOpen, setIsOpen: setDropdownOpen } = usePopperPopup({ offsetY: 4 })
    
    const setTimeframe = (newTimeframe: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, timeframe: newTimeframe }, defaultProps.parameters))
    }

    const setLength = (newLength: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, length: newLength }, defaultProps.parameters))
    }

    const setOffset = (newOffset: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, offset: newOffset }, defaultProps.parameters))
    }

    const setMultiplicator = (newMultiplicator: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, multiplicator: newMultiplicator }, defaultProps.parameters))
    }

    return (
        <>
            <div className={ConditionRow.criteria_dropdown_wrapper} ref={wrapperRef}>
                <div
                    className={ConditionRow.criteria_dropdown_button}
                    style={{ borderColor: dropdownOpen ? "rgb(var(--dl-color-primary-100))" : "", padding: "0px 8px" }}
                    onClick={() => {setDropdownOpen(!dropdownOpen)}} ref={setButton}
                >
                    <span className={ConditionRow.criteria_dropdown_button_text}>{`${timeframe} | ${length}, ${offset}`}</span>
                </div>
                {dropdownOpen ? (
                    <div className={`${ConditionRow.criteria_dropdown_menu} ${ConditionRow.dropdown_parameter}`} ref={setPopup} style={styles.popper} {...attributes.popper}>
                        <div className={ConditionRow.criteria_dropdown_menu_scroll_wrapper} style={{ gap: "8px", overflow: "visible" }}>
                            <div className={ConditionRow.criteria_dropdown_parameter_title_row}>
                                <p className={ConditionRow.criteria_dropdown_parameter_title}>Average True Range (ATR)</p>
                                <BootstrapTooltip title="Indicator Help">
                                    <svg viewBox="0 0 1024 1024" className={ConditionRow.criteria_dropdown_indicator_help_icon}>
                                        <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                                    </svg>
                                </BootstrapTooltip>
                            </div>
                            <div className={ConditionRow.criteria_popup_input}>
                                <p className={ConditionRow.criteria_popup_input_title} style={{ width: props.titleWidth }}>Timeframe</p>
                                <CriteriaInputTimeframe timeframe={timeframe} setTimeframe={setTimeframe} />
                            </div>
                            <div className={ConditionRow.criteria_popup_input}>
                                <p className={ConditionRow.criteria_popup_input_title} style={{ width: props.titleWidth }}>Length</p>
                                <CriteriaInputNumber number={length} setNumber={setLength} min={1} max={200} step="1" width={props.fieldWidth} />
                            </div>
                            <div className={ConditionRow.criteria_popup_input}>
                                <p className={ConditionRow.criteria_popup_input_title} style={{ width: props.titleWidth }}>Offset</p>
                                <CriteriaInputNumber number={offset} setNumber={setOffset} min={-99} max={99} step="1" width={props.fieldWidth} />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            <span className={ConditionRow.criteria_text}>x</span>
            <CriteriaInputNumber number={multiplicator} setNumber={setMultiplicator} min={-99.99} max={99.99} step="0.01" width={props.fieldWidth} />
        </>
    )
}

export default CriteriaParameterATR