import { setPopupElement } from '../../../../../../../store/features/management';
import { changeScreenerData, saveScreener } from '../../../../../../../store/features/screener';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { State } from '../../../../../../../types/Screener';
import useClickOutsidePopup from '../../../../../../../utils/hooks/use-click-outside-popup';
import DashboardScreenerStyles from "../../../DashboardScreener.module.css";

type Props = {
    name: string;
    index: number;
    setSelectedState: React.Dispatch<React.SetStateAction<State | null>>;
    setNewState: React.Dispatch<React.SetStateAction<number | null>>;
}

const BuilderSettingsDeletePopup: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    
    const screener = useAppSelector((state) => state.screener)

    const refClickOutside = useClickOutsidePopup();

    const deleteState = () => {
        const newStates = [...screener.data.states]
        newStates.splice(props.index, 1);
        dispatch(changeScreenerData({
            ...screener.data,
            states: newStates
        }));
        dispatch(saveScreener());
        if (screener.data.states.length > 0) {
            props.setSelectedState(props.index - 1 >= 0 ? screener.data.states[props.index - 1] : null)
        } else {
            props.setSelectedState(null)
        }
        props.setNewState(null);
        dispatch(setPopupElement(null));
    }

    return (
        <div className="app_popup_wrapper_middle" ref={refClickOutside}>
            <div className={DashboardScreenerStyles.delete_container}>
                <span className={DashboardScreenerStyles.delete_header}>Delete Screener State</span>
                <span className={DashboardScreenerStyles.delete_state_name}>
                    {props.name}
                </span>
                <span className={DashboardScreenerStyles.delete_state_warning}>
                    <span className={DashboardScreenerStyles.text_bold_500}>Warning:</span>
                    <span> All settings will be deleted.</span>
                </span>
                <div className={DashboardScreenerStyles.delete_state_button_row}>
                    <div className={DashboardScreenerStyles.delete_state_button} onClick={() => dispatch(setPopupElement(null))}>
                        <span className={DashboardScreenerStyles.delete_state_button_text}>Cancel</span>
                    </div>
                    <div className={`${DashboardScreenerStyles.delete_state_button} ${DashboardScreenerStyles.delete_state_button_delete}`} onClick={() => deleteState()}>
                        <span className={DashboardScreenerStyles.delete_state_button_text} style={{ color: "rgb(var(--dl-color-white-always))" }}>Delete</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuilderSettingsDeletePopup