import Onboarding from './Onboarding.module.css'

type Props = {
    color: string[];
    avatarColor: string;
    setAvatarType: (avatarType: string) => void;
    setAvatarColor: (avatarColor: string) => void;
}

const AvatarColorOption: React.FC<Props> = (props) => (
    <div className={Onboarding.avatar_color_option} style={{ backgroundColor: `rgb(${props.color[0]})`, transform: props.avatarColor === props.color[0] ? "scale(1.6)" : "" }} onClick={() => {props.setAvatarType("icon"); props.setAvatarColor(props.color[0])}}>
        {props.avatarColor === props.color[0] && (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={Onboarding.avatar_color_option_icon_check} viewBox="0 0 16 16">
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
            </svg>
        )}
    </div>
)

export default AvatarColorOption