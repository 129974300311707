import { useMemo } from "react";
import { Cell } from "react-table";

import formatLargeNumber from "../../../../../functions/format-large-number";
import TableTableStyles from "../TableTableStyles.module.css"

type Props = {
    cell: Cell<object>;
    width: number;
}

const CellLargeNumber: React.FC<Props> = (props) => {   
    const number = useMemo(() => formatLargeNumber(parseFloat(props.cell.value as string)), [])
    
    return (
        <div className={TableTableStyles.cell_wrapper} style={{ padding: props.width > 140 ? "6px 16px" : "" }}>
            <span className={TableTableStyles.cell_text}>{number === "NaN" ? "-" : number}</span>
        </div>
    )
}

export default CellLargeNumber