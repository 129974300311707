import React from "react"
import { HeaderGroup } from "react-table";

import TableTableStyles from "./TableTableStyles.module.css"

type Props = {
    column: HeaderGroup<object>;
}

const TableSortIcon: React.FC<Props> = (props) => {
    if (props.column.isSorted) {
        return (
            <span className={TableTableStyles.header_sort_icons}>
                <svg viewBox="0 0 585.1428571428571 1024" className={TableTableStyles.header_sort_icon}>
                    <path style={{ opacity: props.column.isSortedDesc ? "" : "0.3" }} d="M585.143 621.714c0 9.714-4 18.857-10.857 25.714l-256 256c-6.857 6.857-16 10.857-25.714 10.857s-18.857-4-25.714-10.857l-256-256c-6.857-6.857-10.857-16-10.857-25.714 0-20 16.571-36.571 36.571-36.571h512c20 0 36.571 16.571 36.571 36.571z" />
                    <path style={{ opacity: props.column.isSortedDesc ? "0.3" : "" }} d="M585.143 402.286c0 20-16.571 36.571-36.571 36.571h-512c-20 0-36.571-16.571-36.571-36.571 0-9.714 4-18.857 10.857-25.714l256-256c6.857-6.857 16-10.857 25.714-10.857s18.857 4 25.714 10.857l256 256c6.857 6.857 10.857 16 10.857 25.714z" />
                </svg>
            </span>
        )
    }
    return null
}

export default TableSortIcon