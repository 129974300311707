import { TailSpin } from 'react-loader-spinner';

type Props = {
    positionAbsolute?: boolean;
    wrapperWidth: string;
    wrapperHeight: string;
    backgroundColor: string;
    marginTop: string;
    size: string;
    color: string;
}

const LoadingSpinner: React.FC<Props> = (props) => (
    <div style={{ position: props.positionAbsolute ? "absolute" : "relative", width: props.wrapperWidth, height: props.wrapperHeight, backgroundColor: props.backgroundColor, display: "flex", justifyContent: "center", alignItems: "flex-start", marginTop: props.marginTop }}>
        <TailSpin
            height={props.size}
            width={props.size}
            color={props.color}
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
        />
    </div>
)

export default LoadingSpinner