import React, { useState } from "react";

import { useAppSelector } from "../../../../../../store/hooks";
import { Screener } from "../../../../../../types/Screener";
import DashboardScreenerSourceTabScreenersScreener from "./dashboard-screener-source-tab-screeners-screener";
import DashboardScreenerSourceStyles from "./DashboardScreenerSource.module.css"

type Props = {
    screener: Screener;
    search: string
}

const DashboardScreenerSourceTabScreeners: React.FC<Props> = (props) => {
    const allScreeners = useAppSelector((state) => state.allScreeners.screeners)

    const [isExpanded, setIsExpanded] = useState("");
    
    return (
        <div className={DashboardScreenerSourceStyles.stocklist_wrapper}>
            <div className={DashboardScreenerSourceStyles.dropdown_wrapper}>
                <div className={DashboardScreenerSourceStyles.dropdown_button}>
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerSourceStyles.dropdown_button_arrow_icon}>
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z" />
                    </svg>
                    <span className={DashboardScreenerSourceStyles.dropdown_button_text}>All screeners</span>
                </div>
            </div>
            <div className={DashboardScreenerSourceStyles.stocklist_list}>
                <div className={DashboardScreenerSourceStyles.stocklist_list_scroll}>
                    {Object.keys(allScreeners).sort((a, b) => {
                        const fa = allScreeners[a].name.toLowerCase();
                        const fb = allScreeners[b].name.toLowerCase();
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }).map((screenerId, index) => {
                        if (screenerId === props.screener.id) {
                            return null
                        }
                        const screener = allScreeners[screenerId]
                        return <DashboardScreenerSourceTabScreenersScreener screener={props.screener} screenerRowId={screenerId} screenerRow={screener} search={props.search} isExpanded={isExpanded === screenerId} setIsExpanded={setIsExpanded} key={index} />
                    })}
                </div>
            </div>
        </div>
    )
}
    
export default DashboardScreenerSourceTabScreeners