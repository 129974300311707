import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../types/Screener';
import CriteriaDropdown from '../criteria-dropdown';
import CriteriaInputNumber from '../criteria-input-number';
import checkUnusedParams from '../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        number: "5",
        unit: "minutes"
    },
    setParameters: () => {},
}

const CriteriaParameterTimeRange: React.FC<Props> = (props) => {
    const number = useMemo(() => props.parameters.number ? props.parameters.number : defaultProps.parameters.number!, [props.parameters.number])
    const unit = useMemo(() => props.parameters.unit ? props.parameters.unit : defaultProps.parameters.unit!, [props.parameters.unit])

    const setNumber = (newNumber: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, number: newNumber }, defaultProps.parameters))
    }

    const setUnit = (newUnit: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, unit: newUnit }, defaultProps.parameters))
    }

    return (
        <>
            <CriteriaInputNumber number={number} setNumber={setNumber} min={1} max={999} step="1" width="70px" />
            <CriteriaDropdown dropdownOptions={["minutes", "hours", "days", "weeks", "months", "years"]} transformType="" value={unit} setValue={setUnit} />
        </>
    )
}

export default CriteriaParameterTimeRange