import React, { useEffect, useState } from "react";

import { changeScreenerData, saveScreener } from "../../../../../../store/features/screener";
import { changeUserData, saveUser } from "../../../../../../store/features/user";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import BootstrapTooltip from "../../../../../../utils/components/tooltip/bootstrap-tooltip";
import layoutSizes from "../../../../../../utils/constants/layout-sizes";
import DashboardScreenerDetailsStyles from "../DashboardScreenerDetails.module.css"

const DashboardScreenerTopbarRight: React.FC = () => {
    const dispatch = useAppDispatch();

    const screener = useAppSelector((state) => state.screener)
    const dashboardScreenerHeight = useAppSelector((state) => state.user.userData.dashboardScreenerHeight)
    const dashboardScreenerHeightSave = useAppSelector((state) => state.user.userData.dashboardScreenerHeightSave)

    const dashboardScreenerElement = document.getElementById("dashboard-screener")

    const [dashboardScreenerElementFullscreen, setDashboardScreenerElementFullscreen] = useState(() => (dashboardScreenerHeight > (window.innerHeight / 1.5)))

    useEffect(() => {
        setDashboardScreenerElementFullscreen(dashboardScreenerHeight >= window.innerHeight - layoutSizes.topNavHeight)
        const newHeightSave = (dashboardScreenerHeight > layoutSizes.dashboardScreenerHeightMin && dashboardScreenerHeight < (window.innerHeight - layoutSizes.topNavHeight)) ? dashboardScreenerHeight : dashboardScreenerHeightSave
        if (newHeightSave !== dashboardScreenerHeightSave) {
            dispatch(changeUserData({
                dashboardScreenerHeightSave: newHeightSave
            }));
        }
    }, [dashboardScreenerHeight])

    const startStopScreener = () => {
        dispatch(changeScreenerData({ ...screener.data, active: !screener.data.active }));
        dispatch(saveScreener());
    }
    
    const expand = () => {
        if (dashboardScreenerElement) {
            let height = dashboardScreenerHeightSave
            if (dashboardScreenerHeight >= dashboardScreenerHeightSave) {
                height = layoutSizes.dashboardScreenerHeightMin
            }
            dashboardScreenerElement.style.height = `${height}px`
            dispatch(changeUserData({
                dashboardScreenerHeight: height
            }));
            dispatch(saveUser());
        }
    }

    const fullscreen = () => {
        if (dashboardScreenerElement) {
            let height = window.innerHeight - layoutSizes.topNavHeight
            if (dashboardScreenerElementFullscreen) {
                height = dashboardScreenerHeightSave
            }
            dashboardScreenerElement.style.height = `${height}px`
            dispatch(changeUserData({
                dashboardScreenerHeight: height
            }));
            dispatch(saveUser());
        }
    }

    return (
        <div className={DashboardScreenerDetailsStyles.top_bar_right}>
            <div className={DashboardScreenerDetailsStyles.top_bar_right_icon_wrapper}>
                <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.top_bar_right_icon} style={{ marginRight: "2px", width: "20px", height: "20px" }}>
                    <path d="M938.667 381.568v260.864l-182.613-130.432zM128 170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v426.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h469.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-130.432l231.211 165.163c19.157 13.696 45.824 9.259 59.52-9.899 5.376-7.595 7.979-16.341 7.936-24.832v-426.667c0-23.552-19.115-42.667-42.667-42.667-9.301 0-17.92 2.987-24.789 7.936l-231.211 165.163v-130.432c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504zM128 256h469.333c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v426.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-469.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-426.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501z" />
                </svg>
                <span className={DashboardScreenerDetailsStyles.top_bar_right_icon_text}>Tutorial</span>
            </div>
            
            <BootstrapTooltip title={dashboardScreenerHeight >= dashboardScreenerHeightSave ? "Hide screener" : "Show screener"}>
                <div className={DashboardScreenerDetailsStyles.top_bar_right_icon_wrapper} style={{ padding: "0px 6px" }} onClick={() => expand()}>
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.top_bar_right_icon} style={{ transform: dashboardScreenerHeight >= dashboardScreenerHeightSave ? "rotate(180deg)" : "" }}>
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z" />
                    </svg>
                </div>
            </BootstrapTooltip>
            <BootstrapTooltip title={dashboardScreenerElementFullscreen ? "Minimize screener" : "Maximize screener"}>
                <div className={DashboardScreenerDetailsStyles.top_bar_right_icon_wrapper} style={{ padding: "0px 8px" }} onClick={() => fullscreen()}>
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.top_bar_right_icon} style={{ width: "20px", height: "20px" }}>
                        {dashboardScreenerElementFullscreen ? (
                            <path d="M298.667 128v128c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h128c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-128c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM896 298.667h-128c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-128c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v128c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h128c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM725.333 896v-128c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h128c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-128c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v128c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM128 725.333h128c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v128c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-128c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z" />
                        ) : (
                            <path d="M341.333 85.333h-128c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v128c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-128c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h128c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM938.667 341.333v-128c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h128c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v128c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM682.667 938.667h128c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-128c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v128c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM85.333 682.667v128c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h128c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-128c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-128c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z" />
                        )}
                    </svg>
                </div>
            </BootstrapTooltip>
            <div className={screener.data.active ? `${DashboardScreenerDetailsStyles.top_bar_right_stop_button}` : `${DashboardScreenerDetailsStyles.top_bar_right_stop_button} ${DashboardScreenerDetailsStyles.top_bar_right_start_button}`} onClick={() => startStopScreener()}>
                <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.top_bar_right_icon} style={{ color: "rgb(var(--dl-color-white-always))", width: "20px", height: "20px" }}>
                    {screener.data.active ? (
                        <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM469.333 640v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM640 640v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667z" />
                    ) : (
                        <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM450.347 305.835c-6.656-4.48-14.848-7.168-23.68-7.168-23.552 0-42.667 19.115-42.667 42.667v341.333c-0.043 8.021 2.261 16.341 7.168 23.68 13.056 19.627 39.552 24.917 59.179 11.819l256-170.667c4.395-2.901 8.533-6.912 11.819-11.819 13.056-19.627 7.765-46.080-11.819-59.179zM469.333 421.077l136.405 90.923-136.405 90.923z" />
                    )}
                </svg>
                <span className={DashboardScreenerDetailsStyles.top_bar_right_icon_text} style={{ color: "rgb(var(--dl-color-white-always))" }}>{screener.data.active ? "Stop" : "Start"} Screener</span>
            </div>
        </div>
    )
}
    
export default DashboardScreenerTopbarRight