import React, { useState } from "react";

import WidgetStyles from "../../../WidgetStyles.module.css"
import TableDividends from "./table/table-dividends";
import TabDividendsTopRow from "./top-row/tab-dividends-top-row"

type Props = {
    id: string;
}

const TabDividends: React.FC<Props> = (props) => {
    const [tickerSearch, setTickerSearch] = useState("");

    return (
        <div className={WidgetStyles.tab_content}>
            <TabDividendsTopRow id={props.id} tickerSearch={tickerSearch} setTickerSearch={setTickerSearch} />
            <TableDividends tickerSearch={tickerSearch} />
        </div>
    )
}

export default TabDividends