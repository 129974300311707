import dayjs from "dayjs";
import { RecurringReset } from "../../types/Screener";

const sortRecurringResetTimes = (a: RecurringReset, b: RecurringReset, intervalOptions: string[]): number => {
    if (b.interval === "" && b.time === "") {
        return -1;
    }
    if (a.interval === "" && a.time === "") {
        return 1;
    }
    const comp = intervalOptions.indexOf(a.interval) - intervalOptions.indexOf(b.interval);
    if (comp !== 0) {
        return comp;
    }
    const fa = dayjs(a.time, "HH:mm");
    const fb = dayjs(b.time, "HH:mm");
    if (fb.isAfter(fa)) {
        return -1;
    }
    return 1;
}

export default sortRecurringResetTimes