import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../store/hooks"
import { Dashboard } from "../../../types/Dashboard";
import LoadingSpinner from "../../../utils/components/loading-spinner/loading-spinner";
import useIsLoading from "../../../utils/hooks/use-is-loading";
import App from '../App.module.css';
import DashboardStyle from './Dashboard.module.css';

const DashboardOverviewPage: React.FC = () => {
    const allDashboards = useAppSelector((state) => state.allDashboards)

    const navigate = useNavigate();

    const isLoading = useIsLoading({ checkUser: true, checkAllDashboards: true });

    if (isLoading) {
        return <LoadingSpinner positionAbsolute={true} wrapperWidth="100%" wrapperHeight="100%" backgroundColor="rgb(var(--dl-color-backgrounds-white))" marginTop="10%" size="80" color="rgb(var(--dl-color-primary-100))" />
    }

    return (
        <div className={App.content_container} style={{ padding: "16px", flexDirection: "row", gap: "16px" }}>
            {Object.values(allDashboards.dashboards).map((dashboard: Dashboard) => (
                <div className={DashboardStyle.overview_item} key={dashboard.id} onClick={() => navigate(`${dashboard.id}`)}>
                    <p>{dashboard.id}</p>
                    <p>{dashboard.name}</p>
                </div>
            ))}
        </div>
    )
}

export default DashboardOverviewPage