import App from '../App.module.css'
import AppOverview from './AppOverview.module.css'

const AppOverviewPage: React.FC = () => (
    <>
        <div className={App.content_container}>
            <p className={AppOverview.dummy}>Home</p>
        </div>
    </>
)

export default AppOverviewPage