import { useNavigate } from "react-router-dom";

import { createNewDashboard } from "../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import AppTopNavCreateButtonStyles from "./AppTopNavCreateButtonStyles.module.css"

type Props = {
    setPopupOpen: (setOpen: boolean) => void;
}

const AppTopNavCreateNewDashboard: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const allDashboards = useAppSelector((state) => state.allDashboards)

    const navigate = useNavigate();

    const newDashboardClick = async () => {
        try {
            const res = await dispatch(createNewDashboard({
                name: `Dashboard ${Object.keys(allDashboards.dashboards).length + 1}`,
            })).unwrap();
            props.setPopupOpen(false);
            if (res) {
                navigate(`/app/dashboards/${res.id}`);
            }
        } catch (error) {
            navigate(`/app/dashboards`);
        }
    }

    return (
        <div className={AppTopNavCreateButtonStyles.create_button_popup_item} onClick={async () => newDashboardClick()}>
            <svg viewBox="0 0 1024 1024" className={AppTopNavCreateButtonStyles.create_button_popup_item_icon}>
                <path d="M554 128h342v256h-342v-256zM554 896v-426h342v426h-342zM128 896v-256h342v256h-342zM128 554v-426h342v426h-342z" />
            </svg>
            <span className={AppTopNavCreateButtonStyles.create_button_popup_item_text}>Create New Dashboard</span>
        </div>
    )
}

export default AppTopNavCreateNewDashboard