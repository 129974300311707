import { HeaderGroup } from "react-table";

import FilterRowNumberLargeNumber from "./rows/filter-row-number-large-number";
import FilterRowNumberSmallNumber from "./rows/filter-row-number-small-number";

type Props = {
    column: HeaderGroup<object>;
}

const TableTopRowButtonFilterRowSwitch: React.FC<Props> = (props) => {
    switch(props.column.id.split("-")[0]) {
    case "ticker": return null;
    case "price_current": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "change": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "change_from_open": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "change_from_ldc": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "change_pm": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "change_ah": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "overnight_gap": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "relative_volume_today": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "sma": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "ema": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "rsi": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "vwap": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "atr": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "volatility": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "sector": return null;
    case "industry": return null;
    case "country": return null;
    case "short_float": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "institutional_ownership": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "insider_ownership": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "free_cash_flow_margin": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "return_on_equity": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "return_on_assets": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "revenue_growth": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "gross_margin": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "net_margin": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "operating_margin": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "eps": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "eps_growth": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "dividends_per_share": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "dividend_yield": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "dividend_growth": return <FilterRowNumberSmallNumber column={props.column} min={-1000} max={1000} />;
    case "price_to_sales": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "price_to_earnings": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "price_to_fcf": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "price_to_book": return <FilterRowNumberSmallNumber column={props.column} min={0} max={1000} />;
    case "dummy": return null;
    default: return <FilterRowNumberLargeNumber column={props.column} min={0} max={1000000000000} />;
    }
}

export default TableTopRowButtonFilterRowSwitch