import WidgetTopRowStyles from "../../../../WidgetTopRowStyles.module.css"
import IposButtonDate from "./ipos-button-date"

type Props = {
    id: string;
}

const TabIposTopRow: React.FC<Props> = (props) => (
    <div className={WidgetTopRowStyles.container}>
        <div className={WidgetTopRowStyles.group_container}>
            <IposButtonDate id={props.id} />
        </div>
    </div>
)

export default TabIposTopRow