import React, { useState } from "react";

import WidgetStyles from "../../../WidgetStyles.module.css"
import TableEarnings from "./table/table-earnings";
import TabEarningsTopRow from "./top-row/tab-earnings-top-row"

type Props = {
    id: string;
}

const TabEarnings: React.FC<Props> = (props) => {
    const [tickerSearch, setTickerSearch] = useState("");

    return (
        <div className={WidgetStyles.tab_content}>
            <TabEarningsTopRow id={props.id} tickerSearch={tickerSearch} setTickerSearch={setTickerSearch} />
            <TableEarnings tickerSearch={tickerSearch} />
        </div>
    )
}

export default TabEarnings