import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import CriteriaTimePicker from '../../criteria-time-picker';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        timeOfDay: "09:30:00",
    },
    setParameters: () => {},
}

const CriteriaParameterTimeOfDay: React.FC<Props> = (props) => {
    const timeOfDay = useMemo(() => props.parameters.timeOfDay ? props.parameters.timeOfDay : defaultProps.parameters.timeOfDay!, [props.parameters.timeOfDay])

    const setTimeOfDay = (newTimeOfDay: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, timeOfDay: newTimeOfDay }, defaultProps.parameters))
    }

    return (
        <>
            <CriteriaTimePicker time={timeOfDay} setTime={setTimeOfDay} />
        </>
    )
}

export default CriteriaParameterTimeOfDay