import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import CriteriaDropdown from '../../criteria-dropdown';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        ohlc: "open",
    },
    setParameters: () => {},
}

const CriteriaParameterPM: React.FC<Props> = (props) => {
    const ohlc = useMemo(() => props.parameters.ohlc ? props.parameters.ohlc : defaultProps.parameters.ohlc!, [props.parameters.ohlc])

    const setOhlc = (newOhlc: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, ohlc: newOhlc }, defaultProps.parameters))
    }

    return (
        <CriteriaDropdown dropdownOptions={["open", "high", "low", "close"]} transformType="" value={ohlc} setValue={setOhlc} />
    )
}

export default CriteriaParameterPM