import { useMemo } from "react";

import { changeDashboardData } from "../../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../../types/changeHandlers/change-dashboard-widget";
import { TableSettings, Widget } from "../../../../../../types/Dashboard";
import WidgetTableSource from "./WidgetTableSource.module.css"

type Props = {
    widgetId: string;
    indexCategory: string;
    index: string;
    search: string
}

const TableTopRowButtonSourceTabIndicesItem: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const dashboard = useAppSelector((state) => state.dashboard)

    const widget: Widget = useMemo(() => dashboard.data.widgets.find((w) => w.id === props.widgetId)!, [dashboard.data.widgets, props.widgetId])

    const settings = useMemo(() => widget.settings as TableSettings, [widget])

    const sources = useMemo(() => ({ ...settings.sources }), [settings.sources])
    
    const indices = useMemo(() => ([...sources.indices]), [sources])

    const index = useMemo(() => (indices.findIndex((w) => w === props.index)), [sources, indices, props.index])
        
    const addIndex = () => {
        if (index === -1) {
            indices.push(props.index);
            sources.indices = indices;
        } else {
            indices.splice(index, 1)
            sources.indices = indices
        }
        const newSettings = {
            ...settings,
            sources
        }
        const newWidgets = changeDashboardWidgetData(props.widgetId, dashboard.data.widgets, { settings: newSettings })
        dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
    }

    if (props.index.toLowerCase().replace(" ", "").includes(props.search.toLowerCase().replace(" ", ""))) {
        return (
            <div className={WidgetTableSource.stocklist_row} style={{ backgroundColor: index > -1 ? "rgba(var(--dl-color-colors-green-dark), 0.18)" : "" }} onClick={() => addIndex()}>
                <div className={WidgetTableSource.stocklist_row_left}>
                    <div className={WidgetTableSource.stocklist_row_logo_wrapper}>
                        <img alt="Input List" src="/logos/traddle_logo_black_on_white_comp.png" className={WidgetTableSource.stocklist_row_logo} />
                    </div>
                    <span className={WidgetTableSource.stocklist_row_text}>{props.index}</span>
                </div>
                <div className={WidgetTableSource.stocklist_row_middle}>
                    <span className={WidgetTableSource.stocklist_row_text}>{props.indexCategory}</span>
                </div>
                <div className={WidgetTableSource.symbol_count}>
                    <span className={WidgetTableSource.symbol_count_text_gray}>Symbols</span>
                    <span className={WidgetTableSource.stocklist_row_text}>500</span>
                </div>
            </div>
        )
    }
    return null
}

export default TableTopRowButtonSourceTabIndicesItem