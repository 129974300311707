import { useEffect, useMemo, useRef, useState } from "react";

import { setConditionExpandedCard, setPopupElement, setShowOnlyFavouriteConditions } from '../../../../../../../../store/features/management';
import { changeScreenerData, getScreener, saveScreener } from '../../../../../../../../store/features/screener';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store/hooks';
import { FilterAlertConditions } from '../../../../../../../../types/FiltersAlerts';
import { CriteriaRow, isScreener, State } from '../../../../../../../../types/Screener';
import getAlertConditions from '../../../../../../../../utils/components/conditions/alerts';
import getFilterConditions from '../../../../../../../../utils/components/conditions/filters';
import LoadingSpinner from '../../../../../../../../utils/components/loading-spinner/loading-spinner';
import { findRow } from "../../../../../../../../utils/functions/deep-splice-criteria";
import useClickOutsidePopup from "../../../../../../../../utils/hooks/use-click-outside-popup";
import useIntersection from '../../../../../../../../utils/hooks/use-intersection';
import PopUp from "../../../../../../PopUp.module.css";
import DashboardScreenerBuilderConditionCategory from "./dashboard-screener-builder-condition-category";
import DashboardScreenerBuilderConditionSidebarBottom from './dashboard-screener-builder-condition-sidebar-bottom';
import DashboardScreenerBuilderConditionSidebarItem from "./dashboard-screener-builder-condition-sidebar-item";
import DashboardScreenerBuilderConditionStyles from "./DashboardScreenerBuilderCondition.module.css"

type DashboardScreenerBuilderConditionProps = {
    state: State;
    row: CriteriaRow;
}

const DashboardScreenerBuilderCondition: React.FC<DashboardScreenerBuilderConditionProps> = (props) => {
    const dispatch = useAppDispatch();
    const management = useAppSelector((state) => state.management);
    const screener = useAppSelector((state) => state.screener);
    const user = useAppSelector((state) => state.user);

    const state = useMemo(() => {
        const newState = screener.data.states.find((s) => s.id === props.state.id)
        if (typeof newState !== "undefined") {
            return newState
        }
        return props.state
    }, [screener.data.states, props.state])

    const row = useMemo(() => {
        const newRow = findRow(state.criteria.criteriaList, props.row.id)
        if (typeof newRow !== "undefined" && newRow !== null) {
            return newRow
        }
        return props.row
    }, [state, props.row.id])

    const getAllConditions = (): FilterAlertConditions => {
        if (state && row) {
            if (state.type === "filter") {
                return getFilterConditions(state, row)
            }
            return getAlertConditions(state, row)
        }
        return {}
    }

    const allConditions = useMemo(() => getAllConditions(), [props]);
    
    const priceRef = useRef<HTMLDivElement>(null);
    const volumeRef = useRef<HTMLDivElement>(null);
    const indicatorsRef = useRef<HTMLDivElement>(null);
    const descriptiveRef = useRef<HTMLDivElement>(null);
    const fundamentalsRef = useRef<HTMLDivElement>(null);
    const newsRef = useRef<HTMLDivElement>(null);
    const timeRef = useRef<HTMLDivElement>(null);

    const priceInViewport = useIntersection(priceRef, "0px 0px -200px 0px");
    const volumeInViewport = useIntersection(volumeRef, "0px 0px -200px 0px");
    const indicatorsInViewport = useIntersection(indicatorsRef, "0px 0px -200px 0px");
    const descriptiveInViewport = useIntersection(descriptiveRef, "0px 0px -200px 0px");
    const fundamentalsInViewport = useIntersection(fundamentalsRef, "0px 0px -200px 0px");
    const newsInViewport = useIntersection(newsRef, "0px 0px -200px 0px");
    const timeInViewport = useIntersection(timeRef, "0px 0px -200px 0px");

    const [activeCategory, setActiveCategory] = useState("Price");
    const [conditionSearch, setConditionSearch] = useState("");
    
    const [expandedCard, setExpandedCard] = useState(row ? row.settings.idA : "");

    const refClickOutside = useClickOutsidePopup();

    const getRef = (categoryName: string): React.RefObject<HTMLDivElement> => {
        if (categoryName === "Time") {
            return timeRef
        }
        if (categoryName === "News") {
            return newsRef
        }
        if (categoryName === "Fundamentals") {
            return fundamentalsRef
        }
        if (categoryName === "Descriptive") {
            return descriptiveRef
        }
        if (categoryName === "Indicators") {
            return indicatorsRef
        }
        if (categoryName === "Volume") {
            return volumeRef
        }
        return priceRef
    }

    const updateCategoryInView = () => {
        if (timeInViewport) {
            setActiveCategory("Time")
        } else if (newsInViewport) {
            setActiveCategory("News")
        } else if (fundamentalsInViewport) {
            setActiveCategory("Fundamentals")
        } else if (descriptiveInViewport) {
            setActiveCategory("Descriptive")
        } else if (indicatorsInViewport) {
            setActiveCategory("Indicators")
        } else if (volumeInViewport) {
            setActiveCategory("Volume")
        } else if (priceInViewport) {
            setActiveCategory("Price")
        }
    }

    const updateScroll = (e: React.WheelEvent<HTMLDivElement>) => {
        if(e.deltaY) {
            updateCategoryInView()
        }
    }

    useEffect(() => {
        updateCategoryInView();
    }, [management.showOnlyFavouriteConditions])

    useEffect(() => {
        if (management.conditionExpandedCard !== expandedCard) {
            dispatch(setConditionExpandedCard(expandedCard));
        }
    }, [expandedCard])

    const handleCancelButtonClick = async () => {
        if (state) {
            dispatch(setConditionExpandedCard(""));
            dispatch(setPopupElement(null));
            const screenerDb = await dispatch(getScreener(screener.data.id)).unwrap();
            if (screenerDb && isScreener(screenerDb)) {
                dispatch(changeScreenerData(screenerDb));
            }
        }
    }

    const handleSaveButtonClick = () => {
        dispatch(setConditionExpandedCard(""));
        dispatch(setPopupElement(null));
        dispatch(saveScreener());
    }

    const categorySidebarClick = (categoryName: string) => {
        setActiveCategory(categoryName)
        const container = document.getElementById(categoryName);
        if (!container) {
            return
        }
        container.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className="app_popup_wrapper_middle" style={{ width: "100%", maxWidth: "1200px" }} ref={refClickOutside} id="criteria-popup-container">
            <div className={PopUp.top_bar}>
                <h2 className={PopUp.header}>Edit {state.type.charAt(0).toUpperCase() + state.type.slice(1)} Condition</h2>
                <div className={PopUp.header_buttons_wrapper}>
                    {(!screener.getLoading) ? (
                        <div className={PopUp.header_cancel_button} onClick={handleCancelButtonClick}>
                            <span className={PopUp.header_save_button_icon_text}>Cancel</span>
                        </div>
                    ) : (
                        <div className={PopUp.header_cancel_button} style={{ pointerEvents: "none" }}>
                            <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-primary-100))" />
                        </div>
                    )}
                    {(!screener.saveLoading && !user.saveLoading) ? (
                        <div className={PopUp.header_save_button} style={{ width: "131px" }} onClick={() => handleSaveButtonClick()}>
                            <svg viewBox="0 0 1024 1024" className={PopUp.header_save_button_icon}>
                                <path d="M810.667 938.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-469.333c0-10.923-4.181-21.845-12.501-30.165l-213.333-213.333c-7.723-7.723-18.389-12.501-30.165-12.501h-469.333c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v597.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504zM341.333 853.333v-256h341.333v256zM256 170.667v170.667c0 23.552 19.115 42.667 42.667 42.667h341.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-298.667v-128h323.669l188.331 188.331v451.669c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-42.667v-298.667c0-23.552-19.115-42.667-42.667-42.667h-426.667c-23.552 0-42.667 19.115-42.667 42.667v298.667h-42.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-597.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501z" />
                            </svg>
                            <span className={PopUp.header_save_button_icon_text}>Save Condition</span>
                        </div>
                    ) : (
                        <div className={PopUp.header_save_button} style={{ width: "131px", pointerEvents: "none" }}>
                            <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                        </div>
                    )}
                </div>
            </div>
            <div className={PopUp.content_wrapper}>
                <div className={PopUp.sidebar}>
                    <div className={PopUp.sidebar_top}>
                        <div className={PopUp.sidebar_top_category}>
                            <p className={PopUp.sidebar_top_category_text}>Categories</p>
                        </div>
                        {Object.keys(allConditions).map((key, index) => {
                            const conditionCategory = allConditions[key]
                            return <DashboardScreenerBuilderConditionSidebarItem name={conditionCategory.name} icon={conditionCategory.icon} color={conditionCategory.color} isActive={activeCategory === conditionCategory.name} setCategory={categorySidebarClick} key={index} />
                        })}
                    </div>
                    <div className={PopUp.separator} />
                    <DashboardScreenerBuilderConditionSidebarBottom />
                </div>
                <div className={PopUp.content_container}>
                    <div className={DashboardScreenerBuilderConditionStyles.wrapper}>
                        <div className={DashboardScreenerBuilderConditionStyles.card_favourite_icon_wrapper} style={{ height: "26px", left: "16px", top: "24px", borderColor: management.showOnlyFavouriteConditions ? "rgb(var(--dl-color-primary-100))" : "", backgroundColor: management.showOnlyFavouriteConditions ? "rgba(var(--dl-color-primary-100), 0.09)" : "" }} onClick={() => {dispatch(setShowOnlyFavouriteConditions(!management.showOnlyFavouriteConditions))}}>
                            <span className={DashboardScreenerBuilderConditionStyles.card_favourite_icon_text} style={{ color: management.showOnlyFavouriteConditions ? "rgb(var(--dl-color-primary-100))" : "" }}>Show Only Favourites</span>
                            <svg viewBox="0 0 1024 1024" className={DashboardScreenerBuilderConditionStyles.card_favourite_icon} style={{ fill: management.showOnlyFavouriteConditions ? "rgb(var(--dl-color-primary-100))" : "" }}>
                                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z" />
                            </svg>
                        </div>
                        <div className={DashboardScreenerBuilderConditionStyles.search_row}>
                            <div className={DashboardScreenerBuilderConditionStyles.condition_search}>
                                <svg viewBox="0 0 1024 1024" className={DashboardScreenerBuilderConditionStyles.condition_search_icon}>
                                    <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z" />
                                </svg>
                                <input className={DashboardScreenerBuilderConditionStyles.condition_search_input}
                                    type="text"
                                    placeholder="Search..."
                                    onChange={(event) => {if (event.target.value.length <= 50) {setConditionSearch(event.target.value)}}}
                                    value={conditionSearch}
                                />
                                {conditionSearch !== "" && (
                                    <div className={DashboardScreenerBuilderConditionStyles.condition_search_delete} onClick={() => setConditionSearch("")}>
                                        <svg viewBox="0 0 1024 1024" className={DashboardScreenerBuilderConditionStyles.condition_search_delete_icon}>
                                            <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={DashboardScreenerBuilderConditionStyles.categories_container} onWheel={updateScroll}>
                            {Object.keys(allConditions).map((key, index) => {
                                const conditionCategory = allConditions[key]
                                const conditionsList = conditionCategory.conditionsList.filter((condition) => (
                                    (condition.name.toLowerCase().replaceAll(" ", "").replaceAll("-", "").includes(conditionSearch.toLowerCase().replaceAll(" ", "").replaceAll("-", ""))) ||
                                    (condition.id.toLowerCase().replaceAll("-", "").includes(conditionSearch.toLowerCase().replaceAll(" ", "").replaceAll("-", "")))
                                ))
                                if (conditionsList.length > 0) {
                                    const ref = getRef(conditionCategory.name);
                                    return <DashboardScreenerBuilderConditionCategory conditionCategory={conditionCategory} conditionsList={conditionsList} expandedCard={expandedCard} setExpandedCard={setExpandedCard} ref={ref} key={index} />
                                }
                                return null
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardScreenerBuilderCondition