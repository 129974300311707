import { useState } from "react";

import { changeDashboardData } from "../../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../../types/changeHandlers/change-dashboard-widget";
import { isOfTypeWidgetConnection, WidgetConnectionColorName } from "../../../../../../types/Dashboard";
import { widgetConnections } from "../../../../../../utils/constants/widget-connections";
import DashboardWidgetStyles from "../DashboardWidgetStyles.module.css"

type Props = {
    id: string;
    connectionName: WidgetConnectionColorName;
}

const GridWidgetSettingsConnection: React.FC<Props> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useAppDispatch();
    const dashboard = useAppSelector((state) => state.dashboard);

    const setConnection = (newConnection: WidgetConnectionColorName) => {
        const newWidgets = changeDashboardWidgetData(props.id, [...dashboard.data.widgets], { connection: newConnection });
        dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
    }

    return (
        <div className={DashboardWidgetStyles.connection_circle_container} style={{ width: isOpen ? "123px" : "18px" }} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
            <div className={DashboardWidgetStyles.connection_circle_wrapper}>
                {Object.keys(widgetConnections).map((c, index) => {
                    if (c !== props.connectionName && isOfTypeWidgetConnection(c)) {
                        return (
                            <div key={index} className={DashboardWidgetStyles.settings_icon_container}>
                                <div
                                    className={DashboardWidgetStyles.connection_circle}
                                    style={{
                                        backgroundColor: `rgb(var(${widgetConnections[c]}))`,
                                        borderColor: c !== "none" ? `rgb(var(${widgetConnections[c]}))` : "rgba(var(--dl-color-text-black), 0.2)"
                                    }}
                                    onClick = {() => setConnection(c)}
                                >
                                    {c === "none" ? (<img src="/icons/red_cross.png" alt="Traddle Logo" className={DashboardWidgetStyles.connection_circle_cross} />) : (null)}
                                </div>
                            </div>
                        )
                    }
                    return null
                })}
                <div className={DashboardWidgetStyles.settings_icon_container} style={{ pointerEvents: "none" }}>
                    <div
                        className={DashboardWidgetStyles.connection_circle}
                        style={{
                            backgroundColor: `rgb(var(${widgetConnections[props.connectionName]}))`,
                            borderColor: props.connectionName !== "none" ? `rgb(var(${widgetConnections[props.connectionName]}))` : "rgba(var(--dl-color-text-black), 0.4)"
                        }}>
                        {props.connectionName === "none" ? (<img src="/icons/red_cross.png" alt="Traddle Logo" className={DashboardWidgetStyles.connection_circle_cross} />) : (null)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GridWidgetSettingsConnection