import { useMemo } from "react";

import { changeDashboardData } from "../../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../../types/changeHandlers/change-dashboard-widget";
import { TableSettings, Widget } from "../../../../../../types/Dashboard";
import BootstrapTooltip from "../../../../tooltip/bootstrap-tooltip";
import WidgetTableSource from "./WidgetTableSource.module.css"

type Props = {
    widgetId: string;
    id: string;
    name: string;
    type: string;
}

const TableTopRowButtonSourceSelectedItem: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const dashboard = useAppSelector((state) => state.dashboard)

    const widget: Widget = useMemo(() => dashboard.data.widgets.find((w) => w.id === props.widgetId)!, [dashboard.data.widgets, props.widgetId])

    const settings = useMemo(() => widget.settings as TableSettings, [widget])

    const sources = useMemo(() => ({ ...settings.sources }), [settings.sources])

    const indices = useMemo(() => ([...sources.indices]), [sources])
    const screeners = useMemo(() => ([...sources.screeners]), [sources])
    const watchlists = useMemo(() => ([...sources.watchlists]), [sources])

    const notAllowed = useMemo(() => ((indices.length + screeners.length + watchlists.length) === 1), [indices, screeners, watchlists])

    const removeItem = () => {
        if (notAllowed) {
            return
        }
        let flag = false;
        if (props.type === "Indices") {
            const index = indices.findIndex((i) => i === props.id)
            if (index > -1) {
                indices.splice(index, 1)
                sources.indices = indices
                flag = true;
            }
        }
        if (props.type === "Screeners") {
            const index = screeners.findIndex((s) => s.stateId === props.id)
            if (index > -1) {
                screeners.splice(index, 1)
                sources.screeners = screeners
                flag = true;
            }
        }
        if (props.type === "Watchlists") {
            const index = watchlists.findIndex((w) => w === props.id)
            if (index > -1) {
                watchlists.splice(index, 1)
                sources.watchlists = watchlists
                flag = true;
            }
        }
        if (flag) {
            const newSettings = {
                ...settings,
                sources
            }
            const newWidgets = changeDashboardWidgetData(props.widgetId, dashboard.data.widgets, { settings: newSettings })
            dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
        }
    }

    return (
        <BootstrapTooltip title="You have to select at least one item" disableHoverListener={!notAllowed}>
            <div className={WidgetTableSource.selected_sources_list_item} >
                <div className={WidgetTableSource.selected_sources_list_item_logo_wrapper}>
                    <img alt="Input List" src="/logos/traddle_logo_black_on_white_comp.png" className={WidgetTableSource.selected_sources_list_item_logo} />
                </div>
                <span className={WidgetTableSource.selected_sources_list_item_text}>{props.name}</span>
                <div className={WidgetTableSource.selected_sources_list_item_delete_icon_wrapper} style={{ cursor: notAllowed ? "not-allowed" : "default" }} onClick={() => removeItem()}>
                    <svg viewBox="0 0 1024 1024" className={WidgetTableSource.selected_sources_list_item_delete_icon}>
                        <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                    </svg>
                </div>
            </div>
        </BootstrapTooltip>
    )
}
    
export default TableTopRowButtonSourceSelectedItem