import { useEffect, useState } from 'react';

import ConditionRow from "../ConditionRow.module.css"
import CriteriaDropdownSelectionNestedCategory from './criteria-dropdown-selection-nested-category';
import { getBackgroundColor } from './get-background-color';

type Props = {
    dropdownObject: {[sector: string]: string[]};
    withSearch: boolean;
    values: string[];
    setValues: (values: string[]) => void;
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const CriteriaDropdownSelectionNestedPopup: React.FC<Props> = (props) => {
    const { dropdownObject, withSearch, values, setValues, search, setSearch } = { ...props }

    const getAllOptions = () => {
        const allOptions: string[] = []
        Object.values(dropdownObject).forEach((category) => {
            category.forEach((option) => {
                allOptions.push(option)
            })
        })
        allOptions.sort((a, b) => {
            const fa = a.toLowerCase();
            const fb = b.toLowerCase();
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        })
        return allOptions
    }

    const [allOptions, setAllOptions] = useState(getAllOptions())

    useEffect(() => {
        setAllOptions(getAllOptions());
    }, [values]);

    const selectAllClick = () => {
        if (values) {
            if (JSON.stringify([...values].sort((a, b) => {
                const fa = a.toLowerCase();
                const fb = b.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })) === JSON.stringify(allOptions)) {
                setValues([])
            } else {
                setValues(allOptions)
            }
        }
    }

    const setCategoryValues = (categoryValues: string[], categoryName: string) => {
        if (values) {
            setValues([...[...values].filter((obj) => !dropdownObject[categoryName].includes(obj)), ...categoryValues])
        }
    }

    return (
        <>
            <div className={ConditionRow.criteria_dropdown_menu_top}>
                {withSearch && (
                    <div className={ConditionRow.search_wrapper}>
                        <svg viewBox="0 0 1024 1024" className={ConditionRow.search_icon}>
                            <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z" />
                        </svg>
                        <input className={ConditionRow.search_input}
                            type="text"
                            placeholder="Search..."
                            onChange={(event) => {if (event.target.value.length <= 50) {setSearch(event.target.value)}}}
                            value={search}
                        />
                        {search !== "" && (
                            <div className={ConditionRow.search_delete} onClick={() => setSearch("")}>
                                <svg viewBox="0 0 1024 1024" className={ConditionRow.search_delete_icon}>
                                    <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                                </svg>
                            </div>
                        )}
                    </div>
                )}
                <div className={ConditionRow.criteria_dropdown_menu_item_wrapper} style={{ padding: "0px 8px 0px 4px" }} onClick={selectAllClick}>
                    <div className={ConditionRow.criteria_dropdown_menu_item_checkbox}>
                        <div className={ConditionRow.criteria_dropdown_menu_item_checkbox_inner} style={{ backgroundColor: getBackgroundColor(
                            values ? [...values].sort((a, b) => {
                                const fa = a.toLowerCase();
                                const fb = b.toLowerCase();
                                if (fa < fb) {
                                    return -1;
                                }
                                if (fa > fb) {
                                    return 1;
                                }
                                return 0;
                            }) : [],
                            allOptions
                        ) }}/>
                    </div>
                    <p className={ConditionRow.criteria_dropdown_menu_item}>Select All</p>
                </div>
                <div className={ConditionRow.criteria_dropdown_menu_separator} />
            </div>
            <div className={ConditionRow.criteria_dropdown_menu_scroll_wrapper}>
                {Object.keys(dropdownObject).sort((a, b) => {
                    const fa = a.toLowerCase();
                    const fb = b.toLowerCase();
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                }).map((category, index) => <CriteriaDropdownSelectionNestedCategory
                    categoryName={category}
                    dropdownOptions={[...dropdownObject[category]].sort((a, b) => {
                        const fa = a.toLowerCase();
                        const fb = b.toLowerCase();
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    })}
                    searchValue={search}
                    values={values}
                    setValues={setCategoryValues}
                    key={index}
                />)}
            </div>
        </>
    )
}

export default CriteriaDropdownSelectionNestedPopup