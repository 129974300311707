import ConditionRow from "../ConditionRow.module.css"

type Props = {
    switchOptions: string[];
    value: string;
    setValue: (value: string) => void;
    width: string;
}

const CriteriaInputSwitch: React.FC<Props> = (props) => {
    const switchInput = (): void => {
        if (props.value === props.switchOptions[0]) {
            props.setValue(props.switchOptions[1])
        } else {
            props.setValue(props.switchOptions[0])
        }
    }

    return (
        <div className={ConditionRow.criteria_input_wrapper}>
            <div className={ConditionRow.up_down_icons} style={{ gap: "0px", top: "5px" }}>
                <div className={`${ConditionRow.up_down_icon_wrapper} ${ConditionRow.icon_wrapper_no_hover}`}>
                    <svg viewBox="0 0 1024 1024" className={ConditionRow.up_down_icon} style={{ width: "14px", height: "14px" }}>
                        <path d="M512 342l256 256-60 60-196-196-196 196-60-60z" />
                    </svg>
                </div>
                <div className={`${ConditionRow.up_down_icon_wrapper} ${ConditionRow.icon_wrapper_no_hover}`}>
                    <svg viewBox="0 0 1024 1024" className={ConditionRow.up_down_icon} style={{ width: "14px", height: "14px" }}>
                        <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                    </svg>
                </div>
            </div>
            <div className={ConditionRow.criteria_input_switch} style={{ width: props.width }} onClick={() => switchInput()}>
                <div className={ConditionRow.criteria_input_switch_box} style={{ marginTop: props.value === props.switchOptions[1] ? "-28px" : "0px" }}>
                    <div className={ConditionRow.criteria_input_switch_item_wrapper}>
                        <span className={ConditionRow.criteria_input_switch_item}>{props.switchOptions[0]}</span>
                    </div>
                    <div className={ConditionRow.criteria_input_switch_item_wrapper}>
                        <span className={ConditionRow.criteria_input_switch_item}>{props.switchOptions[1]}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CriteriaInputSwitch