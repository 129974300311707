import React, { useMemo } from "react";

import { useAppSelector } from "../../../../../../store/hooks";
import { State } from "../../../../../../types/Screener";
import BootstrapTooltip from "../../../../../../utils/components/tooltip/bootstrap-tooltip";
import findScreenerFirstSource, { getAllSourcesNames } from "../../../../../../utils/functions/find-screener-first-source";
import DashboardScreenerDetailsStyles from "../DashboardScreenerDetails.module.css";

type Props = {
    selectedState: State | null;
    setSelectedState: React.Dispatch<React.SetStateAction<State | null>>;
    newState: number | null;
    setNewState: React.Dispatch<React.SetStateAction<number | null>>;
}

const DashboardScreenerTopbarTabs: React.FC<Props> = (props) => {
    const screener = useAppSelector((state) => state.screener)
    const allScreeners = useAppSelector((state) => state.allScreeners)
    const user = useAppSelector((state) => state.user)

    const sources = useMemo(() => ({ ...screener.data.sources }), [screener.data.sources])
    const indices = useMemo(() => ([...sources.indices]), [sources])
    const screeners = useMemo(() => ([...sources.screeners]), [sources])
    const watchlists = useMemo(() => ([...sources.watchlists]), [sources])

    const sourcesLength = useMemo(() => (indices.length + screeners.length + watchlists.length), [indices, screeners, watchlists])
    
    const allSourcesNames = useMemo(() => getAllSourcesNames(indices, screeners, watchlists, allScreeners, user), [indices, screeners, watchlists])
    
    const firstSourceName: string | null = useMemo(() => findScreenerFirstSource(sources, allScreeners, user), [sources])

    return (
        <div className={DashboardScreenerDetailsStyles.top_bar_tabs}>
            <div className={DashboardScreenerDetailsStyles.top_bar_tab} style={{ backgroundColor: props.selectedState === null && props.newState === null ? "rgb(var(--dl-color-backgrounds-hover1))" : "" }} onClick={() => {props.setSelectedState(null); props.setNewState(null)}}>
                <div className={DashboardScreenerDetailsStyles.top_bar_tab_logo_wrapper}>
                    <img alt="Input List" src="/logos/traddle_logo_black_on_white_comp.png" className={DashboardScreenerDetailsStyles.top_bar_tab_logo} />
                </div>
                <span className={DashboardScreenerDetailsStyles.top_bar_tab_name}>{firstSourceName !== null ? firstSourceName : "No source selected"}</span>
                {sourcesLength > 1 ? (
                    <BootstrapTooltip title={allSourcesNames}>
                        <div className={DashboardScreenerDetailsStyles.screener_input_list_count_wrapper}>
                            <span className={DashboardScreenerDetailsStyles.screener_input_list_count}>+{sourcesLength - 1}</span>
                        </div>
                    </BootstrapTooltip>
                ) : null}
            </div>
            {props.newState === 0 && screener.data.states.length > 0 ? (
                <div className={`${DashboardScreenerDetailsStyles.top_bar_plus_icon_wrapper} ${DashboardScreenerDetailsStyles.top_bar_plus_icon_wrapper_active}`}>
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.top_bar_plus_icon}>
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z" />
                    </svg>
                    <span className={DashboardScreenerDetailsStyles.top_bar_add_state_text}>Add state</span>
                </div>
            ) : null}
            {screener.data.states.map((state, index) => (
                <div
                    className={DashboardScreenerDetailsStyles.top_bar_tab}
                    style={{ backgroundColor: props.selectedState?.id === state.id && !props.newState ? "rgb(var(--dl-color-backgrounds-hover1))" : "" }}
                    onClick={() => {props.setSelectedState(state); props.setNewState(null)}}
                    key={state.id}
                >
                    <div className={DashboardScreenerDetailsStyles.top_bar_filter_alert_icon_wrapper}>
                        {state.type === "filter" ? (
                            <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.top_bar_filter_icon}>
                                <path d="M846.72 170.667l-281.984 333.397c-6.272 7.381-10.069 17.024-10.069 27.563v295.339l-85.333-42.667v-252.672c0.043-9.685-3.285-19.499-10.069-27.563l-281.984-333.397zM938.667 85.333h-853.333c-23.552 0-42.667 19.115-42.667 42.667 0 10.539 3.797 20.181 10.069 27.563l331.264 391.68v263.424c0 16.597 9.472 31.019 23.595 38.144l170.667 85.333c21.077 10.539 46.72 2.005 57.259-19.072 3.072-6.229 4.523-12.843 4.48-19.072v-348.757l331.264-391.68c15.232-18.005 12.971-44.928-5.035-60.117-8.064-6.827-17.877-10.155-27.563-10.112z" />
                            </svg>
                        ) : (
                            <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.top_bar_alert_icon}>
                                <path d="M554 384v128h128v86h-128v128h-84v-128h-128v-86h128v-128h84zM512 854q124 0 211-88t87-212-87-211-211-87-211 87-87 211 87 212 211 88zM512 170q160 0 272 113t112 271-112 271-272 113-272-113-112-271 112-271 272-113zM938 244l-54 66-196-166 54-64zM336 144l-196 164-54-64 196-164z" />
                            </svg>
                        )}
                    </div>
                    <span className={DashboardScreenerDetailsStyles.top_bar_tab_name}>{index + 1}. {state.name}</span>
                </div>
            ))}
            {props.newState === null && screener.data.states.length !== 0 ? (
                <div className={DashboardScreenerDetailsStyles.top_bar_plus_icon_wrapper} onClick={() => props.setNewState(screener.data.states.length)}>
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.top_bar_plus_icon}>
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z" />
                    </svg>
                </div>
            ) : null}
            {props.newState === screener.data.states.length ? (
                <div className={`${DashboardScreenerDetailsStyles.top_bar_plus_icon_wrapper} ${DashboardScreenerDetailsStyles.top_bar_plus_icon_wrapper_active}`}>
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.top_bar_plus_icon}>
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z" />
                    </svg>
                    <span className={DashboardScreenerDetailsStyles.top_bar_add_state_text}>Add state</span>
                </div>
            ) : null}
        </div>
    )
}
    
export default DashboardScreenerTopbarTabs