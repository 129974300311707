/* eslint-disable @typescript-eslint/no-unused-expressions, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Dashboards } from "../../types/Dashboard";

const namespace = "allDashboards"

export type AllDashboardsState = {
    loading: boolean;
    initialized: boolean;
    errorMessage: string;
    dashboards: Dashboards;
}

const initialStateValue: AllDashboardsState = {
    loading: false,
    initialized: false,
    errorMessage: "",
    dashboards: {}
}

export const allDashboardsSlice = createSlice({
    name: namespace,
    initialState: initialStateValue,
    reducers: {
        setDashboards: (state, action: PayloadAction<Dashboards>) => {
            state.loading = false
            state.initialized = true
            state.errorMessage = ""
            state.dashboards = action.payload
        },
    },
})

export const { setDashboards } = allDashboardsSlice.actions;

export default allDashboardsSlice.reducer;