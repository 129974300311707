import { useState } from 'react';

import { updateAuthPassword } from '../../../../store/features/user';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { isText } from '../../../../types/utils/is-text';
import LoadingSpinner from '../../../../utils/components/loading-spinner/loading-spinner';
import SignUpLoginInfoMessage from '../../../signup-login/components/signup-login-info';
import SignUpLoginWarning from '../../../signup-login/components/signup-login-warning';
import SignupLogin from '../../../signup-login/SignupLogin.module.css'
import AppProfile from '../AppProfile.module.css'

const ProfileChangePassword: React.FC = () => {
    const [errors, setErrors] = useState({
        "currentPassword": "",
        "newPassword": "",
        "unknown": ""
    });

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const [passwordSuccess, setPasswordSuccess] = useState(false);

    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user)

    const changeErrors = (errorMessage: string) => {
        const newErrors = {
            "currentPassword": "",
            "newPassword": "",
            "unknown": ""
        };
        if (errorMessage !== "" && !errorMessage.includes("popup-closed")) {
            if (errorMessage.includes("auth/wrong-password")) {
                newErrors.currentPassword = "Wrong password.";
            } else if (errorMessage.includes("auth/too-many-requests")) {
                newErrors.currentPassword = "Wrong password.";
                newErrors.unknown = "Too many failed attempts. Please try again later.";
            } else {
                newErrors.unknown = errorMessage;
            }
        }
        setErrors(newErrors);
    }

    const handlePasswordChange = async () => {
        const newErrors = {
            "currentPassword": "",
            "newPassword": "",
            "unknown": ""
        };
        const currentPasswordTrim = currentPassword.trim()
        const newPasswordTrim = newPassword.trim()
        let errorFound = false;
        if (currentPasswordTrim.length === 0) {
            newErrors.currentPassword = "Please enter your current password.";
            errorFound = true;
        }
        if (newPasswordTrim.includes(" ")) {
            newErrors.newPassword = "A password must not contain white spaces.";
            errorFound = true;
        }
        if (newPasswordTrim.length < 8) {
            newErrors.newPassword = "Please enter at least 8 characters.";
            errorFound = true;
        }
        if (!errorFound) {
            try {
                await dispatch(updateAuthPassword({
                    currentPassword: currentPasswordTrim,
                    newPassword: newPasswordTrim
                })).unwrap()
                setErrors(newErrors);
                setPasswordSuccess(true);
                setCurrentPassword("");
                setNewPassword("");
            } catch (error) {
                if (isText(error)) {
                    changeErrors(error);
                }
            }
        } else {
            setErrors(newErrors);
        }
    }

    const handleKeypress = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            await handlePasswordChange();
        }
    };

    return (
        <>
            {user.userData.providers.includes("password") ? (
                <div className={AppProfile.password_change_container}>
                    <div className={SignupLogin.input_row}>
                        <span>Current Password</span>
                        <div className={SignupLogin.input_field}>
                            <input
                                type={ showCurrentPassword ? "text" : "password" }
                                required
                                placeholder="Type in your old password"
                                className={SignupLogin.textinput}
                                onChange={(event) => {
                                    setCurrentPassword(event.target.value)}
                                }
                                value={currentPassword}
                                onKeyDown={handleKeypress}
                            />
                            <div className={SignupLogin.show_password_wrapper} onClick={() => {setShowCurrentPassword(true)}}>
                                {showCurrentPassword && (
                                    <p className={SignupLogin.show_password}>Hide</p>
                                )}
                                {!showCurrentPassword && (
                                    <p className={SignupLogin.show_password}>Show</p>
                                )}
                            </div>
                        </div>
                        <SignUpLoginWarning visible={errors.currentPassword !== ""} text={errors.currentPassword} />
                    </div>
                    <div className={SignupLogin.input_row}>
                        <span>New Password</span>
                        <div className={SignupLogin.input_field}>
                            <input
                                type={ showNewPassword ? "text" : "password" }
                                required
                                placeholder="At least 8 characters"
                                className={SignupLogin.textinput}
                                onChange={(event) => {
                                    setNewPassword(event.target.value)}
                                }
                                value={newPassword}
                                onKeyDown={handleKeypress}
                            />
                            <div className={SignupLogin.show_password_wrapper} onClick={() => {setShowNewPassword(true)}}>
                                {showNewPassword && (
                                    <p className={SignupLogin.show_password}>Hide</p>
                                )}
                                {!showNewPassword && (
                                    <p className={SignupLogin.show_password}>Show</p>
                                )}
                            </div>
                        </div>
                        <SignUpLoginWarning visible={errors.newPassword !== ""} text={errors.newPassword} />
                    </div>
                    <SignUpLoginInfoMessage visible={errors.unknown.length !== 0} color="red" text={errors.unknown} />
                    <SignUpLoginInfoMessage visible={passwordSuccess} text="Password successfully changed." color="green" />
                    <button type="button" className={`${SignupLogin.submit_button}`} onClick={() => handlePasswordChange()}>
                        <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                        {user.loading ? "" : "Change Password"}
                    </button>
                </div>
            ) : (
                <span>Your are logged in with your social profile and not with e-mail and password.</span>
            )}
        </>
    )
}

export default ProfileChangePassword