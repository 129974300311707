import AvatarStyles from './AvatarStyles.module.css';

type AvatarProps = {
    name: string;
    size: number;
    borderRadius: number;
}

const Avatar: React.FC<AvatarProps> = (props) => (
    <div className={AvatarStyles.screener_icon} style={{ borderRadius: props.borderRadius, width: props.size, height: props.size, backgroundColor: `rgb(var(--dl-color-primary-100))`, color: "rgb(var(--dl-color-white))" }}>
        <span className={AvatarStyles.screener_icon_letter} style={{ fontSize: Math.round(props.size / 1.55) }}>
            {props.name[0]}
        </span>
    </div>
)

export default Avatar