import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import { useAppSelector } from '../../../store/hooks';
import WebsiteTopNav from './WebsiteTopNav.module.css'

const TopNavWebsite: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false)
    const [email, setEmail] = useState("")

    const user = useAppSelector((state) => state.user)

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    return (
        <div className={isVisible ? `${WebsiteTopNav.container} ${WebsiteTopNav.height_100}` : `${WebsiteTopNav.container}`}>
            <div className={WebsiteTopNav.top_bar}>
                <div className={WebsiteTopNav.dashboard_link_container}>
                    <p>{user.userData.email}</p>
                </div>
                <div className={WebsiteTopNav.dashboard_link_container}>
                    <Link to="/app" className={WebsiteTopNav.dashboard_link}>
            Dashboard
                    </Link>
                </div>
                <Link to="/login">
                    <button type="button" className={WebsiteTopNav.navlink_button}>Login</button>
                </Link>
                <button
                    type="button"
                    onClick={() => setIsVisible(true)}
                    className={WebsiteTopNav.navlink_button_tryfree}
                >
          Try Free
                </button>
            </div>
            {isVisible && (
                <div className={WebsiteTopNav.popup_wrapper}>
                    <div className={WebsiteTopNav.popup_container}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={WebsiteTopNav.icon_x} viewBox="0 0 16 16" onClick={() => setIsVisible(false)}>
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                        </svg>
                        <p className={WebsiteTopNav.signup_text}>Sign up for FREE</p>
                        <span className={WebsiteTopNav.signup_subtext}>and start using Traddle immediately!</span>
                        <div className={WebsiteTopNav.email_input_container}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={WebsiteTopNav.icon_email} viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                            </svg>
                            <input
                                type="text"
                                placeholder='Enter your e-mail address'
                                className={WebsiteTopNav.email_input}
                                onChange={handleEmailChange}
                                value={email}
                            />
                        </div>
                        <Link to={email === "" ? "/sign-up" : `/sign-up?email=${  email}`} className={WebsiteTopNav.button_next_link}>
                            <button type="button" className={WebsiteTopNav.button_next}>Next</button>
                        </Link>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TopNavWebsite
