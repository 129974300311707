import React from "react";

import TableIposStyles from "./TableIposStyles.module.css"

const TableIposRowItem: React.FC = () => (
    <div className={TableIposStyles.row_item}>
        <div className={TableIposStyles.row_item_left}>
            <div className={TableIposStyles.row_item_icon_logo_wrapper}>
                <svg viewBox="0 0 1024 1024" className={TableIposStyles.row_item_icon_logo}>
                    <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z" />
                </svg>
            </div>
            <span className={TableIposStyles.row_item_ticker}>DAKT</span>
        </div>
        <div className={TableIposStyles.row_item_right}>
            <span className={TableIposStyles.row_item_text} style={{ width: "60%" }}>Daktronic, Inc</span>
            <span className={TableIposStyles.row_item_text} style={{ width: "40%" }}>5.00-15.00</span>
        </div>
    </div>
)

export default TableIposRowItem