import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import ConditionRow from "../../../ConditionRow.module.css"
import CriteriaDropdown from '../../criteria-dropdown';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
    reportPeriodOptions: string[];
}

const defaultProps: Props = {
    parameters: {
        reportPeriod: "Quarterly Report",
    },
    setParameters: () => {},
    reportPeriodOptions: ["Quarterly Report", "Annual Report", "Trailing 12 Months"],
}

const CriteriaParameterReturnOnEquity: React.FC<Props> = (props) => {
    const reportPeriod = useMemo(() => props.parameters.reportPeriod && props.reportPeriodOptions.includes(props.parameters.reportPeriod) ? props.parameters.reportPeriod : defaultProps.parameters.reportPeriod!, [props.parameters.reportPeriod])

    const setReportPeriod = (newReportPeriod: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, reportPeriod: newReportPeriod }, defaultProps.parameters))
    }

    return (
        <>
            <span className={ConditionRow.criteria_text}>of last</span>
            <CriteriaDropdown dropdownOptions={props.reportPeriodOptions} transformType="" value={reportPeriod} setValue={setReportPeriod} />
        </>
    )
}

export default CriteriaParameterReturnOnEquity