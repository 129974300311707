import { useEffect, useState } from 'react'

import { useAppSelector } from '../../../../../store/hooks';
import { Widget } from '../../../../../types/Dashboard';

const useGridLayouts = (): ReactGridLayout.Layout[] => {
    const dashboard = useAppSelector((state) => state.dashboard)
    const [layout, setLayout] = useState<ReactGridLayout.Layout[]>([]);
    
    useEffect(() => {
        const newLayout: ReactGridLayout.Layout[] = [];
        dashboard.data.widgets.forEach((widget: Widget) => {
            newLayout.push({
                ...widget.layout
            })
        })
        setLayout(newLayout);
    }, [dashboard]);

    return layout
};

export default useGridLayouts