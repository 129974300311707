import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import usePopperPopup from '../../../../../hooks/use-popper-popup';
import BootstrapTooltip from '../../../../tooltip/bootstrap-tooltip';
import ConditionRow from "../../../ConditionRow.module.css"
import CriteriaDropdown from '../../criteria-dropdown';
import CriteriaInputNumber from '../../criteria-input-number';
import CriteriaInputTimeframe from '../../criteria-input-timeframe';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
    titleWidth: string;
    fieldWidth: string;
    referenceOptions: string[];
}

const defaultProps: Props = {
    parameters: {
        reference: "Value",
        timeframe: "1 minute",
        slowEmaLength: "26",
        fastEmaLength: "12",
        signalSmooth: "9"
    },
    setParameters: () => {},
    titleWidth: "160px",
    fieldWidth: "70px",
    referenceOptions: ["Value", "Signal Line", "Histogram"]
}

const CriteriaParameterMACD: React.FC<Props> = (props) => {
    const reference = useMemo(() => props.parameters.reference && props.referenceOptions.includes(props.parameters.reference) ? props.parameters.reference : defaultProps.parameters.reference!, [props.parameters.reference])
    const timeframe = useMemo(() => props.parameters.timeframe ? props.parameters.timeframe : defaultProps.parameters.timeframe!, [props.parameters.timeframe])
    const slowEmaLength = useMemo(() => props.parameters.slowEmaLength ? props.parameters.slowEmaLength : defaultProps.parameters.slowEmaLength!, [props.parameters.slowEmaLength])
    const fastEmaLength = useMemo(() => props.parameters.fastEmaLength ? props.parameters.fastEmaLength : defaultProps.parameters.fastEmaLength!, [props.parameters.fastEmaLength])
    const signalSmooth = useMemo(() => props.parameters.signalSmooth ? props.parameters.signalSmooth : defaultProps.parameters.signalSmooth!, [props.parameters.signalSmooth])
    
    const { setButton, setPopup, buttonRef: wrapperRef, styles, attributes, isOpen: dropdownOpen, setIsOpen: setDropdownOpen } = usePopperPopup({ offsetY: 4 })

    const setReference = (newReference: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, reference: newReference }, defaultProps.parameters))
    }

    const setTimeframe = (newTimeframe: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, timeframe: newTimeframe }, defaultProps.parameters))
    }

    const setSlowEmaLength = (newSlowEmaLength: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, slowEmaLength: newSlowEmaLength }, defaultProps.parameters))
    }

    const setFastEmaLength = (newFastEmaLength: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, fastEmaLength: newFastEmaLength }, defaultProps.parameters))
    }

    const setSignalSmooth = (newSignalSmooth: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, signalSmooth: newSignalSmooth }, defaultProps.parameters))
    }

    return (
        <div className={ConditionRow.criteria_dropdown_wrapper} ref={wrapperRef}>
            <div
                className={ConditionRow.criteria_dropdown_button}
                style={{ borderColor: dropdownOpen ? "rgb(var(--dl-color-primary-100))" : "", padding: "0px 8px" }}
                onClick={() => {setDropdownOpen(!dropdownOpen)}} ref={setButton}
            >
                <span className={ConditionRow.criteria_dropdown_button_text}>{`${reference} | ${timeframe} | ${fastEmaLength}, ${slowEmaLength}, ${signalSmooth}`}</span>
            </div>
            {dropdownOpen ? (
                <div className={`${ConditionRow.criteria_dropdown_menu} ${ConditionRow.dropdown_parameter}`} ref={setPopup} style={styles.popper} {...attributes.popper}>
                    <div className={ConditionRow.criteria_dropdown_menu_scroll_wrapper} style={{ gap: "8px", overflow: "visible" }}>
                        <div className={ConditionRow.criteria_dropdown_parameter_title_row}>
                            <p className={ConditionRow.criteria_dropdown_parameter_title}>Moving Average Convergence Divergence (MACD)</p>
                            <BootstrapTooltip title="Indicator Help">
                                <svg viewBox="0 0 1024 1024" className={ConditionRow.criteria_dropdown_indicator_help_icon}>
                                    <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                                </svg>
                            </BootstrapTooltip>
                        </div>
                        <div className={ConditionRow.criteria_popup_input}>
                            <p className={ConditionRow.criteria_popup_input_title} style={{ width: props.titleWidth }}>Reference</p>
                            <CriteriaDropdown dropdownOptions={props.referenceOptions} transformType="" value={reference} setValue={setReference} />
                        </div>
                        <div className={ConditionRow.criteria_popup_input}>
                            <p className={ConditionRow.criteria_popup_input_title} style={{ width: props.titleWidth }}>Timeframe</p>
                            <CriteriaInputTimeframe timeframe={timeframe} setTimeframe={setTimeframe} />
                        </div>
                        <div className={ConditionRow.criteria_popup_input}>
                            <p className={ConditionRow.criteria_popup_input_title} style={{ width: props.titleWidth }}>Slow EMA Length</p>
                            <CriteriaInputNumber number={slowEmaLength} setNumber={setSlowEmaLength} min={1} max={200} step="1" width={props.fieldWidth} />
                        </div>
                        <div className={ConditionRow.criteria_popup_input}>
                            <p className={ConditionRow.criteria_popup_input_title} style={{ width: props.titleWidth }}>Fast EMA Length</p>
                            <CriteriaInputNumber number={fastEmaLength} setNumber={setFastEmaLength} min={1} max={200} step="1" width={props.fieldWidth} />
                        </div>
                        <div className={ConditionRow.criteria_popup_input}>
                            <p className={ConditionRow.criteria_popup_input_title} style={{ width: props.titleWidth }}>Signal Line Smoothing</p>
                            <CriteriaInputNumber number={signalSmooth} setNumber={setSignalSmooth} min={1} max={200} step="1" width={props.fieldWidth} />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default CriteriaParameterMACD