import { Widget } from "../Dashboard";

const deleteDashboardWidget = (id: string, dashboardWidgets: Widget[]): Widget[] => {
    const newWidgets = [...dashboardWidgets]
    const index = newWidgets.findIndex((w) => w.id === id)
    if (index > -1) {
        newWidgets.splice(index, 1)
    }
    return newWidgets
}

export default deleteDashboardWidget