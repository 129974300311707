import _ from 'lodash';
import { createRef, RefObject, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { resetUserError, signInSSO, signUp } from '../../store/features/user';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { isText } from '../../types/utils/is-text';
import LoadingSpinner from '../../utils/components/loading-spinner/loading-spinner';
import App from '../app/App.module.css'
import SignupLoginBackground from './components/signup-login-bg';
import SignUpLoginInfoMessage from './components/signup-login-info';
import SignUpLoginWarning from './components/signup-login-warning';
import { getInputClasses } from './get-input-classes';
import SignupLogin from './SignupLogin.module.css'

const initialValueErrors = {
    "name": "",
    "email": "",
    "password": "",
    "checkboxMail": "",
    "checkboxSSO": "",
    "unknown": "",
}

const SignUp: React.FC = () => {
    const [searchParams] = useSearchParams();
  
    const [name, setName] = useState("");
    const [email, setEmail] = useState<string>(searchParams.get('email') ? searchParams.get('email')! : "");
    const [password, setPassword] = useState("");
    const [checkboxMail, setCheckboxMail] = useState(false);
    const [checkboxSSO, setCheckboxSSO] = useState(false);
    const [ssoSelected, setSsoSelected] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordClicked, setPasswordClicked] = useState(false);

    const navigate = useNavigate();

    const [errors, setErrors] = useState(_.clone(initialValueErrors));

    const [clickType, setClickType] = useState("");

    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user)

    const [activeInput, setActiveInput] = useState("");
    const nameRef = createRef<HTMLInputElement>();
    const emailRef = createRef<HTMLInputElement>();
    const passwordRef = createRef<HTMLInputElement>();

    useEffect(() => {
        dispatch(resetUserError());
    }, []);

    useEffect(() => {
        if (!passwordClicked) {
            return nameRef.current?.focus();
        }
        passwordRef.current?.focus();
        passwordRef.current?.setSelectionRange(passwordRef.current?.value.length, passwordRef.current?.value.length)
    }, [showPassword]);

    document.addEventListener("mousedown", () => setActiveInput(""));

    const handleMouseOut = (currentRef: RefObject<HTMLInputElement>) => {
        if (document.activeElement === currentRef.current) {
            if (nameRef === currentRef) {
                setActiveInput("name")
            } else if (emailRef === currentRef) {
                setActiveInput("email")
            } else if (passwordRef === currentRef) {
                setActiveInput("password")
            }
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        const nameTrim = event.target.value.trim();

        if (clickType !== "password") {
            return
        }
        
        const newErrors = errors;
        if (nameTrim.length < 3) {
            newErrors.name = "Please enter at least 3 characters.";
        } else {
            newErrors.name = "";
        }
        setErrors(newErrors);
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        const emailTrim = event.target.value.trim();

        if (clickType !== "password") {
            return
        }

        const emailSplit = emailTrim.split("@");
        let emailEnding = "";
        if (emailSplit[emailSplit.length - 1].includes(".")) {
            const emailEndingSplit = emailSplit[emailSplit.length - 1].split(".")
            emailEnding = emailEndingSplit[emailEndingSplit.length - 1]
        }
    
        const newErrors = errors;
        if (emailTrim.length === 0 || !emailTrim.includes("@") || emailEnding.length === 0 || emailTrim.includes(" ")) {
            newErrors.email = "Please enter a valid e-mail address.";
        } else {
            newErrors.email = "";
        }
        setErrors(newErrors);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value
        setPassword(newPassword);

        if (clickType !== "password") {
            return
        }
        
        const newErrors = errors;
        if (password.includes(" ")) {
            newErrors.password = "A password must not contain white spaces.";
        }
        if (password.length < 8) {
            newErrors.password = "Please enter at least 8 characters.";
        } else {
            newErrors.password = "";
        }
        setErrors(newErrors);
    }

    const handleCeckboxMailChange = () => {
        setCheckboxMail(!checkboxMail);

        if (clickType !== "password") {
            return
        }
        
        const newErrors = errors;
        if (checkboxMail) {
            newErrors.checkboxMail = "This field is required.";
        } else {
            newErrors.checkboxMail = "";
        }
        setErrors(newErrors);
    }

    const handleCeckboxSSOChange = () => {
        setCheckboxSSO(!checkboxSSO);
        
        const newErrors = errors;
        if (checkboxSSO) {
            newErrors.checkboxSSO = "This field is required.";
        } else {
            newErrors.checkboxSSO = "";
        }
        setErrors(newErrors);
    }

    const handleSignupPassword = async () => {
        setClickType("password");

        const nameTrim = name.trim()
        const emailTrim = email.trim()

        const emailSplit = emailTrim.split("@");
        let emailEnding = "";
        if (emailSplit[emailSplit.length - 1].includes(".")) {
            const emailEndingSplit = emailSplit[emailSplit.length - 1].split(".")
            emailEnding = emailEndingSplit[emailEndingSplit.length - 1]
        }

        const newErrors = _.clone(initialValueErrors);

        if (nameTrim.length < 3) {
            newErrors.name = "Please enter at least 3 characters.";
        }

        if (emailTrim.length === 0 || !emailTrim.includes("@") || emailEnding.length === 0 || emailTrim.includes(" ")) {
            newErrors.email = "Please enter a valid e-mail address.";
        }

        if (password.includes(" ")) {
            newErrors.password = "A password must not contain white spaces.";
        }
        if (password.length < 8) {
            newErrors.password = "Please enter at least 8 characters.";
        }

        if (!checkboxMail) {
            newErrors.checkboxMail = "This field is required."
        }
    
        if (_.isEqual(newErrors, initialValueErrors) && errors.name === "" && errors.email === "" && errors.password === "" && errors.checkboxMail === "") {
            try {
                const res = await dispatch(signUp({
                    email: emailTrim,
                    password,
                    displayName: nameTrim
                })).unwrap();
                if (res) {
                    navigate(res.route);
                }
            } catch (error) {
                if (isText(error)) {
                    if (!error.includes("Popup error")) {
                        if (error.includes("e-mail")) {
                            newErrors.email = error;
                        } else if (error.includes("password")) {
                            newErrors.password = error;
                        } else if (error.includes("name")) {
                            newErrors.name = error;
                        } else {
                            newErrors.unknown = error;
                        }
                    } else {
                        newErrors.unknown = "";
                    }
                }
            }
        }
        setErrors(newErrors);
    }

    const handleSignupSSO = async () => {
        setClickType("sso");

        const newErrors = _.clone(initialValueErrors);
        setErrors(newErrors);

        if (!checkboxSSO) {
            newErrors.checkboxSSO = "This field is required."
        }

        if (_.isEqual(newErrors, initialValueErrors) && errors.checkboxSSO === "") {
            try {
                let method = ""
                if (ssoSelected === "Google") {
                    method = "google.com"
                } else if (ssoSelected === "Facebook") {
                    method = "facebook.com"
                }
                const res = await dispatch(signInSSO(method)).unwrap();
                if (res) {
                    navigate(res.route);
                }
            } catch (error: unknown) {
                if (isText(error)) {
                    if (!error.includes("Popup error")) {
                        if (error.includes("account-exists-with-different-credential")) {
                            newErrors.unknown = `This e-mail address is not linked with your ${  ssoSelected  } account. Please login with another provider.`
                        } else {
                            newErrors.unknown = error
                        }
                    } else {
                        newErrors.unknown = "";
                    }
                }
            }
        }
        setErrors(newErrors);
    }
  
    const handleKeypress = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter" && ["name", "email", "password"].includes(activeInput)) {
            await handleSignupPassword();
        }
    };

    const handleSsoSwitch = (ssoProvider: string) => {
        if (!checkboxSSO) {
            const newErrors = errors;
            newErrors.checkboxSSO = "This field is required.";
            setErrors(newErrors);
        }
        setSsoSelected(ssoProvider);
    }

    return (
        <div className={SignupLogin.page_wrapper}>
            <Helmet>
                <title>Sign Up | Traddle.io</title>
                <meta property="og:title" content="Sign Up | Traddle.io" />
            </Helmet>
            <div className={SignupLogin.top_bar_wrapper}>
                <div className={SignupLogin.top_bar_left}>
                    <img
                        alt="Traddle Logo"
                        src="/logos/traddle_logo_comp-200h.png"
                        loading="eager"
                        className={SignupLogin.logo}
                    />
                    <span className={SignupLogin.logo_text}>Traddle</span>
                </div>
                <div className={SignupLogin.top_bar_right}>
                    <span className={SignupLogin.top_bar_right_text}>Already have an account?</span>
                    <Link to={!email ? "/login" : `/login?email=${  email}`}>
                        <button type="button" className={SignupLogin.top_bar_right_button}>Login</button>
                    </Link>
                </div>
            </div>
            <SignupLoginBackground />
            <div className={SignupLogin.form_wrapper}>
                <div className={SignupLogin.form_container}>
                    <h1 className={SignupLogin.header}>Start your free trial.</h1>
                    <p className={SignupLogin.subheader}>Try it out for free for 14 days. No credit card required.</p>
                    <form className={SignupLogin.password_input_container}>
                        <div className={SignupLogin.input_row}>
                            <div className={activeInput === "name" ? `${SignupLogin.input_label} ${SignupLogin.input_label_focus}` : `${SignupLogin.input_label}`}>{name === "" || activeInput === "name" ? "Name" : ""}</div>
                            <div className={SignupLogin.input_field}>
                                <input
                                    type="text"
                                    required
                                    id="name"
                                    name="name"
                                    autoComplete="name"
                                    autoFocus
                                    className={getInputClasses(activeInput, "name", errors.name)}
                                    onChange={(event) => handleNameChange(event)}
                                    value={name}
                                    onKeyDown={handleKeypress}
                                    ref={nameRef}
                                    onFocus={() => handleMouseOut(nameRef)}
                                />
                            </div>
                            <SignUpLoginWarning visible={errors.name !== ""} text={errors.name} />
                        </div>
                        <div className={SignupLogin.input_row}>
                            <div className={activeInput === "email" ? `${SignupLogin.input_label} ${SignupLogin.input_label_focus}` : `${SignupLogin.input_label}`}>{email === "" || activeInput === "email" ? "E-Mail" : ""}</div>
                            <div className={SignupLogin.input_field}>
                                <input
                                    type="text"
                                    required
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    className={getInputClasses(activeInput, "email", errors.email)}
                                    onChange={(event) => handleEmailChange(event)}
                                    value={email}
                                    onKeyDown={handleKeypress}
                                    ref={emailRef}
                                    onFocus={() => handleMouseOut(emailRef)}
                                />
                            </div>
                            <SignUpLoginWarning visible={errors.email !== ""} text={errors.email} />
                        </div>
                        <div className={SignupLogin.input_row}>
                            <div className={activeInput === "password" ? `${SignupLogin.input_label} ${SignupLogin.input_label_focus}` : `${SignupLogin.input_label}`}>{password === "" || activeInput === "password" ? "Password (At least 8 characters)" : ""}</div>
                            <div className={SignupLogin.input_field}>
                                <input
                                    type={ showPassword ? "text" : "password" }
                                    required
                                    id="current-password"
                                    name="current-password"
                                    autoComplete="current-password"
                                    className={getInputClasses(activeInput, "password", errors.password)}
                                    onChange={(event) => handlePasswordChange(event)}
                                    value={password}
                                    onKeyDown={handleKeypress}
                                    ref={passwordRef}
                                    onFocus={() => handleMouseOut(passwordRef)}
                                />
                                <div className={SignupLogin.show_password_wrapper} onClick={() => {setPasswordClicked(true); setShowPassword(!showPassword)}}>
                                    {showPassword && (
                                        <p className={SignupLogin.show_password}>Hide</p>
                                    )}
                                    {!showPassword && (
                                        <p className={SignupLogin.show_password}>Show</p>
                                    )}
                                </div>
                            </div>
                            <SignUpLoginWarning visible={errors.password !== ""} text={errors.password} />
                        </div>
                        <div className={SignupLogin.input_row}>
                            <div className={App.checkbox_row}>
                                <input
                                    type="checkbox"
                                    required
                                    className={App.checkbox}
                                    onChange={handleCeckboxMailChange}
                                    checked={checkboxMail}
                                />
                                {checkboxMail && (<div className={App.checkbox_checked_background} />)}
                                <span className={App.checkbox_label} onClick={handleCeckboxMailChange}>
                                    <span>By clicking this box, I acknowledge that I have read and accept Traddle&apos;s&nbsp;</span>
                                    <a
                                        href="https://traddle.io/terms-of-service"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className={App.checkbox_link}
                                    >Terms of Service</a>
                                    <span>&nbsp;and&nbsp;</span>
                                    <a
                                        href="https://traddle.io/privacy-policy"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className={App.checkbox_link}
                                    >Privacy Policy</a>
                                    <span>.</span>
                                </span>
                            </div>
                            <SignUpLoginWarning visible={errors.checkboxMail !== ""} text={errors.checkboxMail} />
                        </div>
                        <SignUpLoginInfoMessage visible={errors.unknown !== "" && clickType === "password"} color="red" text={errors.unknown} />
                        <button type="button" className={clickType !== "password" || (errors.name === "" && errors.email === "" && errors.password === "" && errors.checkboxMail === "" && name !== "" && email !== "" && password !== "" && checkboxMail) ? `${SignupLogin.submit_button}` : `${SignupLogin.submit_button} ${SignupLogin.button_error}`} onClick={handleSignupPassword} disabled={!(clickType !== "password" || (errors.name === "" && errors.email === "" && errors.password === "" && errors.checkboxMail === "" && name !== "" && email !== "" && password !== "" && checkboxMail))}>
                            {(user.loading && clickType === "password") ? (
                                <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                            ) : (
                                <>Trade with Traddle</>
                            )}
                        </button>
                        <div className={SignupLogin.page_switch}>
                            <span>Already have an account?</span>
                            <Link to={!email ? "/login" : `/login?email=${  email}`} className={SignupLogin.page_switch_link}>Login</Link>
                        </div>
                    </form>
                    <div className={SignupLogin.separator_container}>
                        <div className={SignupLogin.separator_line} />
                        <span className={SignupLogin.separator_text}>or signup with</span>
                        <div className={SignupLogin.separator_line} />
                    </div>
                    <div className={SignupLogin.sso_input_container}>
                        <div className={SignupLogin.sso_icon_row}>
                            <div className={ssoSelected === "Google" ? `${SignupLogin.sso_icon_wrapper} ${SignupLogin.border_primary}` : `${SignupLogin.sso_icon_wrapper}`} onClick={() => handleSsoSwitch("Google")}>
                                <img
                                    alt="Google Logo"
                                    src='/icons/google_icon-200w.png'
                                    className={SignupLogin.sso_icon}
                                />
                            </div>
                            <div className={ssoSelected === "Facebook" ? `${SignupLogin.sso_icon_wrapper} ${SignupLogin.border_primary}` : `${SignupLogin.sso_icon_wrapper}`} onClick={() => handleSsoSwitch("Facebook")}>
                                <img
                                    alt="Facebook Logo"
                                    src='/icons/facebook_icon-200h.png'
                                    className={SignupLogin.sso_icon}
                                />
                            </div>
                        </div>
                        {ssoSelected !== "" && (
                            <div className={SignupLogin.sso_submit_wrapper}>
                                <div className={SignupLogin.input_row}>
                                    <div className={App.checkbox_row}>
                                        <input
                                            type="checkbox"
                                            required
                                            className={App.checkbox}
                                            onChange={handleCeckboxSSOChange}
                                            checked={checkboxSSO}
                                        />
                                        {checkboxSSO && (<div className={App.checkbox_checked_background} />)}
                                        <span className={App.checkbox_label} onClick={handleCeckboxSSOChange}>
                                            <span>By clicking this box, I acknowledge that I have read and accept Traddle&apos;s&nbsp;</span>
                                            <a
                                                href="https://traddle.io/terms-of-service"
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                className={App.checkbox_link}
                                            >Terms of Service</a>
                                            <span>&nbsp;and&nbsp;</span>
                                            <a
                                                href="https://traddle.io/privacy-policy"
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                className={App.checkbox_link}
                                            >Privacy Policy</a>
                                            <span>.</span>
                                        </span>
                                    </div>
                                    <SignUpLoginWarning visible={errors.checkboxSSO !== ""} text={errors.checkboxSSO} />
                                </div>
                                <SignUpLoginInfoMessage visible={errors.unknown !== "" && clickType === "sso"} color="red" text={errors.unknown} />
                                <button type="button" className={checkboxSSO ? `${SignupLogin.submit_button}` : `${SignupLogin.submit_button} ${SignupLogin.button_error}`} disabled={!checkboxSSO} onClick={handleSignupSSO}>
                                    {(user.loading && clickType === "sso") ? (
                                        <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                                    ) : (
                                        <>{`Sign up with ${ssoSelected}`}</>
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp