import { IndicatorParameters } from "../../../../types/Screener";
import CriteriaParameterDollarChange from '../elements/parameters/criteria-parameter-dollar-change';
import CriteriaParameterDollarValue from '../elements/parameters/criteria-parameter-dollar-value';
import CriteriaParameterNumber from '../elements/parameters/criteria-parameter-number';
import CriteriaParameterPercentChange from '../elements/parameters/criteria-parameter-percent-change';
import CriteriaParameterPercentValue from '../elements/parameters/criteria-parameter-percent-value';
import CriteriaParameterRangeValue from '../elements/parameters/criteria-parameter-range-value';
import CriteriaParameterRatio from '../elements/parameters/criteria-parameter-ratio';
import CriteriaParameterTimeRange from '../elements/parameters/criteria-parameter-time-range';
import CriteriaParameterATR from '../elements/parameters/indicators/criteria-parameter-atr';
import CriteriaParameterBollinger from "../elements/parameters/indicators/criteria-parameter-bollinger";
import CriteriaParameterEMA from "../elements/parameters/indicators/criteria-parameter-ema";
import CriteriaParameterMACD from '../elements/parameters/indicators/criteria-parameter-macd';
import CriteriaParameterRSI from '../elements/parameters/indicators/criteria-parameter-rsi';
import CriteriaParameterSMA from "../elements/parameters/indicators/criteria-parameter-sma";
import CriteriaParameterStochastic from '../elements/parameters/indicators/criteria-parameter-stochastic';
import CriteriaParameterVolatility from '../elements/parameters/indicators/criteria-parameter-volatility';
import CriteriaParameterAH from "../elements/parameters/price/criteria-parameter-ah-ohlc";
import CriteriaParameterChange from '../elements/parameters/price/criteria-parameter-change';
import CriteriaParameterLastCandle from '../elements/parameters/price/criteria-parameter-last-candle';
import CriteriaParameterPM from '../elements/parameters/price/criteria-parameter-pm-ohlc';
import CriteriaParameterMarketStatus from '../elements/parameters/time/criteria-parameter-market-status';
import CriteriaParameterTimeOfDay from '../elements/parameters/time/criteria-parameter-time-of-day';
import CriteriaParameterAverageVolume from '../elements/parameters/volume/criteria-parameter-average-volume';
import CriteriaParameterVolumeCandle from '../elements/parameters/volume/criteria-parameter-volume-candle';

type Props = {
  id: string;
  unit: string;
  parameters: IndicatorParameters;
  setParameters: (parameters: IndicatorParameters) => void;
}

const SelectedComparison: React.FC<Props> = (props) => {
    switch (props.id) {
    case "number": return <CriteriaParameterNumber parameters={props.parameters} setParameters={props.setParameters} unit={props.unit} />
    case "dollar-value": return <CriteriaParameterDollarValue parameters={props.parameters} setParameters={props.setParameters} />
    case "dollar-change": return <CriteriaParameterDollarChange parameters={props.parameters} setParameters={props.setParameters} />
    case "percent-value": return <CriteriaParameterPercentValue parameters={props.parameters} setParameters={props.setParameters} />
    case "percent-change": return <CriteriaParameterPercentChange parameters={props.parameters} setParameters={props.setParameters} />
    case "range-value": return <CriteriaParameterRangeValue parameters={props.parameters} setParameters={props.setParameters} />
    case "ratio": return <CriteriaParameterRatio parameters={props.parameters} setParameters={props.setParameters} />
    case "time-range": return <CriteriaParameterTimeRange parameters={props.parameters} setParameters={props.setParameters} />
    case "time-of-day": return <CriteriaParameterTimeOfDay parameters={props.parameters} setParameters={props.setParameters} />
    case "market-status": return <CriteriaParameterMarketStatus parameters={props.parameters} setParameters={props.setParameters} />
    case "price-last-full-candle": return <CriteriaParameterLastCandle parameters={props.parameters} setParameters={props.setParameters} />
    case "price-pm-ohlc": return <CriteriaParameterPM parameters={props.parameters} setParameters={props.setParameters} />
    case "price-ah-ohlc": return <CriteriaParameterAH parameters={props.parameters} setParameters={props.setParameters} />
    case "price-change": return <CriteriaParameterChange parameters={props.parameters} setParameters={props.setParameters} />
    case "volume-average-volume": return <CriteriaParameterAverageVolume parameters={props.parameters} setParameters={props.setParameters} titleWidth="80px" fieldWidth="70px" />
    case "volume-candle": return <CriteriaParameterVolumeCandle parameters={props.parameters} setParameters={props.setParameters} />
    case "indicators-sma": return <CriteriaParameterSMA parameters={props.parameters} setParameters={props.setParameters} titleWidth="80px" fieldWidth="70px" />
    case "indicators-ema": return <CriteriaParameterEMA parameters={props.parameters} setParameters={props.setParameters} titleWidth="80px" fieldWidth="70px" />
    case "indicators-bollinger-bands": return <CriteriaParameterBollinger parameters={props.parameters} setParameters={props.setParameters} titleWidth="80px" fieldWidth="70px" />
    case "indicators-macd": return <CriteriaParameterMACD parameters={props.parameters} setParameters={props.setParameters} titleWidth="160px" fieldWidth="70px" referenceOptions={["Value", "Signal Line", "Histogram"]} />
    case "indicators-atr": return <CriteriaParameterATR parameters={props.parameters} setParameters={props.setParameters} titleWidth="80px" fieldWidth="70px" />
    case "indicators-volatility": return <CriteriaParameterVolatility parameters={props.parameters} setParameters={props.setParameters} titleWidth="80px" fieldWidth="70px" />
    case "indicators-rsi": return <CriteriaParameterRSI parameters={props.parameters} setParameters={props.setParameters} titleWidth="80px" fieldWidth="70px" />
    case "indicators-stochastic": return <CriteriaParameterStochastic parameters={props.parameters} setParameters={props.setParameters} titleWidth="80px" fieldWidth="70px" referenceOptions={["%K, %D"]} />
    default: return <CriteriaParameterNumber parameters={props.parameters} setParameters={props.setParameters} unit={props.unit} />
    }
}

export default SelectedComparison