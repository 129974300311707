import React from "react";

import WidgetStyles from "../../../../WidgetStyles.module.css"
import TableIposRowItem from "./table-ipos-row-item";

const TableIposList: React.FC = () => (
    <div className={WidgetStyles.simple_table_wrapper_content}>
        <div className={WidgetStyles.simple_table_wrapper_scroll}>
            <TableIposRowItem />
            <TableIposRowItem />
            <TableIposRowItem />
        </div>
    </div>
)

export default TableIposList