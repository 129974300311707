import TabDividends from "./dividends/tab-dividends";
import TabEarnings from "./earnings/tab-earnings";
import TabEconomy from "./economy/tab-economy";
import TabIpos from "./ipos/tab-ipos";

type Props = {
    id: string;
    tabName: string;
    width: number;
}

const EconomicCalendarTabSwitch: React.FC<Props> = (props): JSX.Element => {
    switch(props.tabName) {
    case "Economy": return <TabEconomy id={props.id} width={props.width} />;
    case "Earnings": return <TabEarnings id={props.id} />;
    case "Dividends": return <TabDividends id={props.id} />;
    case "Upcoming IPOs": return <TabIpos id={props.id} />;
    default: return <></>;
    }
}

export default EconomicCalendarTabSwitch