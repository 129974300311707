import React from "react";

import WidgetStyles from "../../../../WidgetStyles.module.css"
import TableIposHeader from "./table-ipos-header";
import TableIposList from "./table-ipos-list";
import TableIposSeparator from "./table-ipos-separator";

const TableIpos: React.FC = () => (
    <div className={WidgetStyles.simple_table_wrapper}>
        <TableIposHeader />
        <TableIposSeparator />
        <TableIposList />
    </div>
)

export default TableIpos