import { Widget } from "../../../../types/Dashboard";

const getNewWidgets = (layoutNew: ReactGridLayout.Layout[], widgets: Widget[]) => {
    layoutNew.forEach((newWidgetLayout: ReactGridLayout.Layout) => {
        const index = widgets.findIndex((w) => w.id === newWidgetLayout.i)
        if (index > -1) {
            Object.keys(newWidgetLayout).forEach(key => newWidgetLayout[key as keyof ReactGridLayout.Layout] === undefined ? delete newWidgetLayout[key as keyof ReactGridLayout.Layout] : {});
            widgets.splice(index, 1, { ...widgets[index], layout: newWidgetLayout })
        }
    })
    return widgets
}

export default getNewWidgets