import { useNavigate } from "react-router-dom";

import AppTopNavMiddleStyles from "../AppTopNavMiddleStyles.module.css";

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setPopup: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
    styles: { [key: string]: React.CSSProperties };
    attributes: {
        [key: string]: {
            [key: string]: string;
        } | undefined;
    }
}

const AppTopNavButtonNewsMenu: React.FC<Props> = (props) => {
    const navigate = useNavigate();

    const buttonClickNewsstream = () => {
        navigate(`/app`);
        props.setIsOpen(false);
    }

    if (props.isOpen) {
        return (
            <div className={AppTopNavMiddleStyles.middle_item_popup_container} ref={props.setPopup} style={props.styles.popper} {...props.attributes.popper}>
                <div className={AppTopNavMiddleStyles.middle_item_popup}>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_top} onClick={buttonClickNewsstream}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_top_title}>News Stream</span>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_top_text}>Receive the lastest news as soon as they are released! Group them according to your preferences!</span>
                        <div className={AppTopNavMiddleStyles.middle_item_popup_top_button}>
                            <span className={AppTopNavMiddleStyles.middle_item_popup_top_button_title}>Create News Group</span>
                        </div>
                    </div>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_separator} />
                    <div className={AppTopNavMiddleStyles.middle_item_popup_button}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>Press Releases</span>
                    </div>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_button}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>Publishers</span>
                    </div>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_button}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>SEC Filings</span>
                    </div>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_separator} />
                    <div className={AppTopNavMiddleStyles.middle_item_popup_button}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>Template Center</span>
                        <svg viewBox="0 0 1024 1024" className={AppTopNavMiddleStyles.middle_item_popup_button_icon}>
                            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z" />
                        </svg>
                    </div>
                </div>
            </div>
        )
    }
    return null
}

export default AppTopNavButtonNewsMenu