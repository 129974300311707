import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../types/Screener';
import CriteriaInputNumber from '../criteria-input-number';
import checkUnusedParams from '../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        rangeValue: "100",
    },
    setParameters: () => {},
}

const CriteriaParameterRangeValue: React.FC<Props> = (props) => {
    const number = useMemo(() => props.parameters.rangeValue ? props.parameters.rangeValue : defaultProps.parameters.rangeValue!, [props.parameters.rangeValue])

    const setNumber = (newNumber: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, rangeValue: newNumber }, defaultProps.parameters))
    }

    return (
        <CriteriaInputNumber number={number} setNumber={setNumber} min={0} max={100} step="1" width="70px" />
    )
}

export default CriteriaParameterRangeValue