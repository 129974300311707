import { useState } from "react";

import { changeUserData, saveUser } from "../../../../../store/features/user";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import ToggleSwitch from "../../../../../utils/components/toggle-switch/toggle-switch";
import AppTopNavProfileMenuStyles from "./AppTopNavProfileMenuStyles.module.css"

const ThemeSwitch: React.FC = () => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();

    const [isHovered, setIsHovered] = useState(false);

    const themeChange = () => {
        dispatch(changeUserData({
            theme: {
                ...user.userData.theme,
                dark: !user.userData.theme.dark
            }
        }))
        dispatch(saveUser());
    }

    return (
        <div className={AppTopNavProfileMenuStyles.theme_toggle_row} style={{ color: isHovered ? "rgb(var(--dl-color-primary-100))" : "" }} onClick={themeChange} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <span className={AppTopNavProfileMenuStyles.profile_menu_bottom_text}>Dark Mode</span>
            <ToggleSwitch isToggled={user.userData.theme.dark} />
        </div>
    )
}

export default ThemeSwitch