import { useEffect, useRef } from 'react'

import { changeUserData, saveUser } from '../../store/features/user';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import layoutSizes from '../constants/layout-sizes';

const useResizeRightScreenerList = (): {resizableRef: React.MutableRefObject<HTMLDivElement | null>, resizeHandlerRef: React.MutableRefObject<HTMLDivElement | null>} => {
    const dashboardScreenerListWidth = useAppSelector((state) => state.user.userData.dashboardScreenerListWidth)
    const dispatch = useAppDispatch();

    const resizableRef = useRef<HTMLDivElement | null>(null);
    const resizeHandlerRef = useRef<HTMLDivElement | null>(null);

    const setWidth = (width: number) => {
        dispatch(changeUserData({
            dashboardScreenerListWidth: width
        }));
        dispatch(saveUser());
    }

    useEffect(() => {
        const resizeableEle = resizableRef.current;

        if (!resizeableEle) {
            return
        }

        let width = dashboardScreenerListWidth;
        let widthCompare = width;
        let x = 0;

        resizeableEle.style.width = `${width}px`;
        
        const onMouseMoveRightResize = (event: MouseEvent) => {
            const dx = event.clientX - x;
            if (!(resizeableEle.clientWidth <= layoutSizes.dashboardScreenerListWidthMin && dx < 0) && !(resizeableEle.clientWidth >= layoutSizes.dashboardScreenerListWidthMax && dx > 0)) {
                width += dx;
                if (width > layoutSizes.dashboardScreenerListWidthMax) {
                    width = layoutSizes.dashboardScreenerListWidthMax
                } else if (width < layoutSizes.dashboardScreenerListWidthMin) {
                    width = layoutSizes.dashboardScreenerListWidthMin
                }
                x = event.clientX;
                resizeableEle.style.width = `${width}px`;
            }
        };
    
        const onMouseUpRightResize = () => {
            document.body.classList.remove("noselect");
            const appTopNavEle = document.getElementById("app-top-nav");
            if (appTopNavEle) appTopNavEle.classList.remove("pointer_events_none");
            document.removeEventListener("mousemove", onMouseMoveRightResize);
            if (widthCompare !== width) {
                setWidth(width)
                widthCompare = width
            }
        };
    
        const onMouseDownRightResize = (event: MouseEvent) => {
            width = Number(resizeableEle.style.width.replace("px", ""))
            widthCompare = width
            document.body.classList.add("noselect");
            const appTopNavEle = document.getElementById("app-top-nav");
            if (appTopNavEle) appTopNavEle.classList.add("pointer_events_none");
            x = event.clientX;
            const newStyles = window.getComputedStyle(resizeableEle);
            resizeableEle.style.right = newStyles.right;
            resizeableEle.style.left = "";
            document.addEventListener("mousemove", onMouseMoveRightResize);
            document.addEventListener("mouseup", onMouseUpRightResize);
        };

        const resizerRight = resizeHandlerRef.current;
        
        if (!resizerRight) {
            return
        }

        resizerRight.addEventListener("mousedown", onMouseDownRightResize);
        
        return () => {
            resizerRight.removeEventListener("mousedown", onMouseDownRightResize);
        };
    }, [])

    return { resizableRef, resizeHandlerRef };
};

export default useResizeRightScreenerList