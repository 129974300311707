import { useEffect, useRef, useState } from "react";

import { changeDashboardData } from "../../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../../types/changeHandlers/change-dashboard-widget";
import { TableSettings } from "../../../../../../types/Dashboard";
import createColumn, { ColumnBuilder } from "../../../../../constants/table-columns";
import { buildColumnHeader, buildColumnId } from "../../../../../functions/build_column_header_id";
import BootstrapTooltip from "../../../../tooltip/bootstrap-tooltip";
import TableTopRowButtonColumnRowOption from "./table-top-row-button-column-row-option";
import TableTopRowButtonColumnStyle from "./TableTopRowButtonColumn.module.css"

type Props = {
    widgetId: string;
    columnBuilderOption: ColumnBuilder;
    settings: TableSettings;
}

const TableTopRowButtonColumnRow: React.FC<Props> = (props) => {
    const [isClicked, setIsClicked] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [header, setHeader] = useState(buildColumnHeader(props.columnBuilderOption.header, props.columnBuilderOption.parameters));
    const [id, setId] = useState(buildColumnId(props.columnBuilderOption.id, props.columnBuilderOption.parameters));

    const buttonRef = useRef<HTMLDivElement | null>(null);
        
    const dispatch = useAppDispatch();

    const dashboard = useAppSelector((state) => state.dashboard)
   
    const addColumn = () => {
        setIsClicked(false)
        const newColumns = [ ...props.settings.columns ]
        newColumns.splice(newColumns.length - 1, 1)
        if (newColumns.map(c => c.accessor).includes(id)) {
            return
        }
        newColumns.push(createColumn(header, id))
        newColumns.push(createColumn("Dummy", "dummy", { minWidth: 26, width: 26, maxWidth: 26 }))
        const newSettings = {
            ...props.settings,
            columns: newColumns,
            selectedColumnPreset: ""
        }
        const newWidgets = changeDashboardWidgetData(props.widgetId, dashboard.data.widgets, { settings: newSettings })
        dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
    }

    const getBgColor = () => {
        if (isHovered && !isClicked) return "rgb(var(--dl-color-backgrounds-hover2))"
        if (isClicked) return "transparent"
        return ""
    }

    const getBorderColor = () => {
        if (isHovered && !isClicked) return "rgb(var(--dl-color-backgrounds-hover2))"
        if (isClicked) return "rgb(var(--dl-color-backgrounds-hover3))"
        return ""
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (buttonRef.current && !buttonRef.current.contains(event.target as HTMLElement)) {
                setIsClicked(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [buttonRef]);

    return (
        <div className={TableTopRowButtonColumnStyle.added_column_row}>
            <div className={TableTopRowButtonColumnStyle.column_wrapper_left} ref={buttonRef}>
                <div className={TableTopRowButtonColumnStyle.added_column_button} style={{ cursor: !isClicked ? "pointer" : "", backgroundColor: getBgColor(), borderColor: getBorderColor() }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={() => setIsClicked(true)}>
                    <span className={TableTopRowButtonColumnStyle.added_column_button_text}>{props.columnBuilderOption.header}</span>
                    {isClicked && props.columnBuilderOption.parameters ? <div className={TableTopRowButtonColumnStyle.added_column_button_separator} /> : null}
                    {isClicked ? (
                        props.columnBuilderOption.parameters?.map((parameter, index) => <TableTopRowButtonColumnRowOption header={header} setHeader={setHeader} id={id} setId={setId} parameter={parameter} index={index} key={index}/>)
                    ) : null }
                </div>
                {isClicked && !props.settings.columns.map(c => c.accessor).includes(id) ? (
                    <div className={`${TableTopRowButtonColumnStyle.added_column_add_button}`} onClick={() => addColumn()}>
                        <span className={TableTopRowButtonColumnStyle.added_column_add_button_text}>Add</span>
                    </div>
                ) : null}
                {isClicked && props.settings.columns.map(c => c.accessor).includes(id) ? (
                    <BootstrapTooltip title="This column is already added">
                        <div className={`${TableTopRowButtonColumnStyle.added_column_add_button} ${TableTopRowButtonColumnStyle.blue_button_disabled}`}>
                            <span className={TableTopRowButtonColumnStyle.added_column_add_button_text} style={{ color: "rgba(var(--dl-color-text-black), 0.7)" }}>Add</span>
                        </div>
                    </BootstrapTooltip>
                ) : null}
            </div>
        </div>
    )
}

export default TableTopRowButtonColumnRow