import { changeScreenerData, saveScreener } from "../../../../../../../store/features/screener";
import { useAppDispatch, useAppSelector } from "../../../../../../../store/hooks";
import { CriteriaGroup, State } from "../../../../../../../types/Screener";
import DashboardScreenerDetailsStyles from "../../DashboardScreenerDetails.module.css"
import DashboardScreenerBuilderCriteriaGroup from "./dashboard-screener-builder-criteria-group";
import DashboardScreenerBuilderCriteriaStyles from "./DashboardScreenerBuilderCriteria.module.css";

type Props = {
    selectedState: State;
    setNewState: React.Dispatch<React.SetStateAction<number | null>>;
}

const DashboardScreenerBuilderCriteria: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const screener = useAppSelector((state) => state.screener)
    const position = screener.data.states.findIndex((state) => state.id === props.selectedState.id)

    const handleChange = (newCriteria: CriteriaGroup, pos: number, noSave: boolean) => {
        const newState = { ...props.selectedState };
        newState.criteria = newCriteria
        const states = [...screener.data.states];
        const statePosition = screener.data.states.findIndex(((op: State) => op.id === props.selectedState.id))
        states.splice(statePosition, 1, newState);
        dispatch(changeScreenerData({
            ...screener.data,
            states,
        }));
        if (noSave) {
            return
        }
        dispatch(saveScreener());
    }

    const handleDelete = () => {
        const newState = { ...props.selectedState };
        newState.criteria = {
            ...newState.criteria,
            criteriaList: [],
        }
        const states = [...screener.data.states];
        states.splice(position, 1, newState);
        dispatch(changeScreenerData({
            ...screener.data,
            states,
        }));
        dispatch(saveScreener());
    }

    if (position > -1) {
        return (
            <div className={DashboardScreenerDetailsStyles.conditions_container}>
                <div className={DashboardScreenerDetailsStyles.title_row}>
                    <span className={DashboardScreenerDetailsStyles.title_text}>Conditions</span>
                </div>
                <div className={DashboardScreenerDetailsStyles.conditions_criteria_wrapper}>
                    <div className={DashboardScreenerBuilderCriteriaStyles.criteria_container}>
                        <DashboardScreenerBuilderCriteriaGroup level={1} position={0} state={props.selectedState} group={props.selectedState.criteria} setChildHovered={() => {}} handleChange={handleChange} handleDelete={handleDelete}/>
                    </div>
                </div>
                <div className={DashboardScreenerDetailsStyles.conditions_bottom_row}>
                    <button type="button" className={DashboardScreenerDetailsStyles.conditions_add_state_button} onClick={() => props.setNewState(screener.data.states.length)}>Add another state</button>
                </div>
            </div>
        )
    }

    return null
}

export default DashboardScreenerBuilderCriteria