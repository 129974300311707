import { useNavigate } from "react-router-dom";

import { createNewScreener } from "../../../../../store/features/screener";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import AppTopNavCreateButtonStyles from "./AppTopNavCreateButtonStyles.module.css"

type Props = {
    setPopupOpen: (setOpen: boolean) => void;
}

const AppTopNavCreateNewScreener: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const allScreeners = useAppSelector((state) => state.allScreeners)

    const navigate = useNavigate();

    const newScreenerClick = async () => {
        try {
            const res = await dispatch(createNewScreener({
                name: `Screener ${Object.keys(allScreeners.screeners).length + 1}`,
            })).unwrap();
            props.setPopupOpen(false);
            if (res) {
                navigate(`/app/screeners/${res.id}`);
            }
        } catch (error) {
            navigate(`/app/screeners`);
        }
    }

    return (
        <div className={AppTopNavCreateButtonStyles.create_button_popup_item} onClick={() => newScreenerClick()}>
            <svg viewBox="0 0 1024 1024" className={AppTopNavCreateButtonStyles.create_button_popup_item_icon}>
                <path d="M853.333 768c0 23.595-9.515 44.843-25.003 60.331s-36.736 25.003-60.331 25.003-44.843-9.515-60.331-25.003-25.003-36.736-25.003-60.331 9.515-44.843 25.003-60.331 36.736-25.003 60.331-25.003 44.843 9.515 60.331 25.003 25.003 36.736 25.003 60.331zM341.333 256c0 23.595-9.515 44.843-25.003 60.331s-36.736 25.003-60.331 25.003-44.843-9.515-60.331-25.003-25.003-36.736-25.003-60.331 9.515-44.843 25.003-60.331 36.736-25.003 60.331-25.003 44.843 9.515 60.331 25.003 25.003 36.736 25.003 60.331zM554.667 298.667h128c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v261.376c-29.995 7.723-56.832 23.424-77.995 44.587-30.848 30.848-50.005 73.6-50.005 120.704s19.157 89.856 50.005 120.661 73.557 50.005 120.661 50.005 89.856-19.157 120.661-50.005 50.005-73.557 50.005-120.661-19.157-89.856-50.005-120.661c-21.205-21.205-48-36.864-77.995-44.587v-261.419c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM213.333 421.291v474.709c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-474.709c29.995-7.723 56.832-23.424 77.995-44.587 30.848-30.848 50.005-73.6 50.005-120.704s-19.157-89.856-50.005-120.661-73.557-50.005-120.661-50.005-89.856 19.157-120.661 50.005-50.005 73.557-50.005 120.661 19.157 89.856 50.005 120.661c21.163 21.205 48 36.907 77.995 44.629z" />
            </svg>
            <span className={AppTopNavCreateButtonStyles.create_button_popup_item_text}>Create New Screener</span>
        </div>
    )
}

export default AppTopNavCreateNewScreener