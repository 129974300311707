import { useEffect, useMemo } from "react";

import DashboardScreenerBuilderCondition from "../../../../../pages/app/dashboards/screener/details/builder/criteria/condition-popup/dashboard-screener-builder-condition";
import { setPopupElement } from "../../../../../store/features/management";
import { saveScreener } from "../../../../../store/features/screener";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { FilterAlertRowProps } from "../../../../../types/FiltersAlerts";
import { IndicatorParameters } from "../../../../../types/Screener";
import { operators } from "../../../../constants/operators";
import { findRow } from "../../../../functions/deep-splice-criteria";
import ConditionRow from "../../ConditionRow.module.css"
import CriteriaDropdown from "../../elements/criteria-dropdown";
import CriteriaParameterGrossMargin from "../../elements/parameters/fundamentals/criteria-parameter-gross-margin";
import parameterChange, { checkIdB, checkOperator } from "../functions";
import SelectedComparison from "../selected-comparison";

const ConditionRowFundamentalsGrossMargin: React.FC<FilterAlertRowProps> = (props) => {
    const dispatch = useAppDispatch();
    const management = useAppSelector((state) => state.management);
    const screener = useAppSelector((state) => state.screener);

    const state = useMemo(() => {
        const newState = screener.data.states.find((s) => s.id === props.state.id)
        if (typeof newState !== "undefined") {
            return newState
        }
        return props.state
    }, [screener.data.states, props.state])

    const row = useMemo(() => {
        const newRow = findRow(state.criteria.criteriaList, props.row.id)
        if (typeof newRow !== "undefined" && newRow !== null) {
            return newRow
        }
        return props.row
    }, [state, props.row.id])

    const settings = useMemo(() => row.settings, [row])

    useEffect(() => {
        if (management.conditionExpandedCard === props.id && management.conditionExpandedCard !== settings.idA) {
            let newSettings = { ...settings, idA: props.id }
            newSettings = checkIdB(props.type, props.comparisons, newSettings)
            newSettings = checkOperator(newSettings)
            parameterChange(dispatch, newSettings, screener, state, row)
        }
    }, [management.conditionExpandedCard])

    const setIdB1 = (idB1: string) => {
        if (props.id === settings.idA) {
            parameterChange(dispatch, { ...settings, idB1 }, screener, state, row)
            if (management.popupElement === null) {
                dispatch(saveScreener())
            }
        }
    }

    const setIdB2 = (idB2: string) => {
        if (props.id === settings.idA) {
            parameterChange(dispatch, { ...settings, idB2 }, screener, state, row)
            if (management.popupElement === null) {
                dispatch(saveScreener())
            }
        }
    }

    const setOperator = (operator: string) => {
        if (props.id === settings.idA) {
            parameterChange(dispatch, { ...settings, operator }, screener, state, row)
            if (management.popupElement === null) {
                dispatch(saveScreener())
            }
        }
    }

    const setParametersA = (parametersA: IndicatorParameters) => {
        if (props.id === settings.idA) {
            parameterChange(dispatch, { ...settings, parametersA }, screener, state, row)
            if (management.popupElement === null) {
                dispatch(saveScreener())
            }
        }
    }

    const setParametersB1 = (parametersB1: IndicatorParameters) => {
        if (props.id === settings.idA) {
            parameterChange(dispatch, { ...settings, parametersB1 }, screener, state, row)
            if (management.popupElement === null) {
                dispatch(saveScreener())
            }
        }
    }

    const setParametersB2 = (parametersB2: IndicatorParameters) => {
        if (props.id === settings.idA) {
            parameterChange(dispatch, { ...settings, parametersB2 }, screener, state, row)
            if (management.popupElement === null) {
                dispatch(saveScreener())
            }
        }
    }

    return (
        <div className={ConditionRow.criteria_condition}>
            <div className={ConditionRow.criteria_category_wrapper}>
                <div className={ConditionRow.criteria_category_type_box} style={{ borderColor: "rgb(59, 134, 75)", pointerEvents: management.popupElement !== null ? "none" : "auto" }} onClick={() => dispatch(setPopupElement(<DashboardScreenerBuilderCondition state={state} row={row} />))}>
                    <span className={ConditionRow.criteria_category_type_text}>Gross Margin</span>
                </div>
                <CriteriaParameterGrossMargin parameters={settings.parametersA} setParameters={setParametersA} reportPeriodOptions={["Quarterly Report", "Annual Report"]} />
            </div>
            {props.type === "filter" && (<span className={ConditionRow.criteria_text}>is</span>)}
            <CriteriaDropdown dropdownOptions={operators[props.type]} transformType="" value={settings.operator} setValue={setOperator} />
            <div className={ConditionRow.criteria_condition_group}>
                <CriteriaDropdown dropdownOptions={props.comparisons} transformType="comparisons" value={settings.idB1} setValue={setIdB1} />
                <SelectedComparison id={settings.idB1} unit="" parameters={settings.parametersB1} setParameters={setParametersB1} />
            </div>
            {settings.idB2 !== "" && (
                <span className={ConditionRow.criteria_text}>and</span>
            )}
            {settings.idB2 !== "" && (
                <div className={ConditionRow.criteria_condition_group}>
                    <CriteriaDropdown dropdownOptions={props.comparisons} transformType="comparisons" value={settings.idB2} setValue={setIdB2} />
                    <SelectedComparison id={settings.idB2} unit="" parameters={settings.parametersB2} setParameters={setParametersB2} />
                </div>
            )}
        </div>
    )
}

export default ConditionRowFundamentalsGrossMargin