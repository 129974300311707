/* eslint-disable @typescript-eslint/no-unused-expressions, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collection, deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import _ from 'lodash';

import { firestoreDB } from "../../firebase/firebase-config";
import { createDashboard } from "../../types/creators/create-dashboard";
import { Dashboard } from "../../types/Dashboard";
import { getDefaultColumns } from "../../utils/constants/table-columns";
import { UserState } from "./user";

const namespace = "dashboard"

export type DashboardState = {
    loading: boolean;
    unsavedChanges: boolean;
    saveLoading: boolean;
    getLoading: boolean;
    errorMessage: string;
    data: Dashboard;
}

const initialStateValue: DashboardState = {
    loading: false,
    unsavedChanges: false,
    saveLoading: false,
    getLoading: false,
    errorMessage: "",
    data: {
        id: "",
        name: "",
        description: "",
        width: 600,
        adminUid: "",
        access: [],
        widgets: [],
        screenerTableSettings: {
            columns: getDefaultColumns(),
            filters: {},
            selectedColumnPreset: ""
        }
    }
}

export const setDashboard = createAsyncThunk(`${namespace}/setDashboard`, async (id: string, { getState, rejectWithValue }) => {
    try {
        const { user } = getState() as { user: UserState };
        const userId: string = user.userData.uid
        const docRef = await getDoc(doc(firestoreDB, "users", userId, "dashboards", id));
        if (docRef.exists()) {
            return {
                ...docRef.data(),
                id: docRef.id
            }
        } 
        return initialStateValue.data
        
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
})

export const getDashboard = createAsyncThunk(`${namespace}/getDashboard`, async (id: string, { getState, rejectWithValue }) => {
    try {
        const { user } = getState() as { user: UserState };
        const docRef = await getDoc(doc(firestoreDB, "users", user.userData.uid, "dashboards", id));
        if (docRef.exists()) {
            return {
                ...docRef.data(),
                id: docRef.id
            } as Dashboard
        } 
        return initialStateValue.data
        
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
})

export const createNewDashboard = createAsyncThunk(`${namespace}/createNewDashboard`, async (data: Partial<Dashboard>, { getState, rejectWithValue }) => {
    try {
        const { user } = getState() as { user: UserState };
        const docRef = doc(collection(firestoreDB, "users", user.userData.uid, "dashboards"));
        const newData = {
            ...initialStateValue.data,
            ...createDashboard(docRef.id, user.userData.uid, data)
        }
        await setDoc(docRef, newData);
        return newData
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
})

export const deleteDashboard = createAsyncThunk(`${namespace}/deleteDashboard`, async (id: string, { getState, rejectWithValue }) => {
    try {
        const { user } = getState() as { user: UserState };
        await deleteDoc(doc(firestoreDB, "users", user.userData.uid, "dashboards", id));
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
})

export const saveDashboard = createAsyncThunk(`${namespace}/saveDashboard`, async (data, { getState, rejectWithValue }) => {
    try {
        const { dashboard } = getState() as { dashboard: DashboardState };
        const { user } = getState() as { user: UserState };
        const newData: Partial<Dashboard> = _.cloneDeep(dashboard.data);
        await updateDoc(doc(firestoreDB, "users", user.userData.uid, "dashboards", dashboard.data.id), { ...newData });
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
})

export const dashboardSlice = createSlice({
    name: namespace,
    initialState: initialStateValue,
    reducers: {
        changeDashboardData: (state, action: PayloadAction<Partial<Dashboard>>) => {
            state.loading = false
            state.errorMessage = ""
            state.unsavedChanges = true
            state.data = { ...state.data, ...action.payload }
        },
        resetDashboard: (state) => {
            state.loading = false
            state.errorMessage = ""
            state.unsavedChanges = false
            state.data = initialStateValue.data
        },
        resetDashboardError: (state) => {
            state.errorMessage = ""
        },
    },
    extraReducers: (builder) => { builder
        // setDashboard
        .addCase(setDashboard.pending, (state) => {
            state.loading = true
        })
        .addCase(setDashboard.fulfilled, (state, action) => {
            state.loading = false
            state.unsavedChanges = false
            state.data = { ...initialStateValue.data, ...action.payload }
        })
        .addCase(setDashboard.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // getDashboard
        .addCase(getDashboard.pending, (state) => {
            state.getLoading = true
        })
        .addCase(getDashboard.fulfilled, (state) => {
            state.getLoading = false
        })
        .addCase(getDashboard.rejected, (state, action: PayloadAction<any>) => {
            state.getLoading = false
            state.errorMessage = action.payload
        })
        // createNewDashboard
        .addCase(createNewDashboard.pending, (state) => {
            state.loading = true
        })
        
        .addCase(createNewDashboard.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data = action.payload
        })
        .addCase(createNewDashboard.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.unsavedChanges = false
            state.errorMessage = action.payload ? action.payload : initialStateValue.errorMessage
        })
        
        // deleteDashboard
        .addCase(deleteDashboard.pending, (state) => {
            state.loading = true
        })
        .addCase(deleteDashboard.fulfilled, (state) => {
            state.loading = false
            state.unsavedChanges = false
            state.data = initialStateValue.data
        })
        .addCase(deleteDashboard.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // saveDashboard
        .addCase(saveDashboard.pending, (state) => {
            state.saveLoading = true
        })
        .addCase(saveDashboard.fulfilled, (state) => {
            state.saveLoading = false
            state.unsavedChanges = false
        })
        .addCase(saveDashboard.rejected, (state, action: PayloadAction<any>) => {
            state.saveLoading = false
            state.errorMessage = action.payload
        })
    }
})

export const { changeDashboardData, resetDashboard, resetDashboardError } = dashboardSlice.actions;

export default dashboardSlice.reducer;