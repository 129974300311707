import { RefObject, useEffect, useState } from 'react'

const useIntersection = (element: RefObject<HTMLButtonElement | HTMLDivElement>, rootMargin: string): boolean => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            }, { rootMargin }
        );
        
        if (element.current) {
            observer.observe(element.current);
        }
    }, []);

    return isVisible;
};

export default useIntersection