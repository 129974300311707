import { createPortal } from "react-dom";

import { useAppSelector } from "../../../../../../../../store/hooks";
import { EconomicCalendarSettings, Widget } from "../../../../../../../../types/Dashboard";
import usePopperPopup from "../../../../../../../hooks/use-popper-popup";
import BootstrapTooltip from "../../../../../../tooltip/bootstrap-tooltip";
import WidgetTopRowStyles from "../../../../../WidgetTopRowStyles.module.css"
import EconomyButtonPriorityItem from "./economy-button-priority-item";

type Props = {
    id: string
}

const EconomyButtonPriority: React.FC<Props> = (props) => {
    const widget: Widget = useAppSelector((state) => state.dashboard.data.widgets.find((w) => w.id === props.id))!

    const { setButton, setPopup, buttonRef, styles, attributes, isOpen, setIsOpen } = usePopperPopup({ offsetY: 4 });

    const settings = widget.settings as EconomicCalendarSettings

    const { priorities } = settings.economy

    return (
        <div className="popper_wrapper" ref={buttonRef}>
            <BootstrapTooltip title="Select priority">
                <div className={WidgetTopRowStyles.button} ref={setButton} onClick={() => setIsOpen(!isOpen)} style={{ borderColor: priorities.length !== 0 || isOpen ? "rgb(var(--dl-color-primary-100))" : "", backgroundColor: priorities.length !== 0 ? "rgba(var(--dl-color-primary-100), 0.09)" : "" }} >
                    <div className={WidgetTopRowStyles.icon_priority}>
                        <div className={WidgetTopRowStyles.icon_priority_bar_1} style={{ borderWidth: priorities.includes("Low") ? "0px" : "", backgroundColor: priorities.includes("Low") ? "rgb(var(--dl-color-primary-100))" : "" }} />
                        <div className={WidgetTopRowStyles.icon_priority_bar_2} style={{ borderWidth: priorities.includes("Medium") ? "0px" : "", backgroundColor: priorities.includes("Medium") ? "rgb(var(--dl-color-primary-100))" : "" }} />
                        <div className={WidgetTopRowStyles.icon_priority_bar_3} style={{ borderWidth: priorities.includes("High") ? "0px" : "", backgroundColor: priorities.includes("High") ? "rgb(var(--dl-color-primary-100))" : "" }} />
                    </div>
                </div>
            </BootstrapTooltip>
            {isOpen ? createPortal(
                <div className={WidgetTopRowStyles.popup_wrapper} ref={setPopup} style={styles.popper} {...attributes.popper}>
                    <div className={WidgetTopRowStyles.popup_wrapper_scroll}>
                        <EconomyButtonPriorityItem id={props.id} settings={settings} priorities={priorities} name="Low" />
                        <EconomyButtonPriorityItem id={props.id} settings={settings} priorities={priorities} name="Medium" />
                        <EconomyButtonPriorityItem id={props.id} settings={settings} priorities={priorities} name="High" />
                    </div>
                </div>,
                document.body
            ) : null}
        </div>
    )
}

export default EconomyButtonPriority