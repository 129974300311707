import { useState } from 'react';

import { setPopupElement } from '../../../../../../../store/features/management';
import { useAppDispatch } from '../../../../../../../store/hooks';
import { createCriteriaGroup, createCriteriaRow, createRowSettings } from '../../../../../../../types/creators/create-criteria';
import { CriteriaGroup, CriteriaRow, isCriteriaGroup, State } from '../../../../../../../types/Screener';
import DashboardScreenerBuilderCondition from './condition-popup/dashboard-screener-builder-condition';
import DashboardScreenerBuilderCriteriaRow from './dashboard-screener-builder-criteria-row';
import DashboardScreenerBuilderCriteriaStyles from "./DashboardScreenerBuilderCriteria.module.css";

type DashboardScreenerBuilderCriteriaGroupProps = {
    level: number;
    group: CriteriaGroup;
    state: State;
    position: number;
    handleChange: (group: CriteriaGroup, position: number, noSave: boolean) => void;
    handleDelete: (position: number) => void;
    setChildHovered: (childHovered: boolean) => void;
}

const DashboardScreenerBuilderCriteriaGroup: React.FC<DashboardScreenerBuilderCriteriaGroupProps> = (props) => {
    const dispatch = useAppDispatch();
    
    const [isHovered, setIsHovered] = useState(false);
    const [childHovered, setChildHovered] = useState(false);

    const handleCriteriaChange = () => {
        const newGroup = { ...props.group }
        newGroup.connection = props.group.connection === "and" ? "or" : "and";
        props.handleChange(newGroup, props.position, true)
    }

    const handleGroupChange = (rowGroup: CriteriaGroup, rowPosition: number, noSave: boolean) => {
        const newCriteriaList = [...props.group.criteriaList]
        newCriteriaList[rowPosition] = rowGroup
        const newGroup = {
            ...props.group,
            criteriaList: newCriteriaList
        }
        props.handleChange(newGroup, props.position, noSave)
    }

    const addCondition = () => {
        const rowSettings = createRowSettings({
            operator: props.state.type === "filter" ? "below" : "crosses up"
        })
        const newRowCondition = createCriteriaRow({
            settings: rowSettings
        })
        const newCriteriaList = [...props.group.criteriaList]
        newCriteriaList.push(newRowCondition)
        const newGroup = {
            ...props.group,
            criteriaList: newCriteriaList
        }
        props.handleChange(newGroup, props.position, true)
        dispatch(setPopupElement(<DashboardScreenerBuilderCondition state={props.state} row={newRowCondition} />))
    }

    const addGroup = () => {
        const rowSettings = createRowSettings({
            operator: props.state.type === "filter" ? "below" : "crosses up"
        })
        const newRowCondition = createCriteriaRow({
            settings: rowSettings
        })
        const newGroupCondition = createCriteriaGroup({
            criteriaList: [newRowCondition]
        })
        const newCriteriaList = [...props.group.criteriaList]
        newCriteriaList.push(newGroupCondition)
        const newGroup = {
            ...props.group,
            criteriaList: newCriteriaList
        }
        props.handleChange(newGroup, props.position, true)
        dispatch(setPopupElement(<DashboardScreenerBuilderCondition state={props.state} row={newGroupCondition.criteriaList[0] as CriteriaRow} />))
    }

    const handleGroupDelete = (index: number) => {
        setChildHovered(false)
        const newCriteriaList = [...props.group.criteriaList]
        newCriteriaList.splice(index, 1)
        const newGroup = {
            ...props.group,
            criteriaList: newCriteriaList
        }
        if (newCriteriaList.length === 0) {
            props.handleDelete(props.position)
        } else {
            props.handleChange(newGroup, props.position, true)
        }
    }

    const handleRowDelete = (index: number) => {
        const newCriteriaList = [...props.group.criteriaList]
        newCriteriaList.splice(index, 1)
        let newGroup = {
            ...props.group,
            criteriaList: newCriteriaList
        }
        if (newCriteriaList.length === 1 && newCriteriaList[0].type === "group" && isCriteriaGroup(newCriteriaList[0])) {
            newGroup = {
                ...props.group,
                criteriaList: newCriteriaList[0].criteriaList
            }
        }
        if (newCriteriaList.length === 0) {
            props.handleDelete(props.position)
        } else {
            props.handleChange(newGroup, props.position, true)
        }
    }

    return (
        <div
            className={DashboardScreenerBuilderCriteriaStyles.criteria_group}
            style={{ flexGrow: props.level !== 1 ? "1" : "", border: props.level === 1 ? "none" : "", backgroundColor: isHovered && !childHovered ? "rgb(var(--dl-color-backgrounds-hover-light))" : "" }}
            onMouseEnter={() => {setIsHovered(true); props.setChildHovered(true)}}
            onMouseLeave={() => {setIsHovered(false); props.setChildHovered(false)}}
        >
            {props.group.criteriaList.map((row: CriteriaGroup | CriteriaRow, index: number) => {
                const isGroup = isCriteriaGroup(row);
                return (
                    <div className={DashboardScreenerBuilderCriteriaStyles.criteria_row} key={index}>
                        {index === 0 ? (
                            <div className={DashboardScreenerBuilderCriteriaStyles.criteria_connection}>
                                <span className={DashboardScreenerBuilderCriteriaStyles.criteria_connection_text}>IF</span>
                            </div>
                        ) : (
                            <div className={index > 1 ? `${DashboardScreenerBuilderCriteriaStyles.criteria_connection_select} ${DashboardScreenerBuilderCriteriaStyles.criteria_connection_select_disabled}` : `${DashboardScreenerBuilderCriteriaStyles.criteria_connection_select}`} onClick={handleCriteriaChange}>
                                <div className={DashboardScreenerBuilderCriteriaStyles.criteria_connection_select_text_wrapper} style={{ margin: props.group.connection === "and" ? "1px 0px 0px" : "-23px 0px 0px" }}>
                                    <span className={index > 1 ? `${DashboardScreenerBuilderCriteriaStyles.criteria_connection_select_text} ${DashboardScreenerBuilderCriteriaStyles.criteria_connection_select_text_disabled}` : `${DashboardScreenerBuilderCriteriaStyles.criteria_connection_select_text}`}>AND</span>
                                    <span className={index > 1 ? `${DashboardScreenerBuilderCriteriaStyles.criteria_connection_select_text} ${DashboardScreenerBuilderCriteriaStyles.criteria_connection_select_text_disabled}` : `${DashboardScreenerBuilderCriteriaStyles.criteria_connection_select_text}`}>OR</span>
                                </div>
                                {index === 1 && (
                                    <div className={DashboardScreenerBuilderCriteriaStyles.criteria_connection_select_icon_wrapper}>
                                        <svg viewBox="0 0 1024 1024" className={DashboardScreenerBuilderCriteriaStyles.criteria_connection_select_icon} style={{ marginBottom: "-2px" }}>
                                            <path d="M512 342l256 256-60 60-196-196-196 196-60-60z" />
                                        </svg>
                                        <svg viewBox="0 0 1024 1024" className={DashboardScreenerBuilderCriteriaStyles.criteria_connection_select_icon} style={{ marginTop: "-2px" }}>
                                            <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        )}
                        {(row.type === "row" && !isGroup) && (
                            <DashboardScreenerBuilderCriteriaRow state={props.state} row={row} position={index} handleDelete={handleRowDelete} />
                        )}
                        {(row.type === "group" && isGroup) && (
                            <DashboardScreenerBuilderCriteriaGroup level={props.level + 1} state={props.state} group={row} position={index} handleChange={handleGroupChange} handleDelete={handleGroupDelete} setChildHovered={setChildHovered} />
                        )}
                    </div>
                )
            })}
            <div className={DashboardScreenerBuilderCriteriaStyles.criteria_add_row} style={{ maxHeight: ((isHovered && !childHovered) || props.level === 1) ? "40px" : "0px", marginTop: ((isHovered && !childHovered) || props.level === 1) ? "" : "-12px" }}>
                <div className={DashboardScreenerBuilderCriteriaStyles.criteria_add_row_left}>
                    <div className={DashboardScreenerBuilderCriteriaStyles.criteria_add_condition} onClick={addCondition}>
                        <svg viewBox="0 0 1024 1024" className={DashboardScreenerBuilderCriteriaStyles.criteria_add_plus_icon}>
                            <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z" />
                        </svg>
                        <span className={DashboardScreenerBuilderCriteriaStyles.criteria_add_text}>Add Condition</span>
                    </div>
                    {props.level < 3 && (
                        <div className={`${DashboardScreenerBuilderCriteriaStyles.criteria_add_condition} ${DashboardScreenerBuilderCriteriaStyles.criteria_add_group}`} onClick={addGroup}>
                            <svg viewBox="0 0 1024 1024" className={DashboardScreenerBuilderCriteriaStyles.criteria_add_plus_icon}>
                                <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z" />
                            </svg>
                            <span className={DashboardScreenerBuilderCriteriaStyles.criteria_add_text}>Add Group</span>
                        </div>
                    )}
                </div>
                {props.group.criteriaList.length > 0 && (
                    <div className={`${DashboardScreenerBuilderCriteriaStyles.criteria_add_condition} ${DashboardScreenerBuilderCriteriaStyles.criteria_delete_group}`} onClick={() => props.handleDelete(props.position)}>
                        <span className={DashboardScreenerBuilderCriteriaStyles.criteria_add_text}>{props.level === 1 ? "Delete All" : "Delete Group"}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default DashboardScreenerBuilderCriteriaGroup