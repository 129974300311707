import _ from "lodash";
import { useMemo, useState } from "react";

import { changeScreenerData, saveScreener } from "../../../../../../../store/features/screener";
import { useAppDispatch, useAppSelector } from "../../../../../../../store/hooks";
import { State } from "../../../../../../../types/Screener";
import BootstrapTooltip from "../../../../../../../utils/components/tooltip/bootstrap-tooltip"
import DashboardScreenerDetailsStyles from "../../DashboardScreenerDetails.module.css"
import BuilderSettingsResetTimeManual from "./builder-settings-reset-time-manual";
import BuilderSettingsResetTimeRecurring from "./builder-settings-reset-time-recurring";
import BuilderSettingsResetTimeStyles from "./BuilderSettingsResetTime.module.css";

type Props = {
    selectedState: State;
    small: boolean;
}

const BuilderSettingsResetTime: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const screener = useAppSelector((state) => state.screener)
    const user = useAppSelector((state) => state.user)

    const stateIndex = useMemo(() => screener.data.states.findIndex((state) => state.id === props.selectedState.id), [screener.data.states, props.selectedState.id])

    const [tab, setTab] = useState("recurring")

    const addRecurringResetTime = () => {
        let flag = false;
        if (props.selectedState.recurringResets.length === 0) {
            flag = true
        } else if (props.selectedState.recurringResets[props.selectedState.recurringResets.length - 1].interval !== "" && props.selectedState.recurringResets[props.selectedState.recurringResets.length - 1].time !== "") {
            flag = true
        }
        if (flag) {
            const newState = _.cloneDeep(props.selectedState);
            newState.recurringResets.push({
                interval: "",
                time: "",
                timezone: user.userData.timezone
            });
            const states = _.cloneDeep(screener.data.states);
            const position = screener.data.states.findIndex(((op) => op.id === props.selectedState.id))
            states.splice(position, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
    }

    const addManualResetTime = () => {
        let flag = false;
        if (props.selectedState.manualResets.length === 0) {
            flag = true
        } else if (props.selectedState.manualResets[props.selectedState.manualResets.length - 1].date !== "" && props.selectedState.manualResets[props.selectedState.manualResets.length - 1].time !== "") {
            flag = true
        }
        if (flag) {
            const newState = _.cloneDeep(props.selectedState);
            newState.manualResets.push({
                date: "",
                time: "",
                timezone: user.userData.timezone
            });
            const states = _.cloneDeep(screener.data.states);
            const position = screener.data.states.findIndex(((op) => op.id === props.selectedState.id))
            states.splice(position, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
    }

    const addButtonClick = () => {
        if (tab === "recurring") {
            addRecurringResetTime();
        } else {
            addManualResetTime();
        }
    }

    return (
        <div className={DashboardScreenerDetailsStyles.settings_content_row} style={{ flexDirection: props.small ? "column" : "row" }}>
            <div className={DashboardScreenerDetailsStyles.settings_row_left}>
                <span className={DashboardScreenerDetailsStyles.settings_row_title} style={{ maxWidth: props.small ? "none" : "120px" }}>Reset time:</span>
                <BootstrapTooltip title="Learn more about reset times">
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.settings_help_icon}>
                        <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                    </svg>
                </BootstrapTooltip>
            </div>
            <div className={DashboardScreenerDetailsStyles.settings_row_right}>
                <div className={DashboardScreenerDetailsStyles.reset_time_wrapper}>
                    <div className={DashboardScreenerDetailsStyles.toggle_switch_big} style={{ border: "none" }}>
                        <div className={tab === "recurring" ? `${DashboardScreenerDetailsStyles.toggle_big_background}` : `${DashboardScreenerDetailsStyles.toggle_big_background} ${DashboardScreenerDetailsStyles.toggle_big_background_right}`}/>
                        <div className={DashboardScreenerDetailsStyles.toggle_big_item_multi} style={{ width: "calc(50% - 12px)", backgroundColor: tab === "recurring" ? "transparent" : "" }} onClick={() => setTab("recurring")}>
                            <span className={DashboardScreenerDetailsStyles.toggle_big_item_multi_text} style={{ color: tab === "recurring" ? "rgb(var(--dl-color-black))" : "" }}>Recurring</span>
                            {props.selectedState.recurringResets.filter(r => r.interval !== "" && r.time !== "" && r.timezone !== "").length > 0 ? (
                                <div className={DashboardScreenerDetailsStyles.toggle_big_counter_blue}>
                                    <span className={DashboardScreenerDetailsStyles.toggle_big_counter_blue_number}>{props.selectedState.recurringResets.filter(r => r.interval !== "" && r.time !== "" && r.timezone !== "").length}</span>
                                </div>
                            ) : null}
                        </div>
                        <div className={DashboardScreenerDetailsStyles.toggle_big_item_multi} style={{ width: "calc(50% - 12px)", backgroundColor: tab === "manually" ? "transparent" : "" }} onClick={() => setTab("manually")}>
                            <span className={DashboardScreenerDetailsStyles.toggle_big_item_multi_text} style={{ color: tab === "manually" ? "rgb(var(--dl-color-black))" : "" }}>Manually</span>
                            {props.selectedState.manualResets.filter(r => r.date !== "" && r.time !== "" && r.timezone !== "").length > 0 ? (
                                <div className={DashboardScreenerDetailsStyles.toggle_big_counter_blue}>
                                    <span className={DashboardScreenerDetailsStyles.toggle_big_counter_blue_number}>{props.selectedState.manualResets.filter(r => r.date !== "" && r.time !== "" && r.timezone !== "").length}</span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className={DashboardScreenerDetailsStyles.toggle_switch_big_separator} />
                    <div className={DashboardScreenerDetailsStyles.reset_time_table}>
                        <div className={DashboardScreenerDetailsStyles.reset_time_table_header}>
                            <div className={DashboardScreenerDetailsStyles.reset_time_table_header_date}>
                                <span className={DashboardScreenerDetailsStyles.reset_time_table_header_text}>{tab === "recurring" ? "Interval" : "Date"}</span>
                            </div>
                            <div className={DashboardScreenerDetailsStyles.reset_time_table_header_time}>
                                <span className={DashboardScreenerDetailsStyles.reset_time_table_header_text}>Time</span>
                            </div>
                        </div>
                        {tab === "recurring" ? (
                            <div className={DashboardScreenerDetailsStyles.reset_time_table_body}>
                                {[...props.selectedState.recurringResets].map((recurringReset, index) => <BuilderSettingsResetTimeRecurring selectedState={props.selectedState} statePosition={stateIndex} position={index} key={index} />)}
                            </div>
                        ) : (
                            <div className={DashboardScreenerDetailsStyles.reset_time_table_body}>
                                {[...props.selectedState.manualResets].map((manualReset, index) => <BuilderSettingsResetTimeManual selectedState={props.selectedState} statePosition={stateIndex} position={index} key={index} />)}
                            </div>
                        )}
                    </div>
                </div>
                <div className={BuilderSettingsResetTimeStyles.add_row}>
                    <div className={BuilderSettingsResetTimeStyles.add_button} onClick={() => addButtonClick()}>
                        <svg viewBox="0 0 1024 1024" className={BuilderSettingsResetTimeStyles.add_icon}>
                            <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z" />
                        </svg>
                    </div>
                    {tab === "recurring" ? (
                        <span className={BuilderSettingsResetTimeStyles.add_text}>Add a recurring interval at which the alert feed resets and is empty again.</span>
                    ) : (
                        <span className={BuilderSettingsResetTimeStyles.add_text}>Add a specific time at which the alert feed resets and is empty again.</span>
                    )}
                </div>
            </div>
        </div>
    )
}

export default BuilderSettingsResetTime