import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { useAppSelector } from '../../../../store/hooks';
import getDropdownDisplayValue from '../../../functions/dropdown-display-value';
import usePopperPopup from '../../../hooks/use-popper-popup';
import ConditionRow from "../ConditionRow.module.css"
import CriteriaDropdownSelectionNestedPopup from './criteria-dropdown-selection-nested-popup';

type Props = {
    dropdownObject: {[sector: string]: string[]};
    transformType: string;
    withSearch: boolean;
    values: string[];
    setValues: (values: string[]) => void;
}

const defaultProps = {
    dropdownObject: {},
    transformType: "",
    withSearch: false,
    values: [],
    setValues: () => {}
}

const CriteriaDropdownSelectionNested: React.FC<Props> = (props) => {
    const { dropdownObject, transformType, withSearch, values, setValues } = { ...defaultProps, ...props }
    
    const management = useAppSelector((state) => state.management)
    
    const { setButton, setPopup, buttonRef: wrapperRef, styles, attributes, isOpen: dropdownOpen, setIsOpen: setDropdownOpen } = usePopperPopup({ offsetY: 4 })

    const [search, setSearch] = useState("");

    useEffect(() => {
        setSearch("");
    }, [dropdownOpen]);

    return (
        <div className={ConditionRow.criteria_dropdown_wrapper} ref={wrapperRef}>
            <div
                className={ConditionRow.criteria_dropdown_button}
                style={{ borderColor: dropdownOpen ? "rgb(var(--dl-color-primary-100))" : "", width: "500px" }}
                onClick={() => {setDropdownOpen(!dropdownOpen)}} ref={setButton}
            >
                {values?.length === 0 && (
                    <span className={ConditionRow.criteria_dropdown_button_text} style={{ color: "rgba(var(--dl-color-text-gray2), 0.85)" }}>Select Options</span>
                )}
                <div className={ConditionRow.criteria_dropdown_display_wrapper} style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {values?.map((value, index) => (
                        <>
                            {values?.findIndex((val) => val === value) !== 0 && (<span className={ConditionRow.criteria_dropdown_button_text} >|</span>)}
                            <span className={ConditionRow.criteria_dropdown_button_text} key={index}>{getDropdownDisplayValue(transformType || "", value)}</span>
                        </>
                    ))}
                </div>
                <svg viewBox="0 0 1024 1024" className={ConditionRow.criteria_dropdown_button_arrow_icon} style={{ fill: dropdownOpen ? "rgb(var(--dl-color-primary-100))" : "", transform: dropdownOpen ? "rotate(180deg)" : "" }}>
                    <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                </svg>
            </div>
            {dropdownOpen && management.popupElement !== null ? (
                <div className={ConditionRow.criteria_dropdown_menu} ref={setPopup} style={styles.popper} {...attributes.popper}>
                    <CriteriaDropdownSelectionNestedPopup dropdownObject={dropdownObject} withSearch={withSearch} values={values} setValues={setValues} search={search} setSearch={setSearch} />
                </div>
            ) : null}
            {dropdownOpen && management.popupElement === null ? createPortal(
                <div className={ConditionRow.criteria_dropdown_menu} ref={setPopup} style={{ ...styles.popper, minWidth: "500px" }} {...attributes.popper}>
                    <CriteriaDropdownSelectionNestedPopup dropdownObject={dropdownObject} withSearch={withSearch} values={values} setValues={setValues} search={search} setSearch={setSearch} />
                </div>,
                document.body
            ) : null}
        </div>
    )
}

export default CriteriaDropdownSelectionNested