import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import ConditionRow from "../../../ConditionRow.module.css"
import CriteriaInputSwitch from '../../criteria-input-switch';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
    reportPeriodOptions: string[];
}

const defaultProps: Props = {
    parameters: {
        reportPeriod: "Quarterly Report",
    },
    setParameters: () => {},
    reportPeriodOptions: ["Quarterly Report", "Annual Report"],
}

const CriteriaParameterTotalRevenue: React.FC<Props> = (props) => {
    const reportPeriod = useMemo(() => props.parameters.reportPeriod && props.reportPeriodOptions.includes(props.parameters.reportPeriod) ? props.parameters.reportPeriod : defaultProps.parameters.reportPeriod!, [props.parameters.reportPeriod])

    const setReportPeriod = (newReportPeriod: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, reportPeriod: newReportPeriod }, defaultProps.parameters))
    }

    return (
        <>
            <span className={ConditionRow.criteria_text}>of last</span>
            <CriteriaInputSwitch switchOptions={props.reportPeriodOptions} value={reportPeriod} setValue={setReportPeriod} width="140px" />
        </>
    )
}

export default CriteriaParameterTotalRevenue