import { useMemo, useState } from "react";

import { useAppSelector } from "../../../../../../store/hooks";
import { Screener } from "../../../../../../types/Screener";
import BootstrapTooltip from "../../../../../../utils/components/tooltip/bootstrap-tooltip";
import DashboardScreenerDetailsStyles from "../DashboardScreenerDetails.module.css"
import DashboardScreenerSourceSelectedItem from "./dashboard-screener-source-selected-item";
import DashboardScreenerSourceTabIndices from "./dashboard-screener-source-tab-indices";
import DashboardScreenerSourceTabScreeners from "./dashboard-screener-source-tab-screeners";
import DashboardScreenerSourceTabWatchlists from "./dashboard-screener-source-tab-watchlists";
import DashboardScreenerSourceStyles from "./DashboardScreenerSource.module.css"

type Props = {
    screener: Screener;
    setNewState: React.Dispatch<React.SetStateAction<number | null>>;
}

const tabs = ["Indices", "Screeners", "Watchlists"]

const DashboardScreenerSource: React.FC<Props> = (props) => {
    const screener = useAppSelector((state) => state.screener)
    const allScreeners = useAppSelector((state) => state.allScreeners)
    const user = useAppSelector((state) => state.user)

    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const [search, setSearch] = useState("");

    const sourceSelected = useMemo(() => (!(screener.data.sources.indices.length === 0 && screener.data.sources.screeners.length === 0 && screener.data.sources.watchlists.length === 0)), [screener.data.sources])

    return (
        <div className={DashboardScreenerDetailsStyles.builder_wrapper} id="dashboard-screener-builder-wrapper">
            <div className={DashboardScreenerDetailsStyles.builder}>
                <div className={DashboardScreenerDetailsStyles.conditions_container}>
                    <div className={DashboardScreenerDetailsStyles.title_row}>
                        <span className={DashboardScreenerDetailsStyles.title_text}>Source</span>
                    </div>
                    <div className={DashboardScreenerSourceStyles.wrapper}>
                        <div className={DashboardScreenerSourceStyles.explanation_text_row}>
                            <span className={DashboardScreenerSourceStyles.explanation_text}>Select the initial sources, on which this screener will be based on. You can select multiple lists.</span>
                            <BootstrapTooltip title="Learn more about sources">
                                <svg viewBox="0 0 1024 1024" className={DashboardScreenerSourceStyles.explanation_text_help_icon}>
                                    <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                                </svg>
                            </BootstrapTooltip>
                        </div>
                        <div className={DashboardScreenerSourceStyles.tab_selection_wrapper}>
                            <div className={DashboardScreenerSourceStyles.tab_row}>
                                <div className={DashboardScreenerSourceStyles.tab_row_tabs}>
                                    {tabs.map((tab) => (
                                        <div className={tab === selectedTab ? `${DashboardScreenerSourceStyles.tab_row_tab} ${DashboardScreenerSourceStyles.tab_row_tab_active}` : `${DashboardScreenerSourceStyles.tab_row_tab}`} key={tab} onClick={() => setSelectedTab(tab)}>
                                            <span className={DashboardScreenerSourceStyles.tab_row_tab_text}>{tab}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className={DashboardScreenerSourceStyles.search_wrapper}>
                                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerSourceStyles.search_icon}>
                                        <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z" />
                                    </svg>
                                    <input className={DashboardScreenerSourceStyles.search_input}
                                        type="text"
                                        placeholder="Search..."
                                        onChange={(event) => {if (event.target.value.length <= 50) {setSearch(event.target.value)}}}
                                        value={search}
                                    />
                                    {search !== "" && (
                                        <div className={DashboardScreenerSourceStyles.search_delete} onClick={() => setSearch("")}>
                                            <svg viewBox="0 0 1024 1024" className={DashboardScreenerSourceStyles.search_delete_icon}>
                                                <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                                            </svg>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {selectedTab === "Indices" ? <DashboardScreenerSourceTabIndices screener={props.screener} search={search} /> : null}
                        {selectedTab === "Screeners" ? <DashboardScreenerSourceTabScreeners screener={props.screener} search={search} /> : null}
                        {selectedTab === "Watchlists" ? <DashboardScreenerSourceTabWatchlists screener={props.screener} search={search} /> : null}
                        <div className={DashboardScreenerSourceStyles.selected_sources_wrapper}>
                            <span className={DashboardScreenerSourceStyles.selected_sources_title}>Ausgewählte Quellen:</span>
                            <div className={DashboardScreenerSourceStyles.selected_sources_content}>
                                <div className={DashboardScreenerSourceStyles.selected_sources_list}>
                                    {screener.data.sources.indices.map((index) => <DashboardScreenerSourceSelectedItem screener={screener.data} id={index} name={index} type="Indices" key={index} />)}
                                    {screener.data.sources.screeners.map((selectedDashboardScreenerStyles) => {
                                        const selectedScreener = allScreeners.screeners[selectedDashboardScreenerStyles.screenerId]
                                        const selectedState = selectedScreener.states.find((s) => s.id === selectedDashboardScreenerStyles.stateId)
                                        if (typeof selectedScreener !== "undefined" && typeof selectedState !== "undefined") {
                                            return (
                                                <DashboardScreenerSourceSelectedItem screener={screener.data} id={selectedState.id} name={`${selectedScreener.name} / ${selectedState.name}`} type="Screeners" key={selectedDashboardScreenerStyles.stateId} />
                                            )
                                        }
                                        return null
                                    })}
                                    {screener.data.sources.watchlists.map((watchlistId) => {
                                        const watchlist = user.userData.watchlists.find((w) => w.id === watchlistId)
                                        if (typeof watchlist !== "undefined") {
                                            return (
                                                <DashboardScreenerSourceSelectedItem screener={screener.data} id={watchlistId} name={watchlist.name} type="Watchlists" key={watchlistId} />
                                            )
                                        }
                                        return null
                                    })}
                                </div>
                                <div className={DashboardScreenerSourceStyles.symbol_count}>
                                    <span className={DashboardScreenerSourceStyles.symbol_count_text_gray}>Symbols</span>
                                    <span className={DashboardScreenerSourceStyles.stocklist_row_text}>500</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={DashboardScreenerDetailsStyles.conditions_bottom_row}>
                        <button type="button" disabled={!sourceSelected} className={DashboardScreenerDetailsStyles.conditions_add_state_button} onClick={() => props.setNewState(0)}>{screener.data.states.length > 0 ? "Add another state" : "Continue"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
    
export default DashboardScreenerSource