import { useMemo, useRef, useState } from "react";

import { changeScreenerData, saveScreener } from "../../../../../../../store/features/screener";
import { useAppDispatch, useAppSelector } from "../../../../../../../store/hooks";
import { State } from "../../../../../../../types/Screener";
import BootstrapTooltip from "../../../../../../../utils/components/tooltip/bootstrap-tooltip"
import DashboardScreenerDetailsStyles from "../../DashboardScreenerDetails.module.css"

type Props = {
    selectedState: State;
    small: boolean;
}

const BuilderSettingsMaximumAlerts: React.FC<Props> = (props) => {
    const [maxCountInput, setMaxCountInput] = useState(props.selectedState.maxCount);

    const dispatch = useAppDispatch();

    const screener = useAppSelector((state) => state.screener)

    const stateIndex = useMemo(() => screener.data.states.findIndex((state) => state.id === props.selectedState.id), [screener.data.states, props.selectedState.id])

    const inputRef = useRef<HTMLInputElement | null>(null);

    const setNewNumber = () => {
        let newMaxCountInput = maxCountInput
        if (newMaxCountInput !== "") {
            if (parseInt(newMaxCountInput, 10) > 99) {
                newMaxCountInput = "99"
                setMaxCountInput(newMaxCountInput)
            }
            if (parseInt(newMaxCountInput, 10) < 1) {
                newMaxCountInput = ""
                setMaxCountInput(newMaxCountInput)
            }
        }
        if (stateIndex > -1 && newMaxCountInput !== props.selectedState.maxCount) {
            const newState: State = {
                ...props.selectedState,
                maxCount: newMaxCountInput
            }
            const states = [...screener.data.states];
            states.splice(stateIndex, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
    }

    const setNewNumberArrow = (newNumber: string) => {
        let newNumberChecked = newNumber
        if (newNumberChecked !== "" && parseInt(newNumberChecked, 10) > 99) {
            newNumberChecked = "99"
        }
        setMaxCountInput(newNumberChecked)
        if (stateIndex > -1 && (newNumber !== props.selectedState.maxCount)) {
            const newState: State = {
                ...props.selectedState,
                maxCount: newNumberChecked
            }
            const states = [...screener.data.states];
            states.splice(stateIndex, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
    }

    const handleKeypress = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            inputRef.current?.blur();
        }
        if (["e", "E", "+", "-", ".", ","].includes(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <div className={DashboardScreenerDetailsStyles.settings_content_row} style={{ flexDirection: props.small ? "column" : "row" }}>
            <div className={DashboardScreenerDetailsStyles.settings_row_left} style={{ width: props.small ? "100%" : "" }}>
                <span className={DashboardScreenerDetailsStyles.settings_row_title} style={{ width: props.small ? "" : "74px", maxWidth: props.small ? "none" : "120px" }}>Maximum Alert Count:</span>
                <BootstrapTooltip title="Learn more about maximum alert counts">
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.settings_help_icon}>
                        <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                    </svg>
                </BootstrapTooltip>
            </div>
            <div className={DashboardScreenerDetailsStyles.settings_row_right}>
                <div className={DashboardScreenerDetailsStyles.number_input_wrapper}>
                    <div className={DashboardScreenerDetailsStyles.number_input_arrows}>
                        <div className={DashboardScreenerDetailsStyles.number_input_arrow_wrapper} onClick={() => setNewNumberArrow(maxCountInput !== "" ? (parseInt(maxCountInput) + 1).toString() : "1")}>
                            <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.number_input_arrows_icon}>
                                <path d="M512 342l256 256-60 60-196-196-196 196-60-60z" />
                            </svg>
                        </div>
                        <div className={DashboardScreenerDetailsStyles.number_input_arrow_wrapper} onClick={() => setNewNumberArrow(parseInt(maxCountInput) - 1 > 0 ? (parseInt(maxCountInput) - 1).toString() : "")}>
                            <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.number_input_arrows_icon}>
                                <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                            </svg>
                        </div>
                    </div>
                    <input
                        type="number"
                        className={DashboardScreenerDetailsStyles.number_input}
                        placeholder="Max..."
                        onChange={(event) => setMaxCountInput(event.target.value)}
                        onKeyDown={async (event) => await handleKeypress(event)}
                        value={maxCountInput}
                        onBlur={() => setNewNumber()}
                        ref={inputRef}
                    />
                </div>
                {maxCountInput === "" ? (
                    <span className={DashboardScreenerDetailsStyles.settings_row_subtext}>Set a maximum number of alerts for a stock.</span>
                ) : (
                    <span className={DashboardScreenerDetailsStyles.settings_row_subtext}>{`The maximum number of alerts for a stock is ${props.selectedState.maxCount}.`}</span>
                )}
            </div>
        </div>
    )
}

export default BuilderSettingsMaximumAlerts