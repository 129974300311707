import { changeDashboardData, saveDashboard } from "../../../../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../../../../types/changeHandlers/change-dashboard-widget";
import { EconomicCalendarSettings } from "../../../../../../../../types/Dashboard";
import WidgetTopRowStyles from "../../../../../WidgetTopRowStyles.module.css"

type Props = {
    id: string;
    settings: EconomicCalendarSettings;
    priorities: string[];
    name: string;
}

const EconomyButtonPriorityItem: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const dashboard = useAppSelector((state) => state.dashboard)
    
    const changeValues = () => {
        const newEconomy = { ...props.settings.economy }
        const newPriorities = [ ...props.priorities ]
        const index = newPriorities.findIndex((c) => c === props.name)
        if(index > -1) {
            newPriorities.splice(index, 1)
        } else {
            newPriorities.push(props.name)
        }
        newEconomy.priorities = newPriorities
        const newSettings = {
            ...props.settings,
            economy: newEconomy
        }
        const newWidgets = changeDashboardWidgetData(props.id, dashboard.data.widgets, { settings: newSettings })
        dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
        dispatch(saveDashboard());
    }

    return (
        <div className={WidgetTopRowStyles.popup_item} style={{ backgroundColor: props.priorities.includes(props.name) ? "rgb(var(--dl-color-primary-100))" : "" }} onClick={changeValues}>
            <div className={WidgetTopRowStyles.icon_priority} style={{ justifyContent: "flex-start", gap: "2px", marginBottom: "2px" }}>
                <div className={WidgetTopRowStyles.icon_priority_bar_1} style={{ borderColor: props.priorities.includes(props.name) ? "rgb(var(--dl-color-white-always))" : "" }} />
                {["Medium", "High"].includes(props.name) && <div className={WidgetTopRowStyles.icon_priority_bar_2} style={{ borderColor: props.priorities.includes(props.name) ? "rgb(var(--dl-color-white-always))" : "" }} />}
                {props.name === "High" && <div className={WidgetTopRowStyles.icon_priority_bar_3} style={{ borderColor: props.priorities.includes(props.name) ? "rgb(var(--dl-color-white-always))" : "" }} />}
            </div>
            <span className={WidgetTopRowStyles.popup_item_text} style={{ color: props.priorities.includes(props.name) ? "rgb(var(--dl-color-white-always))" : "" }}>{props.name} Importance</span>
        </div>
    )
}

export default EconomyButtonPriorityItem