import { useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { changeScreenerData, saveScreener } from "../../../../../../../store/features/screener";
import { useAppDispatch, useAppSelector } from "../../../../../../../store/hooks";
import { State } from "../../../../../../../types/Screener";
import BootstrapTooltip from "../../../../../../../utils/components/tooltip/bootstrap-tooltip"
import usePopperPopup from "../../../../../../../utils/hooks/use-popper-popup";
import DashboardScreenerDetailsStyles from "../../DashboardScreenerDetails.module.css"

const intervalOptions = [
    "minutes",
    "hours",
    "days",
]

const frequencyOptions: FrequencyOptions = {
    "1m": {
        multiplier: "1",
        interval: "minutes"
    },
    "5m": {
        multiplier: "5",
        interval: "minutes"
    },
    "10m": {
        multiplier: "10",
        interval: "minutes"
    },
    "1h": {
        multiplier: "1",
        interval: "hours"
    },
    "1d": {
        multiplier: "1",
        interval: "days"
    },
}

type FrequencyOptions = {
    [key: string]: FrequencyOption
}

type FrequencyOption = {
    multiplier: string,
    interval: string
}

type Props = {
    selectedState: State;
    small: boolean;
}

const BuilderSettingsFrequency: React.FC<Props> = (props) => {    
    const dispatch = useAppDispatch();

    const screener = useAppSelector((state) => state.screener)  

    const stateIndex = useMemo(() => screener.data.states.findIndex((state) => state.id === props.selectedState.id), [screener.data.states, props.selectedState.id])

    const selectedIndex = useMemo(() => {
        const index = Object.keys(frequencyOptions).findIndex((f) => {
            const option = frequencyOptions[f]
            return (option.interval === props.selectedState.frequency.interval && option.multiplier === props.selectedState.frequency.multiplier)
        })
        if (index > -1) {
            return index
        }
        if (props.selectedState.frequency.type === "predefined") {
            const newFrequency = {
                ...props.selectedState.frequency,
                interval: "minutes",
                multiplier: "10",
            }
            const newState: State = {
                ...props.selectedState,
                frequency: newFrequency
            }
            const states = [...screener.data.states];
            states.splice(stateIndex, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
        return 2
    }, [props.selectedState.frequency])
    
    const widthPercent = useMemo(() => 100 / Object.keys(frequencyOptions).length, [])

    const [multiplierInput, setMultiplierInput] = useState(props.selectedState.frequency.multiplier);
    const multiplierInputRef = useRef<HTMLInputElement | null>(null);
  
    const { setButton, setPopup, buttonRef: intervalRef, styles, attributes, isOpen: intervalPickerOpen, setIsOpen: setIntervalPickerOpen } = usePopperPopup({ offsetY: 6 });

    const setFrequencyType = (newType: string) => {
        if (stateIndex > -1 && newType !== props.selectedState.frequency.type) {
            let newFrequency = {
                ...props.selectedState.frequency,
                type: newType
            }
            if (newType === "predefined") {
                const frequencyIndex = Object.values(frequencyOptions).findIndex((f) => f.interval === props.selectedState.frequency.interval && f.multiplier === props.selectedState.frequency.multiplier)
                if (frequencyIndex > -1) {
                    newFrequency = {
                        ...newFrequency,
                        interval: props.selectedState.frequency.interval,
                        multiplier: props.selectedState.frequency.multiplier,
                    }
                } else {
                    newFrequency = {
                        ...newFrequency,
                        interval: "minutes",
                        multiplier: "10",
                    }
                }
            } else {
                newFrequency = {
                    ...newFrequency,
                    interval: newFrequency.interval === "" ? "hours" : newFrequency.interval,
                    multiplier: newFrequency.multiplier === "" ? "1" : newFrequency.multiplier,
                }
            }
            setMultiplierInput(newFrequency.multiplier)
            const newState: State = {
                ...props.selectedState,
                frequency: newFrequency
            }
            const states = [...screener.data.states];
            states.splice(stateIndex, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
    }

    const setPredefinedFrequency = (newFrequency: FrequencyOption) => {
        if (stateIndex > -1 && (newFrequency.interval !== props.selectedState.frequency.interval || newFrequency.multiplier !== props.selectedState.frequency.multiplier)) {
            const newState: State = {
                ...props.selectedState,
                frequency: {
                    type: "predefined",
                    multiplier: newFrequency.multiplier,
                    interval: newFrequency.interval
                }
            }
            const states = [...screener.data.states];
            states.splice(stateIndex, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
    }

    const setNewMultiplier = () => {
        if (multiplierInput === "") {
            setMultiplierInput("1")
        }
        if (stateIndex > -1 && (multiplierInput !== props.selectedState.frequency.multiplier)) {
            const newState: State = {
                ...props.selectedState,
                frequency: {
                    type: "custom",
                    multiplier: multiplierInput,
                    interval: props.selectedState.frequency.interval
                }
            }
            const states = [...screener.data.states];
            states.splice(stateIndex, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
    }

    const setNewMultiplierArrow = (newMultiplier: string) => {
        setMultiplierInput(newMultiplier)
        if (stateIndex > -1 && (newMultiplier !== props.selectedState.frequency.multiplier)) {
            const newState: State = {
                ...props.selectedState,
                frequency: {
                    type: "custom",
                    multiplier: newMultiplier,
                    interval: props.selectedState.frequency.interval
                }
            }
            const states = [...screener.data.states];
            states.splice(stateIndex, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
    }

    const handleKeypress = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            multiplierInputRef.current?.blur();
        }
        if (["e", "E", "+", "-", ".", ","].includes(e.key)) {
            e.preventDefault();
        }
    };

    const intervalChange = (intervalString: string) => {
        if (stateIndex > -1 && (intervalString !== props.selectedState.frequency.interval)) {
            const newState: State = {
                ...props.selectedState,
                frequency: {
                    type: "custom",
                    multiplier: multiplierInput,
                    interval: intervalString
                }
            }
            const states = [...screener.data.states];
            states.splice(stateIndex, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
        setIntervalPickerOpen(false);
    }
    
    return (
        <div className={DashboardScreenerDetailsStyles.settings_content_row} style={{ flexDirection: props.small ? "column" : "row" }}>
            <div className={DashboardScreenerDetailsStyles.settings_row_left}>
                <span className={DashboardScreenerDetailsStyles.settings_row_title} style={{ maxWidth: props.small ? "none" : "120px" }}>Frequency:</span>
                <BootstrapTooltip title="Learn more about alert frequency">
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.settings_help_icon}>
                        <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                    </svg>
                </BootstrapTooltip>
            </div>
            <div className={DashboardScreenerDetailsStyles.settings_row_right}>
                <div className={DashboardScreenerDetailsStyles.toggle_switch_big_column}>
                    <div className={DashboardScreenerDetailsStyles.toggle_switch_big} style={{ border: "none" }}>
                        <div className={props.selectedState.frequency.type === "predefined" ? `${DashboardScreenerDetailsStyles.toggle_big_background}` : `${DashboardScreenerDetailsStyles.toggle_big_background} ${DashboardScreenerDetailsStyles.toggle_big_background_right}`}/>
                        <div className={DashboardScreenerDetailsStyles.toggle_big_item_multi} style={{ width: "calc(50% - 12px)", backgroundColor: props.selectedState.frequency.type === "predefined" ? "transparent" : "" }} onClick={() => setFrequencyType("predefined")}>
                            <span className={DashboardScreenerDetailsStyles.toggle_big_item_multi_text} style={{ color: props.selectedState.frequency.type === "predefined" ? "rgb(var(--dl-color-black))" : "" }}>Predefined</span>
                        </div>
                        <div className={DashboardScreenerDetailsStyles.toggle_big_item_multi} style={{ width: "calc(50% - 12px)", backgroundColor: props.selectedState.frequency.type === "custom" ? "transparent" : "" }} onClick={() => setFrequencyType("custom")}>
                            <span className={DashboardScreenerDetailsStyles.toggle_big_item_multi_text} style={{ color: props.selectedState.frequency.type === "custom" ? "rgb(var(--dl-color-black))" : "" }}>Custom</span>
                        </div>
                    </div>
                    <div className={DashboardScreenerDetailsStyles.toggle_switch_big_separator} />
                    {props.selectedState.frequency.type === "predefined" ? (
                        <div className={DashboardScreenerDetailsStyles.toggle_switch_big_multi} style={{ border: "none" }}>
                            <div className={DashboardScreenerDetailsStyles.toggle_big_background_multi} style={{ width: `calc(${widthPercent}% - 8px)`, left: `calc(${selectedIndex * widthPercent}% + 8px - ${selectedIndex * 2}px)` }} />
                            {Object.keys(frequencyOptions).map((frequency, index) => {
                                const isSelected = props.selectedState.frequency.multiplier === frequencyOptions[frequency].multiplier && props.selectedState.frequency.interval === frequencyOptions[frequency].interval
                                return (
                                    <div className={DashboardScreenerDetailsStyles.toggle_big_item_multi} style={{ width: `calc(${widthPercent}% - 6px)`, backgroundColor: isSelected ? "transparent" : "" }} onClick={() => setPredefinedFrequency(frequencyOptions[frequency])} key={index}>
                                        <span className={DashboardScreenerDetailsStyles.toggle_big_item_multi_text} style={{ color: isSelected ? "rgb(var(--dl-color-black))" : "" }}>{frequency}</span>
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <div className={DashboardScreenerDetailsStyles.toggle_switch_big} style={{ border: "none", justifyContent: "flex-end" }}>
                            <div className={DashboardScreenerDetailsStyles.number_input_wrapper} style={{ alignSelf: "center" }}>
                                <div className={DashboardScreenerDetailsStyles.number_input_arrows}>
                                    <div className={DashboardScreenerDetailsStyles.number_input_arrow_wrapper} style={{ height: "10px" }} onClick={() => setNewMultiplierArrow((parseInt(multiplierInput) + 1).toString())}>
                                        <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.number_input_arrows_icon}>
                                            <path d="M512 342l256 256-60 60-196-196-196 196-60-60z" />
                                        </svg>
                                    </div>
                                    <div className={DashboardScreenerDetailsStyles.number_input_arrow_wrapper} style={{ height: "10px" }} onClick={() => setNewMultiplierArrow(parseInt(multiplierInput) - 1 > 1 ? (parseInt(multiplierInput) - 1).toString() : "1")}>
                                        <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.number_input_arrows_icon}>
                                            <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                                        </svg>
                                    </div>
                                </div>
                                <input
                                    type="number"
                                    className={DashboardScreenerDetailsStyles.number_input}
                                    style={{ height: "28px" }}
                                    placeholder=""
                                    onChange={(event) => setMultiplierInput(event.target.value)}
                                    onKeyDown={async (event) => await handleKeypress(event)}
                                    value={multiplierInput}
                                    onBlur={() => setNewMultiplier()}
                                    ref={multiplierInputRef}
                                />
                            </div>
                            <div className={DashboardScreenerDetailsStyles.interval_picker_container} ref={intervalRef}>
                                <div className={DashboardScreenerDetailsStyles.interval_picker_button} ref={setButton} style={{ borderColor: intervalPickerOpen ? "rgb(var(--dl-color-primary-100))" : "" }} onClick={() => {setIntervalPickerOpen(!intervalPickerOpen)}}>
                                    <div className={DashboardScreenerDetailsStyles.interval_picker_button_left}>
                                        <div className={DashboardScreenerDetailsStyles.interval_picker_button_text_wrapper}>
                                            <span className={props.selectedState.frequency.interval === "" ? `${DashboardScreenerDetailsStyles.interval_picker_button_text} ${DashboardScreenerDetailsStyles.interval_picker_button_text_placeholder}` : `${DashboardScreenerDetailsStyles.interval_picker_button_text}`}>{props.selectedState.frequency.multiplier === "1" ? `${props.selectedState.frequency.interval.slice(0, -1)}` : `${props.selectedState.frequency.interval}`}</span>
                                        </div>
                                    </div>
                                    <div className={DashboardScreenerDetailsStyles.interval_picker_button_right}>
                                        <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.interval_picker_button_arrow_icon} style={{ fill: intervalPickerOpen ? "rgb(var(--dl-color-primary-100))" : "", transform: intervalPickerOpen ? "rotate(180deg)" : "" }}>
                                            <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                                        </svg>
                                    </div>
                                </div>
                                {intervalPickerOpen ? createPortal(
                                    <div className={DashboardScreenerDetailsStyles.interval_picker_button_options_wrapper} ref={setPopup} style={{ ...styles.popper, width: intervalRef.current?.clientWidth }} {...attributes.popper}>
                                        {intervalOptions.map((intervalString, index) => (
                                            <div className={DashboardScreenerDetailsStyles.interval_picker_button_option} style={{ backgroundColor: props.selectedState.frequency.interval === intervalString ? "rgb(var(--dl-color-primary-100))" : "" }} key={index} onClick={() => intervalChange(intervalString)}>
                                                <p className={DashboardScreenerDetailsStyles.interval_picker_button_option_text} style={{ color: props.selectedState.frequency.interval === intervalString ? "rgb(var(--dl-color-white-always))" : "" }}>{props.selectedState.frequency.multiplier === "1" ? `${intervalString.slice(0, -1)}` : `${intervalString}`}</p>
                                            </div>
                                        ))}
                                    </div>,
                                    document.body
                                ) : null}
                            </div>
                        </div>
                    )}
                </div>
                <span className={DashboardScreenerDetailsStyles.settings_row_subtext}>Set the minimum time between two alerts for the same stock.</span>
            </div>
        </div>
    )
}
                
export default BuilderSettingsFrequency