import WidgetStyles from "../../WidgetStyles.module.css"

type Props = {
    tabName: string,
    isSelected: boolean;
    setSelectedTab: (selectedTab: string) => void;
}

const EconomicCalendarTabMenuItem: React.FC<Props> = (props) => (
    <div className={WidgetStyles.tab_element} onClick={() => props.setSelectedTab(props.tabName)}>
        <div className={WidgetStyles.tab_element_title_wrapper}>
            <span className={WidgetStyles.tab_element_title}>{props.tabName}</span>
            {props.tabName === "Upcoming IPOs" ? (
                <div className={WidgetStyles.tab_element_title_circle}>
                    <span className={WidgetStyles.tab_element_title_circle_text}>2</span>
                </div>
            ) : (null)}
        </div>
        <div className={WidgetStyles.tab_element_bar} style={{ opacity: props.isSelected ? "1" : "0" }} />
    </div>
)

export default EconomicCalendarTabMenuItem