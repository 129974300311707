import AppTopNav from './AppTopNav.module.css'
import AppTopNavLeft from './left/app-top-nav-left';
import AppTopNavMiddle from './middle/app-top-nav-middle';
import AppTopNavRight from './right/app-top-nav-right';

const TopNav: React.FC = () => (
    <div className={AppTopNav.container} id="app-top-nav">
        <AppTopNavLeft />
        <AppTopNavMiddle />
        <AppTopNavRight />
    </div>
)

export default TopNav