import { Cell } from "react-table";

import TableTableStyles from "../TableTableStyles.module.css"

type Props = {
    cell: Cell<object>;
    width: number;
}

const CellText: React.FC<Props> = (props) => (
    <div className={TableTableStyles.cell_wrapper} style={{ padding: props.width > 140 ? "6px 16px" : "" }}>
        <span className={TableTableStyles.cell_text}>{!props.cell.value ? "-" : props.cell.value}</span>
    </div>
)

export default CellText