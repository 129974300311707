import { IndicatorParameters } from "../../../../types/Screener"

const checkUnusedParams = (parameters: IndicatorParameters, defaultParameters: IndicatorParameters): IndicatorParameters => {
    const newParameters = { ...parameters }
    Object.keys(newParameters).forEach((key) => {
        if (!Object.keys(defaultParameters).includes(key)) {
            delete newParameters[key as keyof IndicatorParameters]
        }
    })
    return newParameters
}

export default checkUnusedParams