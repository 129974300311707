/* eslint-disable @typescript-eslint/no-unused-expressions, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { createUserWithEmailAndPassword, EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, reauthenticateWithCredential, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut, updatePassword, updateProfile, verifyBeforeUpdateEmail } from "firebase/auth";
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import _ from 'lodash';

import resolveError from "../../firebase/firebase-auth-errors";
import { auth, firebaseStorage, firestoreDB } from "../../firebase/firebase-config";
import { Avatar } from "../../types/Avatar";
import { OnboardingData, Theme, User } from "../../types/User";
import layoutSizes from "../../utils/constants/layout-sizes";

const namespace = "user"

export type UserState = {
    loading: boolean;
    unsavedChanges: boolean;
    saveLoading: boolean;
    errorMessage: string;
    loggedIn: boolean;
    userData: User;
}

export const userInitialStateValue: UserState = {
    loading: true,
    unsavedChanges: false,
    saveLoading: false,
    errorMessage: "",
    loggedIn: false,
    userData: {
        uid: "",
        displayName: "",
        avatar: {
            type: "icon",
            imageUrl: "",
            iconName: "Letter",
            color: "65, 105, 226"
        },
        email: "",
        providers: [],
        emailVerified: false,
        onboardingCompleted: false,
        theme: {
            dark: false,
        },
        dashboardScreenerHeight: layoutSizes.dashboardScreenerHeightMin,
        dashboardScreenerHeightSave: 340,
        dashboardScreenerSettingsWidth: 340,
        dashboardScreenerResultsHeight: layoutSizes.dashboardScreenerResultsHeightMin,
        dashboardScreenerListWidth: 300,
        onboardingData: {
            usageOptions: [],
            timeOption: "",
            findOption: "",
        },
        tier: "",
        timezone: dayjs.tz ? dayjs.tz.guess() : "America/New_York",
        conditionFavourites: [],
        tableColumnPresets: {},
        watchlists: []
    },
}

export const getUserDoc = createAsyncThunk(`${namespace}/getUserDoc`, async (data, { rejectWithValue }) => {   
    if (auth.currentUser) {
        try {
            const docRef = await getDoc(doc(firestoreDB, "users", auth.currentUser.uid));
            if (!docRef.exists()) {
                const authProviders = auth.currentUser?.providerData.map(p => p.providerId)
                const userData = {
                    ...userInitialStateValue.userData,
                    displayName: auth.currentUser?.displayName,
                    email : auth.currentUser?.email,
                    emailVerified: auth.currentUser?.emailVerified,
                    avatar: {
                        type: auth.currentUser?.photoURL ? "image" : "icon",
                        imageUrl: auth.currentUser?.photoURL,
                        iconName: "Letter",
                        color: "65, 105, 226"
                    },
                    providers: authProviders
                }
                await setDoc(doc(firestoreDB, "users", auth.currentUser.uid), userData);
                return {
                    ...userData,
                    uid: auth.currentUser?.uid
                }
            }

            return {
                ...docRef.data(),
                uid: auth.currentUser?.uid
            }

        } catch (error: unknown) {
            if (error instanceof Error) {
                return rejectWithValue(resolveError(error.message));
            }
        }
    }
    return rejectWithValue(resolveError("unknown-error"));
});

export const updateAuthUser = createAsyncThunk(`${namespace}/updateAuthUser`, async (userData: {emailVerified: boolean; providers: string[]}, { rejectWithValue }) => {   
    if (auth.currentUser) {
        try {
            await updateDoc(doc(firestoreDB, "users", auth.currentUser.uid), userData);
        } catch (error: unknown) {
            if (error instanceof Error) {
                return rejectWithValue(resolveError(error.message));
            }
        }
        return userData
    }
    return rejectWithValue(resolveError("unknown-error"));
});

export const signUp = createAsyncThunk(`${namespace}/signUp`, async (data: {email: string, password: string, displayName: string}, { rejectWithValue, fulfillWithValue }) => {
    try {
        await createUserWithEmailAndPassword(auth, data.email, data.password);
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }    
    try {
        if (auth.currentUser) {
            await sendEmailVerification(auth.currentUser);
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }
    
    try {
        if (auth.currentUser) {
            await updateProfile(auth.currentUser, {
                displayName: data.displayName
            });
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }

    try {
        const authProviders = auth.currentUser?.providerData.map(p => p.providerId)
        const userData = {
            ...userInitialStateValue.userData,
            displayName: auth.currentUser?.displayName,
            email : auth.currentUser?.email,
            emailVerified: auth.currentUser?.emailVerified,
            avatar: {
                type: auth.currentUser?.photoURL ? "image" : "icon",
                imageUrl: auth.currentUser?.photoURL,
                iconName: "Letter",
                color: "65, 105, 226"
            },
            providers: authProviders
        }
        if (auth.currentUser) {
            await setDoc(doc(firestoreDB, "users", auth.currentUser.uid), userData);
        }
        if (!auth.currentUser?.emailVerified) {
            return fulfillWithValue({
                userData: {
                    ...userData,
                    uid: auth.currentUser?.uid
                },
                route: "/verify-email"
            })
        } 
        return fulfillWithValue({
            userData: {
                ...userData,
                uid: auth.currentUser.uid
            },
            route: "/onboarding"
        })
        
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }
})

export const signInSSO = createAsyncThunk(`${namespace}/signInSSO`, async (method: string, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
        if (method === "google.com") {
            const googleProvider = new GoogleAuthProvider();
            await signInWithPopup(auth, googleProvider);
        }

        if (method === "facebook.com") {
            const facebookProvider = new FacebookAuthProvider();
            await signInWithPopup(auth, facebookProvider);

            if (auth.currentUser) {
                if (!auth.currentUser.emailVerified) {
                    try {
                        await sendEmailVerification(auth.currentUser);
                    } catch (error: unknown) {
                        if (error instanceof Error) {
                            return rejectWithValue(resolveError(error.message));
                        }
                    }
                }
            }
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }

    try {
        const docRef = await getDoc(doc(firestoreDB, "users", auth.currentUser ? auth.currentUser.uid : ""));
        if (docRef.exists() && auth.currentUser) {
            const { user } = getState() as { user: UserState };
            const updateData: {
                emailVerified?: boolean,
                avatar?: Avatar
            } = {}
            if (!auth.currentUser.emailVerified) {
                return fulfillWithValue({
                    userData: {
                        ...docRef.data(),
                        uid: auth.currentUser.uid
                    },
                    route: "/verify-email"
                })
            } if (!user.userData.emailVerified) {
                updateData.emailVerified = true;
            }
            if (!user.userData.providers.includes(method)) {
                updateData.avatar = {
                    type: auth.currentUser.photoURL ? "image" : "icon",
                    imageUrl: auth.currentUser.photoURL ? auth.currentUser.photoURL : "",
                    iconName: "Letter",
                    color: "65, 105, 226"
                };
            }
            if (Object.keys(updateData).length !== 0) {
                await updateDoc(doc(firestoreDB, "users", auth.currentUser.uid), updateData);
            }
            if (!docRef.data().onboardingCompleted) {
                return fulfillWithValue({
                    userData: {
                        ...docRef.data(),
                        ...updateData,
                        uid: auth.currentUser.uid
                    },
                    route: "/onboarding"
                })
            }
            return fulfillWithValue({
                userData: {
                    ...docRef.data(),
                    ...updateData,
                    uid: auth.currentUser.uid
                },
                route: "/app"
            })
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }

    try {
        const authProviders = auth.currentUser?.providerData.map(p => p.providerId)
        const userData = {
            ...userInitialStateValue.userData,
            displayName: auth.currentUser?.displayName,
            email : auth.currentUser?.email,
            emailVerified: auth.currentUser?.emailVerified,
            avatar: {
                type: auth.currentUser?.photoURL ? "image" : "icon",
                imageUrl: auth.currentUser?.photoURL,
                iconName: "Letter",
                color: "65, 105, 226"
            },
            providers: authProviders
        }
        if (auth.currentUser) {
            await setDoc(doc(firestoreDB, "users", auth.currentUser.uid), userData);
            if (!auth.currentUser.emailVerified) {
                return fulfillWithValue({
                    userData: {
                        ...userData,
                        uid: auth.currentUser.uid
                    },
                    route: "/verify-email"
                })
            } 
            return fulfillWithValue({
                userData: {
                    ...userData,
                    uid: auth.currentUser.uid
                },
                route: "/onboarding"
            })
            
        }
        return rejectWithValue(resolveError("Login failed."));
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }
})

export const login = createAsyncThunk(`${namespace}/login`, async (data: {email: string, password: string}, { rejectWithValue, fulfillWithValue }) => {
    try {
        await signInWithEmailAndPassword(auth, data.email, data.password);
    } catch (error: unknown) {
        try {
            const q = query(collection(firestoreDB, "users"), where("email", "==", data.email));
            const docRefs = await getDocs(q);
            if (docRefs.docs.length > 0) {
                const { providers } = docRefs.docs[0].data() as { providers: string[] }
                if (providers.includes("google.com")) {
                    return rejectWithValue("Google");
                }

                if (providers.includes("facebook.com")) {
                    return rejectWithValue("Facebook");
                }
            }
        } catch (error2: unknown) {
            if (error2 instanceof Error) {
                return rejectWithValue(resolveError(error2.message));
            }
        }
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }

    try {
        const docRef = await getDoc(doc(firestoreDB, "users", auth.currentUser ? auth.currentUser.uid : ""));
        if (docRef.exists() && auth.currentUser) {
            if (!auth.currentUser.emailVerified) {
                return fulfillWithValue({
                    userData: {
                        ...docRef.data(),
                        uid: auth.currentUser.uid
                    },
                    route: "/verify-email"
                })
            }
            if (!docRef.data().onboardingCompleted) {
                return fulfillWithValue({
                    userData: {
                        ...docRef.data(),
                        uid: auth.currentUser.uid
                    },
                    route: "/onboarding"
                })
            }
            return fulfillWithValue({
                userData: {
                    ...docRef.data(),
                    uid: auth.currentUser.uid
                },
                route: "/app"
            })
        }
        return fulfillWithValue({
            userData: {
                ...userInitialStateValue.userData
            },
            route: "/app"
        })
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }

    try {
        const authProviders = auth.currentUser?.providerData.map(p => p.providerId)
        const userData = {
            ...userInitialStateValue.userData,
            displayName: auth.currentUser?.displayName,
            email : auth.currentUser?.email,
            emailVerified: auth.currentUser?.emailVerified,
            avatar: {
                type: auth.currentUser?.photoURL ? "image" : "icon",
                imageUrl: auth.currentUser?.photoURL,
                iconName: "Letter",
                color: "65, 105, 226"
            },
            providers: authProviders
        }
        if (auth.currentUser) {
            await setDoc(doc(firestoreDB, "users", auth.currentUser.uid), userData);
            if (!auth.currentUser.emailVerified) {
                return fulfillWithValue({
                    userData: {
                        ...userData,
                        uid: auth.currentUser.uid
                    },
                    route: "/verify-email"
                })
            } 
            return fulfillWithValue({
                userData: {
                    ...userData,
                    uid: auth.currentUser.uid
                },
                route: "/onboarding"
            })
            
        }
        return fulfillWithValue({
            userData: {
                ...userInitialStateValue.userData
            },
            route: "/app"
        })
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }
});

export const logout = createAsyncThunk(`${namespace}/logout`, async (data, { rejectWithValue }) => {
    try {
        await signOut(auth);
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }
});

export const resendEmailVerification = createAsyncThunk(`${namespace}/resendEmailVerification`, async (data, { rejectWithValue }) => {
    try {
        if (auth.currentUser) {
            await sendEmailVerification(auth.currentUser);
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }
});

export const reloadUser = createAsyncThunk(`${namespace}/reloadUser`, async (data, { getState, rejectWithValue }) => {
    try {
        if (auth.currentUser) {
            await auth.currentUser.reload();
            const { user } = getState() as { user: UserState };
            if (auth.currentUser.emailVerified && !user.userData.emailVerified) {
                await updateDoc(doc(firestoreDB, "users", auth.currentUser.uid), { emailVerified: true });
                return true
            } 
            return false
            
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }
});

export const updateAuthEmail = createAsyncThunk(`${namespace}/updateAuthEmail`, async (data: {newEmail: string; currentPassword: string}, { rejectWithValue }) => {   
    if (auth.currentUser?.email) {
        if (data.newEmail === auth.currentUser.email) {
            return rejectWithValue(resolveError("same-email"));
        }
        
        try {
            const credential = EmailAuthProvider.credential(
                auth.currentUser.email,
                data.currentPassword
            );
            await reauthenticateWithCredential(auth.currentUser, credential)
            await verifyBeforeUpdateEmail(auth.currentUser, data.newEmail);
            await updateDoc(doc(firestoreDB, "users", auth.currentUser.uid), { email: data.newEmail });
            return data.newEmail
        } catch (error: unknown) {
            if (error instanceof Error) {
                return rejectWithValue(resolveError(error.message));
            }
        }
    }
    return rejectWithValue(resolveError("unknown-error"));
});

export const updateAuthPassword = createAsyncThunk(`${namespace}/updateAuthPassword`, async (data: {currentPassword: string; newPassword: string}, { rejectWithValue }) => {   
    if (auth.currentUser?.email) {
        try {
            const credential = EmailAuthProvider.credential(
                auth.currentUser.email,
                data.currentPassword
            );
            await reauthenticateWithCredential(auth.currentUser, credential)
            await updatePassword(auth.currentUser, data.newPassword)
        } catch (error: unknown) {
            if (error instanceof Error) {
                return rejectWithValue(resolveError(error.message));
            }
        }
    }
    return rejectWithValue(resolveError("unknown-error"));
});

export const updateAuthDisplayName = createAsyncThunk(`${namespace}/updateAuthDisplayName`, async (displayName: string, { rejectWithValue }) => {
    if (auth.currentUser) {
        try {
            await updateProfile(auth.currentUser, { displayName });
            await updateDoc(doc(firestoreDB, "users", auth.currentUser.uid), { displayName });
            return displayName
        } catch (error: unknown) {
            if (error instanceof Error) {
                return rejectWithValue(resolveError(error.message));
            }
        }
    }
    return rejectWithValue(resolveError("unknown-error"));
});

export const forgotPassword = createAsyncThunk(`${namespace}/forgotPassword`, async (email: string, { rejectWithValue }) => {   
    try {
        await sendPasswordResetEmail(auth, email)
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }
});

export const deleteAccount = createAsyncThunk(`${namespace}/deleteAccount`, async (password: string, { rejectWithValue }) => {   
    if (auth.currentUser?.email) {
        try {
            const credential = EmailAuthProvider.credential(
                auth.currentUser.email,
                password
            );
            await reauthenticateWithCredential(auth.currentUser, credential);
            const { uid } = auth.currentUser;
            await auth.currentUser.delete();
            await deleteDoc(doc(firestoreDB, "users", uid));
            await deleteObject(ref(firebaseStorage, uid));
        } catch (error: unknown) {
            if (error instanceof Error) {
                return rejectWithValue(resolveError(error.message));
            }
        }
    }
});

export const submitOnboarding = createAsyncThunk(`${namespace}/submitOnboarding`, async (data: {onboardingCompleted: boolean; theme: Theme; onboardingData: OnboardingData}, { rejectWithValue }) => {   
    if (auth.currentUser) {
        try {
            await updateDoc(doc(firestoreDB, "users", auth.currentUser.uid), { ...data });
            return data
        } catch (error: unknown) {
            if (error instanceof Error) {
                return rejectWithValue(resolveError(error.message));
            }
        }
    }
    return rejectWithValue(resolveError("unknown-error"));
});

export const saveUser = createAsyncThunk(`${namespace}/saveUser`, async (data, { getState, rejectWithValue }) => {
    try {
        const { user } = getState() as { user: UserState };
        const newData: Partial<User> = _.cloneDeep(user.userData);
        delete newData.uid
        await updateDoc(doc(firestoreDB, "users", user.userData.uid), { ...newData });
    } catch (error: unknown) {
        if (error instanceof Error) {
            return rejectWithValue(resolveError(error.message));
        }
    }
})

export const userSlice = createSlice({
    name: namespace,
    initialState: userInitialStateValue,
    reducers: {
        changeUserData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = ""
            state.unsavedChanges = true
            state.userData = {
                ...userInitialStateValue.userData,
                ...state.userData,
                ...action.payload
            }
        },
        resetUser: (state) => {
            state.loading = false
            state.errorMessage = ""
            state.loggedIn = false
            state.userData = userInitialStateValue.userData
        },
        resetUserError: (state) => {
            state.errorMessage = ""
        },
    },
    extraReducers: (builder) => { builder
        // getUserDoc
        .addCase(getUserDoc.pending, (state) => {
            state.loading = true
        })
        .addCase(getUserDoc.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.loggedIn = true
            state.unsavedChanges = false
            state.userData = {
                ...userInitialStateValue.userData,
                ...state.userData,
                ...action.payload
            }
        })
        .addCase(getUserDoc.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // updateAuthUser
        .addCase(updateAuthUser.pending, (state) => {
            state.loading = true
        })
        .addCase(updateAuthUser.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.unsavedChanges = false
            state.userData = {
                ...userInitialStateValue.userData,
                ...state.userData,
                ...action.payload
            }
        })
        .addCase(updateAuthUser.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // signUp
        .addCase(signUp.pending, (state) => {
            state.loading = true
        })
        .addCase(signUp.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.loggedIn = true
            state.unsavedChanges = false
            state.userData = {
                ...userInitialStateValue.userData,
                ...state.userData,
                ...action.payload.userData
            }
        })
        .addCase(signUp.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // signInSSO
        .addCase(signInSSO.pending, (state) => {
            state.loading = true
        })
        .addCase(signInSSO.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.loggedIn = true
            state.unsavedChanges = false
            state.userData = {
                ...userInitialStateValue.userData,
                ...state.userData,
                ...action.payload.userData
            }
        })
        .addCase(signInSSO.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // login
        .addCase(login.pending, (state) => {
            state.loading = true
        })
        .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.loggedIn = true
            state.unsavedChanges = false
            state.userData = {
                ...userInitialStateValue.userData,
                ...state.userData,
                ...action.payload.userData
            }
        })
        .addCase(login.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // logout
        .addCase(logout.pending, (state) => {
            state.loading = true
        })
        .addCase(logout.fulfilled, (state) => {
            state.loading = false
            state.loggedIn = false
            state.unsavedChanges = false
            state.userData = userInitialStateValue.userData
        })
        .addCase(logout.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // resendEmailVerification
        .addCase(resendEmailVerification.pending, (state) => {
            state.loading = true
        })
        .addCase(resendEmailVerification.fulfilled, (state) => {
            state.loading = false
        })
        .addCase(resendEmailVerification.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // reloadUser
        .addCase(reloadUser.pending, (state) => {
            state.loading = true
        })
        .addCase(reloadUser.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.unsavedChanges = false
            state.userData.emailVerified = action.payload
        })
        .addCase(reloadUser.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // updateAuthEmail
        .addCase(updateAuthEmail.pending, (state) => {
            state.loading = true
        })
        .addCase(updateAuthEmail.fulfilled, (state, action) => {
            state.loading = false
            state.unsavedChanges = false
            state.userData.email = action.payload
        })
        .addCase(updateAuthEmail.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // updateAuthPassword
        .addCase(updateAuthPassword.pending, (state) => {
            state.loading = true
        })
        .addCase(updateAuthPassword.fulfilled, (state) => {
            state.loading = false
        })
        .addCase(updateAuthPassword.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // updateAuthDisplayName
        .addCase(updateAuthDisplayName.pending, (state) => {
            state.loading = true
        })
        .addCase(updateAuthDisplayName.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.unsavedChanges = false
            state.userData.displayName = action.payload
        })
        .addCase(updateAuthDisplayName.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // forgotPassword
        .addCase(forgotPassword.pending, (state) => {
            state.loading = true
        })
        .addCase(forgotPassword.fulfilled, (state) => {
            state.loading = false
            state.unsavedChanges = false
        })
        .addCase(forgotPassword.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // deleteAccount
        .addCase(deleteAccount.pending, (state) => {
            state.loading = true
        })
        .addCase(deleteAccount.fulfilled, (state) => {
            state.loading = false
            state.loggedIn = false
            state.unsavedChanges = false
            state.userData = userInitialStateValue.userData
        })
        .addCase(deleteAccount.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // submitOnboarding
        .addCase(submitOnboarding.pending, (state) => {
            state.loading = true
        })
        .addCase(submitOnboarding.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.unsavedChanges = false
            state.userData.onboardingCompleted = true
            state.userData = {
                ...userInitialStateValue.userData,
                ...state.userData,
                ...action.payload
            }
        })
        .addCase(submitOnboarding.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.errorMessage = action.payload
        })
        // saveUser
        .addCase(saveUser.pending, (state) => {
            state.saveLoading = true
        })
        .addCase(saveUser.fulfilled, (state) => {
            state.saveLoading = false
            state.unsavedChanges = false
        })
        .addCase(saveUser.rejected, (state, action: PayloadAction<any>) => {
            state.saveLoading = false
            state.errorMessage = action.payload
        })
    }
})

export const { changeUserData, resetUser, resetUserError } = userSlice.actions

export default userSlice.reducer;