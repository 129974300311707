import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import CriteriaDropdown from '../../criteria-dropdown';
import CriteriaInputTimeframe from '../../criteria-input-timeframe';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        ohlc: "open",
        timeframe: "1 minute",
    },
    setParameters: () => {},
}

const CriteriaParameterLastCandle: React.FC<Props> = (props) => {
    const ohlc = useMemo(() => props.parameters.ohlc ? props.parameters.ohlc : defaultProps.parameters.ohlc!, [props.parameters.ohlc])
    const timeframe = useMemo(() => props.parameters.timeframe ? props.parameters.timeframe : defaultProps.parameters.timeframe!, [props.parameters.timeframe])

    const setOhlc = (newOhlc: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, ohlc: newOhlc }, defaultProps.parameters))
    }

    const setTimeframe = (newTimeframe: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, timeframe: newTimeframe }, defaultProps.parameters))
    }

    return (
        <>
            <CriteriaDropdown dropdownOptions={["open", "high", "low", "close"]} transformType="" value={ohlc} setValue={setOhlc} />
            <CriteriaInputTimeframe timeframe={timeframe} setTimeframe={setTimeframe} />
        </>
    )
}

export default CriteriaParameterLastCandle