import { useEffect, useState } from 'react';

import ConditionRow from "../ConditionRow.module.css"
import { getBackgroundColor } from './get-background-color';

type Props = {
    categoryName: string;
    dropdownOptions: string[],
    searchValue: string;
    values: string[];
    setValues: (categoryValues: string[], categoryName: string) => void;
}

const defaultProps = {
    categoryName: "",
    dropdownOptions: [],
    searchValue: "",
    values: [],
    setValues: () => {}
}

const CriteriaDropdownSelectionNestedCategory: React.FC<Props> = (props) => {
    const { categoryName, dropdownOptions, searchValue, values, setValues } = { ...defaultProps, ...props }

    const [expanded, setExpanded] = useState(false);
    const [categoryValues, setCategoryValues] = useState(values?.filter((v) => dropdownOptions.includes(v)));
  
    useEffect(() => {
        if (JSON.stringify(values?.filter((v) => dropdownOptions.includes(v))) !== JSON.stringify(categoryValues)) {
            setCategoryValues(values?.filter((v) => dropdownOptions.includes(v)))
        }
    }, [values])

    useEffect(() => {
        setValues(categoryValues || [], categoryName)
    }, [categoryValues])

    useEffect(() => {
        if (searchValue !== "") {
            setExpanded(true)
        } else {
            setExpanded(false)
        }
    }, [searchValue])

    const changeValues = (option: string) => {
        const position = categoryValues?.findIndex((op) => option === op);
        const newValues = categoryValues ? [...categoryValues] : []
        if (position !== (undefined || null)) {
            if (position > -1) {
                newValues.splice(position, 1)
                setCategoryValues(newValues)
            } else if (position === -1) {
                newValues.push(option)
                newValues.sort((a, b) => {
                    const fa = a.toLowerCase();
                    const fb = b.toLowerCase();
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                })
                setCategoryValues(newValues)
            }
        }
    }

    const selectAllClick = (event: React.FormEvent) => {
        event.stopPropagation();
        if (JSON.stringify(categoryValues) === JSON.stringify(dropdownOptions)) {
            setCategoryValues([])
        } else {
            setCategoryValues(dropdownOptions)
        }
    }
    
    if (dropdownOptions.filter((option) => option.trim().toLowerCase().replaceAll(" ", "").includes(searchValue.trim().toLowerCase().replaceAll(" ", ""))).length > 0) {
        return (
            <div className={ConditionRow.criteria_dropdown_menu_category_wrapper}>
                <div className={ConditionRow.criteria_dropdown_menu_item_wrapper} style={{ height: "36px", padding: "0px 8px 0px 4px", justifyContent: "space-between", backgroundColor: expanded ? "rgba(var(--dl-color-backgrounds-hover3), 0.8)" : "" }} onClick={() => setExpanded(!expanded)}>
                    <div className={ConditionRow.criteria_dropdown_menu_item_wrapper} style={{ pointerEvents: "none" }}>
                        <div className={ConditionRow.criteria_dropdown_menu_item_checkbox} onClick={(event) => selectAllClick(event)}>
                            <div className={ConditionRow.criteria_dropdown_menu_item_checkbox_inner} style={{ backgroundColor: getBackgroundColor(categoryValues, dropdownOptions) }} />
                        </div>
                        <p className={`${ConditionRow.criteria_dropdown_menu_item} ${ConditionRow.menu_item_category}`}>{categoryName}</p>
                    </div>
                    <svg viewBox="0 0 1024 1024" className={ConditionRow.criteria_dropdown_button_arrow_icon} style={{ fill: expanded ? "rgb(var(--dl-color-primary-100))" : "", transform: expanded ? "rotate(180deg)" : "" }}>
                        <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                    </svg>
                </div>
                <div className={ConditionRow.criteria_dropdown_menu_scroll_wrapper} style={{ maxHeight: expanded ? "800px" : "0px", overflow: "hidden" }}>
                    {dropdownOptions.filter((option) => option.trim().toLowerCase().replaceAll(" ", "").includes(searchValue.trim().toLowerCase().replaceAll(" ", ""))).sort((a, b) => {
                        const fa = a.toLowerCase();
                        const fb = b.toLowerCase();
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }).map((option, index) => (
                        <div className={ConditionRow.criteria_dropdown_menu_item_wrapper} style={{ paddingLeft: "16px" }} key={index} onClick={() => {changeValues(option)}}>
                            <div className={ConditionRow.criteria_dropdown_menu_item_checkbox}>
                                <div className={ConditionRow.criteria_dropdown_menu_item_checkbox_inner} style={{ backgroundColor: categoryValues?.includes(option) ? "rgb(var(--dl-color-primary-100))" : "" }} />
                            </div>
                            <p className={ConditionRow.criteria_dropdown_menu_item}>{option}</p>
                        </div>
                    ))}
                </div>
                <div className={ConditionRow.criteria_dropdown_menu_separator} />
            </div>
        )
    }

    return null
}

export default CriteriaDropdownSelectionNestedCategory