import SignupLoginComponents from './SignupLoginComponents.module.css'

type SignUpLoginInfoProps = {
  text: string;
  color: "red" | "green";
  visible: boolean;
}

const defaultProps = {
    text: "",
    color: "red",
    visible: false,
}

const SignUpLoginInfo: React.FC<SignUpLoginInfoProps> = (props) => {
    const { text, color, visible } = { ...defaultProps, ...props }
    
    return (
        <div className={SignupLoginComponents.info_wrapper}>
            {visible && (
                <div className={color === "green" ? `${SignupLoginComponents.info_container} ${SignupLoginComponents.green}` : `${SignupLoginComponents.info_container}`}>
                    <span>{text}</span>
                </div>
            )}
        </div>
    )
}

export default SignUpLoginInfo