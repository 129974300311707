import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../types/Screener';
import CriteriaInputNumber from '../criteria-input-number';
import checkUnusedParams from '../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        dollarChange: "5",
    },
    setParameters: () => {},
}

const CriteriaParameterDollarChange: React.FC<Props> = (props) => {
    const number = useMemo(() => props.parameters.dollarChange ? props.parameters.dollarChange : defaultProps.parameters.dollarChange!, [props.parameters.dollarChange])

    const setNumber = (newNumber: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, dollarChange: newNumber }, defaultProps.parameters))
    }

    return (
        <CriteriaInputNumber number={number} setNumber={setNumber} min={-9999} max={9999} step="0.01" width="70px" />
    )
}

export default CriteriaParameterDollarChange