import { useState } from "react";

import { changeScreenerData, saveScreener } from "../../../../../../store/features/screener";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { createState } from "../../../../../../types/creators/create-state";
import { State } from "../../../../../../types/Screener";
import DashboardScreenerDetailsStyles from "../DashboardScreenerDetails.module.css"
import DashboardScreenerNewStateCard from "./dashboard-screener-new-state-card";

type Props = {
    setSelectedState: React.Dispatch<React.SetStateAction<State | null>>;
    newState: number;
    setNewState: React.Dispatch<React.SetStateAction<number | null>>;
}

const DashboardScreenerNewState: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    const screener = useAppSelector((state) => state.screener)
    const user = useAppSelector((state) => state.user)

    const [selectedType, setSelectedType] = useState("");

    const addState = () => {
        if (selectedType !== "") {
            const newStates = [...screener.data.states]
            const state = createState(user.userData, {
                type: selectedType as "filter" | "alert",
                name: `${selectedType.charAt(0).toUpperCase()}${selectedType.slice(1)}`,
            })
            newStates.splice(props.newState, 0, state);
            dispatch(changeScreenerData({
                ...screener.data,
                states: newStates
            }));
            dispatch(saveScreener());
            props.setSelectedState(state);
            props.setNewState(null);
        }
    }

    const cancelButtonClick = () => {
        if (screener.data.states.length > 0) {
            props.setSelectedState(props.newState - 1 >= 0 ? screener.data.states[props.newState - 1] : null)
        } else {
            props.setSelectedState(null)
        }
        props.setNewState(null);
    }

    return (
        <div className={DashboardScreenerDetailsStyles.builder_wrapper} id="dashboard-screener-builder-wrapper">
            <div className={DashboardScreenerDetailsStyles.builder}>
                <div className={DashboardScreenerDetailsStyles.conditions_container}>
                    <div className={DashboardScreenerDetailsStyles.title_row}>
                        <span className={DashboardScreenerDetailsStyles.title_text}>Add A New State</span>
                    </div>
                    <div className={DashboardScreenerDetailsStyles.add_state_wrapper}>
                        <DashboardScreenerNewStateCard title="Filter" description="A filter state creates a live updated list of stocks that meet your defined criteria." onClick={() => setSelectedType("filter")} selected={selectedType === "filter"} />
                        <DashboardScreenerNewStateCard title="Alert" description="An alert state creates a time stamped list where a stock gets added every time it meets your criteria." onClick={() => setSelectedType("alert")} selected={selectedType === "alert"} />
                    </div>
                    <div className={DashboardScreenerDetailsStyles.conditions_bottom_row}>
                        <button type="button" className={DashboardScreenerDetailsStyles.conditions_cancel_button} onClick={() => cancelButtonClick()}>Cancel</button>
                        <button type="button" className={DashboardScreenerDetailsStyles.conditions_add_state_button} onClick={() => addState()}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
    
export default DashboardScreenerNewState