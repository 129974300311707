import BootstrapTooltip from "../../../../../../utils/components/tooltip/bootstrap-tooltip";
import DashboardWidgetStyles from "../DashboardWidgetStyles.module.css";
import GridWidgetSettingsOptionDelete from "./grid-widget-settings-option-delete";
import GridWidgetSettingsOptionLoadTemplate from "./grid-widget-settings-option-load-template";
import GridWidgetSettingsOptionReset from "./grid-widget-settings-option-reset";
import GridWidgetSettingsOptionSaveTemplate from "./grid-widget-settings-option-save-template";

type Props = {
    id: string;
}

const GridWidgetSettingsOptionsPopup: React.FC<Props> = (props) => (
    <div className={DashboardWidgetStyles.settings_popup_inner}>
        <div className={DashboardWidgetStyles.settings_top_row}>
            <p className={DashboardWidgetStyles.settings_popup_header}>Widget Settings</p>
            <BootstrapTooltip title="Learn more about widgets">
                <svg viewBox="0 0 1024 1024" className={DashboardWidgetStyles.settings_popup_help_icon}>
                    <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                </svg>
            </BootstrapTooltip>
        </div>
        <div className={DashboardWidgetStyles.settings_popup_section}>
            <GridWidgetSettingsOptionLoadTemplate />
            <GridWidgetSettingsOptionSaveTemplate />
        </div>
        <div className={DashboardWidgetStyles.settings_popup_separator} />
        <div className={DashboardWidgetStyles.settings_popup_section}>
            <GridWidgetSettingsOptionReset />
            <GridWidgetSettingsOptionDelete id={props.id} />
        </div>
    </div>
)

export default GridWidgetSettingsOptionsPopup