const layoutSizes = {
    topNavHeight: 50,
    dashboardScreenerHeightMin: 42,
    dashboardScreenerResultsHeightMin: 42,
    dashboardScreenerSettingsWidthMin: 290,
    dashboardScreenerSettingsWidthMax: 526,
    dashboardScreenerListWidthMin: 200,
    dashboardScreenerListWidthMax: 400
}

export default layoutSizes