import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import ConditionRow from "../../../ConditionRow.module.css"
import CriteriaInputSwitch from '../../criteria-input-switch';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
    typeOptions: string[];
    reportPeriodOptions: string[];
}

const defaultProps: Props = {
    parameters: {
        type: "Basic",
        reportPeriod: "Quarterly Report",
    },
    setParameters: () => {},
    typeOptions: ["Basic", "Diluted"],
    reportPeriodOptions: ["Quarterly Report", "Annual Report"],
}

const CriteriaParameterEPS: React.FC<Props> = (props) => {
    const type = useMemo(() => props.parameters.type && props.typeOptions.includes(props.parameters.type) ? props.parameters.type : defaultProps.parameters.type!, [props.parameters.type])
    const reportPeriod = useMemo(() => props.parameters.reportPeriod && props.reportPeriodOptions.includes(props.parameters.reportPeriod) ? props.parameters.reportPeriod : defaultProps.parameters.reportPeriod!, [props.parameters.reportPeriod])

    const setType = (newType: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, type: newType }, defaultProps.parameters))
    }

    const setReportPeriod = (newReportPeriod: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, reportPeriod: newReportPeriod }, defaultProps.parameters))
    }

    return (
        <>
            <CriteriaInputSwitch switchOptions={props.typeOptions} value={type} setValue={setType} width="100px" />
            <span className={ConditionRow.criteria_text}>of last</span>
            <CriteriaInputSwitch switchOptions={props.reportPeriodOptions} value={reportPeriod} setValue={setReportPeriod} width="140px" />
        </>
    )
}

export default CriteriaParameterEPS