import { useState } from "react";

import { changeDashboardData, getDashboard, saveDashboard } from "../../../../../../store/features/dashboard";
import { setPopupElement } from "../../../../../../store/features/management";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../../types/changeHandlers/change-dashboard-widget";
import { TableSettings, Widget } from "../../../../../../types/Dashboard";
import useClickOutsidePopup from "../../../../../hooks/use-click-outside-popup";
import BootstrapTooltip from "../../../../tooltip/bootstrap-tooltip";
import WidgetTopRowStyles from "../../../WidgetTopRowStyles.module.css";
import TableTopRowButtonSourceSelectedItem from "./dashboard-screener-source-selected-item";
import TableTopRowButtonSourceTabIndices from "./dashboard-screener-source-tab-indices";
import TableTopRowButtonSourceTabScreeners from "./dashboard-screener-source-tab-screeners";
import TableTopRowButtonSourceTabWatchlists from "./table-top-row-button-source-tab-watchlists";
import WidgetTableSource from "./WidgetTableSource.module.css";

type Props = {
    id: string;
}

const tabs = ["Indices", "Screeners", "Watchlists"]

const TableButtonSourcePopup: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    
    const user = useAppSelector((state) => state.user)
    const allScreeners = useAppSelector((state) => state.allScreeners)
    const dashboard = useAppSelector((state) => state.dashboard)

    const widget: Widget = dashboard.data.widgets.find((w) => w.id === props.id)!

    const settings = widget.settings as TableSettings

    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const [search, setSearch] = useState("");

    const refClickOutside = useClickOutsidePopup();

    const resetValues = () => {
        const newSettings = {
            ...settings,
            sources: {
                indices: [],
                screeners: [],
                watchlists: []
            }
        }
        const newWidgets = changeDashboardWidgetData(props.id, dashboard.data.widgets, { settings: newSettings })
        dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
    }

    const saveButtonClick = () => {
        dispatch(setPopupElement(null));
        dispatch(saveDashboard());
    }

    const cancelButtonClick = async () => {
        dispatch(setPopupElement(null));
        const dashboardDb = await dispatch(getDashboard(dashboard.data.id)).unwrap();
        if (dashboardDb) {
            dispatch(changeDashboardData(dashboardDb));
        }
    }

    return (
        <div className="app_popup_wrapper_middle" ref={refClickOutside}>
            <div className={WidgetTopRowStyles.popup_middle_top_row}>
                <span className={WidgetTopRowStyles.popup_middle_top_row_title}>Sources</span>
                <div className={WidgetTopRowStyles.popup_middle_top_row_icon_x_wrapper} onClick={() => dispatch(setPopupElement(null))}>
                    <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.popup_middle_top_row_icon_x}>
                        <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                    </svg>
                </div>
            </div>
            <div className={WidgetTopRowStyles.popup_middle_content_wrapper} style={{ maxWidth: "860px", maxHeight: "560px", padding: "16px 0px 24px" }}>
                <div className={WidgetTopRowStyles.popup_middle_wrapper_scroll} style={{ overflowY: "auto" }}>
                    <div className={WidgetTableSource.explanation_text_row}>
                        <span className={WidgetTableSource.explanation_text}>Select the source list of stocks that will be displayed in the the table.</span>
                        <BootstrapTooltip title="Learn more about sources">
                            <svg viewBox="0 0 1024 1024" className={WidgetTableSource.explanation_text_help_icon}>
                                <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                            </svg>
                        </BootstrapTooltip>
                    </div>
                    <div className={WidgetTableSource.tab_selection_wrapper}>
                        <div className={WidgetTableSource.tab_row}>
                            <div className={WidgetTableSource.tab_row_tabs}>
                                {tabs.map((tab) => (
                                    <div className={tab === selectedTab ? `${WidgetTableSource.tab_row_tab} ${WidgetTableSource.tab_row_tab_active}` : `${WidgetTableSource.tab_row_tab}`} key={tab} onClick={() => setSelectedTab(tab)}>
                                        <span className={WidgetTableSource.tab_row_tab_text}>{tab}</span>
                                    </div>
                                ))}
                            </div>
                            <div className={WidgetTableSource.search_wrapper}>
                                <svg viewBox="0 0 1024 1024" className={WidgetTableSource.search_icon}>
                                    <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z" />
                                </svg>
                                <input className={WidgetTableSource.search_input}
                                    type="text"
                                    placeholder="Search..."
                                    onChange={(event) => {if (event.target.value.length <= 50) {setSearch(event.target.value)}}}
                                    value={search}
                                />
                                {search !== "" && (
                                    <div className={WidgetTableSource.search_delete} onClick={() => setSearch("")}>
                                        <svg viewBox="0 0 1024 1024" className={WidgetTableSource.search_delete_icon}>
                                            <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {selectedTab === "Indices" ? <TableTopRowButtonSourceTabIndices widgetId={props.id} search={search} /> : null}
                    {selectedTab === "Screeners" ? <TableTopRowButtonSourceTabScreeners widgetId={props.id} search={search} /> : null}
                    {selectedTab === "Watchlists" ? <TableTopRowButtonSourceTabWatchlists widgetId={props.id} search={search} /> : null}
                    <div className={WidgetTableSource.selected_sources_wrapper}>
                        <span className={WidgetTableSource.selected_sources_title}>Ausgewählte Quellen:</span>
                        <div className={WidgetTableSource.selected_sources_content}>
                            <div className={WidgetTableSource.selected_sources_list}>
                                {settings.sources.indices.map((index) => <TableTopRowButtonSourceSelectedItem widgetId={props.id} id={index} name={index} type="Indices" key={index} />)}
                                {settings.sources.screeners.map((selectedDashboardScreenerStyles) => {
                                    const selectedScreener = allScreeners.screeners[selectedDashboardScreenerStyles.screenerId]
                                    const selectedState = selectedScreener.states.find((s) => s.id === selectedDashboardScreenerStyles.stateId)
                                    if (typeof selectedScreener !== "undefined" && typeof selectedState !== "undefined") {
                                        return (
                                            <TableTopRowButtonSourceSelectedItem widgetId={props.id} id={selectedState.id} name={`${selectedScreener.name} / ${selectedState.name}`} type="Screeners" key={selectedDashboardScreenerStyles.stateId} />
                                        )
                                    }
                                    return null
                                })}
                                {settings.sources.watchlists.map((watchlistId) => {
                                    const watchlist = user.userData.watchlists.find((w) => w.id === watchlistId)
                                    if (typeof watchlist !== "undefined") {
                                        return (
                                            <TableTopRowButtonSourceSelectedItem widgetId={props.id} id={watchlistId} name={watchlist.name} type="Watchlists" key={watchlistId} />
                                        )
                                    }
                                    return null
                                })}
                            </div>
                            <div className={WidgetTableSource.symbol_count}>
                                <span className={WidgetTableSource.symbol_count_text_gray}>Symbols</span>
                                <span className={WidgetTableSource.stocklist_row_text}>500</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={WidgetTopRowStyles.popup_middle_bottom_row}>
                <div className={WidgetTopRowStyles.popup_middle_bottom_row_split}>
                    <div className={WidgetTopRowStyles.popup_middle_bottom_row_button} onClick={() => {resetValues()}}>
                        <span className={WidgetTopRowStyles.popup_middle_bottom_row_button_text}>Reset to default</span>
                    </div>
                </div>
                <div className={WidgetTopRowStyles.popup_middle_bottom_row_split}>
                    <div className={WidgetTopRowStyles.popup_middle_bottom_row_button} onClick={cancelButtonClick}>
                        <span className={WidgetTopRowStyles.popup_middle_bottom_row_button_text}>Cancel</span>
                    </div>
                    <div className={WidgetTopRowStyles.popup_middle_bottom_row_button_blue} onClick={saveButtonClick}>
                        <span className={WidgetTopRowStyles.popup_middle_bottom_row_button_text}>Save</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableButtonSourcePopup