
import { changeDashboardData, getDashboard, saveDashboard } from "../../../../../../../../store/features/dashboard";
import { setPopupElement } from "../../../../../../../../store/features/management";
import { useAppDispatch, useAppSelector } from "../../../../../../../../store/hooks";
import { getDefaultColumns } from "../../../../../../../../utils/constants/table-columns";
import useClickOutsidePopup from "../../../../../../../../utils/hooks/use-click-outside-popup";
import DashboardScreenerTopRowStyles from "../../DashboardScreenerTopRowStyles.module.css"
import TableTopRowButtonColumnsPopupLeft from "./table-top-row-button-columns-popup-left";
import TableTopRowButtonColumnsPopupRight from "./table-top-row-button-columns-popup-right";
import TableTopRowButtonColumnsTemplate from "./table-top-row-button-columns-template";

const TableTopRowButtonColumnsPopup: React.FC = () => {
    const dispatch = useAppDispatch();
    
    const dashboard = useAppSelector((state) => state.dashboard)

    const settings = dashboard.data.screenerTableSettings

    const { columns } = settings

    const refClickOutside = useClickOutsidePopup();

    const resetValues = async () => {
        const newColumns = getDefaultColumns()
        const newSettings = {
            ...settings,
            columns: newColumns
        }
        dispatch(changeDashboardData({ screenerTableSettings: newSettings }));
    }

    const saveButtonClick = () => {
        dispatch(setPopupElement(null));
        dispatch(saveDashboard());
    }

    const cancelButtonClick = async () => {
        dispatch(setPopupElement(null));
        const dashboardDb = await dispatch(getDashboard(dashboard.data.id)).unwrap();
        if (dashboardDb) {
            dispatch(changeDashboardData(dashboardDb));
        }
    }

    return (
        <div className="app_popup_wrapper_middle" ref={refClickOutside}>
            <div className={DashboardScreenerTopRowStyles.popup_middle_top_row}>
                <span className={DashboardScreenerTopRowStyles.popup_middle_top_row_title}>Columns</span>
                <div className={DashboardScreenerTopRowStyles.popup_middle_top_row_icon_x_wrapper} onClick={() => cancelButtonClick()}>
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerTopRowStyles.popup_middle_top_row_icon_x}>
                        <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                    </svg>
                </div>
            </div>
            <div className={DashboardScreenerTopRowStyles.popup_middle_middle_button_row}>
                <TableTopRowButtonColumnsTemplate settings={settings} />
            </div>
            <div className={DashboardScreenerTopRowStyles.popup_middle_content_wrapper} style={{ maxWidth: "860px", maxHeight: "460px", padding: "8px" }}>
                <div className={DashboardScreenerTopRowStyles.popup_middle_content_column_split}>
                    <TableTopRowButtonColumnsPopupLeft columns={columns} settings={settings} />
                    <div className={DashboardScreenerTopRowStyles.popup_middle_content_column_separator} />
                    <TableTopRowButtonColumnsPopupRight settings={settings} />
                </div>
            </div>
            <div className={DashboardScreenerTopRowStyles.popup_middle_bottom_row}>
                <div className={DashboardScreenerTopRowStyles.popup_middle_bottom_row_split}>
                    <div className={DashboardScreenerTopRowStyles.popup_middle_bottom_row_button} onClick={resetValues}>
                        <span className={DashboardScreenerTopRowStyles.popup_middle_bottom_row_button_text}>Reset to default</span>
                    </div>
                </div>
                <div className={DashboardScreenerTopRowStyles.popup_middle_bottom_row_split}>
                    <div className={DashboardScreenerTopRowStyles.popup_middle_bottom_row_button} onClick={cancelButtonClick}>
                        <span className={DashboardScreenerTopRowStyles.popup_middle_bottom_row_button_text}>Cancel</span>
                    </div>
                    <div className={DashboardScreenerTopRowStyles.popup_middle_bottom_row_button_blue} onClick={saveButtonClick}>
                        <span className={DashboardScreenerTopRowStyles.popup_middle_bottom_row_button_text}>Save</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableTopRowButtonColumnsPopup