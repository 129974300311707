/* eslint-disable */

import { onAuthStateChanged } from 'firebase/auth';
import _ from 'lodash';
import { useEffect } from 'react';

import { auth } from '../../firebase/firebase-config';
import { setScreeners } from '../../store/features/allScreeners';
import { resetManagement } from '../../store/features/management';
import { getUserDoc, resetUser, updateAuthUser } from '../../store/features/user';
import { resetScreener } from '../../store/features/screener';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const UserListener: React.FC = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user)

    useEffect(() => onAuthStateChanged(auth, async (currentUser) => {
        if (currentUser) {
            if (user.loggedIn) {
                const authProviders = auth.currentUser?.providerData.map(p => p.providerId)
                if (auth.currentUser?.emailVerified !== user.userData.emailVerified || !_.isEqual(authProviders, user.userData.providers)) {
                    dispatch(updateAuthUser({
                        emailVerified: auth.currentUser ? auth.currentUser.emailVerified : false,
                        providers: authProviders || []
                    }));
                }
            } else {
                await dispatch(getUserDoc()).unwrap();
            }
        } else {
            dispatch(resetManagement());
            dispatch(resetUser());
            dispatch(resetScreener());
            dispatch(setScreeners({}));
        }
    }), []);

    return <></>
};

export default UserListener