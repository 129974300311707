import { Skeleton } from "@mui/material"

import DashboardScreenerBuilderConditionStyles from "./DashboardScreenerBuilderCondition.module.css"

const DashboardScreenerBuilderConditionCardFallback: React.FC = () => (
    <div className={DashboardScreenerBuilderConditionStyles.card_container}>
        <div className={DashboardScreenerBuilderConditionStyles.card_icons}>
            <Skeleton variant="rounded" animation="wave" sx={{ borderRadius: "8px", bgcolor: "rgb(var(--dl-color-backgrounds-skeleton))" }} width={30} height={30} />
        </div>
        <div className={DashboardScreenerBuilderConditionStyles.card_text_wrapper} style={{ gap: "0px" }}>
            <Skeleton variant="text" animation="wave" sx={{ marginBottom: "4px", fontSize: '18px', bgcolor: "rgb(var(--dl-color-backgrounds-skeleton))" }} width={260} />
            <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', bgcolor: "rgb(var(--dl-color-backgrounds-skeleton))" }} width={360} />
            <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', bgcolor: "rgb(var(--dl-color-backgrounds-skeleton))" }} width={360} />
        </div>
    </div>
)

export default DashboardScreenerBuilderConditionCardFallback