import { darkTheme, lightTheme } from "../constants/theme-styles";

type Props = {
    dark: boolean
}

const setThemeColors = (props: Props) => {
    let theme = lightTheme
    if (props.dark) {
        theme = darkTheme
    }
    const oldValue = getComputedStyle(document.documentElement).getPropertyValue("--dl-color-white")
    if (!(oldValue == theme["--dl-color-white"])) {
        Object.keys(theme).forEach((key) => {
            const color = theme[key];
            document.documentElement.style.setProperty(key, theme[key]);
        })
    }
}

export default setThemeColors