import { useNavigate } from 'react-router-dom';

import PageNotFound from './PageNotFound.module.css'

const ErrorPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className={PageNotFound.wrapper}>
            <p className={PageNotFound.error_header}>Unknown Error</p>
            <p className={PageNotFound.error_subheader}>You have been logged out.</p>
            <button type="button" className={PageNotFound.button_home} onClick={() => {navigate("/login")}}>Back to Login</button>
        </div>
    )
}

export default ErrorPage