import PopUp from "../../../../../../PopUp.module.css";

type DashboardScreenerBuilderConditionSidebarItemProps = {
  name: string;
  icon: JSX.Element;
  color: string;
  isActive: boolean;
  setCategory: (categoryName: string) => void;
}

const DashboardScreenerBuilderConditionSidebarItem: React.FC<DashboardScreenerBuilderConditionSidebarItemProps> = (props) => (
    <div
        className={PopUp.sidebar_top_item}
        style={{ backgroundColor: props.isActive ? `rgba(${props.color}, 0.1)` : "" }}
        onClick={() => {props.setCategory(props.name)}}
    >
        <div className={PopUp.sidebar_top_sidebar} style={{ opacity: props.isActive ? "1" : "0", backgroundColor: `rgb(${props.color})` }}/>
        <div className={PopUp.sidebar_top_sidebar_icon_wrapper} style={{ color: props.isActive ? `rgb(${props.color})` : "" }}>
            {props.icon}
        </div>
        <p className={PopUp.sidebar_top_item_text} style={{ color: props.isActive ? `rgb(${props.color})` : "" }}>{props.name}</p>
    </div>
)

export default DashboardScreenerBuilderConditionSidebarItem