import React from "react";

import WidgetStyles from "../../../../WidgetStyles.module.css"
import TableEconomyHeader from "./table-economy-header";
import TableEconomyList from "./table-economy-list";
import TableEconomySeparator from "./table-economy-separator";

type Props = {
    width: number;
}

const TableEconomy: React.FC<Props> = (props) => (
    <div className={WidgetStyles.simple_table_wrapper}>
        <TableEconomyHeader />
        <TableEconomySeparator />
        <TableEconomyList width={props.width} />
    </div>
)

export default TableEconomy