export interface Screeners {
    [id: string]: Screener
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isScreener(object: any): object is Screener {
    return ("id" in object && "name" in object && "sources" in object && "states" in object)
}

export interface Screener {
    id: string;
    name: string;
    description: string;
    active: boolean;
    adminUid: string;
    access: string[];
    sources: Sources;
    states: State[],
}

export interface Sources {
    indices: string[],
    screeners: ScreenerSource[],
    watchlists: string[]
}

export interface ScreenerSource {
    screenerId: string;
    stateId: string;
}

export interface State {
    id: string;
    type: "filter" | "alert";
    name: string;
    description: string;
    updateInterval: string;
    recurringResets: RecurringReset[];
    manualResets: ManualReset[];
    frequency: {
        type: string;
        interval: string;
        multiplier: string;
    },
    maxCount: string;
    criteria: CriteriaGroup;
    results: ScreenerStateResult[];
}

export interface RecurringReset {
    interval: string;
    time: string;
    timezone: string;
}

export interface ManualReset {
    date: string;
    time: string;
    timezone: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isCriteriaGroup(object: any): object is CriteriaGroup {
    return "criteriaList" in object;
}

export interface CriteriaGroup {
    id: string;
    type: "group";
    connection: string;
    criteriaList: (CriteriaGroup | CriteriaRow)[];
}

export interface CriteriaRow {
    id: string;
    type: "row";
    settings: Settings;
}

export interface Settings {
    idA: string;
    idB1: string;
    idB2: string;
    parametersA: IndicatorParameters;
    parametersB1: IndicatorParameters;
    parametersB2: IndicatorParameters;
    operator: string;
}

export interface IndicatorParameters {
    countries?: string[];
    industries?: string[];
    type?: string;
    reportPeriod?: string;
    calculationPeriod?: string;
    timeframe?: string;
    length?: string;
    offset?: string;
    multiplicator?: string;
    band?: string;
    stdDev?: string;
    reference?: string;
    slowEmaLength?: string;
    fastEmaLength?: string;
    signalSmooth?: string;
    kLength?: string;
    kSmooth?: string;
    dSmooth?: string;
    keywords?: string[];
    ohlc?: string;
    marketStatus?: string[];
    timeOfDay?: string;
    dollarChange?: string;
    dollarValue?: string;
    number?: string;
    factor?: string;
    percentChange?: string;
    percentValue?: string;
    rangeValue?: string;
    ratio?: string;
    unit?: string;
}

export interface ScreenerStateResult {
    ticker: string;
    time: string;
}