import { changeDashboardData } from "../../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../../types/changeHandlers/change-dashboard-widget";
import { TableSettings, Widget } from "../../../../../../types/Dashboard";
import ToggleSwitchBig from "../../../../toggle-switch/toggle-switch-big";
import WidgetTopRowStyles from "../../../WidgetTopRowStyles.module.css"

type Props = {
    id: string;
    settings: TableSettings;
}

const TableButtonSettingsTickerDetails: React.FC<Props> = (props) => {
    const dashboard = useAppSelector((state) => state.dashboard)
    const widget: Widget = useAppSelector((state) => state.dashboard.data.widgets.find((w) => w.id === props.id))!
    const dispatch = useAppDispatch();

    const settings = widget.settings as TableSettings

    const { showTickerDetails } = settings

    const changeSettingsTickerDetails = () => {
        const newSettings = {
            ...settings,
            showTickerDetails: !showTickerDetails
        }
        const newWidgets = changeDashboardWidgetData(props.id, dashboard.data.widgets, { settings: newSettings })
        dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
    }

    return (
        <div className={WidgetTopRowStyles.popup_middle_settings_row}>
            <span className={WidgetTopRowStyles.popup_middle_settings_row_title}>Show ticker details:</span>
            <div className={WidgetTopRowStyles.popup_middle_settings_row_right}>
                <ToggleSwitchBig isToggled={props.settings.showTickerDetails} onClick={changeSettingsTickerDetails} />
                <span className={WidgetTopRowStyles.popup_middle_settings_row_subtext}>The company name and logo are displayed next to the ticker symbol.</span>
            </div>
        </div>
    )
}

export default TableButtonSettingsTickerDetails