import { HeaderGroup, TableState } from "react-table"

import DashboardScreenerTopRowStyles from "../DashboardScreenerTopRowStyles.module.css"
import TableTopRowButtonColumns from "./columns/table-top-row-button-columns"
import TableTopRowButtonFilter from "./filter/table-top-row-button-filter"
import TableTopRowButtonResetWidths from "./table-top-row-button-reset-widths"
import TableTopRowTemplates from "./table-top-row-templates"

type Props = {
    headerGroup: HeaderGroup<object>;
    tableState: TableState<object>;
}

const TableTopRow: React.FC<Props> = (props) => (
    <div className={DashboardScreenerTopRowStyles.container}>
        <TableTopRowTemplates />
        <div className={DashboardScreenerTopRowStyles.group_container}>
            <TableTopRowButtonColumns />
            <TableTopRowButtonFilter headerGroup={props.headerGroup} tableState={props.tableState} />
            <TableTopRowButtonResetWidths />
        </div>
    </div>
)

export default TableTopRow