const findClosestIndex = (array: number[], searchValue: number, reset?: boolean) => {
    const _reset = reset !== null && typeof reset !== "undefined" ? reset : true
    let index = 0
    if (array.length < 2) {
        return index
    }
    array.forEach((v, i) => {
        const dist1 = searchValue - v
        const dist2 = array[i+1] - searchValue
        if (dist1 >= 0 && dist2 >= 0) {
            if (dist1 < dist2) {
                index = i
            } else {
                index = i + 1
            }
        }
    })
    if (index === 0 && _reset) {
        return array.length - 1
    }
    return index
}

export default findClosestIndex