import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '../store/hooks';
import LoadingSpinner from '../utils/components/loading-spinner/loading-spinner';
import useIsLoading from '../utils/hooks/use-is-loading';

const RedirectRoute: React.FC = () => {
    const user = useAppSelector((state) => state.user)
    const { pathname } = useLocation();
    
    const isLoading = useIsLoading({ checkUser: true });

    if (isLoading) {
        return <LoadingSpinner positionAbsolute={true} wrapperWidth="100%" wrapperHeight="100%" backgroundColor="rgb(var(--dl-color-backgrounds-white))" marginTop="10%" size="80" color="rgb(var(--dl-color-primary-100))" />
    }

    if(!user.loading) {
        const newPathname = pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;
        if (user.loggedIn) {
            if (!user.userData.emailVerified) {
                return <Navigate to="/verify-email" replace />;
            }
            if (!user.userData.onboardingCompleted) {
                return <Navigate to="/onboarding" replace />;
            }
            if (["/sign-up", "/login", "/forgot-password", "/verify-email", "/onboarding"].includes(newPathname)) {
                return <Navigate to="/app" replace />;
            }
        } else if (!["/sign-up", "/login", "/forgot-password"].includes(newPathname)) {
            return <Navigate to="/login" replace />;
        }
        if (pathname.endsWith("/")) {
            return <Navigate to={newPathname} replace />
        }
    }
    
    return null
};

export default RedirectRoute