import { useState } from "react";

import { DashboardScreenerTableSettings } from "../../../../../../../../types/Dashboard";
import { columnBuilderOptions } from "../../../../../../../../utils/constants/table-columns";
import TableTopRowButtonColumnRow from "./table-top-row-button-column-row";
import TableTopRowButtonColumnStyle from "./TableTopRowButtonColumn.module.css"

type Props = {
    settings: DashboardScreenerTableSettings;
}

const TableTopRowButtonColumnsPopupRight: React.FC<Props> = (props) => {
    const [search, setSearch] = useState("");

    return (
        <div className={TableTopRowButtonColumnStyle.column_wrapper}>
            <div className={TableTopRowButtonColumnStyle.search_row}>
                <div className={TableTopRowButtonColumnStyle.search_wrapper}>
                    <svg viewBox="0 0 1024 1024" className={TableTopRowButtonColumnStyle.search_icon}>
                        <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z" />
                    </svg>
                    <input className={TableTopRowButtonColumnStyle.search_input}
                        type="text"
                        placeholder="Search..."
                        onChange={(event) => {if (event.target.value.length <= 50) {setSearch(event.target.value)}}}
                        value={search}
                    />
                    {search !== "" && (
                        <div className={TableTopRowButtonColumnStyle.search_delete} onClick={() => setSearch("")}>
                            <svg viewBox="0 0 1024 1024" className={TableTopRowButtonColumnStyle.search_delete_icon}>
                                <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                            </svg>
                        </div>
                    )}
                </div>
            </div>
            <span className={TableTopRowButtonColumnStyle.text_between}>Choose column:</span>
            <div className={TableTopRowButtonColumnStyle.columns_list} style={{ paddingLeft: "24px" }}>
                {columnBuilderOptions.filter(((columnBuilderOption) => {
                    const { header, id } = columnBuilderOption
                    if (header.replace("_", "").includes(search.toLowerCase().replace(" ", ""))) {
                        return true
                    }
                    if (id.replace(" ", "").toLowerCase().includes(search.toLowerCase().replace(" ", ""))) {
                        return true
                    }
                    return false
                })).map((columnBuilderOption) => columnBuilderOption.header !== "Ticker" ? <TableTopRowButtonColumnRow columnBuilderOption={columnBuilderOption} settings={props.settings} key={columnBuilderOption.id} /> : null)}
            </div>
        </div>
    )
}

export default TableTopRowButtonColumnsPopupRight