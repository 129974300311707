
import { setPopupElement } from "../../../../../../../store/features/management";
import { useAppDispatch } from "../../../../../../../store/hooks";
import LayoutIcon from "../../../../../../../utils/components/icons/layout-icon";
import BootstrapTooltip from "../../../../../../../utils/components/tooltip/bootstrap-tooltip";
import TableTopRowButtonColumnsPopup from "../top-row/columns/table-top-row-button-columns-popup";
import TableTableStyles from "./TableTableStyles.module.css";

const ColumnButton: React.FC = () => {
    const dispatch = useAppDispatch();

    return (
        <BootstrapTooltip title="Column setup">
            <div className={TableTableStyles.layout_button} onClick={() => dispatch(setPopupElement(<TableTopRowButtonColumnsPopup />))}>
                <LayoutIcon colored={false} />
            </div>
        </BootstrapTooltip>
    )
}

export default ColumnButton