import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom'

import { auth } from '../../firebase/firebase-config';
import { logout, reloadUser, resendEmailVerification, resetUserError } from '../../store/features/user';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import LoadingSpinner from '../../utils/components/loading-spinner/loading-spinner';
import SignupLoginBackground from './components/signup-login-bg';
import SignUpLoginInfoMessage from './components/signup-login-info';
import SignupLogin from './SignupLogin.module.css'

const VerifyEmail: React.FC = () => {
    const [verificationSent, setVerificationSent] = useState(false);
    const [reloadedUser, setReloadedUser] = useState(false);

    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        "unknown": ""
    });

    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user)

    useEffect(() => {
        dispatch(resetUserError());
    }, []);

    useEffect(() => {
        const newErrors = {
            "unknown": ""
        };
        if (user.errorMessage && user.errorMessage !== "") {
            newErrors.unknown = user.errorMessage
        }
        setErrors(newErrors);
        if (user.userData.emailVerified) {
            if (user.userData.onboardingCompleted) {
                navigate("/app")
            }
            navigate("/onboarding")
        }
    }, [user]);

    const handleLogout = async () => {
        try {
            await dispatch(logout()).unwrap();
            navigate("/login")
        } catch (error) {
            setErrors({
                "unknown": "Logout failed."
            })
        }
    }

    const resendVerificationEmail = async () => {
        setVerificationSent(true);
        setReloadedUser(false);
        dispatch(resendEmailVerification());
    }

    const checkUser = async () => {
        setVerificationSent(false);
        setReloadedUser(true);
        dispatch(reloadUser());
        if (auth.currentUser?.emailVerified) {
            navigate('/app');
        }
    }

    return (
        <div className={SignupLogin.page_wrapper}>
            <Helmet>
                <title>Verify E-Mail | Traddle.io</title>
                <meta property="og:title" content="Sign Up | Traddle.io" />
            </Helmet>
            <div className={SignupLogin.top_bar_wrapper}>
                <div className={SignupLogin.top_bar_left}>
                    <img
                        alt="Traddle Logo"
                        src="/logos/traddle_logo_comp-200h.png"
                        loading="eager"
                        className={SignupLogin.logo}
                    />
                    <span className={SignupLogin.logo_text}>Traddle</span>
                </div>
                <div className={SignupLogin.top_bar_right}>
                    <span className={SignupLogin.top_bar_right_text}>Want to user another account?</span>
                    <button type="button" className={SignupLogin.top_bar_right_button} onClick={() => { handleLogout() }}>Logout</button>
                </div>
            </div>
            <SignupLoginBackground />
            <div className={SignupLogin.form_wrapper}>
                <div className={SignupLogin.form_container}>
                    <h1 className={SignupLogin.header}>Verify your e-mail address.</h1>
                    <div className={SignupLogin.verification_text}>
                        <p className={`${SignupLogin.subheader} ${SignupLogin.verification}`}>Please click the link in the verification e-mail we just sent to</p>
                        <p className={SignupLogin.verification_text_email}>{user.userData.email}.</p>
                    </div>
                    <SignUpLoginInfoMessage visible={errors.unknown !== ""} color="red" text={errors.unknown} />
                    <SignUpLoginInfoMessage visible={!user.loading && !user.userData.emailVerified && reloadedUser} color="red" text="Your e-mail address is still not verified. Please also check your spam folder." />
                    <SignUpLoginInfoMessage visible={!user.loading && verificationSent && user.errorMessage === ""} color="green" text="Verification e-mail sent. Please also check your spam folder." />
                    <button type="button" className={`${SignupLogin.submit_button} ${SignupLogin.button_outline}`} onClick={checkUser}>
                        {(user.loading && reloadedUser) ? (
                            <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                        ) : (
                            <>I Verified My E-Mail</>
                        )}
                    </button>
                    <button type="button" className={SignupLogin.submit_button} onClick={resendVerificationEmail}>
                        {(user.loading && verificationSent) ? (
                            <LoadingSpinner wrapperWidth="auto" wrapperHeight="auto" backgroundColor="transparent" marginTop="0px" size="20" color="rgb(var(--dl-color-white-always))" />
                        ) : (
                            <>Resend Verification E-Mail</>
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmail