import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../types/Screener';
import CriteriaInputNumber from '../criteria-input-number';
import checkUnusedParams from '../functions';

type Props = {
  parameters: IndicatorParameters;
  setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        ratio: "1",
    },
    setParameters: () => {},
}

const CriteriaParameterRatio: React.FC<Props> = (props) => {
    const ratio = useMemo(() => props.parameters.ratio ? props.parameters.ratio : defaultProps.parameters.ratio!, [props.parameters.ratio])

    const setRatio = (newRatio: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, ratio: newRatio }, defaultProps.parameters))
    }

    return (
        <>
            <CriteriaInputNumber number={ratio} setNumber={setRatio} min={0} max={9999} step="0.01" width="70px" />
        </>
    )
}

export default CriteriaParameterRatio