import { ErrorBoundary } from '@sentry/react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useNavigate } from "react-router-dom"

import { setPopupElement } from '../../store/features/management';
import { resetScreenerLoading } from '../../store/features/screener';
import { logout } from '../../store/features/user';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import App from './App.module.css'
import TopNav from './top-nav/app-top-nav'

const AppPage: React.FC = () => {
    const management = useAppSelector((state) => state.management);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setPopupElement(null));
        dispatch(resetScreenerLoading());
    }, []);
  
    return (
        <ErrorBoundary onError={() => {dispatch(logout()); navigate("/error")}}>
            <div className={App.page_wrapper}>
                {management.popupElement !== null ? (
                    <div className="app_popup_wrapper">
                        {management.popupElement}
                    </div>
                ) : null}
                <Helmet>
                    <title>App | Traddle.io</title>
                    <meta property="og:title" content="App | Traddle.io"/>
                </Helmet>
                <TopNav />
                <div className={App.page_container}>
                    <Outlet />
                </div>
            </div>
        </ErrorBoundary>
    )
}

export default AppPage