import { AllScreenersState } from "../../store/features/allScreeners";
import { UserState } from "../../store/features/user";
import { ScreenerSource, Sources } from "../../types/Screener";

const findScreenerFirstSource = (sources: Sources, allScreeners: AllScreenersState, user: UserState): string | null => {
    if (sources.indices.length > 0) {
        return sources.indices[0]
    }
    if (sources.screeners.length > 0) {
        const selectedScreener = allScreeners.screeners[sources.screeners[0].screenerId]
        const selectedState = selectedScreener.states.find((s) => s.id === sources.screeners[0].stateId)
        if (typeof selectedScreener !== "undefined" && typeof selectedState !== "undefined") {
            return `${selectedScreener.name} / ${selectedState.name}`
        }
    }
    if (sources.watchlists.length > 0) {
        const watchlist = user.userData.watchlists.find((w) => w.id === sources.watchlists[0])
        if (typeof watchlist !== "undefined") {
            return watchlist.name
        }
    }
    return null
}

export const getAllSourcesNames = (indices: string[], screeners: ScreenerSource[], watchlists: string[], allScreeners: AllScreenersState, user: UserState): string => {
    const names: string[] = []
    indices.forEach((i) => (names.push(i)))
    screeners.forEach((s) => {
        const selectedScreener = allScreeners.screeners[s.screenerId]
        const selectedState = selectedScreener.states.find((i) => i.id === s.stateId)
        if (typeof selectedScreener !== "undefined" && typeof selectedState !== "undefined") {
            names.push(`${selectedScreener.name} / ${selectedState.name}`)
        }
    })
    watchlists.forEach((w) => {
        const watchlist = user.userData.watchlists.find((i) => i.id === w)
        if (typeof watchlist !== "undefined") {
            names.push(watchlist.name)
        }
    })
    const [, ...namesNew] = names;
    return namesNew.join(", ")
}

export default findScreenerFirstSource