import { setPopupElement } from '../../../../../../../store/features/management';
import { useAppDispatch } from '../../../../../../../store/hooks';
import { State } from '../../../../../../../types/Screener';
import DashboardScreenerDetailsStyles from "../../DashboardScreenerDetails.module.css"
import BuilderSettingsDeletePopup from './builder-settings-delete-popup';

type Props = {
    name: string;
    index: number;
    setSelectedState: React.Dispatch<React.SetStateAction<State | null>>;
    setNewState: React.Dispatch<React.SetStateAction<number | null>>;
}

const BuilderSettingsDelete: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    
    const onDeleteButtonClick = () => {
        dispatch(setPopupElement(<BuilderSettingsDeletePopup name={props.name} index={props.index} setSelectedState={props.setSelectedState} setNewState={props.setNewState} />))
    }

    return (
        <div className={DashboardScreenerDetailsStyles.settings_content_row}>
            <button type="button" className={DashboardScreenerDetailsStyles.conditions_delete_state_button} onClick={() => onDeleteButtonClick()}>Delete state</button>
        </div>
    )
}

export default BuilderSettingsDelete