import { doc, getDoc } from "firebase/firestore"
import { useEffect, useMemo, useState } from "react"

import { firestoreDB } from "../../../../../firebase/firebase-config"
import { setScreener } from "../../../../../store/features/screener"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import { Screener } from "../../../../../types/Screener"
import { User } from "../../../../../types/User"
import ProfileAvatar from "../../../../../utils/components/profile-avatar/profile-avatar"
import BootstrapTooltip from "../../../../../utils/components/tooltip/bootstrap-tooltip"
import { getAllSourcesNames } from "../../../../../utils/functions/find-screener-first-source"
import DashboardScreenerStyles from "../DashboardScreener.module.css"
import DashboardScreenerListItemSettings from "./dashboard-screener-list-item-settings"
import DashboardScreenerListItemSourceItem from "./dashboard-screener-list-item-source-item"

type Props = {
    screener: Screener;
    isSelected: boolean;
}

const DashboardScreenerListItem: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    const screener = useAppSelector((state) => state.screener)
    const allScreeners = useAppSelector((state) => state.allScreeners)
    const user = useAppSelector((state) => state.user)

    const sources = useMemo(() => ({ ...props.screener.sources }), [props.screener.sources])
    const indices = useMemo(() => ([...sources.indices]), [sources])
    const screeners = useMemo(() => ([...sources.screeners]), [sources])
    const watchlists = useMemo(() => ([...sources.watchlists]), [sources])

    const sourcesLength = useMemo(() => (indices.length + screeners.length + watchlists.length), [indices, screeners, watchlists])
    
    const allSourcesNames = useMemo(() => getAllSourcesNames(indices, screeners, watchlists, allScreeners, user), [indices, screeners, watchlists])

    const [adminUser, setAdminUser] = useState<User | null>(null);

    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const newAdminUser = (await getDoc(doc(firestoreDB, "users", props.screener.adminUid))).data() as User;
                setAdminUser(newAdminUser)
            } catch (error: unknown) {
                setAdminUser(null)
            }
        }
        fetchUser()
    }, [])
    
    const screenerClick = () => {
        if (screener.data.id !== props.screener.id) {
            dispatch(setScreener(props.screener.id))
        }
    }

    return (
        <div
            className={props.isSelected ? `${DashboardScreenerStyles.screener_wrapper} ${DashboardScreenerStyles.screener_wrapper_selected}` : `${DashboardScreenerStyles.screener_wrapper}`}
            onClick={() => screenerClick()}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >   
            {!isHovered && props.screener.active ? (
                <div className={DashboardScreenerStyles.screener_running_button_bg}>
                    <div className={DashboardScreenerStyles.screener_running_button}>
                        <span className={DashboardScreenerStyles.screener_running_button_text}>running</span>
                    </div>
                </div>
            ) : null}
            <div className={DashboardScreenerStyles.screener_row} style={{ height: "22px" }}>
                <div className={DashboardScreenerStyles.screener_row_left}>
                    <div className={DashboardScreenerStyles.screener_row_logo_wrapper}>
                        <ProfileAvatar size={22} avatar={adminUser?.avatar} displayName={adminUser?.displayName} />
                    </div>
                    <span className={DashboardScreenerStyles.screener_name}>{props.screener.name}</span>
                </div>
                {isHovered ? <DashboardScreenerListItemSettings screener={props.screener} setIsHovered={setIsHovered} /> : null}
            </div>
            {props.screener.description.length > 0 ? (
                <div className={DashboardScreenerStyles.screener_row}>
                    <span className={DashboardScreenerStyles.screener_description}>{props.screener.description}</span>
                </div>
            ) : null}
            {sourcesLength > 0 ? (
                <div className={DashboardScreenerStyles.screener_row}>
                    <div className={DashboardScreenerStyles.screener_row_left}>
                        <DashboardScreenerListItemSourceItem sources={props.screener.sources} />
                        {sourcesLength > 1 ? (
                            <BootstrapTooltip title={allSourcesNames}>
                                <div className={DashboardScreenerStyles.screener_input_list_count_wrapper}>
                                    <span className={DashboardScreenerStyles.screener_input_list_count}>+{sourcesLength - 1}</span>
                                </div>
                            </BootstrapTooltip>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default DashboardScreenerListItem