import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import { sectorsIndustries } from '../../../../../constants/sectors-industries';
import ConditionRow from '../../../ConditionRow.module.css';
import CriteriaDropdownSelectionNested from '../../criteria-dropdown-selection-nested';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        industries: [],
    },
    setParameters: () => {}
}

const CriteriaParameterSectorIndustry: React.FC<Props> = (props) => {
    const industries = useMemo(() => props.parameters.industries ? props.parameters.industries : defaultProps.parameters.industries!, [props.parameters.industries])

    const setIndustries = (newIndustries: string[]) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, industries: newIndustries }, defaultProps.parameters))
    }

    return (
        <>
            <span className={ConditionRow.criteria_text}>is</span>
            <CriteriaDropdownSelectionNested dropdownObject={sectorsIndustries} transformType="" withSearch={true} values={industries} setValues={setIndustries} />
        </>
    )
}

export default CriteriaParameterSectorIndustry