import { useMemo } from "react";
import { useBlockLayout, useColumnOrder, useFilters, useResizeColumns, useSortBy, useTable } from "react-table";

import TABLE_FILTER_DATA from "../../../../mock-data/table-filter-data";
import { useAppSelector } from "../../../../store/hooks";
import { TableSettings, Widget } from "../../../../types/Dashboard";
import WidgetStyles from "../WidgetStyles.module.css"
import TableTable from "./table/table-table";
import TableTopRow from "./top-row/table-top-row"

type Props = {
    id: string;
}

const TableWidgetContent: React.FC<Props> = (props) => {
    const widget: Widget = useAppSelector((state) => state.dashboard.data.widgets.find((w) => w.id === props.id))!

    const settings = widget.settings as TableSettings

    const columns = useMemo(() => settings?.columns, [settings?.columns])
    const data = useMemo(() => TABLE_FILTER_DATA, [])

    const defaultColumn = useMemo(() => ({
        filter: "between",
        minWidth: 60,
        width: 150,
        maxWidth: 400
    }), [])

    const tableInstance = useTable({ columns, data, defaultColumn }, useBlockLayout, useResizeColumns, useColumnOrder, useFilters, useSortBy)

    return (
        <div className={WidgetStyles.container}>
            <div className={WidgetStyles.tab_content}>
                <TableTopRow id={props.id} headerGroup={tableInstance.headerGroups[0]} tableState={tableInstance.state} />
                <TableTable id={props.id} tableInstance={tableInstance} />
            </div>
        </div>
    )
}

export default TableWidgetContent