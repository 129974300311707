const TABLE_FILTER_DATA = [
    {
        "ticker": "AAPL",
        "free_float": 346000000000,
        "change_from_ldc-pct": -0.65,
        "change-1W-pct": -2.56,
        "price_current": 65.78,
    },
    {
        "ticker": "NFLX",
        "free_float": 34343367787,
        "change_from_ldc-pct": 1.23,
        "change-1W-pct": -2.56,
        "price_current": 243.98,
    },
    {
        "ticker": "MSFT",
        "free_float": 89909943433,
        "change_from_ldc-pct": 2.25,
        "change-1W-pct": 0.44,
        "price_current": 194.53,
    },
    {
        "ticker": "TSLA",
        "free_float": 6768788989,
        "change_from_ldc-pct": -0.56,
        "change-1W-pct": 3.4,
        "price_current":154.76,
    },
    {
        "ticker": "UBER",
        "free_float": 23456784345,
        "change_from_ldc-pct": 3.7,
        "change-1W-pct": 1.95,
        "price_current": 85.5,
    },
    {
        "ticker": "ABNB",
        "free_float": 232335456767,
        "change_from_ldc-pct": 0.23,
        "change-1W-pct": -1.28,
        "price_current": 34.56,
    }
]

export default TABLE_FILTER_DATA