import { Column } from "react-table";

import { changeDashboardData, saveDashboard } from "../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../types/changeHandlers/change-dashboard-widget";
import { TableSettings, Widget } from "../../../../../types/Dashboard";
import BootstrapTooltip from "../../../tooltip/bootstrap-tooltip";
import WidgetTopRowStyles from "../../WidgetTopRowStyles.module.css"

type Props = {
    id: string;
}

const TableTopRowButtonResetWidths: React.FC<Props> = (props) => {
    const dashboard = useAppSelector((state) => state.dashboard)
    const widget: Widget = useAppSelector((state) => state.dashboard.data.widgets.find((w) => w.id === props.id))!
    const dispatch = useAppDispatch();

    const settings = widget.settings as TableSettings

    const { columns } = settings

    const resetColumnWidths = () => {
        const newColumns: Column<object>[] = []
        let flag = false
        columns.forEach((c) => {
            let newWidth = 150
            if (c.accessor === "ticker") newWidth = 200
            if (c.accessor === "dummy") newWidth = 26
            if (typeof c !== "undefined") {
                if (typeof newWidth !== "undefined" && c.width !== newWidth) {
                    flag = true;
                    newColumns.push({
                        ...c,
                        width: newWidth
                    })
                } else {
                    newColumns.push(c)
                }
            }
        })
        if (flag) {
            const newSettings = {
                ...settings,
                columns: newColumns
            }
            const newWidgets = changeDashboardWidgetData(props.id, dashboard.data.widgets, { settings: newSettings })
            dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
            dispatch(saveDashboard());
        }
    }

    return (
        <BootstrapTooltip title="Reset columns widths">
            <div className={WidgetTopRowStyles.button} onClick={() => resetColumnWidths()}>
                <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.button_icon} style={{ width: "14px", height: "14px", transform: "rotateY(180deg)" }}>
                    <path d="M1024 384h-384l143.53-143.53c-72.53-72.526-168.96-112.47-271.53-112.47s-199 39.944-271.53 112.47c-72.526 72.53-112.47 168.96-112.47 271.53s39.944 199 112.47 271.53c72.53 72.526 168.96 112.47 271.53 112.47s199-39.944 271.528-112.472c6.056-6.054 11.86-12.292 17.456-18.668l96.32 84.282c-93.846 107.166-231.664 174.858-385.304 174.858-282.77 0-512-229.23-512-512s229.23-512 512-512c141.386 0 269.368 57.326 362.016 149.984l149.984-149.984v384z" />
                </svg>
            </div>
        </BootstrapTooltip>
    )
}

export default TableTopRowButtonResetWidths