import { useEffect, useMemo } from "react"
import { HeaderGroup, TableState } from "react-table"

import { setPopupElement } from "../../../../../../store/features/management"
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks"
import { TableSettings, Widget } from "../../../../../../types/Dashboard"
import WidgetTopRowStyles from "../../../WidgetTopRowStyles.module.css"
import TableTopRowButtonFilterPopup from "./table-top-row-button-filter-popup"

type Props = {
    id: string;
    headerGroup: HeaderGroup<object>;
    tableState: TableState<object>;
}

const TableTopRowButtonFilter: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const popupElement = useAppSelector((state) => state.management.popupElement)
    const widget: Widget = useAppSelector((state) => state.dashboard.data.widgets.find((w) => w.id === props.id))!

    const settings = widget.settings as TableSettings

    const { filters } = settings

    const tableStateMemo = useMemo(() => props.tableState, [props.tableState]);

    useEffect(() => {
        if (popupElement !== null && typeof popupElement !== "undefined") {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            if (Object.keys(popupElement.props).includes("name")) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (popupElement.props.name === "dashboard-widget-filter") {
                    dispatch(setPopupElement(<TableTopRowButtonFilterPopup name="dashboard-widget-filter" id={props.id} headerGroup={props.headerGroup} />))
                }
            }
        }
    }, [tableStateMemo])

    useEffect(() => {
        if (popupElement === null) {
            props.headerGroup.headers.forEach((column) => {
                if (column.id === "ticker") {
                    return
                }
                if (!Object.keys(filters).includes(column.id)) {
                    column.setFilter(undefined);
                    return
                }
                if (column.filter === "between") {
                    column.setFilter([filters[column.id].min, filters[column.id].max])
                }
            });
        }
    }, [popupElement])
    
    return (
        <div className={WidgetTopRowStyles.dropdown_button} style={{ borderColor: Object.keys(filters).length > 0 ? "rgb(var(--dl-color-primary-100))" : "", backgroundColor: Object.keys(filters).length > 0 ? "rgba(var(--dl-color-primary-100), 0.09)" : "" }} onClick={() => dispatch(setPopupElement(<TableTopRowButtonFilterPopup name="dashboard-widget-filter" id={props.id} headerGroup={props.headerGroup} />))}>
            <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.dropdown_button_icon} style={{ width: "18px", height: "18px", fill: Object.keys(filters).length > 0 ? "rgb(var(--dl-color-primary-100))" : "" }}>
                <path d="M256 554v-84h512v84h-512zM128 256h768v86h-768v-86zM426 768v-86h172v86h-172z" />
            </svg>
            <span className={WidgetTopRowStyles.dropdown_button_text} style={{ color: Object.keys(filters).length > 0 ? "rgb(var(--dl-color-primary-100))" : "" }}>Filter</span>
        </div>
    )
}
    
export default TableTopRowButtonFilter