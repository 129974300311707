import { Screeners } from "../Screener";

const sortScreenersByName = (screeners: Screeners): string[] => Object.keys(screeners).sort((a, b) => {
    const fa = screeners[a].name.toLowerCase();
    const fb = screeners[b].name.toLowerCase();
    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
})

export default sortScreenersByName