import { WidgetConnectionColorName } from "../../../../types/Dashboard";
import GridStyles from "./GridStyles.module.css";

type GridWidgetProps = {
    id: string,
    widgetComponent: JSX.Element;
    connection: WidgetConnectionColorName;
}

const GridWidget: React.FC<GridWidgetProps> = (props) => (
    <div className={GridStyles.widget_wrapper}>
        {props.widgetComponent}
    </div>
)

export default GridWidget