import _ from "lodash";
import { Column } from "react-table";

import { changeDashboardData } from "../../../../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../../../../store/hooks";
import { DashboardScreenerTableSettings } from "../../../../../../../../types/Dashboard";
import tableColumnPresets from "../../../../../../../../utils/constants/table-column-presets";
import createColumn from "../../../../../../../../utils/constants/table-columns";
import usePopperPopup from "../../../../../../../../utils/hooks/use-popper-popup";
import DashboardScreenerTopRowStyles from "../../DashboardScreenerTopRowStyles.module.css"

type Props = {
    settings: DashboardScreenerTableSettings;
}

const TableTopRowButtonColumnsTemplate: React.FC<Props> = (props) => {
    const { setButton, setPopup, buttonRef, styles, attributes, isOpen, setIsOpen } = usePopperPopup({ offsetY: 4 });

    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.user)

    const applyPreset = (columns: Column<object>[], presetId: string) => {
        setIsOpen(false);
        const newColumns: Column<object>[] = [createColumn("Ticker", "ticker", { minWidth: 160, width: 200 })]
        columns.forEach(column => {
            if (typeof column !== "undefined") {
                newColumns.push(column)
            }
        })
        newColumns.push(createColumn("Dummy", "dummy", { minWidth: 26, width: 26, maxWidth: 26 }))
        const newSettings = {
            ...props.settings,
            columns: newColumns,
            selectedColumnPreset: presetId
        }
        if(!_.isEqual(newSettings, props.settings)) {
            dispatch(changeDashboardData({ screenerTableSettings: newSettings }));
        }
    }

    return (
        <div className={DashboardScreenerTopRowStyles.template_row}>
            <div className="popper_wrapper" ref={buttonRef}>
                <div className={DashboardScreenerTopRowStyles.popup_dropdown_button} style={{ borderColor: isOpen || Object.keys(user.userData.tableColumnPresets).includes(props.settings.selectedColumnPreset) ? "rgb(var(--dl-color-primary-100))" : "", backgroundColor: Object.keys(user.userData.tableColumnPresets).includes(props.settings.selectedColumnPreset) ? "rgba(var(--dl-color-primary-100), 0.09)" : "" }} ref={setButton} onClick={() => setIsOpen(!isOpen)}>
                    {Object.keys(user.userData.tableColumnPresets).includes(props.settings.selectedColumnPreset) ? (
                        <span className={DashboardScreenerTopRowStyles.popup_dropdown_button_text}>{user.userData.tableColumnPresets[props.settings.selectedColumnPreset].name}</span>
                    ) : (
                        <span className={DashboardScreenerTopRowStyles.popup_dropdown_button_text}>Own presets</span>
                    )}
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerTopRowStyles.popup_dropdown_button_icon} style={{ transform: isOpen ?  "rotate(180deg)" : "" }}>
                        <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                    </svg>
                </div>
                {isOpen ? (
                    <div className={DashboardScreenerTopRowStyles.popup_wrapper} ref={setPopup} style={styles.popper} {...attributes.popper}>
                        <div className={DashboardScreenerTopRowStyles.popup_wrapper_scroll}>
                            {Object.keys(user.userData.tableColumnPresets).map((presetId) => {
                                const preset = user.userData.tableColumnPresets[presetId]
                                return (
                                    <div className={DashboardScreenerTopRowStyles.popup_item} style={{ backgroundColor: props.settings.selectedColumnPreset === presetId ? "rgb(var(--dl-color-primary-100))" : "", cursor: props.settings.selectedColumnPreset === presetId ? "default" : "pointer" }} onClick={() => applyPreset(preset.columns, presetId)} key={presetId}>
                                        <span className={DashboardScreenerTopRowStyles.popup_item_text}>{preset.name}</span>
                                    </div>
                                )
                            })}
                            {Object.keys(user.userData.tableColumnPresets).length === 0 ? (
                                <div className={DashboardScreenerTopRowStyles.popup_item} style={{ pointerEvents: "none" }}>
                                    <span className={DashboardScreenerTopRowStyles.popup_item_text}>No templates yet</span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
            {Object.keys(tableColumnPresets).map(presetId => {
                const preset = tableColumnPresets[presetId]
                return (
                    <div className={props.settings.selectedColumnPreset === presetId ? `${DashboardScreenerTopRowStyles.popup_tab_button} ${DashboardScreenerTopRowStyles.popup_tab_button_active}` : `${DashboardScreenerTopRowStyles.popup_tab_button}`} key={presetId} onClick={() => applyPreset(preset.columns, presetId)}>
                        <span className={DashboardScreenerTopRowStyles.popup_tab_button_text}>{preset.name}</span>
                    </div>
                )
            })}
            {props.settings.selectedColumnPreset === "" ? (
                <div className={`${DashboardScreenerTopRowStyles.popup_tab_button} ${DashboardScreenerTopRowStyles.popup_tab_button_active}`} style={{ pointerEvents: "none" }}>
                    <span className={DashboardScreenerTopRowStyles.popup_tab_button_text}>Custom</span>
                </div>
            ) : null}
        </div>
    )
}

export default TableTopRowButtonColumnsTemplate