import { useEffect, useState } from 'react';

import { timeframes } from '../../../constants/timeframes';
import ConditionRow from "../ConditionRow.module.css"
import CriteriaDropdown from './criteria-dropdown';

type Props = {
    timeframe: string;
    setTimeframe: (timeframe: string) => void;
    innerPopup?: boolean;
}

const defaultProps = {
    timeframe: "",
    setTimeframe: () => {},
    innerPopup: false
}

const CriteriaInputTimeframe: React.FC<Props> = (props) => {
    const { innerPopup } = { ...defaultProps, ...props }

    const [valueTimeframe, setValueTimeframe] = useState(props.timeframe);

    useEffect(() => {
        if (valueTimeframe !== props.timeframe) {
            setValueTimeframe(props.timeframe)
        }
    }, [props.timeframe])

    useEffect(() => {
        props.setTimeframe(valueTimeframe)
    }, [valueTimeframe])

    return (
        <div className={ConditionRow.criteria_input_timeframe_wrapper}>
            <CriteriaDropdown dropdownOptions={timeframes} transformType="" value={valueTimeframe} setValue={setValueTimeframe} innerPopup={innerPopup} />
        </div>
    )
}

export default CriteriaInputTimeframe