import { useMemo } from "react";
import { useBlockLayout, useColumnOrder, useFilters, useResizeColumns, useSortBy, useTable } from "react-table";

import TABLE_FILTER_DATA from "../../../../../../mock-data/table-filter-data";
import { useAppSelector } from "../../../../../../store/hooks";
import useResizeTopScreenerResults from "../../../../../../utils/hooks/use-resize-top-screener-results";
import DashboardScreenerResultsStyles from "./DashboardScreenerResults.module.css"
import TableTable from "./table/table-table";
import TableTopRow from "./top-row/table-top-row"

const DashboardScreenerResults: React.FC = () => {
    const dashboard = useAppSelector((state) => state.dashboard)

    const { resizableRef, resizeHandlerRef } = useResizeTopScreenerResults();

    const columns = useMemo(() => dashboard.data.screenerTableSettings.columns, [dashboard.data.screenerTableSettings.columns])
    const data = useMemo(() => TABLE_FILTER_DATA, [])

    const defaultColumn = useMemo(() => ({
        filter: "between",
        minWidth: 60,
        width: 150,
        maxWidth: 400
    }), [])

    const tableInstance = useTable({ columns, data, defaultColumn }, useBlockLayout, useResizeColumns, useColumnOrder, useFilters, useSortBy)

    return (
        <div className={DashboardScreenerResultsStyles.container} ref={resizableRef}>
            <div className={DashboardScreenerResultsStyles.separator} ref={resizeHandlerRef} />
            <div className={DashboardScreenerResultsStyles.wrapper}>
                <TableTopRow headerGroup={tableInstance.headerGroups[0]} tableState={tableInstance.state} />
                <TableTable tableInstance={tableInstance} />
            </div>
        </div>
    )
}

export default DashboardScreenerResults