import "../../../../WidgetTopRowDatePicker.css";

import { useEffect, useRef, useState } from "react";

import WidgetTopRowStyles from "../../../../WidgetTopRowStyles.module.css"

type Props = {
    id: string;
    tickerSearch: string;
    setTickerSearch: React.Dispatch<React.SetStateAction<string>>;
}

const DividendsButtonSearch: React.FC<Props> = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (isExpanded) {
            inputRef.current?.focus();
        }
    }, [isExpanded])

    return (
        <div className={WidgetTopRowStyles.ticker_search_dummy}>
            <div className={isExpanded ? `${WidgetTopRowStyles.ticker_search} ${WidgetTopRowStyles.ticker_search_expanded}` : `${WidgetTopRowStyles.ticker_search}`} onClick={() => {if (!isExpanded) setIsExpanded(true)}}>
                <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.ticker_search_icon}>
                    <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z" />
                </svg>
                <input className={WidgetTopRowStyles.ticker_search_input}
                    style={{ width: isExpanded ? "100%" : "0px" }}
                    type="text"
                    placeholder="Search..."
                    onChange={(event) => {if (event.target.value.length <= 50) {props.setTickerSearch(event.target.value)}}}
                    value={props.tickerSearch}
                    ref={inputRef}
                />
                <div className={WidgetTopRowStyles.ticker_search_delete} style={{ display: isExpanded ? "" : "none" }} onClick={() => setIsExpanded(false)}>
                    <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.ticker_search_delete_icon}>
                        <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default DividendsButtonSearch