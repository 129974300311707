import { v4 } from "uuid";

import { getDefaultColumns } from "../../utils/constants/table-columns";
import { TableType, Widget, WidgetComponentName } from "../Dashboard";

const tableSettings = (id: string) => ({
    layout: {
        i: id,
        x: 0,
        y: 0,
        w: 6,
        h: 30,
        minW: 4,
        minH: 10,
    },
    settings: {
        widgetName: "table" as WidgetComponentName,
        tableType: "filter" as TableType,
        sources: {
            indices: [],
            screeners: [],
            watchlists: []
        },
        columns: getDefaultColumns(),
        filters: {},
        selectedColumnPreset: "",
        playSound: false,
        showTickerDetails: false,
        showNews: false,
    }
})

const economicCalendarSettings = (id: string) => ({
    layout: {
        i: id,
        x: 0,
        y: 0,
        w: 4,
        h: 28,
        minW: 4,
        maxW: 8,
        minH: 21,
    },
    settings: {
        widgetName: "economic-calendar" as WidgetComponentName,
        selectedTab: "Economy",
        economy: {
            countries: [],
            priorities: [],
            categories: []
        },
        earnings: {
            countries: []
        },
        dividends: {
            countries: []
        }
    }
})

const getSettings = (id: string, widgetName: WidgetComponentName) => {
    switch(widgetName) {
    case "table": return tableSettings(id)
    case "economic-calendar": return economicCalendarSettings(id)
    default: return {
        layout: {
            i: id,
            x: 0,
            y: 0,
            w: 6,
            h: 20,
            minW: 4
        },
        settings: null
    }
    }
}

export const createWidget = (widgetName: WidgetComponentName, overrides?: Partial<Widget>): Widget => {
    const id = v4();

    return {
        id,
        componentName: widgetName,
        connection: "none",
        ...getSettings(id, widgetName),
        ...overrides
    }
}