import { getDefaultColumns } from "../../utils/constants/table-columns";
import { Dashboard } from "../Dashboard";
import { createWidget } from "./create-widget";

export const createDashboard = (docId: string, userId: string, overrides?: Partial<Dashboard>): Dashboard => ({
    id: docId,
    name: "Dashboard",
    description: "",
    width: 600,
    adminUid: userId,
    access: [userId],
    widgets: [
        createWidget("table"),
        createWidget("economic-calendar")
    ],
    screenerTableSettings: {
        columns: getDefaultColumns(),
        filters: {},
        selectedColumnPreset: ""
    },
    ...overrides
})