import React from "react";

import WidgetStyles from "../../../../WidgetStyles.module.css"

const TableEconomyHeader: React.FC = () => (
    <div className={WidgetStyles.simple_table_header}>
        <span className={WidgetStyles.simple_table_header_item} style={{ maxWidth: "70px" }}>Time</span>
        <div className={WidgetStyles.simple_table_header_right}>
            <span className={WidgetStyles.simple_table_header_item}>Current</span>
            <span className={WidgetStyles.simple_table_header_item}>Forecast</span>
            <span className={WidgetStyles.simple_table_header_item}>Previous</span>
        </div>
    </div>
)

export default TableEconomyHeader