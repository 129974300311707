
import { setPopupElement } from "../../../../../../../../store/features/management";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import LayoutIcon from "../../../../../../../../utils/components/icons/layout-icon";
import DashboardScreenerTopRowStyles from "../../DashboardScreenerTopRowStyles.module.css"
import TableTopRowButtonColumnsPopup from "./table-top-row-button-columns-popup";

const TableTopRowButtonColumns: React.FC = () => {  
    const dispatch = useAppDispatch();

    return (
        <div className={DashboardScreenerTopRowStyles.dropdown_button} onClick={() => dispatch(setPopupElement(<TableTopRowButtonColumnsPopup />))}>
            <LayoutIcon colored={false} />
            <span className={DashboardScreenerTopRowStyles.dropdown_button_text}>Columns</span>
        </div>
    )
}
    
export default TableTopRowButtonColumns