import { useMemo } from "react";

import { changeScreenerData, saveScreener } from "../../../../../../store/features/screener";
import { useAppDispatch } from "../../../../../../store/hooks";
import { Screener } from "../../../../../../types/Screener";
import BootstrapTooltip from "../../../../../../utils/components/tooltip/bootstrap-tooltip";
import DashboardScreenerSourceStyles from "./DashboardScreenerSource.module.css"

type Props = {
    screener: Screener;
    indexCategory: string;
    index: string;
    search: string
}

const DashboardScreenerSourceTabIndicesItem: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const sources = useMemo(() => ({ ...props.screener.sources }), [props.screener.sources])
    
    const indices = useMemo(() => ([...sources.indices]), [sources])
    const screeners = useMemo(() => ([...sources.screeners]), [sources])
    const watchlists = useMemo(() => ([...sources.watchlists]), [sources])

    const index = useMemo(() => (indices.findIndex((w) => w === props.index)), [sources, indices, props.index])
    
    const notAllowed = useMemo(() => (indices.length + screeners.length + watchlists.length === 1), [indices, screeners, watchlists])
    
    const addIndex = () => {
        if (index === -1) {
            indices.push(props.index);
            sources.indices = indices;
        } else {
            if (notAllowed) {
                return
            }
            indices.splice(index, 1)
            sources.indices = indices
        }
        dispatch(changeScreenerData({
            ...props.screener,
            sources
        }));
        dispatch(saveScreener());
    }

    if (props.index.toLowerCase().replace(" ", "").includes(props.search.toLowerCase().replace(" ", ""))) {
        return (
            <BootstrapTooltip title="You have to select at least one item" followCursor={true} disableHoverListener={!notAllowed || index === -1}>
                <div className={DashboardScreenerSourceStyles.stocklist_row} style={{ cursor: notAllowed && index > -1 ? "not-allowed" : "default", backgroundColor: index > -1 ? "rgba(var(--dl-color-colors-green-dark), 0.18)" : "" }} onClick={() => addIndex()}>
                    <div className={DashboardScreenerSourceStyles.stocklist_row_left}>
                        <div className={DashboardScreenerSourceStyles.stocklist_row_logo_wrapper}>
                            <img alt="Input List" src="/logos/traddle_logo_black_on_white_comp.png" className={DashboardScreenerSourceStyles.stocklist_row_logo} />
                        </div>
                        <span className={DashboardScreenerSourceStyles.stocklist_row_text}>{props.index}</span>
                    </div>
                    <div className={DashboardScreenerSourceStyles.stocklist_row_middle}>
                        <span className={DashboardScreenerSourceStyles.stocklist_row_text}>{props.indexCategory}</span>
                    </div>
                    <div className={DashboardScreenerSourceStyles.symbol_count}>
                        <span className={DashboardScreenerSourceStyles.symbol_count_text_gray}>Symbols</span>
                        <span className={DashboardScreenerSourceStyles.stocklist_row_text}>500</span>
                    </div>
                </div>
            </BootstrapTooltip>
        )
    }
    return null
}

export default DashboardScreenerSourceTabIndicesItem