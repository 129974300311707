import React from "react";

import { State } from "../../../../../../types/Screener";
import DashboardScreenerDetailsStyles from "../DashboardScreenerDetails.module.css"
import DashboardScreenerTopbarRight from "./dashboard-screener-topbar-right";
import DashboardScreenerTopbarTabs from "./dashboard-screener-topbar-tabs";

type Props = {
    selectedState: State | null;
    setSelectedState: React.Dispatch<React.SetStateAction<State | null>>;
    newState: number | null;
    setNewState: React.Dispatch<React.SetStateAction<number | null>>;
}

const DashboardScreenerTopbar: React.FC<Props> = (props) => (
    <div className={DashboardScreenerDetailsStyles.top_bar}>
        <DashboardScreenerTopbarTabs selectedState={props.selectedState} setSelectedState={props.setSelectedState} newState={props.newState} setNewState={props.setNewState} />
        <DashboardScreenerTopbarRight />
    </div>
)
    
export default DashboardScreenerTopbar