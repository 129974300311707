import { v4 } from "uuid";

import { State } from "../Screener";
import { User } from "../User";

export const createState = (user: User, overrides?: Partial<State>): State => ({
    id: v4(),
    type: "filter",
    name: "New Filter",
    description: "",
    updateInterval: "10m",
    recurringResets: [{
        interval: "",
        time: "",
        timezone: user.timezone
    }],
    manualResets: [{
        date: "",
        time: "",
        timezone: user.timezone
    }],
    frequency: {
        type: "predefined",
        interval: "minutes",
        multiplier: "10",
    },
    maxCount: "5",
    criteria: {
        id: v4(),
        type: "group",
        connection: "and",
        criteriaList: []
    },
    results: [],
    ...overrides
})