import "./Dashboard.css";

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { setDashboard } from "../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import LoadingSpinner from "../../../utils/components/loading-spinner/loading-spinner";
import useIsLoading from "../../../utils/hooks/use-is-loading";
import DashboardStyle from './Dashboard.module.css';
import DashboardAddWidget from "./dashboard-add-widget";
import DashboardDelete from "./dashboard-delete";
import GridLayout from "./grid/grid-layout";
import DashboardScreener from "./screener/dashboard-screener";

const DashboardPage: React.FC = () => {
    const { dashboardId } = useParams();
    
    const allDashboards = useAppSelector((state) => state.allDashboards)
    const dashboard = useAppSelector((state) => state.dashboard)

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const isLoading = useIsLoading({ checkUser: true, checkDashboard: true, checkAllDashboards: true });

    useEffect(() => {
        if (dashboardId && allDashboards.initialized) {
            if (!(Object.keys(allDashboards.dashboards).includes(dashboardId))) {
                navigate('/app');
            }
            dispatch(setDashboard(dashboardId));
        }
    }, [dashboardId, allDashboards]);

    if (isLoading) {
        return <LoadingSpinner positionAbsolute={true} wrapperWidth="100%" wrapperHeight="100%" backgroundColor="rgb(var(--dl-color-backgrounds-white))" marginTop="10%" size="80" color="rgb(var(--dl-color-primary-100))" />
    }
    
    if (dashboardId) {
        return (
            <>
                <div className={DashboardStyle.dashboard_container}>
                    <div className={DashboardStyle.top_row}>
                        <DashboardAddWidget />
                        <DashboardDelete />
                    </div>
                    <div className={DashboardStyle.container}>
                        <div className={DashboardStyle.cut_width_provider} style={{ width: dashboard.data.width }}>
                            <div className={DashboardStyle.full_width_provider}>
                                <GridLayout />
                            </div>
                        </div>
                    </div>
                </div>
                <DashboardScreener />
            </>
        )
    }
                        
    return null
}

export default DashboardPage
