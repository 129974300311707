import { createPortal } from 'react-dom';

import usePopperPopup from '../../../../../utils/hooks/use-popper-popup';
import AppTopNavCreateNewDashboard from './app-top-nav-create-new-dashboard';
import AppTopNavCreateNewScreener from './app-top-nav-create-new-screener';
import AppTopNavCreateButtonStyles from "./AppTopNavCreateButtonStyles.module.css"

const AppTopNavCreateButton: React.FC = () => {
    const { setButton, setPopup, buttonRef, styles, attributes, isOpen: popupOpen, setIsOpen: setPopupOpen } = usePopperPopup({ offsetY: 8 })

    return (
        <div className={AppTopNavCreateButtonStyles.create_button_wrapper} ref={buttonRef}>
            <div className={AppTopNavCreateButtonStyles.create_button} ref={setButton} onClick={() => setPopupOpen(!popupOpen)}>
                <span className={AppTopNavCreateButtonStyles.create_button_text}>Create New</span>
            </div>
            {popupOpen ? createPortal(
                <div className={AppTopNavCreateButtonStyles.create_button_popup} ref={setPopup} style={styles.popper} {...attributes.popper}>
                    <AppTopNavCreateNewScreener setPopupOpen={setPopupOpen} />
                    <AppTopNavCreateNewDashboard setPopupOpen={setPopupOpen} />
                </div>,
                document.body
            ) : null}
        </div>
    )
}

export default AppTopNavCreateButton