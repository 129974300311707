import { HeaderGroup } from "react-table";

import { changeDashboardData, saveDashboard } from "../../../../../../store/features/dashboard";
import { setPopupElement } from "../../../../../../store/features/management";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { changeDashboardWidgetData } from "../../../../../../types/changeHandlers/change-dashboard-widget";
import { TableFilters, TableSettings, Widget } from "../../../../../../types/Dashboard";
import useClickOutsidePopup from "../../../../../hooks/use-click-outside-popup";
import WidgetTopRowStyles from "../../../WidgetTopRowStyles.module.css"
import TableTopRowButtonFilterRowSwitch from "./table-top-row-button-filter-row-switch";

type Props = {
    name: string;
    id: string;
    headerGroup: HeaderGroup<object>;
}

const TableTopRowButtonFilterPopup: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const dashboard = useAppSelector((state) => state.dashboard)
    const widget: Widget = dashboard.data.widgets.find((w) => w.id === props.id)!

    const settings = widget.settings as TableSettings

    const refClickOutside = useClickOutsidePopup();
    
    const resetValues = () => {
        props.headerGroup.headers.forEach((column) => {
            if (column.id === "ticker") {
                return
            }
            column.setFilter(undefined)
        });
    }

    const saveButtonClick = () => {
        dispatch(setPopupElement(null));
        const newFilters: TableFilters = {}
        props.headerGroup.headers.forEach((column) => {
            if (column.id === "ticker") {
                return
            }
            if (column.filter === "between") {
                const filterValue = column.filterValue ? column.filterValue as number[] : undefined;
                if (!filterValue) {
                    return
                }
                newFilters[column.id] = {
                    min: typeof filterValue[0] !== "undefined" ? filterValue[0] : null,
                    max: typeof filterValue[1] !== "undefined" ? filterValue[1] : null,
                }
            }
        });
        const newSettings = {
            ...settings,
            filters: newFilters
        }
        const newWidgets = changeDashboardWidgetData(props.id, dashboard.data.widgets, { settings: newSettings })
        dispatch(changeDashboardData({ widgets: [ ...newWidgets ] }));
        dispatch(saveDashboard());
    }

    const cancelButtonClick = async () => {
        dispatch(setPopupElement(null));
    }

    return (
        <div className="app_popup_wrapper_middle" ref={refClickOutside}>
            <div className={WidgetTopRowStyles.popup_middle_top_row}>
                <span className={WidgetTopRowStyles.popup_middle_top_row_title}>Filter</span>
                <div className={WidgetTopRowStyles.popup_middle_top_row_icon_x_wrapper} onClick={() => dispatch(setPopupElement(null))}>
                    <svg viewBox="0 0 1024 1024" className={WidgetTopRowStyles.popup_middle_top_row_icon_x}>
                        <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z" />
                    </svg>
                </div>
            </div>
            <div className={WidgetTopRowStyles.popup_middle_content_wrapper} style={{ maxWidth: "660px", maxHeight: "560px" }}>
                <div className={WidgetTopRowStyles.popup_middle_wrapper_scroll}>
                    {props.headerGroup.headers.map((column) => (
                        <TableTopRowButtonFilterRowSwitch column={column} key={column.id} />
                    ))}
                </div>
            </div>
            <div className={WidgetTopRowStyles.popup_middle_bottom_row}>
                <div className={WidgetTopRowStyles.popup_middle_bottom_row_split}>
                    <div className={WidgetTopRowStyles.popup_middle_bottom_row_button} onClick={() => {resetValues()}}>
                        <span className={WidgetTopRowStyles.popup_middle_bottom_row_button_text}>Reset to default</span>
                    </div>
                </div>
                <span className={WidgetTopRowStyles.popup_middle_bottom_row_matches}>{props.headerGroup.headers[0].filteredRows.length} {props.headerGroup.headers[0].filteredRows.length === 1 ? "match" : "matches"}</span>
                <div className={WidgetTopRowStyles.popup_middle_bottom_row_split}>
                    <div className={WidgetTopRowStyles.popup_middle_bottom_row_button} onClick={cancelButtonClick}>
                        <span className={WidgetTopRowStyles.popup_middle_bottom_row_button_text}>Cancel</span>
                    </div>
                    <div className={WidgetTopRowStyles.popup_middle_bottom_row_button_blue} onClick={saveButtonClick}>
                        <span className={WidgetTopRowStyles.popup_middle_bottom_row_button_text}>Save</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableTopRowButtonFilterPopup