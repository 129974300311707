import BootstrapTooltip from '../../../../tooltip/bootstrap-tooltip';
import ConditionRow from "../../../ConditionRow.module.css"
import CriteriaInputNumber from '../../criteria-input-number';
import CriteriaInputTimeframe from '../../criteria-input-timeframe';

type Props = {
    titleWidth: string;
    fieldWidth: string;
    timeframe: string;
    setTimeframe: (newTimeframe: string) => void;
    length: string;
    setLength: (newTimeframe: string) => void
}

const CriteriaParameterAverageVolumePopup: React.FC<Props> = (props) => (
    <div className={ConditionRow.criteria_dropdown_menu_scroll_wrapper} style={{ gap: "8px", overflow: "visible" }}>
        <div className={ConditionRow.criteria_dropdown_parameter_title_row}>
            <p className={ConditionRow.criteria_dropdown_parameter_title}>Average Volume</p>
            <BootstrapTooltip title="Indicator Help">
                <svg viewBox="0 0 1024 1024" className={ConditionRow.criteria_dropdown_indicator_help_icon}>
                    <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                </svg>
            </BootstrapTooltip>
        </div>
        <div className={ConditionRow.criteria_popup_input}>
            <p className={ConditionRow.criteria_popup_input_title} style={{ width: props.titleWidth }}>Timeframe</p>
            <CriteriaInputTimeframe timeframe={props.timeframe} setTimeframe={props.setTimeframe} innerPopup={true} />
        </div>
        <div className={ConditionRow.criteria_popup_input}>
            <p className={ConditionRow.criteria_popup_input_title} style={{ width: props.titleWidth }}>Length</p>
            <CriteriaInputNumber number={props.length} setNumber={props.setLength} min={1} max={200} step="1" width={props.fieldWidth} />
        </div>
    </div>
)

export default CriteriaParameterAverageVolumePopup