import "./ResetTimeDateTimePicker.css";

import { TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { createPortal } from "react-dom";

import { changeScreenerData, saveScreener } from '../../../../../../../store/features/screener';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { State } from '../../../../../../../types/Screener';
import sortRecurringResetTimes from "../../../../../../../utils/functions/sort-recurring-reset-times";
import usePopperPopup from '../../../../../../../utils/hooks/use-popper-popup';
import DashboardScreenerDetailsStyles from '../../DashboardScreenerDetails.module.css';
import BuilderSettingsResetTimeStyles from './BuilderSettingsResetTime.module.css';

const intervalOptions = [
    "Every Day",
    "Every Two Days",
    "Every Weekend",
    "Every 2 Weeks",
    "Every 4 Weeks",
    "First Of Month",
    "Every 3 Months",
    "First Of Year",
]

type Props = {
    selectedState: State;
    statePosition: number;
    position: number;
}

const BuilderSettingsResetTimeRecurring: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const screener = useAppSelector((state) => state.screener);
    const user = useAppSelector((state) => state.user);

    const [isHovered, setIsHovered] = useState(false);

    const timeRef = useRef<HTMLDivElement | null>(null);

    const { setButton, setPopup, buttonRef: intervalRef, styles, attributes, isOpen: intervalPickerOpen, setIsOpen: setIntervalPickerOpen } = usePopperPopup({ offsetY: 6 });

    const currentResetTime = useMemo(() => {
        let resetTime = props.selectedState.recurringResets[props.position]
        if (resetTime.timezone === user.userData.timezone) {
            return resetTime
        }
        const resetTimeOld = dayjs(`${resetTime.time}`, "HH:mm").tz(resetTime.timezone, true)
        const resetTimeNew = resetTimeOld.tz(user.userData.timezone)
        resetTime = {
            interval: resetTime.interval,
            time: resetTimeNew.format("HH:mm") !== "Invalid Date" ? resetTimeNew.format("HH:mm") : "",
            timezone: user.userData.timezone
        }
        const newState = _.cloneDeep(props.selectedState);
        newState.recurringResets.splice(props.position, 1, resetTime);
        newState.recurringResets = [...newState.recurringResets].sort((a, b) => sortRecurringResetTimes(a, b, intervalOptions));
        const states = _.cloneDeep(screener.data.states);
        states.splice(props.statePosition, 1, newState);
        dispatch(changeScreenerData({
            ...screener.data,
            states,
        }));
        dispatch(saveScreener());
        return resetTime
    }, [props.selectedState.recurringResets, props.position])

    const intervalChange = (newInterval: string) => {
        if (newInterval !== currentResetTime.interval) {
            const newState = _.cloneDeep(props.selectedState);
            newState.recurringResets.splice(props.position, 1, {
                ...currentResetTime,
                interval: newInterval
            });
            newState.recurringResets = [...newState.recurringResets].sort((a, b) => sortRecurringResetTimes(a, b, intervalOptions));
            const states = _.cloneDeep(screener.data.states);
            states.splice(props.statePosition, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
        setIntervalPickerOpen(false);
    }

    const timeChange = (newTime: string) => {
        if (newTime !== currentResetTime.time) {
            const newState = _.cloneDeep(props.selectedState);
            newState.recurringResets.splice(props.position, 1, {
                ...currentResetTime,
                time: newTime
            });
            newState.recurringResets = [...newState.recurringResets].sort((a, b) => sortRecurringResetTimes(a, b, intervalOptions));
            const states = _.cloneDeep(screener.data.states);
            states.splice(props.statePosition, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
        timeRef.current?.blur();
    }

    const deleteRecurringResetTime = () => {
        const newState = _.cloneDeep(props.selectedState);
        newState.recurringResets.splice(props.position, 1);
        const states = _.cloneDeep(screener.data.states);
        states.splice(props.statePosition, 1, newState);
        dispatch(changeScreenerData({
            ...screener.data,
            states,
        }));
        dispatch(saveScreener());
    }
    
    const getCSSColor = (intervalString: string, colorVarName: string): string => {
        if (props.selectedState.recurringResets && currentResetTime.interval === intervalString) {
            return `rgb(var(${colorVarName}))`
        }
        return ""
    }

    const getTimePickerValue = (): Dayjs | undefined => {
        if (props.selectedState.recurringResets && currentResetTime.time !== "") {
            return dayjs(currentResetTime.time, "HH:mm")
        }
        return undefined
    }

    return (
        <div className={BuilderSettingsResetTimeStyles.recurring_picker_row} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className={BuilderSettingsResetTimeStyles.recurring_picker}>
                <div className={BuilderSettingsResetTimeStyles.interval_picker_container} ref={intervalRef}>
                    <div className={BuilderSettingsResetTimeStyles.interval_picker_button} style={{ borderBottomLeftRadius: props.position === props.selectedState.recurringResets.length - 1 ? "6px" : "" }} ref={setButton} onClick={() => {setIntervalPickerOpen(!intervalPickerOpen)}}>
                        <div className={BuilderSettingsResetTimeStyles.interval_picker_button_left}>
                            <div className={BuilderSettingsResetTimeStyles.interval_picker_button_text_wrapper}>
                                <span className={currentResetTime.interval === "" ? `${BuilderSettingsResetTimeStyles.interval_picker_button_text} ${BuilderSettingsResetTimeStyles.interval_picker_button_text_placeholder}` : `${BuilderSettingsResetTimeStyles.interval_picker_button_text}`}>{currentResetTime.interval !== "" ? currentResetTime.interval : "Set Interval"}</span>
                            </div>
                        </div>
                        <div className={BuilderSettingsResetTimeStyles.interval_picker_button_right}>
                            <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.interval_picker_button_arrow_icon} style={{ fill: intervalPickerOpen ? "rgb(var(--dl-color-primary-100))" : "", transform: intervalPickerOpen ? "rotate(180deg)" : "" }}>
                                <path d="M708 366l60 60-256 256-256-256 60-60 196 196z" />
                            </svg>
                        </div>
                    </div>
                    {intervalPickerOpen ? createPortal(
                        <div className={BuilderSettingsResetTimeStyles.interval_picker_button_options_wrapper} ref={setPopup} style={{ ...styles.popper, width: intervalRef.current?.clientWidth }} {...attributes.popper}>
                            {intervalOptions.map((intervalString, index) => (
                                <div className={BuilderSettingsResetTimeStyles.interval_picker_button_option} style={{ backgroundColor: getCSSColor(intervalString, "--dl-color-primary-100") }} key={index} onClick={() => intervalChange(intervalString)}>
                                    <p className={BuilderSettingsResetTimeStyles.interval_picker_button_option_text} style={{ color: getCSSColor(intervalString, "--dl-color-white-always") }}>{intervalString}</p>
                                </div>
                            ))}
                        </div>,
                        document.body
                    ) : null}
                </div>
                <div className="reset_time_picker" style={{ borderWidth: isHovered ? "0px 1px 0px 0px" : "0px", marginRight: isHovered ? "28px" : "" }}>
                    <TimePicker
                        format="HH:mm"
                        allowClear={false}
                        popupClassName="date-picker"
                        value={getTimePickerValue()}
                        onChange={(time, timeString) => {
                            timeChange(timeString)
                        }}
                        placeholder="Set Time"
                        ref={timeRef}
                    />
                </div>
            </div>
            <svg viewBox="0 0 804.5714285714286 1024" className={BuilderSettingsResetTimeStyles.delete_icon} style={{ opacity: isHovered ? "1" : "0" }} onClick={() => deleteRecurringResetTime()}>
                <path d="M292.571 420.571v329.143c0 10.286-8 18.286-18.286 18.286h-36.571c-10.286 0-18.286-8-18.286-18.286v-329.143c0-10.286 8-18.286 18.286-18.286h36.571c10.286 0 18.286 8 18.286 18.286zM438.857 420.571v329.143c0 10.286-8 18.286-18.286 18.286h-36.571c-10.286 0-18.286-8-18.286-18.286v-329.143c0-10.286 8-18.286 18.286-18.286h36.571c10.286 0 18.286 8 18.286 18.286zM585.143 420.571v329.143c0 10.286-8 18.286-18.286 18.286h-36.571c-10.286 0-18.286-8-18.286-18.286v-329.143c0-10.286 8-18.286 18.286-18.286h36.571c10.286 0 18.286 8 18.286 18.286zM658.286 834.286v-541.714h-512v541.714c0 27.429 15.429 43.429 18.286 43.429h475.429c2.857 0 18.286-16 18.286-43.429zM274.286 219.429h256l-27.429-66.857c-1.714-2.286-6.857-5.714-9.714-6.286h-181.143c-3.429 0.571-8 4-9.714 6.286zM804.571 237.714v36.571c0 10.286-8 18.286-18.286 18.286h-54.857v541.714c0 62.857-41.143 116.571-91.429 116.571h-475.429c-50.286 0-91.429-51.429-91.429-114.286v-544h-54.857c-10.286 0-18.286-8-18.286-18.286v-36.571c0-10.286 8-18.286 18.286-18.286h176.571l40-95.429c11.429-28 45.714-50.857 76-50.857h182.857c30.286 0 64.571 22.857 76 50.857l40 95.429h176.571c10.286 0 18.286 8 18.286 18.286z" />
            </svg>
        </div>
    )
}

export default BuilderSettingsResetTimeRecurring