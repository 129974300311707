import { useEffect, useState } from "react"

import { useAppSelector } from "../../../../../store/hooks"
import { State } from "../../../../../types/Screener"
import DashboardScreenerBuilder from "./builder/dashboard-screener-builder"
import DashboardScreenerDetailsStyles from "./DashboardScreenerDetails.module.css"
import DashboardScreenerNewState from "./new-state/dashboard-screener-new-state"
import DashboardScreenerResults from "./results/dashboard-screener-results"
import DashboardScreenerSource from "./source/dashboard-screener-source"
import DashboardScreenerTopbar from "./topbar/dashboard-screener-topbar"

const DashboardScreenerDetails: React.FC = () => {
    const screener = useAppSelector((state) => state.screener)

    const [selectedState, setSelectedState] = useState<State | null>(null);
    const [newState, setNewState] = useState<number | null>(null);

    useEffect(() => {
        if (selectedState !== null) {
            const newSelectedState = screener.data.states.find((state) => state.id === selectedState.id)
            if (typeof newSelectedState !== "undefined") {
                setSelectedState(newSelectedState)
            }
        }
    }, [screener.data.states])

    useEffect(() => {
        setSelectedState(null);
        setNewState(null);
    }, [screener.data.id])

    return (
        <div className={DashboardScreenerDetailsStyles.container}>
            <DashboardScreenerTopbar selectedState={selectedState} setSelectedState={setSelectedState} newState={newState} setNewState={setNewState} />
            {(screener.data.states.length === 0 || selectedState === null) && newState === null ? <DashboardScreenerSource screener={screener.data} setNewState={setNewState} /> : null}
            {selectedState !== null && !newState ? <DashboardScreenerBuilder selectedState={selectedState} setSelectedState={setSelectedState} setNewState={setNewState} /> : null}
            {newState !== null ? <DashboardScreenerNewState setSelectedState={setSelectedState} newState={newState} setNewState={setNewState} /> : null}
            <DashboardScreenerResults />
        </div>
    )
}

export default DashboardScreenerDetails