import { forwardRef, lazy, Suspense } from 'react';

import { useAppSelector } from '../../../../../../../../store/hooks';
import { FilterAlertCondition, FilterAlertConditionsCategory } from '../../../../../../../../types/FiltersAlerts';
import DashboardScreenerBuilderConditionCardFallback from './dashboard-screener-builder-condition-card-fallback';
import DashboardScreenerBuilderConditionStyles from "./DashboardScreenerBuilderCondition.module.css"

const DashboardScreenerBuilderConditionCard = lazy(() => import('./dashboard-screener-builder-condition-card'));

type DashboardScreenerBuilderConditionCategoryProps = {
  conditionCategory: FilterAlertConditionsCategory;
  conditionsList: FilterAlertCondition[],
  expandedCard: string;
  setExpandedCard: (name: string) => void;
}

const DashboardScreenerBuilderConditionCategory = forwardRef<HTMLDivElement, DashboardScreenerBuilderConditionCategoryProps>((props, ref) => {
    const management = useAppSelector((state) => state.management);
    const user = useAppSelector((state) => state.user);

    if (props.conditionsList.filter((condition) => {
        if (management.showOnlyFavouriteConditions) {
            if (user.userData.conditionFavourites.includes(condition.id)) {
                return true
            } 
            return false
        } 
        return true
    
    }).length > 0) {
        return (
            <div className={DashboardScreenerBuilderConditionStyles.category_wrapper} ref={ref} id={`${props.conditionCategory.name}`}>
                <div className={DashboardScreenerBuilderConditionStyles.category_title_row}>
                    <p className={DashboardScreenerBuilderConditionStyles.category_title}>{props.conditionCategory.name}</p>
                </div>
                <div className={DashboardScreenerBuilderConditionStyles.conditions_wrapper}>
                    {props.conditionsList.filter((condition) => {
                        if (management.showOnlyFavouriteConditions) {
                            if (user.userData.conditionFavourites.includes(condition.id)) {
                                return true
                            } 
                            return false
                        } 
                        return true
                    }).map((condition, index) => (
                        <Suspense fallback={<DashboardScreenerBuilderConditionCardFallback />} key={index}>
                            <DashboardScreenerBuilderConditionCard condition={condition} gridRow={Math.ceil(index + 1) / 2} isExpanded={props.expandedCard === condition.id} setExpandedCard={props.setExpandedCard} />
                        </Suspense>
                    ))}
                </div>
            </div>
        )
    }

    return null
})

DashboardScreenerBuilderConditionCategory.displayName = "DashboardScreenerBuilderConditionCategory"

export default DashboardScreenerBuilderConditionCategory