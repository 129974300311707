import WidgetTable from "../../pages/app/dashboards/grid/widgets/widget-table"
import WidgetEconomicCalendar from "../../pages/app/dashboards/grid/widgets/widget-economic-calendar"
import { WidgetConnectionColorName } from "../../types/Dashboard"

export interface WidgetComponents {
    [componentName: string]: WidgetComponent
}

type WidgetComponent = {
    displayName: string,
    component: JSX.Element,
    icon: JSX.Element
}

const getAllWidgetComponents = (id: string, connectionName: WidgetConnectionColorName, iconSize: number, isHovered: boolean): WidgetComponents => {
    return {
        "table": {
            displayName: "Table",
            component: <WidgetTable id={id} connectionName={connectionName} isHovered={isHovered} />,
            icon: <svg viewBox="0 0 1024 1024" style={{fill: "currentColor", width: iconSize, height: iconSize }}>
                    <path d="M341.333 298.667h554.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-554.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM341.333 554.667h554.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-554.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM341.333 810.667h554.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-554.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM170.667 256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667 19.115 42.667 42.667 42.667 42.667-19.115 42.667-42.667zM170.667 512c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667 19.115 42.667 42.667 42.667 42.667-19.115 42.667-42.667zM170.667 768c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667 19.115 42.667 42.667 42.667 42.667-19.115 42.667-42.667z"></path>
                </svg>
        },
        "economic-calendar": {
            displayName: "Calendar",
            component: <WidgetEconomicCalendar id={id} connectionName={connectionName} isHovered={isHovered} />,
            icon: <svg viewBox="0 0 1024 1024" style={{fill: "currentColor", width: iconSize, height: iconSize }}>
                    <path d="M298.667 85.333v42.667h-85.333c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v597.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-597.333c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-85.333v-42.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v42.667h-256v-42.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM853.333 384h-682.667v-128c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h85.333v42.667c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-42.667h256v42.667c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-42.667h85.333c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165zM170.667 469.333h682.667v384c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-597.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165z"></path>
                </svg>
        }
    }
}

export const getAllWidgetComponentKeys = (): string[] => {
    return Object.keys(getAllWidgetComponents("", "none", 14, true))
}

export const getWidgetComponent = (id: string, componentName: string, connectionName: WidgetConnectionColorName, iconSize: number, isHovered: boolean): JSX.Element => {
    const widgetComponents = getAllWidgetComponents(id, connectionName, iconSize, isHovered)
    if (getAllWidgetComponentKeys().includes(componentName)) {
        return widgetComponents[componentName].component
    }
    return <></>
}

export default getAllWidgetComponents