import { doc, getDoc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";

import { firestoreDB } from "../../../../../../firebase/firebase-config";
import { Screener } from "../../../../../../types/Screener";
import { User } from "../../../../../../types/User";
import ProfileAvatar from "../../../../../../utils/components/profile-avatar/profile-avatar";
import DashboardScreenerSourceTabScreenersState from "./dashboard-screener-source-tab-screeners-state";
import DashboardScreenerSourceStyles from "./DashboardScreenerSource.module.css"

type Props = {
    screener: Screener;
    screenerRowId: string;
    screenerRow: Screener;
    search: string;
    isExpanded: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<string>>;

}

const DashboardScreenerSourceTabScreenersScreener: React.FC<Props> = (props) => {
    const [adminUser, setAdminUser] = useState<User | null>(null);

    const filteredStates = useMemo(() => props.screenerRow.states.filter((s) => s.name.toLowerCase().replace(" ", "").includes(props.search.toLowerCase().replace(" ", ""))), [props.screenerRow, props.search])
    
    const sources = useMemo(() => ({ ...props.screener.sources }), [props.screener.sources])
    const screeners = useMemo(() => ([...sources.screeners]), [sources])
    
    const screenerSelected = screeners.filter(s => props.screenerRow.states.map((sR) => sR.id).includes(s.stateId)).length > 0;

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const newAdminUser = (await getDoc(doc(firestoreDB, "users", props.screenerRow.adminUid))).data() as User;
                setAdminUser(newAdminUser)
            } catch (error: unknown) {
                setAdminUser(null)
            }
        }
        fetchUser()
    }, [])
    
    if (filteredStates.length > 0) {
        return (
            <div className={DashboardScreenerSourceStyles.stocklist_state_list}>
                <div className={DashboardScreenerSourceStyles.stocklist_row} style={{ cursor: "pointer", backgroundColor: props.isExpanded ? "rgb(var(--dl-color-backgrounds-hover2))" : "" }} onClick={() => props.setIsExpanded(props.isExpanded ? "" : props.screenerRowId)}>
                    <div className={DashboardScreenerSourceStyles.stocklist_row_left}>
                        <div className={DashboardScreenerSourceStyles.stocklist_row_logo_wrapper}>
                            <ProfileAvatar size={25} avatar={adminUser?.avatar} displayName={adminUser?.displayName} />
                        </div>
                        <span className={DashboardScreenerSourceStyles.stocklist_row_text}>{props.screenerRow.name}</span>
                    </div>
                    <div className={DashboardScreenerSourceStyles.stocklist_row_middle} style={{ flexShrink: props.screenerRow.description === "" ? "1" : "" }}>
                        <span className={DashboardScreenerSourceStyles.stocklist_row_text}>{props.screenerRow.description}</span>
                    </div>
                    <div className={DashboardScreenerSourceStyles.symbol_count}>
                        <span className={DashboardScreenerSourceStyles.symbol_count_text_gray}>States</span>
                        <span className={DashboardScreenerSourceStyles.stocklist_row_text}>{props.screenerRow.states.length}</span>
                    </div>
                </div>
                <div className={DashboardScreenerSourceStyles.stocklist_state_list}>
                    {filteredStates.map((state, index) => <DashboardScreenerSourceTabScreenersState screener={props.screener} screenerRowId={props.screenerRowId} index={index} state={state} isExpanded={props.isExpanded} screenerSelected={screenerSelected} key={index} />)}
                </div>
            </div>
        )
    }
    return null
}

export default DashboardScreenerSourceTabScreenersScreener