import WidgetTopRowStyles from "../../../../WidgetTopRowStyles.module.css"
import EconomyButtonCategory from "./category/economy-button-category"
import EconomyButtonCountry from "./countries/economy-button-country"
import EconomyButtonDate from "./economy-button-date"
import EconomyButtonPriority from "./priority/economy-button-priority"

type Props = {
    id: string;
    width: number;
}

const TabEconomyTopRow: React.FC<Props> = (props) => (
    <div className={WidgetTopRowStyles.container}>
        <div className={WidgetTopRowStyles.group_container}>
            <EconomyButtonDate id={props.id} width={props.width} />
        </div>
        <div className={WidgetTopRowStyles.group_container}>
            <EconomyButtonCountry id={props.id} />
            <EconomyButtonPriority id={props.id} />
            <EconomyButtonCategory id={props.id} width={props.width} />
        </div>
    </div>
)

export default TabEconomyTopRow