import React from "react";

import WidgetStyles from "../../../WidgetStyles.module.css"
import TableEconomy from "./table/table-economy";
import TabEconomyTopRow from "./top-row/tab-economy-top-row"

type Props = {
    id: string;
    width: number;
}

const TabEconomy: React.FC<Props> = (props) => (
    <div className={WidgetStyles.tab_content}>
        <TabEconomyTopRow id={props.id} width={props.width} />
        <TableEconomy width={props.width} />
    </div>
)

export default TabEconomy