import { useResizeDetector } from "react-resize-detector";

import { useAppSelector } from "../../../../../../../store/hooks";
import { State } from "../../../../../../../types/Screener";
import DashboardScreenerDetailsStyles from "../../DashboardScreenerDetails.module.css"
import BuilderSettingsDelete from "./builder-settings-delete";
import BuilderSettingsFrequency from "./builder-settings-frequency";
import BuilderSettingsMaximumAlerts from "./builder-settings-maximum-alerts";
import BuilderSettingsName from "./builder-settings-name";
import BuilderSettingsResetTime from "./builder-settings-reset-time";
import BuilderSettingsUpdateInterval from "./builder-settings-update-interval"

type Props = {
    selectedState: State;
    setSelectedState: React.Dispatch<React.SetStateAction<State | null>>;
    setNewState: React.Dispatch<React.SetStateAction<number | null>>;
}

const DashboardScreenerBuilderSettings: React.FC<Props> = (props) => {
    const screener = useAppSelector((state) => state.screener)

    const index = screener.data.states.findIndex((state) => state.id === props.selectedState.id)

    const { width: settingsWidth, ref: settingsRef } = useResizeDetector();
    
    if (index > -1) {
        return (
            <>
                <div className={DashboardScreenerDetailsStyles.title_row} ref={settingsRef}>
                    <span className={DashboardScreenerDetailsStyles.title_text}>Settings</span>
                </div>
                {settingsWidth ? (
                    <div className={DashboardScreenerDetailsStyles.settings_content} style={{ gap: settingsWidth < 420 ? "32px" : "" }}>
                        <BuilderSettingsName selectedState={props.selectedState} small={settingsWidth < 420} />
                        <BuilderSettingsUpdateInterval selectedState={props.selectedState} small={settingsWidth < 420} />
                        {props.selectedState.type === "alert" ? <BuilderSettingsMaximumAlerts selectedState={props.selectedState} small={settingsWidth < 420} /> : null}
                        {props.selectedState.type === "alert" ? <BuilderSettingsFrequency selectedState={props.selectedState} small={settingsWidth < 420} /> : null}
                        {props.selectedState.type === "alert" ? <BuilderSettingsResetTime selectedState={props.selectedState} small={settingsWidth < 420} /> : null}
                        <BuilderSettingsDelete name={props.selectedState.name} index={index} setSelectedState={props.setSelectedState} setNewState={props.setNewState} />
                    </div>
                ) : null}
            </>
        )
    }

    return null
}

export default DashboardScreenerBuilderSettings