import { doc, getDoc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";

import { firestoreDB } from "../../../../../../firebase/firebase-config";
import { useAppSelector } from "../../../../../../store/hooks";
import { TableSettings, Widget } from "../../../../../../types/Dashboard";
import { Screener } from "../../../../../../types/Screener";
import { User } from "../../../../../../types/User";
import ProfileAvatar from "../../../../profile-avatar/profile-avatar";
import TableTopRowButtonSourceTabScreenersState from "./dashboard-screener-source-tab-screeners-state";
import WidgetTableSource from "./WidgetTableSource.module.css"

type Props = {
    widgetId: string;
    screenerRowId: string;
    screenerRow: Screener;
    search: string;
    isExpanded: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<string>>;

}

const TableTopRowButtonSourceTabScreenersScreener: React.FC<Props> = (props) => {
    const [adminUser, setAdminUser] = useState<User | null>(null);

    const filteredStates = useMemo(() => props.screenerRow.states.filter((s) => s.name.toLowerCase().replace(" ", "").includes(props.search.toLowerCase().replace(" ", ""))), [props.screenerRow, props.search])
    
    const dashboard = useAppSelector((state) => state.dashboard)

    const widget: Widget = useMemo(() => dashboard.data.widgets.find((w) => w.id === props.widgetId)!, [dashboard.data.widgets, props.widgetId])

    const settings = useMemo(() => widget.settings as TableSettings, [widget])

    const sources = useMemo(() => ({ ...settings.sources }), [settings.sources])

    const screeners = useMemo(() => ([...sources.screeners]), [sources])
    
    const screenerSelected = screeners.filter(s => props.screenerRow.states.map((sR) => sR.id).includes(s.stateId)).length > 0;

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const newAdminUser = (await getDoc(doc(firestoreDB, "users", props.screenerRow.adminUid))).data() as User;
                setAdminUser(newAdminUser)
            } catch (error: unknown) {
                setAdminUser(null)
            }
        }
        fetchUser()
    }, [])
    
    if (filteredStates.length > 0) {
        return (
            <div className={WidgetTableSource.stocklist_state_list}>
                <div className={WidgetTableSource.stocklist_row} style={{ cursor: "pointer", backgroundColor: props.isExpanded ? "rgb(var(--dl-color-backgrounds-hover2))" : "" }} onClick={() => props.setIsExpanded(props.isExpanded ? "" : props.screenerRowId)}>
                    <div className={WidgetTableSource.stocklist_row_left}>
                        <div className={WidgetTableSource.stocklist_row_logo_wrapper}>
                            <ProfileAvatar size={25} avatar={adminUser?.avatar} displayName={adminUser?.displayName} />
                        </div>
                        <span className={WidgetTableSource.stocklist_row_text}>{props.screenerRow.name}</span>
                    </div>
                    <div className={WidgetTableSource.stocklist_row_middle} style={{ flexShrink: props.screenerRow.description === "" ? "1" : "" }}>
                        <span className={WidgetTableSource.stocklist_row_text}>{props.screenerRow.description}</span>
                    </div>
                    <div className={WidgetTableSource.symbol_count}>
                        <span className={WidgetTableSource.symbol_count_text_gray}>States</span>
                        <span className={WidgetTableSource.stocklist_row_text}>{props.screenerRow.states.length}</span>
                    </div>
                </div>
                <div className={WidgetTableSource.stocklist_state_list}>
                    {filteredStates.map((state, index) => <TableTopRowButtonSourceTabScreenersState widgetId={props.widgetId} screenerRowId={props.screenerRowId} index={index} state={state} isExpanded={props.isExpanded} screenerSelected={screenerSelected} key={index} />)}
                </div>
            </div>
        )
    }
    return null
}

export default TableTopRowButtonSourceTabScreenersScreener