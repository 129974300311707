import { useMemo } from 'react';

import { IndicatorParameters } from '../../../../../../types/Screener';
import ConditionRow from "../../../ConditionRow.module.css"
import CriteriaInputNumber from '../../criteria-input-number';
import CriteriaInputTimeframe from '../../criteria-input-timeframe';
import checkUnusedParams from '../../functions';

type Props = {
    parameters: IndicatorParameters;
    setParameters: (parameters: IndicatorParameters) => void;
}

const defaultProps: Props = {
    parameters: {
        timeframe: "1 minute",
        multiplicator: "1"
    },
    setParameters: () => {},
}

const CriteriaParameterVolumeCandle: React.FC<Props> = (props) => {
    const timeframe = useMemo(() => props.parameters.timeframe ? props.parameters.timeframe : defaultProps.parameters.timeframe!, [props.parameters.timeframe])
    const multiplicator = useMemo(() => props.parameters.multiplicator ? props.parameters.multiplicator : defaultProps.parameters.multiplicator!, [props.parameters.multiplicator])

    const setTimeframe = (newTimeframe: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, timeframe: newTimeframe }, defaultProps.parameters))
    }

    const setMultiplicator = (newMultiplicator: string) => {
        props.setParameters(checkUnusedParams({ ...props.parameters, multiplicator: newMultiplicator }, defaultProps.parameters))
    }

    return (
        <>
            <CriteriaInputTimeframe timeframe={timeframe} setTimeframe={setTimeframe} />
            <span className={ConditionRow.criteria_text}>x</span>
            <CriteriaInputNumber number={multiplicator} setNumber={setMultiplicator} min={-99.99} max={99.99} step="0.01" width="70px" />
        </>
    )
}

export default CriteriaParameterVolumeCandle