export const timeframes = [
    "1 minute",
    "2 minutes",
    "3 minutes",
    "5 minutes",
    "15 minutes",
    "30 minutes",
    "45 minutes",
    "1 hour",
    "2 hours",
    "4 hours",
    "6 hours",
    "1 day",
    "1 week",
    "1 month",
    "3 months",
    "6 months",
    "1 year"
]