import { useState } from "react";

import ToggleSwitchStyles from "./ToggleSwitch.module.css";

type Props = {
    isToggled: boolean;
    onClick?: (setClicked: boolean) => void;
}

const ToggleSwitch: React.FC<Props> = (props) => {
    const [isHovered, setIsHovered] = useState(false);

    const getBackgroundColor = () => {
        if (props.isToggled) {  
            if (isHovered) {
                return "rgb(var(--dl-color-primary-300))"
            }
            return "rgb(var(--dl-color-primary-100))"
        }
        return ""
    }

    return (
        <div
            className={ToggleSwitchStyles.toggle_switch}
            style={{ justifyContent: props.isToggled ? "flex-end" : "", backgroundColor: getBackgroundColor() }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {if (props.onClick) {props.onClick(!props.isToggled)}}}
        >
            <div className={ToggleSwitchStyles.toggle} />
        </div>
    )
}

export default ToggleSwitch