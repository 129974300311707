import DashboardWidgetStyles from "../DashboardWidgetStyles.module.css";

const GridWidgetSettingsOptionLoadTemplate: React.FC = () => (
    <div className={DashboardWidgetStyles.settings_popup_item}>
        <div className={DashboardWidgetStyles.settings_popup_item_left}>
            <svg viewBox="0 0 1024 1024" className={DashboardWidgetStyles.settings_popup_item_icon}>
                <path d="M342 640l170-170 170 170-60 62-68-68v176h-84v-176l-68 66zM768 854v-470h-214v-214h-298v684h512zM598 86l256 256v512q0 34-26 59t-60 25h-512q-34 0-60-25t-26-59l2-684q0-34 25-59t59-25h342z"/>
            </svg>
            <p className={DashboardWidgetStyles.settings_popup_item_text}>Load template</p>
        </div>
        <svg viewBox="0 0 1024 1024" className={DashboardWidgetStyles.settings_popup_item_icon_arrow}>
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z" />
        </svg>
    </div>
)

export default GridWidgetSettingsOptionLoadTemplate