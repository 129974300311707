import { useEffect, useMemo } from "react";

import DashboardScreenerBuilderCondition from "../../../../../pages/app/dashboards/screener/details/builder/criteria/condition-popup/dashboard-screener-builder-condition";
import { setPopupElement } from "../../../../../store/features/management";
import { saveScreener } from "../../../../../store/features/screener";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { FilterAlertRowProps } from "../../../../../types/FiltersAlerts";
import { IndicatorParameters } from "../../../../../types/Screener";
import { findRow } from "../../../../functions/deep-splice-criteria";
import ConditionRow from "../../ConditionRow.module.css"
import CriteriaParameterSectorIndustry from "../../elements/parameters/descriptive/criteria-parameter-sector-industry";
import parameterChange, { checkIdB, checkOperator } from "../functions";

const ConditionRowSectorIndustry: React.FC<FilterAlertRowProps> = (props) => {
    const dispatch = useAppDispatch();
    const management = useAppSelector((state) => state.management);
    const screener = useAppSelector((state) => state.screener);

    const state = useMemo(() => {
        const newState = screener.data.states.find((s) => s.id === props.state.id)
        if (typeof newState !== "undefined") {
            return newState
        }
        return props.state
    }, [screener.data.states, props.state])

    const row = useMemo(() => {
        const newRow = findRow(state.criteria.criteriaList, props.row.id)
        if (typeof newRow !== "undefined" && newRow !== null) {
            return newRow
        }
        return props.row
    }, [state, props.row.id])

    const settings = useMemo(() => row.settings, [row])

    useEffect(() => {
        if (management.conditionExpandedCard === props.id && management.conditionExpandedCard !== settings.idA) {
            let newSettings = { ...settings, idA: props.id, idB1: "", idB2: "", parametersB1: {}, parametersB2: {} }
            newSettings = checkIdB(props.type, props.comparisons, newSettings)
            newSettings = checkOperator(newSettings)
            parameterChange(dispatch, newSettings, screener, state, row)
        }
    }, [management.conditionExpandedCard])

    const setParametersA = (parametersA: IndicatorParameters) => {
        if (props.id === settings.idA) {
            parameterChange(dispatch, { ...settings, parametersA }, screener, state, row)
            if (management.popupElement === null) {
                dispatch(saveScreener())
            }
        }
    }

    return (
        <div className={ConditionRow.criteria_condition}>
            <div className={ConditionRow.criteria_category_wrapper}>
                <div className={ConditionRow.criteria_category_type_box} style={{ borderColor: "rgb(227, 40, 114)", pointerEvents: management.popupElement !== null ? "none" : "auto" }} onClick={() => dispatch(setPopupElement(<DashboardScreenerBuilderCondition state={state} row={row} />))}>
                    <span className={ConditionRow.criteria_category_type_text}>Sector and Industry</span>
                </div>
                <CriteriaParameterSectorIndustry parameters={settings.parametersA} setParameters={setParametersA} />
            </div>
        </div>
    )
}

export default ConditionRowSectorIndustry