import { WidgetConnectionColorName } from "../../../../../../types/Dashboard";
import DashboardWidgetStyles from "../DashboardWidgetStyles.module.css"
import GridWidgetSettingsConnection from "./grid-widget-settings-connection";
import GridWidgetSettingsOptions from "./grid-widget-settings-options";

type Props = {
    id: string;
    connectionName: WidgetConnectionColorName;
    visible: boolean;
}

const GridWidgetSettings: React.FC<Props> = (props) => {
    return (
        <div className={DashboardWidgetStyles.settings_container} style={{ backgroundColor: props.visible ? "" : "transparent", boxShadow: props.visible ? "" : "none" }}>
            <GridWidgetSettingsConnection id={props.id} connectionName={props.connectionName} />
            <div className={DashboardWidgetStyles.settings_expanded} style={{ width: props.visible ? "33px" : "0px", overflow: props.visible ? "" : "hidden" }}>
                <div className={DashboardWidgetStyles.settings_separator} />
                <GridWidgetSettingsOptions id={props.id} visible={props.visible} />
            </div>
        </div>
    )
    
    return null
}

export default GridWidgetSettings