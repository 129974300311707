import TableTableStyles from "../TableTableStyles.module.css"

type Props = {
    width: number;
    firstCellWidth: number;
}

const CellNewsRight: React.FC<Props> = (props) => (
    <div className={TableTableStyles.row_cell} style={{ width: `calc(100% - ${props.firstCellWidth}px - 26px)` }}>
        <div className={TableTableStyles.cell_wrapper} style={{ borderRight: "1px solid rgb(var(--dl-color-borders-separator))", justifyContent: "flex-start", padding: props.width > 140 ? "0px 16px 6px 16px" : "0px 8px 6px 8px" }}>
            <span className={TableTableStyles.news_text}>Nvidia Does It Again. Revenue Beat at $13.5bn, Updated Guidance Chasing $16bn.</span>
            <span className={TableTableStyles.news_time}>13:45</span>
        </div>
    </div>
)

export default CellNewsRight