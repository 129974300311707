import SignupLoginComponents from './SignupLoginComponents.module.css'

const SignupLoginBackground: React.FC = () => (
    <div className={SignupLoginComponents.background_image}>
        <svg xmlns="http://www.w3.org/2000/svg" width="2000" height="2000" fill="url(#gradient)" className={SignupLoginComponents.image_svg} viewBox="0 0 529.16666 529.16668">
            <defs>
                <linearGradient id="gradient" gradientTransform="rotate(-50)">
                    <stop offset="0%" stopColor="#4169e2" />
                    <stop offset="80%" stopColor="#ee43f1" />
                </linearGradient>
            </defs>
            <path d="M -87.684865,388.01872 C -12.793393,306.65995 135.6758,270.0891 303.21453,237.27327 470.75325,204.45743 657.35999,175.39681 727.6152,251.92822 797.87041,328.45962 751.77195,510.57357 583.57207,606.46485 415.37218,702.35613 125.09513,712.01205 -19.397107,653.76617 -163.88934,595.52028 -162.57634,469.3775 -87.684865,388.01872 Z"/>
        </svg>
    </div>
)

export default SignupLoginBackground