import React, { Dispatch, useEffect, useMemo, useRef, useState } from "react";
import { HeaderGroup } from "react-table";

import findClosestIndex from "../../../../../../functions/find-closest-index";
import formatLargeNumber, { reverseFormatLargeNumber } from "../../../../../../functions/format-large-number";
import TableButtonFilterStyles from "../TableButtonFilterStyles.module.css"

type Props = {
    column: HeaderGroup<object>;
    min: number;
    max: number;
}

const FilterRowNumberLargeNumber: React.FC<Props> = ({ column, min, max }) => {
    const steps = [min, 50000000, 250000000, 500000000, 1000000000, 5000000000, 10000000000, 50000000000, 100000000000, 500000000000, max]

    const filterValue: number[] = useMemo(() => column.filterValue ? column.filterValue as number[] : [], [column.filterValue])
    const setFilterValue = useMemo(() => column.setFilter, [column.setFilter])
    
    const inputMinRef = useRef<HTMLInputElement | null>(null);
    const inputMaxRef = useRef<HTMLInputElement | null>(null);

    const inputRangeMinRef = useRef<HTMLInputElement | null>(null);
    const inputRangeMaxRef = useRef<HTMLInputElement | null>(null);

    const [valueMin, setValueMin] = useState<string>(filterValue[0] ? formatLargeNumber(filterValue[0]) : "");
    const [valueMax, setValueMax] = useState<string>(filterValue[1] ? formatLargeNumber(filterValue[1]) : "");

    const [valueRangeMin, setValueRangeMin] = useState<string>(filterValue[0] ? findClosestIndex(steps, filterValue[0]).toString() : "0");
    const [valueRangeMax, setValueRangeMax] = useState<string>(filterValue[1] ? findClosestIndex(steps, filterValue[1]).toString() : (steps.length - 1).toString());

    useEffect(() => {
        if (filterValue.length === 0 || typeof filterValue[0] === "undefined" || filterValue[0] === null) {
            setValueMin("")
            setValueRangeMin("0")
        } else {
            setValueMin(formatLargeNumber(filterValue[0]))
            const closestIndex = findClosestIndex(steps, filterValue[0], filterValue[0] === max).toString()
            setValueRangeMin(closestIndex !== valueRangeMax ? closestIndex : (Number(valueRangeMax) - 1).toString())
        }
        if (filterValue.length === 0 || typeof filterValue[1] === "undefined" || filterValue[1] === null) {
            setValueMax("")
            setValueRangeMax((steps.length - 1).toString())
        } else {
            setValueMax(formatLargeNumber(filterValue[1]) !== "0" ? formatLargeNumber(filterValue[1]) : "")
            const closestIndex = findClosestIndex(steps, filterValue[1], filterValue[1] === min).toString()
            setValueRangeMax(closestIndex !== valueRangeMin ? closestIndex : (Number(valueRangeMin) + 1).toString())
        }
    }, [filterValue])

    const onBlurMin = () => {
        const newNumber = reverseFormatLargeNumber(valueMin, filterValue[0] ? formatLargeNumber(filterValue[0]) : "", min, max)
        let newFilterValue = newNumber ? parseInt(newNumber, 10) : undefined
        if ((newNumber === min.toString()) || (newNumber === max.toString())) {
            newFilterValue = undefined
        }
        if (typeof newFilterValue === "undefined") {
            setFilterValue((old = []) => [undefined, old[1]])
            setValueMin("")
        } else if (newFilterValue > filterValue[1]) {
            setFilterValue((old = []) => [old[1], old[1]])
            if (inputRangeMinRef?.current) inputRangeMinRef.current.style.zIndex = "1"
            if (inputRangeMaxRef?.current) inputRangeMaxRef.current.style.zIndex = "0"
        } else {
            setFilterValue((old = []) => [newFilterValue, old[1]])
        }
    }
    
    const onBlurMax = () => {
        const newNumber = reverseFormatLargeNumber(valueMax, filterValue[1] ? formatLargeNumber(filterValue[1]) : "", min, max)
        let newFilterValue = newNumber ? parseInt(newNumber, 10) : undefined
        if ((newNumber === min.toString()) || (newNumber === max.toString())) {
            newFilterValue = undefined
        }
        if (typeof newFilterValue === "undefined") {
            setFilterValue((old = []) => [old[0], undefined])
            setValueMax("")
        } else if (newFilterValue < filterValue[0]) {
            setFilterValue((old = []) => [old[0], old[0]])
            if (inputRangeMinRef?.current) inputRangeMinRef.current.style.zIndex = "0"
            if (inputRangeMaxRef?.current) inputRangeMaxRef.current.style.zIndex = "1"
        } else {
            setFilterValue((old = []) => [old[0], newFilterValue])   
        }
    }

    const inputChange = (event: React.ChangeEvent<HTMLInputElement>, setValue: Dispatch<string>) => {
        const newValue = event.target.value
        if (newValue.length === 0) {
            setValue("")
        } else {
            setValue(newValue)
        }
    }

    const handleKeypress = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            inputMinRef.current?.blur();
            inputMaxRef.current?.blur();
        }
    }
    
    const rangeInputMinChange = (newValue: string) => {
        if (parseInt(newValue) >= parseInt(valueRangeMax) - 1) {
            setValueRangeMin((parseInt(valueRangeMax) - 1).toString())
            setFilterValue((old = []) => [steps[parseInt(valueRangeMax, 10) - 1] !== max ? steps[parseInt(valueRangeMax, 10) - 1] : undefined, old[1]])
        } else {
            setValueRangeMin(newValue)
            setFilterValue((old = []) => [steps[parseInt(newValue, 10)] !== min ? steps[parseInt(newValue, 10)] : undefined, old[1]])
        }
    }

    const rangeInputMaxChange = (newValue: string) => {
        if (parseInt(newValue) <= parseInt(valueRangeMin) + 1) {
            setValueRangeMax((parseInt(valueRangeMin) + 1).toString())
            setFilterValue((old = []) => [old[0], steps[parseInt(valueRangeMin, 10) + 1] !== max ? steps[parseInt(valueRangeMin, 10) + 1] : undefined])
        } else {
            setValueRangeMax(newValue)
            setFilterValue((old = []) => [old[0], steps[parseInt(newValue, 10)] !== max ? steps[parseInt(newValue, 10)] : undefined])
        }
    }

    return (
        <div className={TableButtonFilterStyles.container}>
            <span className={TableButtonFilterStyles.filter_name}>{column.render('Header')}</span>
            <div className={TableButtonFilterStyles.range_slider}>
                <input
                    type="text"
                    className={TableButtonFilterStyles.range_slider_input_value}
                    placeholder="0"
                    onChange={(event) => {inputChange(event, setValueMin)}}
                    onKeyDown={handleKeypress}
                    onBlur={() => {onBlurMin()}}
                    value={valueMin}
                    ref={inputMinRef}
                />
                <div className={TableButtonFilterStyles.range_slider_wrapper}>
                    <div className={TableButtonFilterStyles.range_slider_colored} style={{ left: `${100 * (parseInt(valueRangeMin, 10) / (steps.length - 1))}%`, right: `${100 * (1 - (parseInt(valueRangeMax, 10) / (steps.length - 1)))}%` }} />
                    <div className={TableButtonFilterStyles.range_slider_toggle_wrapper}>
                        <input
                            type="range"
                            className={TableButtonFilterStyles.range_slider_toggle}
                            min={0}
                            max={steps.length - 1}
                            step="1"
                            value={valueRangeMin}
                            onChange={(event) => rangeInputMinChange(event.target.value)}
                            ref={inputRangeMinRef}
                        />
                        <input
                            type="range"
                            className={TableButtonFilterStyles.range_slider_toggle}
                            min={0}
                            max={steps.length - 1}
                            step="1"
                            value={valueRangeMax}
                            onChange={(event) => rangeInputMaxChange(event.target.value)}
                            ref={inputRangeMaxRef}
                        />
                    </div>
                </div>
                <input
                    type="text"
                    className={TableButtonFilterStyles.range_slider_input_value}
                    placeholder=">999B"
                    onChange={(event) => {inputChange(event, setValueMax)}}
                    onKeyDown={handleKeypress}
                    onBlur={() => {onBlurMax()}}
                    value={valueMax}
                    ref={inputMaxRef}
                />
                <div className={TableButtonFilterStyles.delete_wrapper} style={{ opacity: column.filterValue ? "1" : "0", pointerEvents: column.filterValue ? "auto" : "none" }} onClick={() => column.setFilter(undefined)}>
                    <svg viewBox="0 0 1024 1024" className={TableButtonFilterStyles.delete_icon}>
                        <path d="M1024 384h-384l143.53-143.53c-72.53-72.526-168.96-112.47-271.53-112.47s-199 39.944-271.53 112.47c-72.526 72.53-112.47 168.96-112.47 271.53s39.944 199 112.47 271.53c72.53 72.526 168.96 112.47 271.53 112.47s199-39.944 271.528-112.472c6.056-6.054 11.86-12.292 17.456-18.668l96.32 84.282c-93.846 107.166-231.664 174.858-385.304 174.858-282.77 0-512-229.23-512-512s229.23-512 512-512c141.386 0 269.368 57.326 362.016 149.984l149.984-149.984v384z" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default FilterRowNumberLargeNumber