import WidgetTopRowStyles from "../../../../WidgetTopRowStyles.module.css"
import EarningsButtonCountry from "./countries/earnings-button-country"
import EarningsButtonDate from "./earnings-button-date"
import EarningsButtonSearch from "./earnings-button-search";

type Props = {
    id: string;
    tickerSearch: string;
    setTickerSearch: React.Dispatch<React.SetStateAction<string>>;
}

const TabEarningsTopRow: React.FC<Props> = (props) => (
    <div className={WidgetTopRowStyles.container}>
        <div className={WidgetTopRowStyles.group_container}>
            <EarningsButtonDate id={props.id} />
        </div>
        <div className={WidgetTopRowStyles.group_container}>
            <EarningsButtonCountry id={props.id} />
            <EarningsButtonSearch id={props.id} tickerSearch={props.tickerSearch} setTickerSearch={props.setTickerSearch} />
        </div>
    </div>
)
    
export default TabEarningsTopRow