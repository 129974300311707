import { ColumnBuilderParameter } from "../constants/table-columns";

export const buildColumnHeader = (header: string, parameters?: ColumnBuilderParameter[]): string => {
    if (typeof parameters === "undefined") {
        return header
    }
    let newHeader = header + " (";
    parameters.forEach(parameter => {
        newHeader += parameter.options[0].name + ", "
    })
    newHeader = newHeader.slice(0, -2) + ")"
    return newHeader
}

export const buildColumnId = (id: string, parameters?: ColumnBuilderParameter[]): string => {
    if (typeof parameters === "undefined") {
        return id
    }
    let newId = id
    parameters.forEach(parameter => {
        newId += "-" + parameter.options[0].id
    })
    return newId
}