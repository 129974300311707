import DashboardScreenerDetailsStyles from "../DashboardScreenerDetails.module.css"

type Props = {
    title: string;
    description: string;
    selected: boolean;
    onClick: () => void;
}

const DashboardScreenerNewStateCard: React.FC<Props> = (props) => (
    <div className={DashboardScreenerDetailsStyles.add_state_card} style={{ borderColor: props.selected ? "rgb(var(--dl-color-primary-100))" : "" }} onClick={() => props.onClick()}>
        <div className={DashboardScreenerDetailsStyles.add_state_card_top}>
            {props.title === "Filter" ? (
                <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.top_bar_filter_icon}>
                    <path d="M846.72 170.667l-281.984 333.397c-6.272 7.381-10.069 17.024-10.069 27.563v295.339l-85.333-42.667v-252.672c0.043-9.685-3.285-19.499-10.069-27.563l-281.984-333.397zM938.667 85.333h-853.333c-23.552 0-42.667 19.115-42.667 42.667 0 10.539 3.797 20.181 10.069 27.563l331.264 391.68v263.424c0 16.597 9.472 31.019 23.595 38.144l170.667 85.333c21.077 10.539 46.72 2.005 57.259-19.072 3.072-6.229 4.523-12.843 4.48-19.072v-348.757l331.264-391.68c15.232-18.005 12.971-44.928-5.035-60.117-8.064-6.827-17.877-10.155-27.563-10.112z" />
                </svg>
            ) : (
                <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.top_bar_alert_icon}>
                    <path d="M554 384v128h128v86h-128v128h-84v-128h-128v-86h128v-128h84zM512 854q124 0 211-88t87-212-87-211-211-87-211 87-87 211 87 212 211 88zM512 170q160 0 272 113t112 271-112 271-272 113-272-113-112-271 112-271 272-113zM938 244l-54 66-196-166 54-64zM336 144l-196 164-54-64 196-164z" />
                </svg>
            )}
            <span className={DashboardScreenerDetailsStyles.add_state_card_title}>{props.title}</span>
            <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.settings_help_icon} style={{ marginTop: "0px" }}>
                <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
            </svg>
        </div>
        <div className={DashboardScreenerDetailsStyles.add_state_card_description_wrapper}>
            <span className={DashboardScreenerDetailsStyles.add_state_card_description}>
                {props.description}
            </span>
        </div>
    </div>
)

export default DashboardScreenerNewStateCard