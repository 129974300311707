/* eslint-disable */

import { useEffect } from 'react';
import { firestoreDB } from '../../firebase/firebase-config';
import { collectionGroup, query, where } from 'firebase/firestore';
import { setScreeners } from '../../store/features/allScreeners';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Screener, Screeners } from '../../types/Screener';
import { useCollection } from 'react-firebase-hooks/firestore';
import { changeScreenerData, resetScreener } from '../../store/features/screener';

const ScreenersListener = () => {
  let allScreeners = useAppSelector((state) => state.allScreeners)
  const user = useAppSelector((state) => state.user)
  const screener = useAppSelector((state) => state.screener)
  const dispatch = useAppDispatch();

  const [snapshot, loading, error] = useCollection(query(collectionGroup(firestoreDB, "screeners"), where("access", "array-contains", user.userData.uid)), {});

    useEffect(() => {
        if (!user.loggedIn || !snapshot) {
            return
        }
        const newScreeners: Screeners = {};
        snapshot.docs.map((doc) => newScreeners[doc.id] = {...doc.data()} as Screener)
        if (!_.isEqual(allScreeners.screeners, newScreeners) || !allScreeners.initialized) {
            dispatch(setScreeners(newScreeners));
            if (!Object.keys(newScreeners).includes(screener.data.id)) {
                dispatch(resetScreener())
            }
        }
        if (typeof screener.data.id !== "undefined" && screener.data.id !== "") {
            if (!_.isEqual(newScreeners[screener.data.id], screener.data)) {
                const newScreenerData = newScreeners[screener.data.id]
                if (typeof newScreenerData !== "undefined") {
                    dispatch(changeScreenerData(newScreeners[screener.data.id]))
                }
            }
        }
    }, [snapshot])

    return <></>
};

export default ScreenersListener