import { State } from "../../../../../../types/Screener"
import useResizeLeftSettings from "../../../../../../utils/hooks/use-resize-left-settings"
import DashboardScreenerDetailsStyles from "../DashboardScreenerDetails.module.css"
import DashboardScreenerBuilderCriteria from "./criteria/dashboard-screener-builder-criteria";
import DashboardScreenerBuilderSettings from "./settings/dashboard-screener-builder-settings"

type Props = {
    selectedState: State;
    setSelectedState: React.Dispatch<React.SetStateAction<State | null>>;
    setNewState: React.Dispatch<React.SetStateAction<number | null>>;
}

const DashboardScreenerBuilder: React.FC<Props> = (props) => {
    const { resizableRef, resizeHandlerRef } = useResizeLeftSettings();

    return (
        <div className={DashboardScreenerDetailsStyles.builder_wrapper} id="dashboard-screener-builder-wrapper">
            <div className={DashboardScreenerDetailsStyles.builder}>
                <DashboardScreenerBuilderCriteria selectedState={props.selectedState} setNewState={props.setNewState} />
                <div className={DashboardScreenerDetailsStyles.settings_container} ref={resizableRef}>
                    <div className={DashboardScreenerDetailsStyles.vertical_separator_wrapper} ref={resizeHandlerRef}>
                        <div className={DashboardScreenerDetailsStyles.vertical_separator} />
                    </div>
                    <DashboardScreenerBuilderSettings selectedState={props.selectedState} setSelectedState={props.setSelectedState} setNewState={props.setNewState} />
                </div>
            </div>
        </div>
    )
}
    
export default DashboardScreenerBuilder