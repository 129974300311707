import { withProfiler } from "@sentry/react";
import { HelmetProvider } from "react-helmet-async"
import { Provider } from "react-redux"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { PersistGate } from "redux-persist/lib/integration/react"

import AllListeners from "./hooks/all-listeners"
import AppPage from "./pages/app/app"
import DashboardPage from "./pages/app/dashboards/dashboard";
import DashboardOverviewPage from "./pages/app/dashboards/dashboard-overview";
import AppOverviewPage from "./pages/app/overview/app-overview"
import AppProfilePage from "./pages/app/profile/app-profile"
import OnboardingPage from "./pages/onboarding/onboarding"
import ForgotPassword from "./pages/signup-login/forgot-password"
import Login from "./pages/signup-login/login"
import SignUp from "./pages/signup-login/sign-up"
import VerifyEmail from "./pages/signup-login/verify-email"
import ErrorPage from "./pages/utility/error"
import PageNotFoundPage from "./pages/utility/page-not-found"
import Home from "./pages/website/website-home"
import RedirectRoute from "./redirects/redirect-route"
import { persistor, store } from "./store/store"

const App = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <AllListeners />
            <HelmetProvider>
                <BrowserRouter>
                    <Routes>
                        <Route element={<Home />} path="/" />
                        <Route element={<><RedirectRoute /><AppPage /></>} path="/app">
                            <Route element={<AppOverviewPage/>} index />
                            <Route element={<DashboardOverviewPage />} path="dashboards" />
                            <Route element={<DashboardPage />} path="dashboards/:dashboardId" />
                            <Route element={<AppProfilePage />} path="profile" />
                        </Route>
                        <Route element={<><RedirectRoute /><SignUp/></>} path="/sign-up" />
                        <Route element={<><RedirectRoute /><Login/></>} path="/login" />
                        <Route element={<><RedirectRoute /><ForgotPassword/></>} path="/forgot-password" />
                        <Route element={<><RedirectRoute /><VerifyEmail/></>} path="/verify-email" />
                        <Route element={<><RedirectRoute /><OnboardingPage/></>} path="/onboarding" />
                        <Route path="*" element={<PageNotFoundPage />} />
                        <Route element={<ErrorPage />} path="/error" />
                    </Routes>
                </BrowserRouter>
            </HelmetProvider>
        </PersistGate>
    </Provider>
)

export default withProfiler(App);