import { Screener } from "../Screener";

export const createScreener = (docId: string, userId: string, overrides?: Partial<Screener>): Screener => ({
    id: docId,
    name: "",
    description: "",
    active: true,
    adminUid: userId,
    access: [userId],
    sources: {
        indices: [],
        screeners: [],
        watchlists: []
    },
    states: [],
    ...overrides
})