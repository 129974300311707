import { useMemo } from "react";

import { changeScreenerData, saveScreener } from "../../../../../../../store/features/screener";
import { useAppDispatch, useAppSelector } from "../../../../../../../store/hooks";
import { State } from "../../../../../../../types/Screener";
import BootstrapTooltip from "../../../../../../../utils/components/tooltip/bootstrap-tooltip"
import DashboardScreenerDetailsStyles from "../../DashboardScreenerDetails.module.css"

const updateIntervals: UpdateIntervals = {
    "1s": "second",
    "1m": "minute",
    "10m": "10 minutes",
    "1h": "hour",
    "1d": "day"
}

type UpdateIntervals = {
    [key: string]: string
}

type Props = {
    selectedState: State;
    small: boolean;
}

const BuilderSettingsUpdateInterval: React.FC<Props> = (props) => {
    const selectedIndex = useMemo(() => Object.keys(updateIntervals).findIndex((i) => i === props.selectedState.updateInterval), [props.selectedState.updateInterval])
    const widthPercent = useMemo(() => 100 / Object.keys(updateIntervals).length, [])
    
    const dispatch = useAppDispatch();

    const screener = useAppSelector((state) => state.screener)   

    const stateIndex = useMemo(() => screener.data.states.findIndex((state) => state.id === props.selectedState.id), [screener.data.states, props.selectedState.id])

    const setSelectedState = (interval: string) => {
        if (stateIndex > -1 && interval !== props.selectedState.updateInterval) {
            const newState: State = {
                ...props.selectedState,
                updateInterval: interval
            }
            const states = [...screener.data.states];
            states.splice(stateIndex, 1, newState);
            dispatch(changeScreenerData({
                ...screener.data,
                states,
            }));
            dispatch(saveScreener());
        }
    }
    
    return (
        <div className={DashboardScreenerDetailsStyles.settings_content_row} style={{ flexDirection: props.small ? "column" : "row" }}>
            <div className={DashboardScreenerDetailsStyles.settings_row_left}>
                <span className={DashboardScreenerDetailsStyles.settings_row_title} style={{ maxWidth: props.small ? "none" : "120px" }}>Update interval:</span>
                <BootstrapTooltip title="Learn more about update intervals">
                    <svg viewBox="0 0 1024 1024" className={DashboardScreenerDetailsStyles.settings_help_icon}>
                        <path d="M642 480q40-40 40-96 0-70-50-120t-120-50-120 50-50 120h84q0-34 26-60t60-26 60 26 26 60-26 60l-52 54q-50 54-50 120v22h84q0-66 50-120zM554 810v-84h-84v84h84zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z" />
                    </svg>
                </BootstrapTooltip>
            </div>
            <div className={DashboardScreenerDetailsStyles.settings_row_right}>
                <div className={DashboardScreenerDetailsStyles.toggle_switch_big_multi}>
                    <div className={DashboardScreenerDetailsStyles.toggle_big_background_multi} style={{ width: `calc(${widthPercent}% - 8px)`, left: `calc(${selectedIndex * widthPercent}% + 8px - ${selectedIndex * 2}px)` }} />
                    {Object.keys(updateIntervals).map((interval, index) => (
                        <div className={DashboardScreenerDetailsStyles.toggle_big_item_multi} style={{ width: `calc(${widthPercent}% - 6px)`, backgroundColor: props.selectedState.updateInterval === interval ? "transparent" : "" }} onClick={() => setSelectedState(interval)} key={index}>
                            <span className={DashboardScreenerDetailsStyles.toggle_big_item_multi_text} style={{ color: props.selectedState.updateInterval === interval ? "rgb(var(--dl-color-black))" : "" }}>{interval}</span>
                        </div>
                    ))}
                </div>
                <span className={DashboardScreenerDetailsStyles.settings_row_subtext}>The screener updates every {updateIntervals[props.selectedState.updateInterval]}.</span>
            </div>
        </div>
    )
}
                
export default BuilderSettingsUpdateInterval