import './CriteriaTimePickerStyles.css'

import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { useRef } from 'react';

type Props = {
    time: string;
    setTime: (time: string) => void;
}

const CriteriaTimePicker: React.FC<Props> = (props) => {
    const timeRef = useRef<HTMLDivElement | null>(null);

    const timeChange = (timeString:string) => {
        props.setTime(timeString)
        timeRef.current?.blur();
    }

    return (
        <div className="criteria_time_picker">
            <TimePicker
                allowClear={false}
                popupClassName="date-picker"
                value={props.time !== "" ? dayjs(props.time, "HH:mm:ss") : undefined}
                onChange={(time, timeString) => {
                    timeChange(timeString)
                }}
                placeholder="Set Time"
                ref={timeRef}
                size="small"
                getPopupContainer={() => {
                    const el = document.getElementById("criteria-popup-container")
                    if (el !== null) {
                        return el
                    }
                    return document.body
                }}
            />
        </div>
    )
}

export default CriteriaTimePicker