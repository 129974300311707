import { useNavigate } from "react-router-dom";

import { createNewDashboard } from "../../../../../store/features/dashboard";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import sortDashboardsByName from "../../../../../types/utils/sort-dashboards-by-name";
import AppTopNavMiddleStyles from "../AppTopNavMiddleStyles.module.css";

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setPopup: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
    styles: { [key: string]: React.CSSProperties };
    attributes: {
        [key: string]: {
            [key: string]: string;
        } | undefined;
    }
}

const AppTopNavButtonDashboardMenu: React.FC<Props> = (props) => {
    const allDashboards = useAppSelector((state) => state.allDashboards)

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const buttonClickDashboardOverview = () => {
        navigate(`/app/dashboards`);
        props.setIsOpen(false);
    }

    const buttonClickNewDashboard = async () => {
        try {
            const res = await dispatch(createNewDashboard({
                name: `Dashboard ${Object.keys(allDashboards.dashboards).length + 1}`,
            })).unwrap();
            if (res) {
                navigate(`/app/dashboards/${res.id}`);
            }
        } catch (error) {
            navigate(`/app/dashboards`);
        }
        props.setIsOpen(false);
    }

    const buttonClickDashboard = (id: string) => {
        navigate(`/app/dashboards/${id}`);
        props.setIsOpen(false);
    }

    if (props.isOpen) {
        return (
            <div className={AppTopNavMiddleStyles.middle_item_popup_container} ref={props.setPopup} style={props.styles.popper} {...props.attributes.popper}>
                <div className={AppTopNavMiddleStyles.middle_item_popup}>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_top} onClick={buttonClickDashboardOverview}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_top_title}>Dashboard</span>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_top_text}>Indispensable. For the best investors and traders. Adapted to your needs!</span>
                        <div className={AppTopNavMiddleStyles.middle_item_popup_top_button} onClick={async () => {await buttonClickNewDashboard()}}>
                            <span className={AppTopNavMiddleStyles.middle_item_popup_top_button_title}>Create Dashboard</span>
                        </div>
                    </div>
                    {Object.keys(allDashboards.dashboards).length > 0 ? (
                        <>
                            <div className={AppTopNavMiddleStyles.middle_item_popup_separator} />
                            <div className={AppTopNavMiddleStyles.middle_item_popup_scroll_wrapper}>
                                {sortDashboardsByName(allDashboards.dashboards).map((id) => {
                                    const dashboard = allDashboards.dashboards[id]
                                    if (dashboard) {
                                        return (
                                            <div className={AppTopNavMiddleStyles.middle_item_popup_button} key={id} onClick={() => buttonClickDashboard(id)}>
                                                <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>{dashboard.name}</span>
                                            </div>
                                        )
                                    }
                                    return null
                                })}
                            </div>
                        </>
                    ) : null}
                    <div className={AppTopNavMiddleStyles.middle_item_popup_separator} />
                    <div className={AppTopNavMiddleStyles.middle_item_popup_button}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>Momentum Daytrading</span>
                    </div>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_button}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>Large Cap Swingtrading</span>
                    </div>
                    <div className={AppTopNavMiddleStyles.middle_item_popup_separator} />
                    <div className={AppTopNavMiddleStyles.middle_item_popup_button}>
                        <span className={AppTopNavMiddleStyles.middle_item_popup_button_text}>Template Center</span>
                        <svg viewBox="0 0 1024 1024" className={AppTopNavMiddleStyles.middle_item_popup_button_icon}>
                            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z" />
                        </svg>
                    </div>
                </div>
            </div>
        )
    }
    return null
}

export default AppTopNavButtonDashboardMenu